export type Language = 'ru' | 'en';

export const englishLang: Language = 'en';
export const russianLang: Language = 'ru';
export const defaultLang: Language = englishLang;

export const languages: Language[] = [englishLang, russianLang];

/**
 * Возвращает один из поддерживаемых языков
 *
 * Если `langString` представляет собой поддерживаемый язык,
 * то возвращает её.
 * Если `langString` не является поддерживаемым языком, возвращает
 * стандартный.
 */
export function parseLangString(langString: string): Language {
  if (languages.includes(langString as Language)) {
    return langString as Language;
  }
  return defaultLang;
}

export function isValidLang(lang: string): boolean {
  return lang && languages.some((l) => l === lang);
}

export const getNavigatorLanguage = () => {
  if (typeof navigator === 'undefined') {
    return 'en';
  }
  if (navigator.languages && navigator.languages.length) {
    return navigator.languages[0];
  } else {
    return (
      (navigator as any).userLanguage ||
      navigator.language ||
      (navigator as any).browserLanguage ||
      'en'
    );
  }
};

export function normalizeLanguage(lang: string): Language {
  if (lang.startsWith('ru')) {
    return 'ru';
  }
  if (lang.startsWith('en')) {
    return 'en';
  }
  return defaultLang;
}
