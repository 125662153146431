import { Injectable } from '@angular/core';
import {
  StoriesDialogComponent,
  StoriesDialogData,
} from './stories-dialog/stories-dialog.component';
import { Story } from '@core/redux/story/story.model';
import { MatDialog } from '@angular/material/dialog';

@Injectable({
  providedIn: 'root',
})
export class StoriesDialogService {
  constructor(private readonly dialog: MatDialog) {}

  /**
   * Открыть диалог историй
   *
   * @param stories показываются переданные истории, если их нет, то их основного потока
   */
  open(stories?: Story[]) {
    const data: StoriesDialogData = { stories };
    return this.dialog.open(StoriesDialogComponent, {
      backdropClass: 'black-backdrop',
      data,
      maxHeight: '100vh',
      maxWidth: '100vw',
    });
  }
}
