import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { TagInterface } from '@core/models/tag.interface';
import { createSelector } from '@ngrx/store';
import { TagActions, TagActionTypes } from '@core/redux/tag/tag.actions';

export interface State extends EntityState<TagInterface> {
  ids: string[];
  loading: boolean;
  errors: any;
}

export const initialState: State = {
  ids: [],
  loading: true,
  entities: {},
  errors: {},
};

export const adapter: EntityAdapter<TagInterface> = createEntityAdapter<
  TagInterface
>({
  selectId: (tag: TagInterface) => tag.id,
  sortComparer: false,
});

export function reducer(state = initialState, action: TagActions): State {
  switch (action.type) {
    case TagActionTypes.TagLoad:
      return Object.assign({}, state, {
        loading: true,
      });

    case TagActionTypes.TagLoadSuccess:
      return adapter.setMany(
        action.payload,
        Object.assign({}, state, {
          loading: false,
        }),
      );

    case TagActionTypes.TagLoadFailure:
      return Object.assign({}, state, {
        loading: false,
        errors: action.payload.error,
      });

    default:
      return state;
  }
}

export const getEntitiesObjects = (state: State) => state.entities;
export const getIds = (state: State) => state.ids;
export const getLoading = (state: State) => state.loading;

export const getAll = createSelector(
  getEntitiesObjects,
  getIds,
  (entities, ids) => ids.map((id: string) => entities[id]),
);

export const getErrors = (state: State) => state.errors;
