import { Injectable } from '@angular/core';
import { InfoPopupRef, InfoPopupService } from '@ui/info-popup';
import { NotificationsComponent } from './notifications.component';

export interface NotificationsPopupResult {
  navigatedToDownloads?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class NotificationsPopupService {
  private openedInstance?: InfoPopupRef<
    NotificationsComponent,
    NotificationsPopupResult | undefined
  >;
  constructor(private readonly infoPopupService: InfoPopupService) {}

  /**
   * Открывает попап оповещений, если еще не открыт
   *
   * Возвращает кортеж из двух элементов:
   *
   * 1. Был ли открыт попап в результате выполнения метода?
   * `true` означает, что был открыт новый попап. `false` означает, что попап был открыт до вызова метода.
   *
   * 2. Экземпляр открытого InfoPopupRef
   */
  open(): [
    boolean,
    InfoPopupRef<NotificationsComponent, NotificationsPopupResult | undefined>,
  ] {
    if (this.openedInstance) {
      return [false, this.openedInstance];
    }
    const infoPopupRef = this.infoPopupService.open(NotificationsComponent, {
      width: 460,
    });
    infoPopupRef
      .afterClosed()
      .subscribe(() => (this.openedInstance = undefined));
    infoPopupRef
      .afterOpened()
      .subscribe(() => (this.openedInstance = infoPopupRef));
    return [true, infoPopupRef];
  }

  close() {
    this.openedInstance?.close();
  }

  /**
   * Открывает или закрывает попап оповещений
   *
   * Если был открыт, то закрывает. Если был закрыт, то открывает.
   *
   * Возвращает кортеж из двух элементов:
   *
   * 1. Состояние попап после выполнения метода.
   * `true` означает, что попап теперь открыт. `false` означает, что теперь попап закрыт.
   *
   * 2. Экземпляр только что закрытого или только что открытого InfoPopupRef.
   */
  toggle(): [
    boolean,
    InfoPopupRef<NotificationsComponent, NotificationsPopupResult | undefined>,
  ] {
    if (this.openedInstance) {
      this.openedInstance.close();
      return [false, this.openedInstance];
    } else {
      return this.open();
    }
  }
}
