import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UiFilePreviewComponent } from '@ui/ui-file-preview/ui-file-preview.component';
import { BackgroundImageModule } from '@shared/pipes/background-image/background-image.module';

@NgModule({
  declarations: [UiFilePreviewComponent],
  exports: [UiFilePreviewComponent],
  imports: [CommonModule, BackgroundImageModule],
})
export class UiFilePreviewModule {}
