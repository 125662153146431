import { Injectable } from '@angular/core';
import * as amplitude from 'amplitude-js';
import { environment } from '../../../environments/environment';
import { IdentityInterface } from '@core/models/identity.interface';

@Injectable({
  providedIn: 'root',
})
export class AmplitudeService {
  constructor() {
    if (environment.amplitude !== false) {
      amplitude.getInstance().init(environment.amplitude);
    }
  }

  setUser(user: IdentityInterface) {
    if (!environment.amplitude) {
      return;
    }
    amplitude.getInstance().setUserId(user.id);
    const identify = new amplitude.Identify()
      .set('email', user.email)
      .set('firstName', user.firstName)
      .set('isTest', user.isTest ? 1 : 0)
      .set('isActive', user.isActive ? 1 : 0)
      .set('isConfirmation', user.isConfirmation ? 1 : 0)
      .set('isNotificationEmail', user.isNotificationEmail ? 1 : 0)
      .set('notificationTime', user.notificationTime)
      .set('language', user.language)
      .set('connectedSocialAccounts', user.connectedSocialAccounts);
    amplitude.getInstance().identify(identify);
  }

  logEvent(event: string) {
    if (!environment.amplitude) {
      return;
    }
    amplitude.getInstance().logEvent(event);
  }
}
