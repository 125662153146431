import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UiFilePreviewFormComponent } from '@ui/ui-file-preview-form/ui-file-preview-form.component';
import { UiFilePreviewModule } from '@ui/ui-file-preview/ui-file-preview.module';
import { UiIconModule } from '@ui/ui-icon/ui-icon.module';
import { ButtonsModule } from '@ui/buttons/buttons.module';
import { UiTooltipModule } from '@ui/ui-tooltip/ui-tooltip.module';
import { TranslateModule } from '@ngx-translate/core';
import { TooltipModule } from '@ui/tooltip';
import { TextTooltipModule } from '@tooltips';

@NgModule({
  declarations: [UiFilePreviewFormComponent],
  exports: [UiFilePreviewFormComponent],
  imports: [
    CommonModule,
    UiFilePreviewModule,
    UiIconModule,
    ButtonsModule,
    UiTooltipModule,
    TranslateModule,
    TooltipModule,
    TextTooltipModule,
  ],
})
export class UiFilePreviewFormModule {}
