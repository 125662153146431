import { Injectable } from '@angular/core';
import { UiFilePreviewComponent } from './ui-file-preview.component';
import { ImagePreloadService } from '@core/services/images/image-preload.service';

@Injectable({
  providedIn: 'root',
})
export class PreviewPreloadService {
  static getImagesSortedBySize(components: UiFilePreviewComponent[]): string[] {
    return components.reduce(
      (acc, comp) => {
        const smallest = comp.image.sizes[comp.preloadSizes[0]];
        const other = comp.preloadSizes
          .slice(1)
          .map(size => comp.image.sizes[size]);
        return [smallest, ...acc, ...other];
      },
      [] as string[],
    );
  }

  private readonly components: Set<UiFilePreviewComponent> = new Set();
  private readonly intersectionObserver = new IntersectionObserver(
    entries => this.receiveIntersectionChanges(entries),
    { rootMargin: '80px' },
  );

  get componentsCount(): number {
    return this.components.size;
  }

  constructor(private imagePreloadService: ImagePreloadService) {}

  registerComponent(component: UiFilePreviewComponent) {
    this.components.add(component);
    this.intersectionObserver.observe(component.elementRef.nativeElement);
  }

  unregisterComponent(component: UiFilePreviewComponent) {
    this.components.delete(component);
    this.intersectionObserver.unobserve(component.elementRef.nativeElement);
  }

  private receiveIntersectionChanges(entries: IntersectionObserverEntry[]) {
    const intersectingComponents = this.getIntersectingComponents(entries);
    const sortedImages = PreviewPreloadService.getImagesSortedBySize(
      intersectingComponents,
    );

    intersectingComponents.forEach(c => c.markAsIntersected());
    sortedImages.forEach(img => this.imagePreloadService.preloadImage(img));
  }

  private getIntersectingComponents(
    entries: IntersectionObserverEntry[],
  ): UiFilePreviewComponent[] {
    const intersectingComponents: UiFilePreviewComponent[] = [];
    const componentsArray = Array.from(this.components.values());
    entries
      .filter(entry => entry.isIntersecting)
      .forEach(entry => {
        for (const component of componentsArray) {
          if (component.elementRef.nativeElement === entry.target) {
            intersectingComponents.push(component);
            break;
          }
        }
      });
    return intersectingComponents;
  }
}
