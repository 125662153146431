import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { of } from 'rxjs/internal/observable/of';
import { catchError, exhaustMap, map, switchMap, tap } from 'rxjs/operators';
import {
  CategoryActionTypes,
  CategoryCreate,
  CategoryCreateFailure,
  CategoryCreateSuccess,
  CategoryDelete,
  CategoryDeleteFailure,
  CategoryDeleteSuccess,
  CategoryLoad,
  CategoryLoadFailure,
  CategoryLoadSuccess,
  CategoryUpdate,
  CategoryUpdateFailure,
  CategoryUpdateSuccess,
} from './category.actions';
import { CategoryService } from '@core/services/category.service';
import { CategoryQueryModel } from '@core/models/category.interface';
import { AmplitudeService } from '@core/services/amplitude.service';
import { CategoryFormDialogService } from '@dialogs/category-form';
/**
 * Effect для работы с категориями
 */
@Injectable()
export class CategoryEffects {
  /**
   * Создание новую категорию
   */
  @Effect()
  search$ = this.actions$.pipe(
    ofType(CategoryActionTypes.CategoryLoad),
    map((action: CategoryLoad) => action.payload),
    switchMap((query: CategoryQueryModel) => {
      return this.categoryService.search(query).pipe(
        map(categories => new CategoryLoadSuccess(categories)),
        catchError(errors => of(new CategoryLoadFailure(errors))),
      );
    }),
  );

  /**
   * Создание новую категорию
   */
  @Effect()
  create$ = this.actions$.pipe(
    ofType(CategoryActionTypes.CategoryCreate),
    map((action: CategoryCreate) => action.payload),
    exhaustMap(model => {
      return this.categoryService.create(model).pipe(
        map(user => new CategoryCreateSuccess(user)),
        catchError(errors => of(new CategoryCreateFailure(errors))),
      );
    }),
  );

  /**
   * Обновление категории
   */
  @Effect()
  update$ = this.actions$.pipe(
    ofType(CategoryActionTypes.CategoryUpdate),
    map((action: CategoryUpdate) => action.payload),
    exhaustMap(model => {
      return this.categoryService.update(model).pipe(
        map(user => new CategoryUpdateSuccess(user)),
        catchError(errors => of(new CategoryUpdateFailure(errors))),
      );
    }),
  );

  @Effect({ dispatch: false })
  updateSuccess$ = this.actions$.pipe(
    ofType(
      CategoryActionTypes.CategoryUpdateSuccess,
      CategoryActionTypes.CategoryCreateSuccess,
    ),
    tap(() => this.categoryFormDialogService.close()),
  );

  @Effect()
  delete$ = this.actions$.pipe(
    ofType(CategoryActionTypes.CategoryDelete),
    map((action: CategoryDelete) => action.payload),
    exhaustMap((payload: any) => {
      return this.categoryService.destroy(payload).pipe(
        map(_ => new CategoryDeleteSuccess(payload.id)),
        catchError(errors => of(new CategoryDeleteFailure(errors))),
      );
    }),
  );

  @Effect({ dispatch: false })
  createSuccess$ = this.actions$.pipe(
    ofType(CategoryActionTypes.CategoryCreateSuccess),
    tap(() => {
      this.amplitudeService.logEvent('Категория создана');
    }),
  );

  constructor(
    private readonly actions$: Actions,
    private readonly amplitudeService: AmplitudeService,
    private readonly categoryFormDialogService: CategoryFormDialogService,
    private readonly categoryService: CategoryService,
  ) {}
}
