import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OptionTargetComponent } from './option-target.component';
import { UiIconModule } from '@ui/ui-icon/ui-icon.module';
import { ImagePreviewModule } from '@shared/pipes/image-preview/image-preview.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [OptionTargetComponent],
  exports: [OptionTargetComponent],
  imports: [CommonModule, UiIconModule, ImagePreviewModule, TranslateModule],
})
export class UiOptionTargetModule {}
