import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import { Story } from '@core/redux/story/story.model';
import { getEmptyNote } from '@core/models/note.interface';
import { MatDialogRef } from '@angular/material/dialog';
import { StoriesDialogComponent } from '@dialogs/stories';
import { Router } from '@angular/router';
import { YoutubeDialogService } from '@dialogs/youtube';
import { NoteDialogService } from '@dialogs/note';
import { GlobalErrorHandler } from '@core/error-handler';
import { delay, take } from 'rxjs/operators';
import { interval, Subscription } from 'rxjs';

@Component({
  selector: 'app-story',
  templateUrl: './story.component.html',
  styleUrls: ['./story.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StoryComponent implements AfterViewInit, OnDestroy {
  @Input()
  story: Story;

  @Output()
  stoped = new EventEmitter();

  @Output()
  resumed = new EventEmitter();

  /**
   * В HammerJS не работает stopPropagation,
   * поэтому в родительском компоненте нельзя слушать события tap,
   * потому что тогда они будут срабатывать при нажатии на кнопку.
   */
  @Output()
  tapped = new EventEmitter<{ center: { x: number } }>();

  @ViewChild('videoElement')
  videoElRef: ElementRef<HTMLVideoElement>;

  /**
   * Prevent AbortError
   *
   * AbortError: The play() request was interrupted by a call to pause()
   * https://goo.gl/LdLk22
   */
  private videoPromise?: Promise<void>;
  private paused = false;
  private volumeSub?: Subscription;

  constructor(
    private readonly dialogRef: MatDialogRef<StoriesDialogComponent>,
    private readonly errorHandler: GlobalErrorHandler,
    private readonly noteDialogService: NoteDialogService,
    private readonly router: Router,
    private readonly youtubeDialogService: YoutubeDialogService,
  ) {}

  ngOnDestroy() {
    this.volumeSub?.unsubscribe();
  }

  ngAfterViewInit() {
    if (!this.videoElRef) {
      return;
    }
    const video = this.videoElRef.nativeElement;
    const numberOfSteps = 10;
    const oldVolume = video.volume;
    const step = oldVolume / numberOfSteps;
    video.volume = 0;
    this.volumeSub = interval(30)
      .pipe(take(10))
      .subscribe(() => {
        video.volume += step;
      });
    // В тестах иногда выдает ошибку
    this.videoPromise = video.play();
    if (
      this.videoPromise &&
      this.videoPromise.then &&
      this.videoPromise.catch
    ) {
      this.videoPromise
        .then(() => (this.videoPromise = undefined))
        .catch((e) => this.errorHandler.handleError(e));
    }
  }

  onMouseup() {
    this.resume();
  }

  onMousedown() {
    this.stop();
  }

  onButtonClick(event: MouseEvent) {
    event.stopPropagation();
    if (this.story.buttonCallback === 'showVideo') {
      this.stop();
      this.youtubeDialogService
        .open({ videoId: this.story.youtubeId })
        .afterClosed()
        .subscribe(() => this.resume());
    } else if (
      this.story.buttonCallback === 'createNote' ||
      this.story.buttonCallback === 'createNoteAndCopyText'
    ) {
      this.dialogRef
        .afterClosed()
        .pipe(delay(300))
        .subscribe(() => {
          const note =
            this.story.buttonCallback === 'createNote'
              ? ''
              : this.story.text + '\n';
          this.noteDialogService.create(
            { ...getEmptyNote(), note },
            false,
            this.story,
          );
        });
      this.dialogRef.close();
    } else if (this.story.buttonCallback === 'createTarget') {
      this.dialogRef.close();
      this.router.navigate(['/target']);
    } else if (this.story.buttonCallback === 'payment') {
      this.dialogRef.close();
      this.router.navigate(['/payment']);
    } else if (this.story.buttonCallback === 'createTask') {
      this.dialogRef
        .afterClosed()
        .pipe(delay(300))
        .subscribe(() => {
          this.noteDialogService.create(
            { ...getEmptyNote(), isTask: true },
            false,
            this.story,
          );
        });
      this.dialogRef.close();
    } else if (this.story.buttonCallback === 'closeStory') {
      this.dialogRef.close();
    }
  }

  private resume() {
    if (!this.paused) {
      return;
    }
    this.paused = false;
    this.resumed.emit();
    // В тестах иногда выдает ошибку
    this.videoElRef.nativeElement
      .play()
      .catch((e) => this.errorHandler.handleError(e));
  }

  private stop() {
    if (this.paused) {
      return;
    }
    const promise = this.videoPromise || Promise.resolve();
    promise.then(() => {
      this.paused = true;
      this.videoElRef.nativeElement.pause();
      this.stoped.emit();
    });
  }
}
