import { Injectable } from '@angular/core';
import { ApiService } from '@core/services/api.service';
import { TagInterface } from '@core/models/tag.interface';
import { Observable } from 'rxjs/internal/Observable';
import { of } from 'rxjs/internal/observable/of';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class TagService extends ApiService {
  search(): Observable<TagInterface[]> {
    return this.http
      .get<TagInterface[]>(this.getApiUrl(`tags`))
      .pipe(catchError(() => of([])));
  }
}
