<div
  class="option__item"
  [ngClass]="{ option__item_active: active, option__item_focused: isFocused }"
>
  <span
    class="option__text body-text body-text_style_2"
    #contentWrapper
    (click)="onClick($event)"
  >
    <ng-content></ng-content>
  </span>
  <ui-icon
    *ngIf="editable"
    (click)="onEdit($event)"
    class="ui-icon ui-icon_hover_pencil option__icon option__icon-edit"
    name="edit"
  >
  </ui-icon>
  <ui-icon class="ui-icon option__icon option__icon-done" name="done">
  </ui-icon>
</div>
