import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SafeLinkyPipe } from './safe-linky.pipe';

@NgModule({
  declarations: [SafeLinkyPipe],
  exports: [SafeLinkyPipe],
  imports: [CommonModule],
})
export class SafeLinkyModule {}
