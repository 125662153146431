import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { of } from 'rxjs/internal/observable/of';
import { catchError, exhaustMap, map } from 'rxjs/operators';
import {
  InvoiceActionTypes,
  InvoiceCreate,
  InvoiceCreateFailure,
  InvoiceCreateSuccess,
  InvoiceLoadFailure,
  InvoiceLoadSuccess,
} from './invoice.actions';
import { InvoiceService } from '@core/services/invoice.service';

/**
 * Effect для работы с категориями
 */
@Injectable()
export class InvoiceEffects {
  @Effect()
  search$ = this.actions$.pipe(
    ofType(InvoiceActionTypes.InvoiceLoad),
    exhaustMap(() => {
      return this.invoiceService.search().pipe(
        map(invoices => new InvoiceLoadSuccess(invoices)),
        catchError(errors => of(new InvoiceLoadFailure(errors))),
      );
    }),
  );

  @Effect()
  create$ = this.actions$.pipe(
    ofType(InvoiceActionTypes.InvoiceCreate),
    map((action: InvoiceCreate) => action.payload),
    exhaustMap(model => {
      return this.invoiceService.create(model).pipe(
        map(invoice => new InvoiceCreateSuccess(invoice)),
        catchError(errors => of(new InvoiceCreateFailure(errors))),
      );
    }),
  );

  constructor(
    private actions$: Actions,
    private invoiceService: InvoiceService,
  ) {}
}
