import { Injectable } from '@angular/core';
import { ApiService } from '@core/services/api.service';
import { Observable } from 'rxjs/internal/Observable';
import { TargetImageInterface } from '@core/models/target-image.interface';

/**
 * Сервис для работы с апи типами целей
 */
@Injectable({
  providedIn: 'root',
})
export class TargetImageService extends ApiService {
  /**
   * Получить с сервера все типы
   */
  search(): Observable<TargetImageInterface[]> {
    return this.http.get<TargetImageInterface[]>(
      this.getApiUrl(`targets/default-images`),
    );
  }
}
