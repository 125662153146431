import {
  ComponentFactoryResolver,
  Directive,
  ElementRef,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewContainerRef,
} from '@angular/core';

import { AutocompleteTagsListComponent } from './autocomplete-tags-list.component';
import { getCaretPosition, insertValue, setCaretPosition } from './utils';

export interface Tag {
  name: string;
  count: number;
}

const KEY_BACKSPACE = 8;
const KEY_TAB = 9;
const KEY_ENTER = 13;
const KEY_SHIFT = 16;
const KEY_ESCAPE = 27;
const KEY_SPACE = 32;
const KEY_LEFT = 37;
const KEY_UP = 38;
const KEY_RIGHT = 39;
const KEY_DOWN = 40;
const KEY_2 = 50;

@Directive({ selector: '[autocompleteTags]' })
export class AutocompleteTagsDirective implements OnInit, OnChanges {
  private active = true;
  private maxItems = 0;
  private searchString: string;
  private searchList: AutocompleteTagsListComponent;
  private startPos: number;
  private startNode;
  private stopSearch: boolean;
  private triggerChar = '#';
  // tslint:disable-next-line:variable-name
  private _tags: Tag[];
  private keyReactions = {
    [KEY_TAB]: (pos: number) => this.selectTag(pos),
    [KEY_ENTER]: (pos: number) => this.selectTag(pos),
    [KEY_ESCAPE]: (pos: number) => this.stop(),
    [KEY_DOWN]: (pos: number) => this.searchList.activateNextTag(),
    [KEY_UP]: (pos: number) => this.searchList.activatePreviousTag(),
    // : (pos: number) => ,
  };
  @Input()
  set autocompleteTags(tags: Tag[]) {
    this._tags = tags;
  }
  get tags(): Tag[] {
    return this._tags;
  }

  @Input()
  set autocompleteTagsActive(v: boolean) {
    this.active = v;
  }

  constructor(
    private elementRef: ElementRef,
    private componentResolver: ComponentFactoryResolver,
    private viewContainerRef: ViewContainerRef,
  ) {}

  ngOnInit() {
    if (this.searchList && !this.searchList.hidden) {
      this.updateSearchList();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.tags) {
      this.ngOnInit();
    }
  }

  @HostListener('blur', ['$event'])
  blurHandler(event: any) {
    this.stopEvent(event);
    this.stop();
  }

  @HostListener('focus', ['$event'])
  focusHandler(event: any) {
    const pos = getCaretPosition(this.elementRef.nativeElement);
    const value: string = this.elementRef.nativeElement.value;
    if (value[pos - 1] === this.triggerChar && this.active) {
      this.showSuggestions(pos - 1);
    }
  }

  @HostListener('keydown', ['$event'])
  keyHandler(event: any) {
    const nativeElement: HTMLInputElement = this.elementRef.nativeElement;
    const val: string = nativeElement.value;
    let pos = getCaretPosition(nativeElement);
    const charPressed = this.whichCharPressed(event);
    if (this.wasTagClicked(event) && pos < this.startPos) {
      // put caret back in position prior to contenteditable menu click
      pos = this.startNode.length;
      setCaretPosition(this.startNode, pos);
    }
    if (charPressed === this.triggerChar) {
      this.showSuggestions(pos);
      return;
    }
    if (this.startPos < 0 || this.stopSearch) {
      return;
    }
    if (pos <= this.startPos) {
      this.searchList.hidden = true;
      return;
    }
    // игнорируем одинокий Shift
    const normalSituation =
      event.keyCode !== KEY_SHIFT &&
      !event.metaKey &&
      // !event.altKey && мешали смене языка
      // !event.ctrlKey &&
      pos > this.startPos;
    if (normalSituation) {
      if (event.keyCode === KEY_SPACE) {
        this.startPos = -1;
      } else if (event.keyCode === KEY_BACKSPACE && pos > 0) {
        pos--;
        if (pos === 0) {
          this.stopSearch = true;
        }
        this.searchList.hidden = this.stopSearch;
      } else if (!this.searchList.hidden) {
        const reacted = this.reactToSpecialKeys(pos, event);
        if (reacted) {
          return false;
        }
      }

      if (event.keyCode === KEY_LEFT || event.keyCode === KEY_RIGHT) {
        this.stopEvent(event);
        return false;
      } else {
        // нажата не какая-то специальная клавиша
        this.updateSearchQuery(pos, val, charPressed, event);
      }
    }
  }

  /**
   * Запустить событие, чтобы запустить ангуляровский change detection
   */
  private fireEvent() {
    if ('createEvent' in document) {
      const evt = document.createEvent('HTMLEvents');
      evt.initEvent('input', false, true);
      this.elementRef.nativeElement.dispatchEvent(evt);
    }
  }

  private stop() {
    if (this.searchList) {
      this.searchList.hidden = true;
    }
    this.stopSearch = true;
  }

  /**
   * Вызывает keyHandler с событием,
   * которое keyHandler сможет определить, как искуссвенное.
   */
  private onTagClick() {
    this.elementRef.nativeElement.focus();
    const fakeKeydown = { keyCode: KEY_ENTER, wasClick: true };
    this.keyHandler(fakeKeydown);
  }

  /**
   * Помогает избавиться от большого кол-ва трудночитаемых if.
   * Отыскивает нужную функцию по ключу-keyCode'у и вызывает её.
   * Возвращает true, если функция была найдена.
   */
  private reactToSpecialKeys(pos: number, event: any): boolean {
    const reaction = this.keyReactions[event.keyCode];
    if (reaction) {
      this.stopEvent(event);
      reaction(pos);
      return true;
    }
    return false;
  }

  private selectTag(pos: number) {
    this.searchList.hidden = true;
    insertValue(
      this.elementRef.nativeElement,
      this.startPos,
      pos,
      this.triggerChar + this.searchList.activeItem.name,
    );
    this.fireEvent();
    this.startPos = -1;
  }

  private showSuggestions(pos: number) {
    this.startPos = pos;
    this.startNode = window.getSelection().anchorNode;
    this.stopSearch = false;
    this.searchString = null;
    this.prepareListComponent();
    this.updateSearchList();
  }

  private stopEvent(event: Event) {
    if (event instanceof KeyboardEvent) {
      event.preventDefault();
      event.stopPropagation();
      event.stopImmediatePropagation();
    }
  }

  private prepareListComponent() {
    if (!this.searchList) {
      const componentFactory = this.componentResolver.resolveComponentFactory(
        AutocompleteTagsListComponent,
      );
      const componentRef = this.viewContainerRef.createComponent(
        componentFactory,
      );
      this.searchList = componentRef.instance;
      this.searchList.position(this.elementRef.nativeElement);
      componentRef.instance.tagClick.subscribe(() => this.onTagClick());
    } else {
      this.searchList.activeIndex = 0;
      this.searchList.position(this.elementRef.nativeElement);
      setTimeout(() => this.searchList.resetScroll());
    }
  }

  private updateSearchList() {
    let matches = [];
    if (this.tags) {
      let objects = this.tags;
      // disabling the search relies on the async operation to do the filtering
      if (this.searchString) {
        const searchStringLowerCase = this.searchString.toLowerCase();
        objects = this.tags.filter(e =>
          e.name.toLowerCase().startsWith(searchStringLowerCase),
        );
      }
      matches = objects;
      if (this.maxItems > 0) {
        matches = matches.slice(0, this.maxItems);
      }
    }
    // обновление элементов списка
    if (this.searchList) {
      this.searchList.tags = matches;
      this.searchList.hidden = matches.length === 0;
    }
  }

  private updateSearchQuery(
    pos: number,
    val: string,
    charPressed: string,
    event: any,
  ) {
    const newChar = event.keyCode === KEY_BACKSPACE ? '' : charPressed;
    const oldSearch = val.substring(this.startPos + 1, pos);
    const nextSearch = oldSearch + newChar;
    this.searchString = nextSearch;
    this.updateSearchList();
  }

  private wasTagClicked(event: any): boolean {
    return event.keyCode === KEY_ENTER && event.wasClick;
  }

  /**
   * Определяет, какая буква была нажата.
   */
  private whichCharPressed(event: any): string {
    if (event.key) {
      return event.key;
    }
    const charCode = event.which || event.keyCode;
    if (!event.shiftKey && (charCode >= 65 && charCode <= 90)) {
      return String.fromCharCode(charCode + 32);
    } else if (event.shiftKey && charCode === KEY_2) {
      return this.triggerChar;
    } else {
      // TODO (dmacfarlane) fix this for non-alpha keys
      // http://stackoverflow.com/questions/2220196/how-to-decode-character-pressed-from-jquerys-keydowns-event-handler?lq=1
      return String.fromCharCode(event.which || event.keyCode);
    }
  }
}
