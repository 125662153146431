import { OverlayRef } from '@angular/cdk/overlay';
import { TooltipConfig } from './tooltip-config';
import { ComponentRef } from '@angular/core';
import { TooltipComponent } from './tooltip.component';

export class TooltipRef<D> {
  // tslint:disable-next-line:variable-name
  private _componentRef: ComponentRef<TooltipComponent>;

  get componentRef() {
    return this._componentRef;
  }

  constructor(
    public readonly config: TooltipConfig<D>,
    private readonly overlayRef: OverlayRef,
  ) {}

  /**
   * @hidden
   */
  setComponentRef(componentRef: ComponentRef<TooltipComponent>) {
    if (this._componentRef) {
      throw new Error('componentRef is already set');
    }
    this._componentRef = componentRef;
  }

  close() {
    this.overlayRef.detach();
    this.overlayRef.dispose();
  }
}
