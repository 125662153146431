import {
  TariffGroupActions,
  TariffGroupActionTypes,
} from './tariff-group.actions';

export interface State {
  loading: boolean;
  entity: any;
}

export const initialState: State = {
  loading: false,
  entity: undefined,
};

export function reducer(
  state = initialState,
  action: TariffGroupActions,
): State {
  switch (action.type) {
    case TariffGroupActionTypes.GetDefaultAction: {
      return { ...state, loading: true };
    }

    case TariffGroupActionTypes.GetDefaultActionSuccessAction:
      return {
        ...state,
        entity: action.payload,
        loading: false,
      };

    case TariffGroupActionTypes.GetDefaultActionFailureAction:
      return { ...state, loading: false };

    default:
      return state;
  }
}

export const getTariffGroup = (state: State) => state.entity;
