import {
  Component,
  ChangeDetectionStrategy,
  Inject,
  OnInit,
  ChangeDetectorRef,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface YoutubeDialogData {
  videoId: string;
}

@Component({
  selector: 'app-youtube',
  templateUrl: './youtube.component.html',
  styleUrls: ['./youtube.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class YoutubeComponent implements OnInit {
  @ViewChild('iframeElement', { static: true })
  iframeElement: ElementRef<HTMLIFrameElement>;

  src: string;
  width = Math.min(window.innerWidth, 720);
  height = Math.round(this.width * (9 / 16));
  constructor(
    private readonly changeDetectorRef: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) private readonly data: YoutubeDialogData,
  ) {
    const url = 'https://www.youtube.com/embed/' + this.data.videoId;
    this.src = url;
  }

  ngOnInit() {
    this.changeDetectorRef.detach();

    // Не хочет работать через [src]="src"
    this.iframeElement.nativeElement.src = this.src;

    this.iframeElement.nativeElement.width = this.width.toString();
    this.iframeElement.nativeElement.height = this.height.toString();
  }
}
