import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import {
  getStories,
  getStoriesSuccess,
  getStoriesFailure,
  markStoryAsViewed,
  markStoryAsViewedSuccess,
  markStoryAsViewedFailute,
} from './story.actions';
import { map, switchMap, catchError, mergeMap, tap } from 'rxjs/operators';
import { ApiStoryService } from './api-story.service';
import { of } from 'rxjs';
import { ImagePreloadService } from '@core/services/images/image-preload.service';

@Injectable()
export class StoryEffects {
  getStories$ = createEffect(() =>
    this.actions.pipe(
      ofType(getStories),
      switchMap(() =>
        this.apiStoryService.get().pipe(
          map((stories) => getStoriesSuccess({ stories })),
          catchError((error) => of(getStoriesFailure({ error }))),
        ),
      ),
    ),
  );

  // getStoriesSuccess$ = createEffect(
  //   () =>
  //     this.actions.pipe(
  //       ofType(getStoriesSuccess),
  //       tap((action) =>
  //         action.stories.forEach((s) =>
  //           this.imagePreloader.preloadVideo(s.videoUrl),
  //         ),
  //       ),
  //     ),
  //   { dispatch: false },
  // );

  markStoryAsViews$ = createEffect(() =>
    this.actions.pipe(
      ofType(markStoryAsViewed),
      mergeMap((action) =>
        this.apiStoryService.markAsViewed(action.id).pipe(
          map((story) => markStoryAsViewedSuccess({ story })),
          catchError((error) => of(markStoryAsViewedFailute({ error }))),
        ),
      ),
    ),
  );

  constructor(
    private actions: Actions,
    private apiStoryService: ApiStoryService,
    private imagePreloader: ImagePreloadService,
  ) {}
}
