import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from 'src/environments/environment';
import { DataVersionService } from '@core/services/data-version.service';

const responseDataVersionHeader = 'X-data-version';

@Injectable({
  providedIn: 'root',
})
export class DataVersionInterceptor implements HttpInterceptor {
  constructor(private dataVersionService: DataVersionService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    if (
      !request.url.startsWith(environment.apiUrl) ||
      request.url.startsWith(environment.electron.storage)
    ) {
      return next.handle(request);
    }
    const headers = {
      [responseDataVersionHeader]: this.dataVersionService.version,
    };
    return next.handle(
      request.clone({
        setHeaders: headers,
      }),
    );
  }
}
