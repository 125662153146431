import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { QiwiComponent } from './qiwi.component';
import { MatDialogModule } from '@angular/material/dialog';
import { UiIconModule } from '@ui/ui-icon/ui-icon.module';
import { ButtonsModule } from '@ui/buttons/buttons.module';

@NgModule({
  declarations: [QiwiComponent],
  imports: [CommonModule, MatDialogModule, UiIconModule, ButtonsModule],
  entryComponents: [QiwiComponent],
})
export class QiwiModule {}
