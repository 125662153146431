import { Component, OnInit, Inject } from '@angular/core';
import { INFO_POPUP_DATA, InfoPopupRef } from '@ui/info-popup';

export class ErrorPopupData {
  constructor(
    public readonly header: string,
    public readonly text: string,
    public readonly message: string,
  ) {}
}

@Component({
  selector: 'app-error-popup',
  templateUrl: './error-popup.component.html',
  styleUrls: ['../info-popup.less', './error-popup.component.less'],
})
export class ErrorPopupComponent implements OnInit {
  header: string;
  text: string;
  message: string;
  constructor(
    @Inject(INFO_POPUP_DATA)
    private readonly data: ErrorPopupData,
    private readonly infoPopupRef: InfoPopupRef<ErrorPopupComponent>,
  ) {}

  ngOnInit() {
    this.header = this.data.header;
    this.text = this.data.text;
    this.message = this.data.message;
  }

  onCloseClick() {
    this.infoPopupRef.close();
  }
}
