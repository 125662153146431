import { Component, OnInit, Inject } from '@angular/core';
import { PromoCodeService } from '@core/services/promo-code.service';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface PromocodeDialogData {
  code: string;
}

@Component({
  selector: 'app-promocode-confirm-apply',
  templateUrl: './promocode-confirm-apply.component.html',
  styleUrls: ['./promocode-confirm-apply.component.less'],
})
export class PromocodeConfirmApplyComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    private readonly data: PromocodeDialogData,
    private readonly promoCodeService: PromoCodeService,
  ) {}

  ngOnInit() {}

  onAccept() {
    this.promoCodeService.apply(this.data.code).subscribe(() => {
      location.reload();
    });
  }

  onDiscard() {}
}
