import { Injectable } from '@angular/core';
import { ApiService } from '@core/services/api.service';
import { Observable } from 'rxjs/internal/Observable';
import { TariffGroupInterface } from '@core/models/tariff-group.interface';

@Injectable({
  providedIn: 'root',
})
export class TariffGroupService extends ApiService {
  getDefault(): Observable<TariffGroupInterface> {
    return this.http.get<TariffGroupInterface>(this.getApiUrl(`tariff-groups`));
  }
}
