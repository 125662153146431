import { select, Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import * as fromRoot from '@core/redux/index';
import { showUpdateAlert } from '@core/redux/release/release.actions';
import { Observable } from 'rxjs';
import {
  inElectron,
  receiveFromElectron,
} from 'src/app/helpers/consts/electron';

@Injectable({
  providedIn: 'root',
})
export class ReleaseService {
  constructor(private store: Store<fromRoot.State>) {
    this.subscribeToElectronUpdates();
  }

  /**
   * Сообщает сервису о том, что веб версия обновилась
   *
   * Если не в электроне, то включит показ попапа с просьбой обновиться.
   * В электроне не вызывает никакого эффекта.
   */
  receiveWebUpdate() {
    if (inElectron) {
      return;
    }
    this.store.dispatch(showUpdateAlert());
  }

  /**
   * Нужно ли показывать попап с просьбой обновиться?
   */
  shouldShowUpdateAlert(): Observable<boolean> {
    return this.store.pipe(select(fromRoot.getShouldShowUpdateAlert));
  }

  private subscribeToElectronUpdates() {
    if (inElectron) {
      receiveFromElectron('fromElectron:update-downloaded', () =>
        this.store.dispatch(showUpdateAlert()),
      );
    }
  }
}
