import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs/internal/observable/of';
import { catchError, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { NoteService as NoteApiService } from '@core/services/note.service';
import { NoteQueryModel } from '@core/models/note.interface';
import { AmplitudeService } from '@core/services/amplitude.service';
import { AnalyticsService } from '@core/services/analytics.service';
import {
  noteCreateAction,
  noteCreateFailureAction,
  noteCreateFromBatchPopupAction,
  noteCreateFromBatchPopupFailureAction,
  noteCreateFromBatchPopupSuccessAction,
  noteCreateSuccessAction,
  noteDeleteAction,
  noteDeleteFailureAction,
  noteDeleteSuccessAction,
  noteHardReloadAction,
  noteHardReloadFailureAction,
  noteHardReloadSuccessAction,
  noteLoadAction,
  noteLoadFailureAction,
  noteLoadForReportAction,
  noteLoadForReportFailuteAction,
  noteLoadForReportSuccessAction,
  noteLoadForReportTagAction,
  noteLoadForReportTagFailuteAction,
  noteLoadForReportTagSuccessAction,
  noteLoadForReportTargetAction,
  noteLoadForReportTargetFailuteAction,
  noteLoadForReportTargetSuccessAction,
  noteLoadSuccessAction,
  noteOneLoadAction,
  noteOneLoadFailureAction,
  noteOneLoadSuccessAction,
  noteUpdateAction,
  noteUpdateFailureAction,
  noteUpdateSuccessAction,
} from '@core/redux/note/note.actions';
import { getUserAction } from '@core/redux/auth/auth.actions';
import { TargetLoad } from '@core/redux/goal/target.actions';
import { TagLoad } from '@core/redux/tag/tag.actions';

@Injectable()
export class NoteEffects {
  noteOneLoad$ = createEffect(() =>
    this.actions$.pipe(
      ofType(noteOneLoadAction),
      mergeMap((action) =>
        this.noteApiService.one(action.id).pipe(
          map((note) => noteOneLoadSuccessAction({ note })),
          catchError((error) => of(noteOneLoadFailureAction({ error }))),
        ),
      ),
    ),
  );

  noteLoadForReportTag$ = createEffect(() =>
    this.actions$.pipe(
      ofType(noteLoadForReportTagAction),
      mergeMap((action) =>
        this.noteApiService.search(NoteQueryModel.fromTags(action.tagIds)).pipe(
          map((notes) => noteLoadForReportTagSuccessAction({ notes })),
          catchError((error) =>
            of(noteLoadForReportTagFailuteAction({ error })),
          ),
        ),
      ),
    ),
  );

  noteLoadForReportTarget$ = createEffect(() =>
    this.actions$.pipe(
      ofType(noteLoadForReportTargetAction),
      mergeMap((action) =>
        this.noteApiService
          .search(NoteQueryModel.fromTarget(action.targetId))
          .pipe(
            map((notes) => noteLoadForReportTargetSuccessAction({ notes })),
            catchError((error) =>
              of(noteLoadForReportTargetFailuteAction({ error })),
            ),
          ),
      ),
    ),
  );

  noteLoadForReport$ = createEffect(() =>
    this.actions$.pipe(
      ofType(noteLoadForReportAction),
      mergeMap((action) =>
        this.noteApiService
          .search(NoteQueryModel.fromCategory([action.categoryId]))
          .pipe(
            map((notes) => noteLoadForReportSuccessAction({ notes })),
            catchError((error) =>
              of(noteLoadForReportFailuteAction({ error })),
            ),
          ),
      ),
    ),
  );

  hardReload$ = createEffect(() =>
    this.actions$.pipe(
      ofType(noteHardReloadAction),
      mergeMap((action) =>
        this.noteApiService.search(action.query).pipe(
          map((notes) => noteHardReloadSuccessAction({ notes })),
          catchError((error) => of(noteHardReloadFailureAction({ error }))),
        ),
      ),
    ),
  );

  search$ = createEffect(() =>
    this.actions$.pipe(
      ofType(noteLoadAction),
      mergeMap((action) =>
        this.noteApiService.search(action.query).pipe(
          map((notes) => noteLoadSuccessAction({ notes })),
          catchError((error) => of(noteLoadFailureAction({ error }))),
        ),
      ),
    ),
  );

  createFromBatchPopup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(noteCreateFromBatchPopupAction),
      mergeMap((action) =>
        this.noteApiService.create(action.note).pipe(
          switchMap((note) => [
            noteCreateFromBatchPopupSuccessAction({ note }),
            getUserAction(),
            new TargetLoad(),
            new TagLoad(),
          ]),
          catchError((error) =>
            of(noteCreateFromBatchPopupFailureAction({ error })),
          ),
        ),
      ),
    ),
  );

  create$ = createEffect(() =>
    this.actions$.pipe(
      ofType(noteCreateAction),
      mergeMap((action) =>
        this.noteApiService.create(action.note).pipe(
          switchMap((note) => [
            noteCreateSuccessAction({ note }),
            getUserAction(),
            new TargetLoad(),
            new TagLoad(),
          ]),
          catchError((error) => of(noteCreateFailureAction({ error }))),
        ),
      ),
    ),
  );

  update$ = createEffect(() =>
    this.actions$.pipe(
      ofType(noteUpdateAction),
      mergeMap((action) =>
        this.noteApiService.update(action.note).pipe(
          switchMap((note) => [
            noteUpdateSuccessAction({ note, viewMode: action.viewMode }),
            getUserAction(),
            new TargetLoad(),
            new TagLoad(),
          ]),
          catchError((error) => of(noteUpdateFailureAction({ error }))),
        ),
      ),
    ),
  );

  delete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(noteDeleteAction),
      mergeMap((action) =>
        this.noteApiService.destroy(action.id).pipe(
          switchMap(() => [
            noteDeleteSuccessAction({ id: action.id }),
            getUserAction(),
            new TargetLoad(),
            new TagLoad(),
          ]),
          catchError((error) => of(noteDeleteFailureAction({ error }))),
        ),
      ),
    ),
  );

  createSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(noteCreateSuccessAction),
        tap(({ note }) => {
          this.amplitudeService.logEvent(
            note.isTask ? 'Задача поставлена' : 'Заметка создана',
          );
          if (note.files.length) {
            const suffix = note.isTask ? 'задачи' : 'заметки';
            this.amplitudeService.logEvent(`Загрузил фото для ${suffix}`);
          }
          if (note.checklist.length) {
            this.amplitudeService.logEvent('Создал чеклист для задачи');
          }
          if (note.targetProgress) {
            const suffix = note.isTask ? 'задаче' : 'заметке';
            this.amplitudeService.logEvent(`Прикрепил цель к ${suffix}`);
          }
          if (note.isTask) {
            this.analyticsService.taskCreated();
          } else {
            this.analyticsService.noteCreated();
          }
        }),
      ),
    { dispatch: false },
  );

  constructor(
    private actions$: Actions,
    private noteApiService: NoteApiService,
    private amplitudeService: AmplitudeService,
    private analyticsService: AnalyticsService,
  ) {}
}
