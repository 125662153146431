import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { DOCUMENT, ViewportScroller } from '@angular/common';
import { BROWSERS, DeviceDetectorService } from 'ngx-device-detector';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { Subscription } from 'rxjs/internal/Subscription';
import { ChatraService } from '@core/services/chatra.service';
import {
  ActivatedRoute,
  Event,
  NavigationEnd,
  Router,
  Scroll,
} from '@angular/router';
import { IdentityInterface } from '@core/models/identity.interface';
import {
  defaultLang,
  getNavigatorLanguage,
  normalizeLanguage,
} from '@helpers/consts/language';
import { filter, map } from 'rxjs/operators';
import * as FullStory from '@fullstory/browser';
import { AuthService } from '@core/redux/auth/auth.service';
import { Title } from '@angular/platform-browser';
import { languageCookieKey } from 'src/app/helpers/consts/cookie';
import { SocketBaseService } from '@core/socket/socket.base.service';
import { ReleaseService } from '@core/redux/release/release.service';
import { PersistentStorageService } from '@core/services/persistent-storage.service';
import { AmplitudeService } from '@core/services/amplitude.service';
import { Observable } from 'rxjs';
import { OfflineNotificationService } from '@ui/offline-notification/offline-notification.service';
import { InitService } from '@core/services/init.service';
import { ThemeService } from '@core/services/theme/theme.service';
import { AnalyticsService } from '@core/services/analytics.service';
import { NgxMetrikaService } from '@kolkov/ngx-metrika';
import { SyncService } from '@core/redux/sync/sync.service';
import { environment } from '../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less'],
})
export class AppComponent implements OnInit, OnDestroy {
  user: IdentityInterface;
  outdated$: Observable<boolean> = this.releaseService.shouldShowUpdateAlert();

  private readonly titles = {
    en: 'Logtime - a web service to manage your daily life',
    ru: 'Logtime - сервис для учета событий за день',
  };
  private routesToSkipScroll = ['/calendar'];
  private readonly subscriptions$: Subscription = new Subscription();

  constructor(
    @Inject(DOCUMENT)
    private readonly document: Document,
    private readonly amplitude: AmplitudeService,
    private readonly authService: AuthService,
    private readonly deviceService: DeviceDetectorService,
    private readonly chatra: ChatraService,
    private readonly initService: InitService,
    private readonly offlineNotificationService: OfflineNotificationService,
    private readonly persistentStorage: PersistentStorageService,
    private readonly releaseService: ReleaseService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly socketBaseService: SocketBaseService,
    private readonly title: Title,
    private readonly translate: TranslateService,
    private readonly viewportScroller: ViewportScroller,
    private readonly analyticsService: AnalyticsService,
    private readonly themeService: ThemeService,
    private readonly ym: NgxMetrikaService,
    private readonly syncService: SyncService,
  ) {
    this.subscribeRouteGTM();
    this.prepareLanguage();
    this.onLangChange();
    this.subscribeLanguage();
    FullStory.init({ orgId: '17NEC6', devMode: !environment.production });
  }

  ngOnInit() {
    this.offlineNotificationService
      .refreshRequests()
      .subscribe(() => this.initService.prepare());
    this.detectDevice();
    this.defineScrollBehaviour();
    this.subscribeToken();
    this.detectMobile();
    this.subscribeToRedirectRequests();
    this.subscribeUser();
    this.themeService.setUserTheme();
    this.syncService.subscribeElectronSync();
  }

  ngOnDestroy() {
    this.subscriptions$.unsubscribe();
  }

  private subscribeRouteGTM() {
    this.router.events.forEach((item) => {
      if (item instanceof NavigationEnd) {
        this.analyticsService.pageView(item);
      }
    });
  }

  private subscribeUser() {
    this.subscriptions$.add(
      this.authService.getUser().subscribe((user) => {
        this.user = user;
        this.persistentStorage.set(languageCookieKey, user.language);
        this.translate.use(user.language);
        this.chatra.setData(user);
        this.amplitude.setUser(user);
        this.analyticsService.setUserId(user.id);
        FullStory.setUserVars({
          displayName: `${user.firstName} ${user.email}`,
          ...user,
        });
      }),
    );
  }

  private subscribeToken() {
    this.subscriptions$.add(
      this.authService.getAccessToken().subscribe((token: string) => {
        if (token) {
          this.socketBaseService.listen(token);
        }
      }),
    );
  }

  private detectDevice() {
    if (
      this.deviceService.os !== 'mac' &&
      !this.deviceService.isMobile() &&
      !this.deviceService.isTablet()
    ) {
      this.document.body.classList.add('no-mac');
    }
    if (this.deviceService.isMobile()) {
      this.document.body.classList.add('mobile');
      if (this.deviceService.browser === BROWSERS.CHROME) {
        this.document.body.classList.add('chrome-mobile');
      }
    }
    if (this.deviceService.isTablet()) {
      this.document.body.classList.add('tablet');
    }
  }

  private defineScrollBehaviour() {
    this.subscriptions$.add(
      this.router.events
        .pipe(
          filter((e: Event): e is Scroll => e instanceof Scroll),
          filter((e: Scroll) => this.isScrollNeeded(e.routerEvent.url)),
        )
        .subscribe((e) => {
          if (e.position) {
            // backward navigation
            this.viewportScroller.scrollToPosition(e.position);
          } else if (e.anchor) {
            // anchor navigation
            this.viewportScroller.scrollToAnchor(e.anchor);
          } else {
            // forward navigation
            this.viewportScroller.scrollToPosition([0, 0]);
          }
        }),
    );
  }

  private onLangChange() {
    this.title.setTitle(this.titles[this.translate.currentLang]);
    if (this.document) {
      const html = this.document.getElementsByTagName('html');
      if (html.length) {
        html[0].setAttribute('lang', this.translate.currentLang);
      }
    }
  }

  private isScrollNeeded(url: string): boolean {
    const shouldSkip = this.routesToSkipScroll.some((route) =>
      url.startsWith(route),
    );
    return !shouldSkip;
  }

  private detectMobile() {
    if (
      this.deviceService.os !== 'mac' &&
      !this.deviceService.isMobile() &&
      !this.deviceService.isTablet()
    ) {
      this.document.body.classList.add('no-mac');
    }
    if (this.deviceService.isMobile()) {
      this.document.body.classList.add('mobile');
    }
    if (this.deviceService.isTablet()) {
      this.document.body.classList.add('tablet');
    }
  }

  private prepareLanguage() {
    const cookieLang = this.persistentStorage.get(languageCookieKey);
    const lang = cookieLang || getNavigatorLanguage();
    const normalizedLang = normalizeLanguage(lang);
    moment.locale(normalizedLang);
    this.translate.setDefaultLang(defaultLang);
    this.translate.use(normalizedLang);
  }

  private subscribeLanguage() {
    this.subscriptions$.add(
      this.translate.onLangChange.subscribe(() => this.onLangChange()),
    );
  }

  private subscribeToRedirectRequests() {
    this.subscriptions$.add(
      this.route.queryParams
        .pipe(
          map((params) => params.path),
          filter((p) => !!p),
        )
        .subscribe((path) => {
          this.router.navigateByUrl(path);
        }),
    );
  }
}
