import { Pipe, PipeTransform } from '@angular/core';
import { FileInterface } from '@core/models/file.interface';

/**
 * Pipe для получения URL картинки с заданным размером
 */
@Pipe({ name: 'imagePreview' })
export class ImagePreviewPipe implements PipeTransform {
  transform(
    value: FileInterface,
    width: number = 0,
    height: number = 0,
  ): string {
    const requestedSize = `${width}x${height}`;
    return value.sizes[requestedSize] || value.sizes.origin;
  }
}
