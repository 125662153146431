import { MatDialogRef } from '@angular/material/dialog';

// TODO: мб как-то лучше можно сделать?
export function removeMatDialogContainerPadding(dialogRef: MatDialogRef<any>) {
  const matContainerId = dialogRef._containerInstance._id;
  const matContainer = document.getElementById(matContainerId);
  if (matContainer) {
    matContainer.style.padding = '0';
  }
}

export function removeMatDialogOverflow(dialogRef: MatDialogRef<any>) {
  const matContainer = document.getElementById(
    dialogRef._containerInstance._id,
  );
  if (matContainer) {
    matContainer.style.overflow = 'hidden';
  }
}
