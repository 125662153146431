import { Injectable } from '@angular/core';
import { IdentityInterface } from '@core/models/identity.interface';

declare const Chatra;

@Injectable({
  providedIn: 'root',
})
export class ChatraService {
  setData(user: IdentityInterface) {
    if (typeof Chatra === 'undefined') {
      return;
    }
    Chatra('updateIntegrationData', {
      name: user.firstName,
      'Дата завершения': user.expiredAt,
      'Количество заполненных дней': user.achievements?.filledDays?.stepsDone,
      email: user.email,
      Тестовый: user.isTest ? 'Да' : 'Нет',
      clientId: user.id,
    });
  }

  hide() {
    if (typeof Chatra === 'undefined' || typeof Chatra.hide !== 'function') {
      return;
    }
    Chatra.hide();
  }

  open() {
    if (
      typeof Chatra === 'undefined' ||
      typeof Chatra.openChat !== 'function' ||
      Chatra._chatExpanded
    ) {
      return;
    }
    Chatra.openChat();
  }

  show() {
    if (typeof Chatra === 'undefined' || typeof Chatra.show !== 'function') {
      return;
    }
    Chatra.show();
  }
}
