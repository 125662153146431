import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class LanguageInterceptor implements HttpInterceptor {
  constructor(private translate: TranslateService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    if (
      !request.url.startsWith(environment.apiUrl) ||
      request.url.startsWith(environment.electron.storage)
    ) {
      return next.handle(request);
    }
    return next.handle(
      request.clone({
        setParams: { lang: this.translate.currentLang },
      }),
    );
  }
}
