import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Autolinker } from 'autolinker';
import { linkyDefaults } from '@helpers/settings/linky';
import { hashtagRegexp } from '@helpers/settings/hashtag-regexp';

function replaceHashtags(text: string): string {
  return text.replace(
    hashtagRegexp,
    `<a
  class="logtime__hashtag logtime__hashtag_inner"
  href="/report-tag/$1" routerLink="/report-tag/$1">#$1</a>`,
  );
}

@Pipe({ name: 'safeLinky' })
export class SafeLinkyPipe implements PipeTransform {
  constructor(protected sanitizer: DomSanitizer) {}

  transform(value: string, options?: any): SafeHtml {
    const sumOptions = Object.assign(linkyDefaults, options);
    const withTags = replaceHashtags(value);
    const html = Autolinker.link(withTags, sumOptions);
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }
}
