<div class="info-popup-content__header">
  <button
    (click)="onCloseClick()"
    ui-button-icon
    class="info-popup-content__close"
    type="button"
  >
    <ui-icon class="ui-icon ui-icon_hover_close" name="close"></ui-icon>
  </button>
</div>
<div>
  <ng-content></ng-content>
</div>
<div *ngIf="action" class="info-popup-content__footer">
  <button (click)="actionClick.emit()" ui-button-primary-long type="button">
    {{ action | translate }}
  </button>
</div>
