import { NgModule } from '@angular/core';
import { ConfirmMailComponent } from './confirm-mail.component';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { UiIconModule } from '@ui/ui-icon/ui-icon.module';
import { ButtonsModule } from '@ui/buttons/buttons.module';

@NgModule({
  imports: [CommonModule, TranslateModule, UiIconModule, ButtonsModule],
  declarations: [ConfirmMailComponent],
  exports: [ConfirmMailComponent],
  entryComponents: [ConfirmMailComponent],
})
export class ConfirmMailModule {}
