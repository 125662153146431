import {
  Component,
  HostBinding,
  Input,
  ChangeDetectionStrategy,
} from '@angular/core';

@Component({
  selector: 'ui-icon',
  templateUrl: './ui-icon.component.html',
  styleUrls: ['./ui-icon.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UiIconComponent {
  @HostBinding('attr.name') @Input() name: string;
  @Input() width = '24';
  @Input() height = '24';
}
