import { Action } from '@ngrx/store';
import { TagInterface } from '@core/models/tag.interface';

export enum TagActionTypes {
  TagLoad = '[Tag] Load',
  TagLoadSuccess = '[Tag] Load Success',
  TagLoadFailure = '[Tag] Load Failure',
}

export class TagLoad implements Action {
  readonly type = TagActionTypes.TagLoad;

  constructor() {}
}

export class TagLoadSuccess implements Action {
  readonly type = TagActionTypes.TagLoadSuccess;

  constructor(public payload: TagInterface[]) {}
}

export class TagLoadFailure implements Action {
  readonly type = TagActionTypes.TagLoadFailure;

  constructor(public payload: any) {}
}

export type TagActions = TagLoad | TagLoadSuccess | TagLoadFailure;
