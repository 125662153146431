import { throwError as observableThrowError, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

const API_URL = environment.apiUrl;

/**
 * Базовый сервис для работы с API
 */
@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(protected http: HttpClient) {}

  /**
   * Билдер API URL
   */
  protected getApiUrl(action: string): string {
    return API_URL + action;
  }

  /**
   * Обработка ошибок API
   */
  protected handleError(error: Response | any): Observable<never> {
    console.error('ApiService::handleError', error);
    return observableThrowError(error);
  }
}
