<span *ngIf="note?.targetProgress" class="note-progress__numb">
  {{ note.targetProgress | signedDecimal }}
</span>
<span
  *ngIf="note?.target"
  [style.background-color]="note.target.color"
  [style.margin-top.px]="circleTopOffset"
  style="background: red"
  class="note-progress__circle circle"
>
</span>
