<span class="mat-button-wrapper">
  <span class="center-icon" *ngIf="centerIcon">
    <ui-icon [name]="centerIcon"></ui-icon>
  </span>
  <span *ngIf="loading">
    <ui-icon name="loading"></ui-icon>
  </span>
  <span *ngIf="!loading">
    <ng-content></ng-content>
  </span>
</span>
<span class="icon" *ngIf="icon">
  <ui-icon [name]="icon"></ui-icon>
</span>
<div
  matRipple
  class="mat-button-ripple"
  [class.mat-button-ripple-round]="isRoundButton || isIconButton"
  [matRippleDisabled]="isRippleDisabled"
  [matRippleCentered]="isIconButton"
  [matRippleTrigger]="elementRef"
></div>
<div class="mat-button-focus-overlay"></div>
