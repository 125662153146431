import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconPopupComponent } from './icon-popup.component';
import { UiIconModule } from '@ui/ui-icon/ui-icon.module';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonsModule } from '@ui/buttons/buttons.module';
import { InfoPopupModule } from '@ui/info-popup';

@NgModule({
  declarations: [IconPopupComponent],
  imports: [
    CommonModule,
    UiIconModule,
    TranslateModule,
    ButtonsModule,
    InfoPopupModule,
  ],
  entryComponents: [IconPopupComponent],
})
export class IconPopupModule {}
