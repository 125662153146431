import { Injectable } from '@angular/core';
import { ApiService } from '@core/services/api.service';
import { PageResult } from '@core/models/filters/page-result';
import { AffiliateStatisticInterface } from '@core/models/affiliate-statistic.interface';
import { ReferralInterface } from '@core/models/identity.interface';
import { AffiliateInfoInterface } from '@core/models/affiliate-info.interface';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ApiAffiliateService extends ApiService {
  /**
   * Пользователи который пришли по реферальной программе
   */
  getReferrals(page: number, expand = []) {
    return this.http
      .get<any>(
        this.getApiUrl(
          `affiliates/referrals?page=${page}&expand=${expand.join(',')}`,
        ),
        { observe: 'response' },
      )
      .pipe(
        map(
          response =>
            new PageResult<ReferralInterface[]>(response, response.body),
        ),
      );
  }

  /**
   * Статистика партнера. Основные метрики.
   */
  getStatistic() {
    return this.http.get<AffiliateStatisticInterface>(
      this.getApiUrl(`affiliates/statistic`),
    );
  }

  /**
   * Статистика партнера. Основные метрики.
   */
  saveInfo(model: AffiliateInfoInterface) {
    return this.http.put<AffiliateStatisticInterface>(
      this.getApiUrl(`affiliates/info`),
      model,
    );
  }
}
