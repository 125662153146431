import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InnerHtmlLinkDirective } from '@shared/directives/inner-html-link/inner-html-link.directive';

@NgModule({
  declarations: [InnerHtmlLinkDirective],
  exports: [InnerHtmlLinkDirective],
  imports: [CommonModule],
})
export class InnerHtmlLinkModule {}
