import { Action } from '@ngrx/store';
import { TargetInterface } from '@core/models/target.interface';

export enum TargetActionTypes {
  TargetSelectAction = '[Target] Select',
  TargetCreateAction = '[Target] Create',
  TargetCreateSuccessAction = '[Target] Create Success',
  TargetCreateFailureAction = '[Target] Create Failure',
  TargetUpdateAction = '[Target] Update',
  TargetUpdateSuccessAction = '[Target] Update Success',
  TargetUpdateFailureAction = '[Target] Update Failure',
  TargetViewAction = '[Target] View',
  TargetViewSuccessAction = '[Target] View Success',
  TargetViewFailureAction = '[Target] View Failure',
  TargetLoad = '[Target] Load',
  TargetLoadSuccess = '[Target] Load Success',
  TargetLoadFailure = '[Target] Load Failure',
  TargetDelete = '[Target] Delete',
  TargetDeleteSuccess = '[Target] Delete Success',
  TargetDeleteFailure = '[Target] Delete Failure',
}

export class TargetSelect implements Action {
  readonly type = TargetActionTypes.TargetSelectAction;

  constructor(public id: string) {}
}

export class TargetLoad implements Action {
  readonly type = TargetActionTypes.TargetLoad;

  constructor() {}
}

export class TargetLoadSuccess implements Action {
  readonly type = TargetActionTypes.TargetLoadSuccess;

  constructor(public payload: TargetInterface[]) {}
}

export class TargetLoadFailure implements Action {
  readonly type = TargetActionTypes.TargetLoadFailure;

  constructor(public payload: any) {}
}

export class TargetCreateAction implements Action {
  readonly type = TargetActionTypes.TargetCreateAction;

  constructor(public payload: TargetInterface) {}
}

export class TargetCreateSuccessAction implements Action {
  readonly type = TargetActionTypes.TargetCreateSuccessAction;

  constructor(public payload: TargetInterface) {}
}

export class TargetCreateFailureAction implements Action {
  readonly type = TargetActionTypes.TargetCreateFailureAction;

  constructor(public payload: any) {}
}

export class TargetViewAction implements Action {
  readonly type = TargetActionTypes.TargetViewAction;

  constructor(public id: string) {}
}

export class TargetViewSuccessAction implements Action {
  readonly type = TargetActionTypes.TargetViewSuccessAction;

  constructor(public payload: TargetInterface) {}
}

export class TargetViewFailureAction implements Action {
  readonly type = TargetActionTypes.TargetViewFailureAction;

  constructor(public payload: any) {}
}

export class TargetUpdateAction implements Action {
  readonly type = TargetActionTypes.TargetUpdateAction;

  constructor(public payload: TargetInterface) {}
}

export class TargetUpdateSuccessAction implements Action {
  readonly type = TargetActionTypes.TargetUpdateSuccessAction;

  constructor(public payload: TargetInterface) {}
}

export class TargetUpdateFailureAction implements Action {
  readonly type = TargetActionTypes.TargetUpdateFailureAction;

  constructor(public payload: any) {}
}

export class TargetDeleteAction implements Action {
  readonly type = TargetActionTypes.TargetDelete;

  constructor(public payload: string) {}
}

export class TargetDeleteSuccess implements Action {
  readonly type = TargetActionTypes.TargetDeleteSuccess;

  constructor(public payload: TargetInterface) {}
}

export class TargetDeleteFailure implements Action {
  readonly type = TargetActionTypes.TargetDeleteFailure;

  constructor(public payload: any) {}
}

export type TargetActions =
  | TargetSelect
  | TargetCreateAction
  | TargetCreateSuccessAction
  | TargetCreateFailureAction
  | TargetViewAction
  | TargetViewSuccessAction
  | TargetViewFailureAction
  | TargetUpdateAction
  | TargetUpdateSuccessAction
  | TargetUpdateFailureAction
  | TargetLoad
  | TargetLoadSuccess
  | TargetLoadFailure
  | TargetDeleteAction
  | TargetDeleteSuccess
  | TargetDeleteFailure;
