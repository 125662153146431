import { NgModule } from '@angular/core';
import { CategoryFormModule } from './category-form';
import { ConfirmationDialogModule } from './confirmation-dialog';
import { ConfirmMailModule } from './email-confirmation';
import { DeleteCategoryDialogModule } from './delete-category-dialog';
import { ImageSliderModule } from './image-slider';
import { NoteDialogModule } from './note';
import { PromocodeConfirmApplyModule } from './promocode-confirm-apply';
import { QiwiModule } from './qiwi';
import { StoriesModule } from './stories/stories.module';
import { SubscriptionEndedModule } from './subscription-ended';
import { YoutubeModule } from './youtube';

@NgModule({
  exports: [
    CategoryFormModule,
    ConfirmationDialogModule,
    ConfirmMailModule,
    DeleteCategoryDialogModule,
    ImageSliderModule,
    NoteDialogModule,
    PromocodeConfirmApplyModule,
    QiwiModule,
    StoriesModule,
    SubscriptionEndedModule,
    YoutubeModule,
  ],
})
export class DialogsModule {}
