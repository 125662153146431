import { Injectable, InjectionToken, Inject } from '@angular/core';
import {
  AsyncValidator,
  AbstractControl,
  ValidationErrors,
} from '@angular/forms';

const browserImageSize: (
  file: Blob,
) => Promise<ImageSize> = require('browser-image-size');

export interface ImageSize {
  height: number;
  width: number;
}

interface DimentionErrorCommon {
  actual: number;
}

export interface TooBigError extends DimentionErrorCommon {
  max: number;
}

export interface TooSmallError extends DimentionErrorCommon {
  min: number;
}

export type DimentionError = TooBigError | TooSmallError;

export interface WidthHeightError {
  height: DimentionError;
  width: DimentionError;
}

export interface HeightError {
  height: DimentionError;
}

export interface WidthError {
  width: DimentionError;
}

/**
 * Ошибки валидации размера картинки
 *
 * @usageNotes
 *
 * ### Картинка слишком маленькая по высоте, но слишком большая по длине
 *
 * ```typescript
 * {
 *   imageSize: {
 *     height: {
 *       actual: 120,
 *       min: 240
 *     },
 *     width: {
 *       actual: 10000,
 *       max: 900
 *     }
 *   }
 * }
 * ```
 */
export interface ImageSizeValidationErrors extends ValidationErrors {
  imageSize: WidthHeightError | WidthError | HeightError;
}

export type imageSizeValidatorFunc = (
  size: ImageSize,
) => ImageSizeValidationErrors | null;

const minSize = 240;

/**
 * Функция проверки картинки по умолчанию
 */
export const defaultIsValid: imageSizeValidatorFunc = size => {
  if (size.height < minSize && size.width < minSize) {
    return {
      imageSize: {
        height: {
          actual: size.height,
          min: minSize,
        },
        width: {
          actual: size.width,
          min: minSize,
        },
      },
    };
  }
  if (size.height < minSize) {
    return {
      imageSize: {
        height: {
          actual: size.height,
          min: minSize,
        },
      },
    };
  }
  if (size.width < minSize) {
    return {
      imageSize: {
        width: {
          actual: minSize,
          min: minSize,
        },
      },
    };
  }
  return null;
};

export const IMAGE_SIZE_VALIDATOR = new InjectionToken<imageSizeValidatorFunc>(
  'Image size validator function',
  {
    factory: () => defaultIsValid,
  },
);

/**
 * @description
 * Асинхронный валидатор, проверяющий, что была передана картинка нужного размера.
 * Требует, чтобы value у FormControl было File. Обрати внимание, что он должен передаваться
 * третьим аргументом, а не со списком синхронных валидаторов.
 *
 * @usageNotes
 *
 * ### Валидация размера картинки
 *
 * ```typescript
 * const control = new FormControl(bigImage, [], this.imageSizeValidator.validate.bind(this.imageSizeValidator));
 *
 * console.log(control.errors); // {imageSize: {height: {actual: 10, max: 9 }}}
 * ```
 */
@Injectable({ providedIn: 'root' })
export class ImageSizeValidator implements AsyncValidator {
  constructor(
    @Inject(IMAGE_SIZE_VALIDATOR)
    private readonly isValid: imageSizeValidatorFunc,
  ) {}

  validate(ctrl: AbstractControl): Promise<ImageSizeValidationErrors | null> {
    const file = ctrl.value;
    if (!file) {
      return Promise.resolve(null);
    }
    return browserImageSize(file)
      .then(this.isValid)
      .catch(() => null);
  }
}
