import { Injectable } from '@angular/core';
import { ConfirmationDialogComponent } from './confirmation-dialog.component';
import { ConfirmationDialogData } from './confirmation-dialog-config';
import { DialogService } from '@ui/dialog';

@Injectable({
  providedIn: 'root',
})
export class ConfirmationDialogService {
  constructor(private readonly dialog: DialogService) {}

  open(data: ConfirmationDialogData) {
    return this.dialog.open(ConfirmationDialogComponent, { data }, true);
  }
}
