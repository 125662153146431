export class FileInterface {
  id: string;
  originalName: string;
  sizes: { [key: string]: string };
}

export function emptyFile(file: Partial<FileInterface> = {}): FileInterface {
  return {
    id: '',
    originalName: '',
    sizes: {},
    ...file,
  };
}
