import { select, Store, Action } from '@ngrx/store';
import { Injectable } from '@angular/core';
import * as fromRoot from '@core/redux/index';
import {
  CategoryTasks,
  NoteInterface,
  NoteQueryModel,
} from '@core/models/note.interface';
import { combineLatest, Observable, of, throwError } from 'rxjs';
import { concatMap, map, take } from 'rxjs/operators';
import { CategoryService } from '@core/redux/category/category.service';
import { CategoryInterface } from '@core/models/category.interface';
import { TargetInterface } from '@core/models/target.interface';
import { TagInterface } from '@core/models/tag.interface';
import { Actions, ofType } from '@ngrx/effects';
import {
  clearNotesForReportAction,
  clearNotesForReportTagAction,
  clearNotesForReportTargetAction,
  noteChangeCategoryAction,
  noteChangeSortAction,
  noteClearListFromBatchPopupAction,
  noteCreateAction,
  noteCreateFailureAction,
  noteCreateFromBatchPopupAction,
  noteCreateFromBatchPopupSuccessAction,
  noteCreateSuccessAction,
  noteDeleteAction,
  noteHardReloadAction,
  noteLoadAction,
  noteLoadForReportAction,
  noteLoadForReportTagAction,
  noteLoadForReportTargetAction,
  noteOneLoadAction,
  noteUpdateAction,
  noteUpdateFailureAction,
  noteUpdateSuccessAction,
} from '@core/redux/note/note.actions';

export function findCategoryAndTargetForNote(
  note: NoteInterface,
  categories: CategoryInterface[],
  targets: TargetInterface[],
) {
  return {
    ...note,
    category: categories.find((c) => c.id === note.categoryId),
    target: targets.find((t) => t.id === note.targetId),
  };
}

export interface TagGroup {
  tag: TagInterface;
  notes: NoteInterface[];
}

@Injectable({
  providedIn: 'root',
})
export class NoteService {
  filterQuery: NoteQueryModel;

  constructor(
    private actions: Actions,
    private store: Store<fromRoot.State>,
    private categoryService: CategoryService,
  ) {
    this.resetFilterQuery();
  }

  resetFilterQuery() {
    this.filterQuery = new NoteQueryModel();
  }

  /**
   * Получение записей всего списка
   */
  getNotesAll(categoryId?: string, isTask?: boolean) {
    const categoryList$ = this.categoryService.getAll();
    const noteList$ = this.store.pipe(
      select(fromRoot.getNotesAll(categoryId, isTask)),
    );
    const targetList$ = this.store.pipe(select(fromRoot.getTargetListAll));

    return combineLatest([categoryList$, noteList$, targetList$]).pipe(
      map(([categories, notes, targets]) =>
        notes.map((n) => findCategoryAndTargetForNote(n, categories, targets)),
      ),
    );
  }

  getCategoriesWithTasks(): Observable<CategoryTasks[]> {
    return of([]);
    // this.getNotesAll().pipe(
    //   map((notes) => notes.filter((task) => task.isTask)),
    //   map((notes) => {
    //     const categoryTasks: CategoryTasks[] = [];
    //
    //     notes.map((note) => {
    //       const indexNote = categoryTasks.findIndex(
    //         (el) => el.category.id === note.category.id,
    //       );
    //
    //       if (indexNote === -1) {
    //         categoryTasks.push({ category: note.category, tasks: [note] });
    //       } else {
    //         categoryTasks[indexNote] = {
    //           ...categoryTasks[indexNote],
    //           tasks: [...categoryTasks[indexNote].tasks, note],
    //         };
    //       }
    //     });
    //     return categoryTasks;
    //   }),
    // );
  }

  getNoteById(id: string): Observable<NoteInterface | undefined> {
    const categoryList$ = this.categoryService.getAll();
    const targetList$ = this.store.pipe(select(fromRoot.getTargetListAll));
    const note$ = this.store.pipe(select(fromRoot.getNoteById(id)));
    return combineLatest([categoryList$, targetList$, note$]).pipe(
      map(([categories, targets, note]) =>
        note ? findCategoryAndTargetForNote(note, categories, targets) : note,
      ),
    );
  }

  /**
   * Получение записей за определенный день
   */
  getNotes(
    day: number,
    month: number,
    year: number,
    isTask?: boolean,
    categoryId?: string,
  ): Observable<NoteInterface[]> {
    const categoryList$ = this.categoryService.getAll();
    const noteList$ = this.store.pipe(
      select(
        fromRoot.getNoteEntitiesByDay(year, month, day, isTask, categoryId),
      ),
    );
    const targetList$ = this.store.pipe(select(fromRoot.getTargetListAll));
    return combineLatest([categoryList$, noteList$, targetList$]).pipe(
      map(([categories, notes, targets]) => {
        return notes.map((n) =>
          findCategoryAndTargetForNote(n, categories, targets),
        );
      }),
    );
  }

  getPastNotesByStatus(
    status: boolean,
    categoryId: string,
    isTask?: boolean,
  ): Observable<NoteInterface[]> {
    const noteList$ = this.store.pipe(
      select(fromRoot.getPastNoteByStatus(status, categoryId, isTask)),
    );
    const categoryList$ = this.categoryService.getAll();
    const targetList$ = this.store.pipe(select(fromRoot.getTargetListAll));
    return combineLatest([categoryList$, noteList$, targetList$]).pipe(
      map(([categories, notes, targets]) => {
        return notes.map((n) =>
          findCategoryAndTargetForNote(n, categories, targets),
        );
      }),
    );
  }

  getNoteLoading() {
    return this.store.pipe(select(fromRoot.getNoteLoading));
  }

  getReportTagNotesLoading() {
    return this.store.pipe(select(fromRoot.getReportTagLoading));
  }

  getReportTargetNotesLoading() {
    return this.store.pipe(select(fromRoot.getReportTargetLoading));
  }

  getReportNotesLoading() {
    return this.store.pipe(select(fromRoot.getReportLoading));
  }

  getNotesForReport(): Observable<NoteInterface[]> {
    const categoryList$ = this.categoryService.getAll();
    const noteList$ = this.store.pipe(select(fromRoot.getNotesForReport));
    const targetList$ = this.store.pipe(select(fromRoot.getTargetListAll));
    return combineLatest([categoryList$, noteList$, targetList$]).pipe(
      map(([categories, notes, targets]) =>
        notes.map((n) => findCategoryAndTargetForNote(n, categories, targets)),
      ),
    );
  }

  getNotesCurrentCreating(): Observable<NoteInterface[]> {
    const categoryList$ = this.categoryService.getAll();
    const noteList$ = this.store.pipe(select(fromRoot.getNotesCurrentCreating));
    const targetList$ = this.store.pipe(select(fromRoot.getTargetListAll));
    return combineLatest([categoryList$, noteList$, targetList$]).pipe(
      map(([categories, notes, targets]) =>
        notes.map((n) => findCategoryAndTargetForNote(n, categories, targets)),
      ),
    );
  }

  getNotesGroupedByTag() {
    const tagList$ = this.store.pipe(select(fromRoot.getTagList));
    const noteList$ = this.store.pipe(select(fromRoot.getNotesForReportTag));
    const targetList$ = this.store.pipe(select(fromRoot.getTargetListAll));
    const categoryList$ = this.categoryService.getAll();

    return combineLatest([
      tagList$,
      noteList$,
      targetList$,
      categoryList$,
    ]).pipe(
      map(([tags, notes, targets, categories]) => {
        const result: TagGroup[] = [];
        const withFilteredTags = notes.map((note) => ({
          ...note,
          tags: tags.filter((tag) =>
            (note.tags || []).find((t) => t.id === tag.id),
          ),
        }));

        const withCategoryAndTarget = withFilteredTags.map((n) =>
          findCategoryAndTargetForNote(n, categories, targets),
        );

        tags.forEach((tag) => {
          const temp: TagGroup = {
            tag,
            notes: withCategoryAndTarget.filter((item: NoteInterface) =>
              item.tagIds.some((id) => id === tag.id),
            ),
          };

          if (temp.notes.length) {
            result.push(temp);
          }
        });
        return result;
      }),
    );
  }

  getNotesForTarget() {
    const categoryList$ = this.categoryService.getAll();
    const noteList$ = this.store.pipe(select(fromRoot.getNotesForReportTarget));
    const targetList$ = this.store.pipe(select(fromRoot.getTargetListAll));

    return combineLatest([categoryList$, noteList$, targetList$]).pipe(
      map(([categories, notes, targets]) =>
        notes.map((n) => findCategoryAndTargetForNote(n, categories, targets)),
      ),
    );
  }

  hardReloadAll(query = this.filterQuery) {
    this.store.dispatch(noteHardReloadAction({ query }));
  }

  dispatchAll(query = this.filterQuery) {
    this.store.dispatch(noteLoadAction({ query }));
  }

  loadNoteById(id: string) {
    this.store.dispatch(noteOneLoadAction({ id }));
  }

  loadNotesForReportTag(tagIds: string[]) {
    this.store.dispatch(noteLoadForReportTagAction({ tagIds }));
  }

  loadNotesForReportTarget(targetId: string) {
    this.store.dispatch(noteLoadForReportTargetAction({ targetId }));
  }

  loadNotesForReport(categoryId: string) {
    this.store.dispatch(noteLoadForReportAction({ categoryId }));
  }

  changeSort(ids: string[], note: NoteInterface) {
    this.store.dispatch(noteChangeSortAction({ ids, note }));
  }

  changeCategory(categoryId: string, newCategoryId: string) {
    this.store.dispatch(
      noteChangeCategoryAction({ categoryId, newCategoryId }),
    );
  }

  update(note: NoteInterface): Observable<Action> {
    this.store.dispatch(noteUpdateAction({ note, viewMode: true }));
    return this.actions.pipe(
      ofType(noteUpdateSuccessAction, noteUpdateFailureAction),
      take(1),
      concatMap((action: Action) =>
        action.type === noteUpdateSuccessAction.type
          ? of(action)
          : throwError(action),
      ),
    );
  }

  delete(id: string) {
    this.store.dispatch(noteDeleteAction({ id }));
  }

  create(note: NoteInterface) {
    this.store.dispatch(noteCreateAction({ note }));
    return this.actions.pipe(
      ofType(noteCreateSuccessAction, noteCreateFailureAction),
      take(1),
      concatMap((action) =>
        action.type === noteCreateSuccessAction.type
          ? of(action)
          : throwError(action),
      ),
    );
  }

  createFromBatchPopup(note: NoteInterface) {
    this.store.dispatch(noteCreateFromBatchPopupAction({ note }));
    return this.actions.pipe(
      ofType(
        noteCreateFromBatchPopupSuccessAction,
        noteCreateFromBatchPopupSuccessAction,
      ),
      take(1),
      concatMap((action) =>
        action.type === noteCreateFromBatchPopupSuccessAction.type
          ? of(action)
          : throwError(action),
      ),
    );
  }

  clearNotesFromBatchPopup() {
    this.store.dispatch(noteClearListFromBatchPopupAction());
  }

  clearNotesForReportTag() {
    this.store.dispatch(clearNotesForReportTagAction());
  }

  clearNotesForReportTarget() {
    this.store.dispatch(clearNotesForReportTargetAction());
  }

  clearNotesForReport() {
    this.store.dispatch(clearNotesForReportAction());
  }
}
