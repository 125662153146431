import {
  trigger,
  style,
  transition,
  animate,
  state,
  AnimationEvent,
} from '@angular/animations';
import {
  BasePortalOutlet,
  CdkPortalOutlet,
  ComponentPortal,
} from '@angular/cdk/portal';
import {
  ChangeDetectorRef,
  Component,
  ComponentRef,
  HostBinding,
  HostListener,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { Subject } from 'rxjs';

function throwInfoPopupContentAlreadyAttachedError() {
  throw Error(
    'Attempting to attach info popup content after content is already attached',
  );
}

@Component({
  selector: 'rt-info-popup-desktop-container',
  templateUrl: './info-popup-desktop-container.component.html',
  styleUrls: ['./info-popup-desktop-container.component.less'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('dialog', [
      state('enter', style({ opacity: 1, transform: 'none' })),
      state(
        'exit, void',
        style({ opacity: 0, transform: 'translateY(100px)' }),
      ),
      transition('* => enter', animate(100)),
      transition('* => exit, * => void', animate(100)),
    ]),
  ],
})
export class InfoPopupDesktopContainerComponent extends BasePortalOutlet {
  @ViewChild(CdkPortalOutlet, { static: true }) portalOutlet: CdkPortalOutlet;

  @HostBinding('class')
  klass = 'rt-info-popup-desktop-container';

  @HostBinding('@dialog')
  animationState: 'void' | 'enter' | 'exit' = 'enter';

  // tslint:disable-next-line:variable-name
  readonly _afterExit = new Subject();
  // tslint:disable-next-line:variable-name
  readonly _beforeExit = new Subject();
  // tslint:disable-next-line:variable-name
  readonly _afterEnter = new Subject();

  constructor(private readonly changeDetectorRef: ChangeDetectorRef) {
    super();
  }

  @HostListener('@dialog.start', ['$event'])
  onAnimationStart(event: AnimationEvent) {
    if (event.toState === 'enter') {
      this._afterEnter.next();
      this._afterEnter.complete();
    }
  }

  @HostListener('@dialog.done', ['$event'])
  onAnimationDone(event: AnimationEvent) {
    if (event.toState === 'exit') {
      this._afterExit.next();
      this._afterExit.complete();
    }
  }

  _startExiting() {
    this.animationState = 'exit';

    // Mark the container for check so it can react if the
    // view container is using OnPush change detection.
    this.changeDetectorRef.markForCheck();
  }

  attachComponentPortal<T>(portal: ComponentPortal<T>): ComponentRef<T> {
    if (this.portalOutlet.hasAttached()) {
      throwInfoPopupContentAlreadyAttachedError();
    }
    return this.portalOutlet.attachComponentPortal(portal);
  }

  attachTemplatePortal(): never {
    throw new Error('Not Implemented');
  }
}
