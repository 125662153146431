import { Injectable } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  Validators,
} from '@angular/forms';
import {
  getEmptyNote,
  NoteInterface,
  Subtask,
} from '@core/models/note.interface';
import { NoteService } from '@core/redux/note/note.service';
import { CategoryInterface } from '@core/models/category.interface';
import { go } from 'fuzzysort';
import { fileTypeValidator } from '@shared/validators/file-type.validator';
import { FileSizeValidator } from '@shared/validators/file-size.validator';
import { FileService } from '@core/services/file.service';
import { ImageSizeValidator } from '@shared/validators/image-size.validator';
import { filter, first, map, take } from 'rxjs/operators';
import { FileInterface } from '@core/models/file.interface';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { DeleteCategoryDialogService } from '@dialogs/delete-category-dialog';
import { CategoryFormDialogService } from '@dialogs/category-form';
import { Story } from '@core/redux/story/story.model';
import { parseCleaveNumber } from '@shared/tokens/cleave-number';
import { formatNumber } from '@angular/common';

const now = new Date();

const dailyQuestionTemplateId = /^question\d+$/;

export function shouldAddQuestionId(story?: Story): boolean {
  return story && dailyQuestionTemplateId.test(story.templateGroupKey);
}

@Injectable({
  providedIn: 'root',
})
export class NoteFormService {
  /**
   * Добавляет подзадачу из инпута компонента в список подзадач
   *
   * @param currentValue значение из формы
   */
  static extractSubtasks(
    startedSubtask: string,
    currentValue: Subtask[],
  ): Subtask[] {
    // добавить начатую в инпуте подзадачу
    const dirtyTasks = [
      ...currentValue,
      { text: startedSubtask, isComplete: false },
    ];
    // удалить подзадачи без текста перед отправкой
    return dirtyTasks.filter((subtask: Subtask) => subtask.text.trim());
  }

  readonly form = this.formBuilder.group({
    id: [undefined],
    note: [''],
    categoryId: [undefined, [Validators.required]],
    targetId: [undefined],
    isTask: [false],
    isComplete: [false],
    targetProgress: [0, []],
    year: [now.getFullYear(), [Validators.required]],
    month: [now.getMonth() + 1, [Validators.required]],
    day: [now.getDate(), [Validators.required]],
    files: [[]],
    pendingFiles: this.formBuilder.array([], Validators.maxLength(0)),
    checklist: [[]],
  });

  /**
   * Паттерн фильтраци dпоиска категорий
   */
  private readonly searchSettings = { key: 'name', threshold: -500 };

  /**
   * Какие поля проверить на наличие изменений для вызова подтверждения
   *
   * Если в каком-то из этих полей есть изменения, то при попытке закрыть
   * форму будет вызвано окно с подтверждением закрытия.
   *
   * Селект категории изначально помечается как измененный,
   * поэтому проверяем не все.
   */
  private readonly controlsToCheck = [
    'checklist',
    'note',
    'files',
    'targetProgress',
    'targetId',
  ];

  private readonly preventCategorySelectClosing$ = new BehaviorSubject(false);

  get files(): FormControl {
    return this.form.get('files') as FormControl;
  }

  get pendingFiles(): FormArray {
    return this.form.get('pendingFiles') as FormArray;
  }

  get preventCategorySelectClosing(): Observable<boolean> {
    return this.preventCategorySelectClosing$.asObservable();
  }

  constructor(
    private readonly categoryFormDialogService: CategoryFormDialogService,
    private readonly deleteCategoryDialogService: DeleteCategoryDialogService,
    private readonly fileSizeValidator: FileSizeValidator,
    private readonly fileService: FileService,
    private readonly formBuilder: FormBuilder,
    private readonly imageSizeValidator: ImageSizeValidator,
    private readonly noteService: NoteService,
  ) {
    this.subscribeToOpenDialogs();
  }

  addNewFiles(files: File[]) {
    for (const file of files) {
      const fileControl = this.getFormControlForFile(file);
      this.pendingFiles.insert(0, fileControl);
      fileControl.statusChanges
        .pipe(
          filter(
            () =>
              fileControl.status === 'VALID' ||
              fileControl.status === 'INVALID',
          ),
          first(),
        )
        .subscribe(() => {
          if (fileControl.status === 'VALID') {
            this.uploadFile(file);
          }
        });
    }
  }

  deleteImage(
    image: FileInterface,
    uploadedFiles: AbstractControl,
    noteFiles: FileInterface[],
  ) {
    const files: FileInterface[] = uploadedFiles.value.filter(
      (file) => file.id !== image.id,
    );
    uploadedFiles.setValue(files);

    // проверить, не вернулись ли файлы к тому же состоянию,
    // в котором они были изначально в заметке
    const sameLengthAsInital = files.length === noteFiles.length;
    const sameAsInitial =
      sameLengthAsInital && files.every((f, i) => f.id === noteFiles[i].id);
    // если файлы такие же, как и были
    if (sameAsInitial) {
      // пометить форму как чистую,
      // чтобы не вызвать попап подтверждения
      uploadedFiles.markAsPristine();
    } else {
      uploadedFiles.markAsDirty();
    }
  }

  deletePendingImage(file: File) {
    const imageControlIndex = this.pendingFiles.controls.findIndex(
      (c) => c.value === file,
    );
    if (imageControlIndex === -1) {
      return;
    }
    this.pendingFiles.removeAt(imageControlIndex);
  }

  filterCategories(query: string, allCategories: CategoryInterface[]) {
    if (!query) {
      return allCategories;
    }
    return go(query, allCategories, this.searchSettings).map(
      (sortResult) => sortResult.obj,
    );
  }

  isDirty() {
    return this.controlsToCheck.some((name) => this.form.get(name).dirty);
  }

  /**
   *
   * @param noteData если undefined, то используется последнее сохраненное состояние формы
   */
  updateFormWith(noteData: Partial<NoteInterface> | string | undefined) {
    if (!noteData) {
      return;
    }
    const note$ =
      typeof noteData === 'string'
        ? this.noteService.getNoteById(noteData).pipe(take(1))
        : of({ ...getEmptyNote(), ...noteData });
    note$.subscribe((note) => {
      this.form.get('id').setValue(note.id);
      this.form.get('note').setValue(note.note);
      this.form.get('categoryId').setValue(note.categoryId);
      this.form.get('targetId').setValue(note.targetId);
      this.form.get('isTask').setValue(note.isTask);
      this.form.get('isComplete').setValue(note.isComplete);
      this.form
        .get('targetProgress')
        .setValue(formatNumber(note.targetProgress, 'ru'));
      this.form.get('year').setValue(note.year);
      this.form.get('month').setValue(note.month);
      this.form.get('day').setValue(note.day);
      this.files.setValue(note.files);
      this.pendingFiles.clear();
      this.form
        .get('checklist')
        .setValue(note.checklist.map((c) => Object.assign({}, c)));
      this.form.markAsPristine();
    });
  }

  submit(note: NoteInterface, startedSubtaskText: string, story?: Story) {
    const request: NoteInterface = {
      ...note,
      checklist: NoteFormService.extractSubtasks(
        startedSubtaskText,
        note.checklist,
      ),
      questionOfDayId: shouldAddQuestionId(story)
        ? story.templateId
        : undefined,
      targetProgress: parseCleaveNumber(note.targetProgress.toString()),
    };
    return request.id
      ? this.noteService.update(request)
      : this.noteService.create(request);
  }

  private getFormControlForFile(file: File): FormControl {
    const control = new FormControl(
      file,
      [
        fileTypeValidator('image'),
        this.fileSizeValidator.validate.bind(this.fileSizeValidator),
      ],
      this.imageSizeValidator.validate.bind(this.imageSizeValidator),
    );
    // иначе не запускается .valueChanges
    control.updateValueAndValidity();
    return control;
  }

  private uploadFile(file: File) {
    this.fileService.upload(file).subscribe(
      (uploadedFile) => {
        const nextFiles = [uploadedFile, ...this.files.value];
        this.files.setValue(nextFiles);
        this.files.markAsDirty();
        this.deletePendingImage(file);
      },
      (err) => this.files.setErrors({ uploadFailure: err }),
    );
  }

  private subscribeToOpenDialogs() {
    combineLatest([
      this.categoryFormDialogService.opened,
      this.deleteCategoryDialogService.opened,
    ])
      .pipe(map((dialogs) => dialogs.some((o) => o)))
      .subscribe((hasOpen) => this.preventCategorySelectClosing$.next(hasOpen));
  }
}
