import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfirmationDialogComponent } from './confirmation-dialog.component';
import { UiIconModule } from '@ui/ui-icon/ui-icon.module';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonsModule } from '@ui/buttons/buttons.module';

@NgModule({
  declarations: [ConfirmationDialogComponent],
  imports: [CommonModule, UiIconModule, TranslateModule, ButtonsModule],
  entryComponents: [ConfirmationDialogComponent],
})
export class ConfirmationDialogModule {}
