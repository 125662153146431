import { Pipe, PipeTransform } from '@angular/core';
import { AchievementType } from '@core/models/identity.interface';

const typeToIconMap: { [key in AchievementType]: string[] } = {
  maxStreak: ['achievement-sun', 'achievement-cloud', 'eyee', 'flower'],
  questions: new Array(4).fill('achievement-question'),
  goals: new Array(4).fill('achievement-flow'),
  affiliates: new Array(4).fill('achievement-lamp'),
  filledDays: ['bronze', 'silver', 'gold', 'diamond', 'space', 'space'],
};

@Pipe({
  name: 'achievementIcon',
})
export class AchievementIconPipe implements PipeTransform {
  transform(
    achievementType: AchievementType,
    achievementLevel: number,
  ): string {
    return typeToIconMap[achievementType][achievementLevel - 1];
  }
}
