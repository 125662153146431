import { select, Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import * as fromRoot from '@core/redux/index';

import { filter, map, mergeMap, take } from 'rxjs/operators';
import {
  BackendIdentity,
  IdentityInterface,
} from '@core/models/identity.interface';
import { SignInInterface } from '@core/models/sign-in.interface';
import { SignUpInterface } from '@core/models/sign-up.interface';
import { tokenKey } from '@core/utils/token.utils';
import { Observable, of, throwError } from 'rxjs';
import { SignInSocialInterface } from '@core/models/sign-in-social.interface';
import { AddAccountInterface } from '@core/models/add-account.interface';
import { PersistentStorageService } from '@core/services/persistent-storage.service';
import * as Sentry from '@sentry/browser';
import { inElectron, sendToElectron } from '@helpers/consts/electron';
import {
  addAccountAction,
  changePasswordAction,
  checkPasswordResetTokenAction,
  checkPasswordResetTokenFailureAction,
  getUserAction,
  getUserSuccessAction,
  setTokenAction,
  signInAction,
  signInSocialAction,
  signOutAction,
  signUpAction,
  updateAvatarAction,
  updateAvatarFailureAction,
  updateAvatarSuccessAction,
  updateProfileAction,
  updateProfileFailureAction,
  updateProfileSuccessAction,
} from './auth.actions';
import { HttpErrorResponse } from '@angular/common/http';
import { Actions, ofType } from '@ngrx/effects';
import { FileInterface } from '@core/models/file.interface';
import { TypedAction } from '@ngrx/store/src/models';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    private readonly actions: Actions,
    private readonly persistentStorageService: PersistentStorageService,
    private readonly store: Store<fromRoot.State>,
  ) {
    const accessToken = this.persistentStorageService.get(tokenKey);
    if (accessToken) {
      this.setToken(accessToken);
    }

    this.updateSentryUserOnUserChange();
  }

  signOut() {
    this.store.dispatch(signOutAction());
  }

  getUser(allowUndefined = false): Observable<IdentityInterface> {
    const filterIfAsked = allowUndefined
      ? map<IdentityInterface, IdentityInterface>((u) => u)
      : filter<IdentityInterface>((u) => !!u);
    return this.store.pipe(select(fromRoot.getUser), filterIfAsked);
  }

  authSocial(data: SignInSocialInterface) {
    this.store.dispatch(signInSocialAction({ data }));
  }

  addAccount(data: AddAccountInterface) {
    this.store.dispatch(addAccountAction({ data }));
  }

  getIsAuth() {
    return this.store.pipe(select(fromRoot.getIsAuth));
  }

  getValidationErrors() {
    return this.store.pipe(select(fromRoot.getErrors));
  }

  getLoading() {
    return this.store.pipe(select(fromRoot.getLoading));
  }

  getIsActive() {
    return this.store.pipe(select(fromRoot.getIsActive));
  }

  getAccessToken() {
    return this.store.pipe(select(fromRoot.getToken));
  }

  getPasswordResetState() {
    return this.store.pipe(select(fromRoot.getPasswordResetState));
  }

  refreshUser() {
    this.store.dispatch(getUserAction());
  }

  update(user: IdentityInterface) {
    this.store.dispatch(
      updateProfileAction({
        user: {
          ...user,
          achievements: undefined,
        },
      }),
    );
    return this.actions.pipe(
      ofType(updateProfileSuccessAction, updateProfileFailureAction),
      take(1),
    );
  }

  signIn(credentials: SignInInterface, redirect?: string) {
    this.store.dispatch(signInAction({ credentials, redirect }));
  }

  signUp(credentials: SignUpInterface) {
    this.store.dispatch(signUpAction({ credentials }));
  }

  setToken(token: string) {
    this.store.dispatch(setTokenAction({ token }));
  }

  changePassword(newPassword: string) {
    this.store.dispatch(changePasswordAction({ newPassword }));
  }

  setUser(user: BackendIdentity) {
    this.store.dispatch(getUserSuccessAction({ user }));
  }

  checkPasswordResetToken(token: string) {
    if (token) {
      this.store.dispatch(checkPasswordResetTokenAction({ token }));
    } else {
      this.store.dispatch(
        checkPasswordResetTokenFailureAction({
          error: new HttpErrorResponse({ status: 400 }),
        }),
      );
    }
  }

  updateAvatar(
    file: File,
  ): Observable<
    {
      avatar: FileInterface;
    } & TypedAction<'[account api] update avatar success'>
  > {
    this.store.dispatch(updateAvatarAction({ newAvatar: file }));
    return this.actions.pipe(
      ofType(updateAvatarSuccessAction, updateAvatarFailureAction),
      mergeMap((action) =>
        action.type === updateAvatarSuccessAction.type
          ? of(action)
          : throwError(action.error),
      ),
      take(1),
    );
  }

  private updateSentryUserOnUserChange() {
    this.getUser(true).subscribe((user) => {
      const sentryUser: Sentry.User | null = user
        ? { id: user.id, email: user.email, language: user.language }
        : null;
      Sentry.setUser(sentryUser);
      if (inElectron) {
        sendToElectron('toElectron:update-user', sentryUser);
      }
    });
  }
}
