import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { Store } from '@ngrx/store';
import { map, debounceTime } from 'rxjs/operators';
import { Observable } from 'rxjs/internal/Observable';
import * as fromRoot from '@core/redux/index';

@Injectable({
  providedIn: 'root',
})
export class AuthGuardService implements CanActivate {
  constructor(private router: Router, private store: Store<fromRoot.State>) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> {
    return this.store.select(fromRoot.getIsAuth).pipe(
      map(isAuth => {
        if (!isAuth) {
          this.router.navigate(['/auth/sign-in'], {
            queryParams: { redirect: state.url },
          });
          return false;
        }

        return true;
      }),
    );
  }
}
