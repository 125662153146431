import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

/**
 * Ошибки валидации типа файла
 */
export interface FileTypeValidationErrors extends ValidationErrors {
  /**
   * Название ошибки
   */
  fileType: {
    /**
     * Тип файла в инпуте
     */
    actual: string;
    /**
     * Ожидаемый тип файла
     */
    expected: string;
  };
}

/**
 * @description
 * Валидатор, проверяющий, что был передан файл выбранного типа. Если value
 * у контрола пустое (null), или не имеет свойства `type`, то проверка пройдет, так
 * как не все браузеры поддерживают свойство `type` (Firefox на андроиде).
 *
 * @usageNotes
 *
 * ### Валидация картинок
 *
 * ```typescript
 * const control = new FormControl({type: 'application/pdf'}, fileTypeValidator('image'));
 *
 * console.log(control.errors); // {fileType: {actual: 'application/pdf', expected: 'image'}}
 * ```
 *
 * @returns Функцию валидацию, которая возвращает `FileTypeValidationErrors` или `null`,
 * в зависимости от того, относится ли тип файла к `fileType`
 */
export function fileTypeValidator(fileType: string): ValidatorFn {
  return (control: AbstractControl): FileTypeValidationErrors | null => {
    const value = control.value as File;
    if (!value) {
      return null;
    }
    if (!value.type) {
      return null;
    }
    if (value.type.startsWith(fileType)) {
      return null;
    }
    return {
      fileType: {
        actual: value.type,
        expected: fileType,
      },
    };
  };
}
