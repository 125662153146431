import { NgModule } from '@angular/core';
import { YoutubeComponent } from './youtube.component';
import { CommonModule } from '@angular/common';

@NgModule({
  imports: [CommonModule],
  declarations: [YoutubeComponent],
  exports: [YoutubeComponent],
  entryComponents: [YoutubeComponent],
})
export class YoutubeModule {}
