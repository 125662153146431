import { select, Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import * as TargetActions from '@core/redux/goal/target.actions';
import { TargetLoad, TargetLoadSuccess } from '@core/redux/goal/target.actions';
import * as fromRoot from '@core/redux/index';
import { combineLatest, Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import {
  GoalStepsViewMode,
  TargetInterface,
} from '@core/models/target.interface';
import * as moment from 'moment';
import { TargetTypeInterface } from '@core/models/target-type.interface';
import { Actions, ofType } from '@ngrx/effects';
import { changeGoalStepsGroupingAction } from './goals.actions';

function addType(
  target: TargetInterface,
  types: TargetTypeInterface[],
): TargetInterface {
  return { ...target, type: types.find((t) => t.id === target.typeId) };
}

@Injectable({
  providedIn: 'root',
})
export class GoalsService {
  constructor(
    private readonly actions: Actions,
    private readonly store: Store<fromRoot.State>,
  ) {}

  getById(goalId: string): Observable<TargetInterface | undefined> {
    return combineLatest([
      this.store.pipe(select(fromRoot.getTargetTypeListAll)),
      this.store.pipe(select(fromRoot.getTargetById, { goalId })),
    ]).pipe(
      map(([types, target]) => (target ? addType(target, types) : target)),
    );
  }

  getRequestState() {
    return this.store.select(fromRoot.getTargetRequestState);
  }

  /**
   * Получение целей списком
   */
  getAll() {
    const targetsList$ = this.store.pipe(select(fromRoot.getTargetList));
    const targetTypeList$ = this.store.pipe(
      select(fromRoot.getTargetTypeListAll),
    );

    return combineLatest([targetsList$, targetTypeList$]).pipe(
      map(([targets, types]) => targets.map((t) => addType(t, types))),
    );
  }

  /**
   * Все, даже удаленные и завершенные
   */
  getAllReallyAll() {
    const targetsList$ = this.store.pipe(select(fromRoot.getTargetListAll));
    const targetTypeList$ = this.store.pipe(
      select(fromRoot.getTargetTypeListAll),
    );

    return combineLatest([targetsList$, targetTypeList$]).pipe(
      map(([targets, types]) => targets.map((t) => addType(t, types))),
    );
  }

  /**
   *
   */
  getAllForMain() {
    const targetsList$ = this.store.pipe(select(fromRoot.getTargetListForMain));
    const targetTypeList$ = this.store.pipe(
      select(fromRoot.getTargetTypeListAll),
    );

    return combineLatest([targetsList$, targetTypeList$]).pipe(
      map(([targets, types]) => targets.map((t) => addType(t, types))),
    );
  }

  getTotalCount() {
    return this.store.pipe(select(fromRoot.getTargetCount));
  }

  getLoading() {
    return this.store.pipe(select(fromRoot.getTargetLoading));
  }

  getValidationErrors() {
    return this.store.pipe(select(fromRoot.getTargetErrors));
  }

  /**
   *
   */
  dispatchAll() {
    this.store.dispatch(new TargetLoad());
  }

  dispatchAllSuccess(data) {
    this.store.dispatch(new TargetLoadSuccess(data));
  }

  update(
    target: TargetInterface,
  ): Observable<
    | TargetActions.TargetUpdateFailureAction
    | TargetActions.TargetUpdateSuccessAction
  > {
    this.store.dispatch(new TargetActions.TargetUpdateAction(target));
    return this.actions.pipe(
      ofType(
        TargetActions.TargetActionTypes.TargetUpdateFailureAction,
        TargetActions.TargetActionTypes.TargetUpdateSuccessAction,
      ),
      take(1),
    );
  }

  changeStepsGroupingView(goalId: string, view: GoalStepsViewMode) {
    this.store.dispatch(
      changeGoalStepsGroupingAction({
        view,
        goalId,
      }),
    );
  }

  delete(id: string) {
    this.store.dispatch(new TargetActions.TargetDeleteAction(id));
  }

  create(
    model: TargetInterface,
  ): Observable<
    | TargetActions.TargetCreateSuccessAction
    | TargetActions.TargetCreateFailureAction
  > {
    this.store.dispatch(new TargetActions.TargetCreateAction(model));
    return this.actions.pipe(
      ofType(
        TargetActions.TargetActionTypes.TargetCreateSuccessAction,
        TargetActions.TargetActionTypes.TargetCreateFailureAction,
      ),
      take(1),
    );
  }

  getDeadlineCssClass(target: TargetInterface) {
    let deadlineClass = '';

    if (target.deadlineAt) {
      const nowStartDay = moment().startOf('day');
      const deadlineAt = moment(target.deadlineAt).valueOf();

      if (deadlineAt < nowStartDay.valueOf() && !target.isComplete) {
        deadlineClass = 'target__description_red';
      } else {
        deadlineClass = 'target__description_green';
      }
    }

    return deadlineClass;
  }
}
