import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatCommonModule, MatRippleModule } from '@angular/material/core';
import { ButtonComponent } from './button/button.component';
import { UiIconModule } from '@ui/ui-icon/ui-icon.module';

@NgModule({
  declarations: [ButtonComponent],
  imports: [CommonModule, MatRippleModule, MatCommonModule, UiIconModule],
  exports: [ButtonComponent],
})
export class ButtonsModule {}
