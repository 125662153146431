import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { catchError, exhaustMap, map } from 'rxjs/operators';
import { of } from 'rxjs/index';
import {
  GetDefaultAction,
  GetDefaultActionFailureAction,
  GetDefaultActionSuccessAction,
  TariffGroupActionTypes,
} from '@core/redux/tariff-group/tariff-group.actions';
import { TariffGroupService } from '@core/services/tariff-group.service';

@Injectable()
export class TariffGroupEffects {
  @Effect()
  getDefault$ = this.actions$.pipe(
    ofType(TariffGroupActionTypes.GetDefaultAction),
    map((action: GetDefaultAction) => action),
    exhaustMap(() => {
      return this.tariffGroupService.getDefault().pipe(
        map(response => new GetDefaultActionSuccessAction(response)),
        catchError(errors => of(new GetDefaultActionFailureAction(errors))),
      );
    }),
  );

  constructor(
    private actions$: Actions,
    private tariffGroupService: TariffGroupService,
  ) {}
}
