<div *ngIf="target" class="ui-option-target">
  <span
    *ngIf="target.image?.sizes"
    class="ui-option-target__img"
    [style.background-image]="
      'url(' + (target.image | imagePreview: 82:82) + ')'
    "
  ></span>
  <div class="ui-option-target__info">
    <b class="ui-option-target__title">{{ target.title }}</b>
    <span class="ui-option-target__sminfo">
      <i [style.backgroundColor]="target.color" class="circle"></i>
      <span>
        {{ target.progress || 0 | number }} {{ 'UI.NOTE_FORM.OF' | translate }}
        {{ target.target || 0 | number }}. ({{ relativeProgress | number }}%)
      </span>
    </span>
  </div>
  <div class="ui-option-target__check">
    <ui-icon *ngIf="selected" name="done" class="ui-icon_color_green"></ui-icon>
  </div>
</div>
