import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { inElectron, nativeOS } from 'src/app/helpers/consts/electron';
import { tap } from 'rxjs/operators';
import { ReleaseService } from '@core/redux/release/release.service';
import { environment } from 'src/environments/environment';

const packageJson = require('../../../../package.json');

const nodeToServerOSMap = {
  win32: 'windows',
  darwin: 'macOS',
};

const normalizedPlatformInfo = inElectron
  ? nodeToServerOSMap[nativeOS] || 'macOS' // for Linux, etc
  : 'web';

const headers = {
  'X-app-info-platform': normalizedPlatformInfo,
  'X-app-info-version': packageJson.version,
};

const responseVersionHeader = 'X-App-Current-Version';

@Injectable({
  providedIn: 'root',
})
export class AppInfoInterceptor implements HttpInterceptor {
  constructor(private releaseService: ReleaseService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    if (
      !request.url.startsWith(environment.apiUrl) ||
      request.url.startsWith(environment.electron.storage)
    ) {
      return next.handle(request);
    }
    return next
      .handle(
        request.clone({
          setHeaders: headers,
        }),
      )
      .pipe(
        tap((event) => {
          if (
            event instanceof HttpResponse &&
            request.url.startsWith(environment.apiUrl)
          ) {
            const responseVersion = event.headers.get(responseVersionHeader);
            if (responseVersion && responseVersion !== packageJson.version) {
              this.releaseService.receiveWebUpdate();
            }
          }
        }),
      );
  }
}
