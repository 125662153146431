import { Injectable } from '@angular/core';
import { ApiService } from '@core/services/api.service';
import { InitInterface } from '@core/models/init.interface';
import { AuthService } from '@core/redux/auth/auth.service';
import { TariffGroupService } from '@core/redux/tariff-group/tariff-group.service';
import { TagService } from '@core/redux/tag/tag.service';
import { CategoryService } from '@core/redux/category/category.service';
import { TargetTypeService } from '@core/redux/target-type/target-type.service';
import { TargetImageService } from '@core/redux/target-image/target-image.service';
import { GoalsService } from '@core/redux/goal/goals.service';
import { TariffService } from '@core/redux/tariff/tariff.service';
import { HttpClient } from '@angular/common/http';
import { retryWhen, delay, take, concat } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { getStoriesSuccess } from '@core/redux/story/story.actions';
import { Store } from '@ngrx/store';
import { State } from '@core/redux';

@Injectable({
  providedIn: 'root',
})
export class InitService extends ApiService {
  constructor(
    private authService: AuthService,
    private categoryService: CategoryService,
    private store: Store<State>,
    private tagService: TagService,
    private tariffGroupService: TariffGroupService,
    private targetTypeService: TargetTypeService,
    private targetImageService: TargetImageService,
    private targetService: GoalsService,
    private tariffService: TariffService,
    protected http: HttpClient,
  ) {
    super(http);
  }

  prepare() {
    return this.http
      .get<InitInterface>(this.getApiUrl('init'))
      .pipe(
        retryWhen((errors) =>
          errors.pipe(delay(500), take(5), concat(throwError(errors))),
        ),
      )
      .subscribe(
        (response) => {
          this.tagService.dispatchAllSuccess(response.tags);
          this.authService.setUser(response.user);
          this.tariffGroupService.dispatchDefaultSuccess(response.tariffGroup);
          this.categoryService.dispatchAllSuccess(response.categories);
          this.targetTypeService.dispatchAllSuccess(response.targetTypes);
          this.targetImageService.dispatchAllSuccess(response.targetImages);
          this.targetService.dispatchAllSuccess(response.targets);
          this.tariffService.dispatchAllSuccess(response.tariff);
          this.store.dispatch(getStoriesSuccess({ stories: response.stories }));
        },
        (error) => console.log(error),
      );
  }
}
