import { Pipe, PipeTransform } from '@angular/core';
import { AchievementType } from '@core/models/identity.interface';

@Pipe({
  name: 'translateAchievement',
})
export class TranslateAchievementPipe implements PipeTransform {
  transform(
    achievementType: AchievementType,
    level: number,
    phrase: string,
  ): string;
  transform(achievementType: AchievementType, phrase: string): string;
  transform(
    achievementType: AchievementType,
    levelOrPhrase: number | string,
    phrase?: string,
  ) {
    if (phrase) {
      return `CONSTS.ACHIEVEMENTS.${achievementType.toLocaleUpperCase()}.LEVELS.${levelOrPhrase}.${phrase}`;
    } else if (typeof levelOrPhrase === 'string') {
      return `CONSTS.ACHIEVEMENTS.${achievementType.toLocaleUpperCase()}.${levelOrPhrase.toLocaleUpperCase()}`;
    }
  }
}
