import {
  Injectable,
  StaticProvider,
  Injector,
  NgZone,
  InjectionToken,
  TemplateRef,
  ElementRef,
} from '@angular/core';
import {
  ConnectedPosition,
  OverlayRef,
  RepositionScrollStrategy,
  ScrollDispatcher,
  Overlay,
  OverlayPositionBuilder,
  ViewportRuler,
  OverlayConfig,
} from '@angular/cdk/overlay';
import { TooltipRef } from './tooltip-ref';
import { TooltipComponent } from './tooltip.component';
import { ComponentPortal, ComponentType } from '@angular/cdk/portal';
import { TooltipConfig } from './tooltip-config';

export const TOOLTIP_DATA = new InjectionToken('RtTooltipData');

export const TOOLTIP_RIGHT_POSITION: ConnectedPosition = {
  originX: 'end',
  originY: 'center',
  overlayX: 'start',
  overlayY: 'center',
};

export const TOOLTIP_TOP_POSITION: ConnectedPosition = {
  originX: 'center',
  originY: 'top',
  overlayX: 'center',
  overlayY: 'bottom',
};

export function withOffset(
  position: ConnectedPosition,
  offsetY: number,
  offsetX: number,
): ConnectedPosition {
  return {
    ...position,
    offsetX,
    offsetY,
  };
}

@Injectable({
  providedIn: 'root',
})
export class TooltipService {
  private readonly position: ConnectedPosition[] = [
    {
      originX: 'center',
      originY: 'bottom',
      overlayX: 'center',
      overlayY: 'top',
    },
  ];

  constructor(
    private readonly injector: Injector,
    private readonly ngZone: NgZone,
    private readonly overlay: Overlay,
    private readonly overlayPositionBuilder: OverlayPositionBuilder,
    private readonly scrollDispatcher: ScrollDispatcher,
    private readonly viewportRuler: ViewportRuler,
  ) {}

  open<D = any, C = any>(
    content: ComponentType<C> | TemplateRef<any> | string,
    config: TooltipConfig<D>,
  ): TooltipRef<D> {
    const overlayConfig = this.getOverlayConfig(config);
    const overlayRef = this.overlay.create(overlayConfig);
    const tooltipRef = new TooltipRef<D>(config, overlayRef);
    const componentPortal = new ComponentPortal(
      TooltipComponent,
      undefined,
      this.createInjector(config.data, overlayRef, tooltipRef),
    );
    const componentRef = overlayRef.attach(componentPortal);
    componentRef.instance.setView<C>(content, config);
    tooltipRef.setComponentRef(componentRef);
    this.removeTooltipWhenDOMElementRemoved(config, tooltipRef);
    return tooltipRef;
  }

  /**
   * Creates a custom injector to be used inside the tooltip.
   * @param config Config object that is used to construct the tooltip.
   */
  private createInjector<D>(
    data: D,
    overlayRef: OverlayRef,
    tooltipRef: TooltipRef<D>,
  ): Injector {
    const providers: StaticProvider[] = [
      { provide: TOOLTIP_DATA, useValue: data },
      { provide: TooltipRef, useValue: tooltipRef },
      { provide: OverlayRef, useValue: overlayRef },
    ];
    return Injector.create({ parent: this.injector, providers });
  }

  private getOverlayConfig(config: TooltipConfig<unknown>): OverlayConfig {
    const position = config.position ? [config.position] : this.position;
    const positionStrategy = this.overlayPositionBuilder
      // Create position attached to the elementRef
      .flexibleConnectedTo(config.relativeTo)
      // Describe how to connect overlay to the elementRef
      // Means, attach overlay's center bottom point to the
      // top center point of the elementRef.
      .withPositions(position);
    return new OverlayConfig({
      positionStrategy,
      scrollStrategy: new RepositionScrollStrategy(
        this.scrollDispatcher,
        this.viewportRuler,
        this.ngZone,
      ),
    });
  }

  private removeTooltipWhenDOMElementRemoved(
    config: TooltipConfig<unknown>,
    tooltipRef: TooltipRef<unknown>,
  ) {
    const isDOMElement =
      (config.relativeTo as ElementRef).nativeElement ||
      (config.relativeTo as Node).nodeName;
    if (!isDOMElement) {
    }
    const originNode: HTMLElement = (config.relativeTo as ElementRef)
      .nativeElement
      ? (config.relativeTo as ElementRef).nativeElement
      : config.relativeTo;
    const mutationObserver = new MutationObserver((mutationsList) => {
      for (const mutation of mutationsList) {
        let removedOrigin = false;
        if (mutation.type === 'childList') {
          mutation.removedNodes.forEach((node) => {
            if (node.isSameNode(originNode) || node.contains(originNode)) {
              removedOrigin = true;
            }
          });
        }
        if (removedOrigin) {
          tooltipRef.close();
          mutationObserver.disconnect();
        }
      }
    });
    mutationObserver.observe(document.body, {
      childList: true,
      subtree: true,
      attributes: false,
      characterData: false,
    });
  }
}
