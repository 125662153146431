import { Injectable } from '@angular/core';
import { ApiService } from '@core/services/api.service';
import {
  GoalStepsViewMode,
  TargetInterface,
} from '@core/models/target.interface';
import { Observable } from 'rxjs/internal/Observable';

/**
 * Сервис для работы с апи целями
 */
@Injectable({
  providedIn: 'root',
})
export class GoalsHttpService extends ApiService {
  /**
   * Получить с сервера все категории
   */
  search(): Observable<TargetInterface[]> {
    return this.http.get<TargetInterface[]>(this.getApiUrl(`targets`));
  }

  /**
   * Создать запись
   */
  create(model: TargetInterface): Observable<TargetInterface> {
    return this.http.post<TargetInterface>(this.getApiUrl(`targets`), model);
  }

  /**
   * Обновить запись
   */
  update(model: TargetInterface): Observable<TargetInterface> {
    return this.http.put<TargetInterface>(
      this.getApiUrl(`targets/${model.id}`),
      model,
    );
  }

  view(id: string): Observable<TargetInterface> {
    return this.http.get<TargetInterface>(this.getApiUrl(`targets/${id}`));
  }

  updateStepsGroupingView(goalId: string, view: GoalStepsViewMode) {
    return this.http.put<TargetInterface>(
      this.getApiUrl(`targets/${goalId}/change-achievement-view`),
      {
        view,
      },
    );
  }

  /**
   * Удалить запись
   */
  destroy(id: string): Observable<TargetInterface> {
    return this.http.delete<TargetInterface>(this.getApiUrl(`targets/${id}`));
  }
}
