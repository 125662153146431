import { getSearchQuerys } from '@core/utils/querybuilder';
import { TagInterface } from '@core/models/tag.interface';
import { TargetInterface } from '@core/models/target.interface';
import { CategoryInterface } from '@core/models/category.interface';
import { FileInterface } from '@core/models/file.interface';
import { NoteType } from '@core/models/constants/note.model';

export class NotePositionInterface {
  id: string;
  prevId?: string;
  nextId?: string;
  year: number;
  month: number;
  day: number;
  isSort: boolean;
}

export interface Subtask {
  isComplete: boolean;
  text: string;
  animate?: string;
}

/**
 * Модель заметки
 */
export class NoteInterface extends NotePositionInterface {
  note: string;
  categoryId: string;
  tags: TagInterface[];
  tagIds: string[];
  target: TargetInterface;
  category: CategoryInterface;
  files: FileInterface[] = [];
  hours: number;
  minutes: number;
  targetId: string;
  targetProgress: number;
  isTask: boolean;
  isComplete: boolean;
  createdAt: string;
  checklist: Subtask[];
  questionOfDayId?: string;
}

/**
 * Модель категории с задачами
 */
export interface CategoryTasks {
  category: CategoryInterface;
  tasks: NoteInterface[];
}

/**
 * Модель описывающая response ошибки валидации с API
 */
export class NoteErrorModel {
  note: string;
  categoryId: string;
  day: string;
  year: string;
  month: string;
  hours: string;
  minutes: string;
}

/**
 * Query Builder для получения заметок
 */
export class NoteQueryModel {
  static fromCategory(categoryId: string[]): NoteQueryModel {
    const query = new NoteQueryModel();
    query.categoryId = categoryId;
    return query;
  }

  static fromTags(tagIds: string[]): NoteQueryModel {
    const query = new NoteQueryModel();
    query.tagIds = tagIds;
    return query;
  }

  static fromTarget(targetId: string): NoteQueryModel {
    const query = new NoteQueryModel();
    query.targetId = targetId;
    return query;
  }

  id: number;
  month: number;
  year: number;
  tagIds: string[] = [];
  targetId: string;
  categoryId: string[] = [];
  type: NoteType;
  fromDay: number;
  fromMonth: number;
  fromYear: number;
  toDay: number;
  toMonth: number;
  toYear: number;
  status: number;

  getQuery(): string {
    return getSearchQuerys(this).join('&');
  }
}

export class NoteChangeCategoryInterface {
  categoryId: string;
  newCategoryId: string;
}

export function getEmptyNote(date = new Date()): NoteInterface {
  const noteDate = {
    day: date.getDate(),
    month: date.getMonth() + 1,
    year: date.getFullYear(),
    hours: date.getHours(),
    minutes: date.getMinutes(),
  };
  return {
    id: undefined,
    categoryId: undefined,
    tagIds: [],
    tags: [],
    target: undefined,
    targetId: undefined,
    category: undefined,
    files: [],
    hours: 0,
    minutes: 0,
    day: 1,
    month: 1,
    year: 2000,
    checklist: [],
    targetProgress: 0,
    createdAt: '2000-01-01T00:00:00',
    note: '',
    isTask: false,
    isComplete: false,
    isSort: false,
    ...noteDate,
  };
}
