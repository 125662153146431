import { Injectable } from '@angular/core';
import { ApiService } from '@core/services/api.service';
import { Story } from './story.model';

@Injectable({
  providedIn: 'root',
})
export class ApiStoryService extends ApiService {
  get() {
    return this.http.get<Story[]>(this.getApiUrl('stories'));
  }

  markAsViewed(id: string) {
    return this.http.put<Story>(this.getApiUrl('stories/' + id), {
      isWatched: 1,
    });
  }
}
