<div class="goal-select__header">
  {{ 'UI.NOTE_FORM.CHOOSE' | translate }}
  <button (click)="onClose()" ui-button-icon type="button">
    <ui-icon name="close" class="ui-icon ui-icon_hover_close"></ui-icon>
  </button>
</div>
<div class="goal-select__scroll-content">
  <button
    (click)="onSelect(undefined)"
    class="btn btn_not-button goal-select__empty"
    type="button"
  >
    <div class="goal-select__empty-icon">
      <ui-icon class="ui-icon" name="no-state"></ui-icon>
    </div>
    <div>
      {{ 'UI.NOTE_FORM.NO_GOAL' | translate }}
    </div>
  </button>
  <button
    *ngFor="let goal of goals"
    (click)="onSelect(goal)"
    class="btn btn_not-button goal-select__goal"
    type="button"
  >
    <option-target [target]="goal" [selected]="selectedId === goal.id">
    </option-target>
  </button>
</div>
