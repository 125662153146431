import { Injectable } from '@angular/core';
import {
  DeleteCategoryDialogComponent,
  DeleteCategoryDialogData,
} from './delete-category-dialog.component';
import { DialogService } from '@ui/dialog';
import { MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DeleteCategoryDialogService {
  private dialogRef: MatDialogRef<DeleteCategoryDialogComponent>;
  private readonly opened$ = new BehaviorSubject<boolean>(false);

  get opened(): Observable<boolean> {
    return this.opened$.asObservable();
  }

  constructor(private readonly dialog: DialogService) {}

  close() {
    if (this.dialogRef) {
      this.dialogRef.close();
    }
  }

  open(data: DeleteCategoryDialogData) {
    this.dialogRef = this.dialog.open(
      DeleteCategoryDialogComponent,
      { data },
      false,
    );
    this.dialogRef.afterOpened().subscribe(() => {
      this.opened$.next(true);
    });
    this.dialogRef.afterClosed().subscribe(() => {
      this.opened$.next(false);
      this.dialogRef = undefined;
    });
    return this.dialogRef;
  }
}
