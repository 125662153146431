import {
  ModuleWithProviders,
  NgModule,
  Optional,
  SkipSelf,
} from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { TokenInterceptor } from '@core/interceptors/token.interceptor';
import { LanguageInterceptor } from '@core/interceptors/language.interceptor';
import { AppInfoInterceptor } from './interceptors/app-info.interceptor';
import { DataVersionInterceptor } from '@core/interceptors/data-version.interceptor';
import { CustomDatePipe } from './models/pipes/custom-date.pipe';

@NgModule({
  imports: [],
  declarations: [CustomDatePipe],
  exports: [CustomDatePipe],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LanguageInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AppInfoInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: DataVersionInterceptor,
      multi: true,
    },
  ],
})
export class CoreModule {
  static forRoot(): ModuleWithProviders<CoreModule> {
    return { ngModule: CoreModule };
  }

  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error('CoreModule already loaded; import in root module only.');
    }
  }
}
