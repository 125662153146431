import {
  Component,
  ViewChild,
  ViewChildren,
  QueryList,
  AfterViewInit,
  Inject,
  ElementRef,
  OnInit,
} from '@angular/core';
import { FileInterface } from '@core/models/file.interface';
import { NgxTinySliderComponent } from 'ngx-tiny-slider/lib/ngx-tiny-slider.component';
import { NgxTinySliderSettingsInterface } from 'ngx-tiny-slider';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DeviceDetectorService } from 'ngx-device-detector';
import { removeMatDialogContainerPadding } from '@shared/functions/mat-dialog-container';

export interface ImageSliderDialogData {
  images: FileInterface[];
  startFrom: number;
}

@Component({
  selector: 'app-image-slider',
  templateUrl: './image-slider.component.html',
  styleUrls: ['./image-slider.component.less'],
})
export class ImageSliderComponent implements OnInit, AfterViewInit {
  @ViewChild('slider')
  slider: NgxTinySliderComponent;

  @ViewChildren('previewButtons')
  previewButtons: QueryList<ElementRef<HTMLButtonElement>>;

  images: FileInterface[];

  tinySliderConfig: NgxTinySliderSettingsInterface = {
    items: 1,
    arrowKeys: true,
    lazyload: true,
    touch: true,
    mouseDrag: true,
    waitForDom: true,
    nav: false,
    controls: false,
  };

  activeIndex = 0;
  initialImg: FileInterface;
  /**
   * Отвечает за показ первой картинки поверх слайдера
   *
   * Помогает скрыть анимацию, которая есть в слайдере.
   * Пока анимация скролла не закончилась, показывается
   * картинка поверх слайдера. Как анимация закончится,
   * картинка удаляется и показывается настоящий слайдер.
   */
  shouldShowFakeImage = true;

  readonly isMobile: boolean;

  constructor(
    deviceService: DeviceDetectorService,
    @Inject(MAT_DIALOG_DATA)
    private readonly data: ImageSliderDialogData,
    private readonly dialogRef: MatDialogRef<ImageSliderComponent>,
  ) {
    this.isMobile = deviceService.isMobile() || deviceService.isTablet();
  }

  ngOnInit() {
    this.images = this.data.images;
    this.tinySliderConfig.startIndex = this.data.startFrom;
    this.initialImg = this.images[this.data.startFrom];
  }

  ngAfterViewInit() {
    removeMatDialogContainerPadding(this.dialogRef);
    this.slider.domReady.next();
    this.goToSelectedImage();
    this.slider.sliderInstance.events.on('indexChanged', (info) => {
      this.activeIndex = info.displayIndex - 1;
      const button = this.previewButtons.find(
        (b, index) => index === this.activeIndex,
      );
      if (button) {
        const options: ScrollIntoViewOptions = { behavior: 'smooth' };
        button.nativeElement.scrollIntoView(options);
      }
    });
  }

  onClose() {
    this.dialogRef.close();
  }

  onPanDown(event: { deltaY: number }, el: HTMLDivElement) {
    if (!this.isMobile) {
      return;
    }
    const img = el.getElementsByTagName('img')[0];
    if (!img) {
      return;
    }
    if (event.deltaY < 50) {
      img.style.transform = `translateY(${event.deltaY}px)`;
    } else {
      img.style.transform = 'none';
    }
  }

  onPanEnd(event: { deltaY: number }, el: HTMLDivElement) {
    if (!this.isMobile) {
      return;
    }
    const img = el.getElementsByTagName('img')[0];
    if (!img) {
      return;
    }
    if (event.deltaY < -150) {
      img.style.opacity = '0';
      img.style.transform = `translateY(${-1000}px)`;
      setTimeout(() => {
        this.onClose();
      }, 100);
    } else {
      img.style.transform = 'none';
    }
  }

  onPanUp(event: { deltaY: number }, el: HTMLDivElement) {
    if (!this.isMobile) {
      return;
    }
    const img = el.getElementsByTagName('img')[0];
    if (!img) {
      return;
    }
    img.style.transform = `translateY(${event.deltaY}px)`;
  }

  onSelectImage(index: number | 'next' | 'prev') {
    this.slider.sliderInstance.goTo(index);
  }

  private goToSelectedImage() {
    if (this.tinySliderConfig.startIndex) {
      this.onSelectImage(this.tinySliderConfig.startIndex);
      setTimeout(() => {
        this.activeIndex = this.tinySliderConfig.startIndex;
      });
    }
    setTimeout(() => {
      this.shouldShowFakeImage = false;
    }, 500);
  }
}
