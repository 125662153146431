<div class="matdialog_adaptive confirmation-dialog">
  <button
    (click)="onClose()"
    ui-button-icon
    class="confirmation-dialog__close"
    type="button"
  >
    <ui-icon class="ui-icon ui-icon_hover_close" name="close"></ui-icon>
  </button>
  <h2 class="confirmation-dialog__header">
    {{ data.title | translate }}
  </h2>
  <p class="confirmation-dialog__text" [innerHTML]="data.body | translate"></p>
  <div class="confirmation-dialog__actions">
    <button
      (click)="onDiscard()"
      ui-button-secondary
      class="confirmation-dialog__cancel"
    >
      {{ data.discard.text | translate }}
    </button>
    <button
      (click)="onAccept()"
      ui-button-primary-delete
      class="confirmation-dialog__discard"
    >
      {{ data.accept.text | translate }}
    </button>
  </div>
</div>
