<angular-popper
  #popper
  [show]="isOpen"
  [modifiers]="popperModifiers"
  [placement]="popperPlacement"
  class="select__popper"
>
  <div
    (click)="onToggleOpen()"
    [ngClass]="{ select__header_active: isOpen, select__header_dots: dots }"
    class="select__header"
  >
    <span *ngIf="isEmpty" class="select__value-view">
      <span class="select__value-view-text">
        {{ placeholder }}
      </span>
      <ui-icon name="arrow" class="ui-icon select__icon-arrow"> </ui-icon>
    </span>
    <span
      *ngIf="!isEmpty"
      [class.select__value-view_empty]="emptyValueView"
      class="select__value-view"
    >
      <span [innerHTML]="content"></span>
      <ui-icon name="arrow" class="ui-icon select__icon-arrow"> </ui-icon>
    </span>
  </div>
  <div
    (clickOutside)="onClose()"
    [class.select__popup_searchable]="withSearch"
    [exclude]="'.select__header'"
    [excludeBeforeClick]="true"
    [delayClickOutsideInit]="true"
    [class.select__popup_hidden]="!showPopper"
    class="select__popup"
    content
  >
    <ng-container *ngIf="!isEditing">
      <div *ngIf="withSearch" class="select__search">
        <ui-icon
          class="ui-icon ui-icon_color_dark-gray"
          name="search"
        ></ui-icon>
        <input
          #searchInput
          (input)="search.emit(searchInput.value)"
          [placeholder]="withSearch"
          class="form__input select__search-input"
          type="search"
          name="select"
        />
      </div>
      <div
        #optionList
        (mouseover)="removeAllFocus()"
        [class.select__content_full]="!limitHeight"
        class="select__content"
      >
        <ng-content></ng-content>
      </div>
      <div
        class="select__add body-text body-text_style_2"
        (click)="onCreate($event)"
        *ngIf="editable"
      >
        <ui-icon class="ui-icon select__icon-plus" name="plus"></ui-icon>
        <span translate>{{ addText }}</span>
      </div>
    </ng-container>
  </div>
</angular-popper>
