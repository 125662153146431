<div class="stories-dialog">
  <div class="stories-dialog__timeline">
    <span
      *ngFor="let story of stories; let index = index"
      [class.stories-dialog__dot_current]="index === currentStoryIndex"
      class="stories-dialog__dot"
    >
      <span
        *ngIf="index === currentStoryIndex"
        [ngStyle]="timeNgStyle"
        class="stories-dialog__time"
      >
      </span>
    </span>
  </div>
  <div class="stories-dialog__stories">
    <button
      (click)="onClose()"
      ui-button-icon
      class="stories-dialog__close"
      type="button"
    >
      <ui-icon name="close"></ui-icon>
    </button>
    <ng-container *ngFor="let story of stories">
      <app-story
        *ngIf="currentStory === story"
        @storyChange
        (tapped)="onTap($event)"
        (resumed)="onResume()"
        (stoped)="onStop()"
        [story]="currentStory"
        class="stories-dialog__story"
      >
      </app-story>
    </ng-container>
  </div>
</div>
<button
  (click)="onNext()"
  class="stories-dialog__right-button"
  type="button"
></button>
<button
  (click)="onNext(-1)"
  class="stories-dialog__left-button"
  type="button"
></button>
