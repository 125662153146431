import { Injectable } from '@angular/core';
import {
  CategoryFormDialogData,
  CategoryFormComponent,
} from './category-form.component';
import { DialogService } from '@ui/dialog';
import { MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CategoryFormDialogService {
  private dialogRef: MatDialogRef<CategoryFormComponent>;
  private opened$ = new BehaviorSubject<boolean>(false);

  get opened(): Observable<boolean> {
    return this.opened$.asObservable();
  }

  constructor(private readonly dialog: DialogService) {}

  close(): void {
    if (this.dialogRef) {
      this.dialogRef.close();
    }
  }

  open(data: CategoryFormDialogData) {
    this.dialogRef = this.dialog.open(
      CategoryFormComponent,
      { hasBackdrop: false, autoFocus: false, data },
      false,
    );
    this.dialogRef.afterOpened().subscribe(() => {
      this.opened$.next(true);
    });
    this.dialogRef.afterClosed().subscribe(() => {
      this.dialogRef = undefined;
      this.opened$.next(false);
    });
    return this.dialogRef;
  }
}
