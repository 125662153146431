import { Injectable } from '@angular/core';
import { YoutubeDialogData, YoutubeComponent } from './youtube.component';
import { MatDialog } from '@angular/material/dialog';

@Injectable({
  providedIn: 'root',
})
export class YoutubeDialogService {
  constructor(private readonly dialog: MatDialog) {}

  open(data: YoutubeDialogData) {
    return this.dialog.open(YoutubeComponent, { data });
  }
}
