import { Action } from '@ngrx/store';
import { BillingInformationInterface } from '@core/models/billing-information.interface';

export enum BillingInformationActionTypes {
  BillingInformationLoad = '[BillingInformation] Load',
  BillingInformationLoadSuccess = '[BillingInformation] Load Success',
  BillingInformationLoadFailure = '[BillingInformation] Load Failure',
  BillingInformationDelete = '[BillingInformation] Delete',
  BillingInformationDeleteSuccess = '[BillingInformation] Delete Success',
  BillingInformationDeleteFailure = '[BillingInformation] Delete Failure',
}

export class BillingInformationLoad implements Action {
  readonly type = BillingInformationActionTypes.BillingInformationLoad;

  constructor() {}
}

export class BillingInformationLoadSuccess implements Action {
  readonly type = BillingInformationActionTypes.BillingInformationLoadSuccess;

  constructor(public payload: BillingInformationInterface[]) {}
}

export class BillingInformationLoadFailure implements Action {
  readonly type = BillingInformationActionTypes.BillingInformationLoadFailure;

  constructor(public payload: any) {}
}

export class BillingInformationDelete implements Action {
  readonly type = BillingInformationActionTypes.BillingInformationDelete;

  constructor(public payload: BillingInformationInterface) {}
}

export class BillingInformationDeleteSuccess implements Action {
  readonly type = BillingInformationActionTypes.BillingInformationDeleteSuccess;

  constructor(public payload: string) {}
}

export class BillingInformationDeleteFailure implements Action {
  readonly type = BillingInformationActionTypes.BillingInformationDeleteFailure;

  constructor(public payload: any) {}
}

export type BillingInformationActions =
  | BillingInformationLoad
  | BillingInformationLoadSuccess
  | BillingInformationLoadFailure
  | BillingInformationDelete
  | BillingInformationDeleteSuccess
  | BillingInformationDeleteFailure;
