import {
  Component,
  Input,
  Inject,
  PLATFORM_ID,
  OnChanges,
  ViewChild,
  ElementRef,
  OnDestroy,
  AfterViewInit,
  SimpleChanges,
  AfterContentChecked,
  ChangeDetectionStrategy,
} from '@angular/core';
import { isPlatformBrowser, DOCUMENT } from '@angular/common';
import { fromEvent, Subscription, merge, Observable, Subject } from 'rxjs';
import { debounceTime, map, throttleTime } from 'rxjs/operators';
import { createPopper, Instance, Options } from '@popperjs/core';

/**
 * Компонент тултипа
 *
 * Прячет элемент тултипа из шаблона, копирует его и
 * помещает в конец body. Это означает, что ангуляр
 * перестает управлять компонентом тултипа.
 *
 * @example
 *
 * ### Простой тултип с неизменяемым текстом, показывается при ховере
 *
 * ```html
 * <ui-tooltip>
 *   <button ngProjectAs="content">Button</button>
 *   <div ngProjectAs="tip">Tooltip text</div>
 * </ui-tooltip>
 * ```
 *
 * ### Тултип с динамическим контентом
 *
 * ```html
 * <ui-tooltip [dynamicContent]="true">
 *   <button ngProjectAs="content">Button</button>
 *   <div ngProjectAs="tip">{{ someVariable }}</div>
 * </ui-tooltip>
 * ```
 */
@Component({
  selector: 'ui-tooltip',
  templateUrl: './ui-tooltip.component.html',
  styleUrls: ['./ui-tooltip.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UiTooltipComponent
  implements OnChanges, OnDestroy, AfterViewInit, AfterContentChecked {
  @Input()
  arrowSize: 'standard' | 'big' = 'standard';

  @Input()
  behavior:
    | 'always-show'
    | 'show-on-hover'
    | 'show-on-click'
    | 'always-hidden' = 'show-on-hover';

  @Input()
  delay = 10;

  /**
   * Будет ли меняться контент подсказки после ее показа?
   *
   * Так как обычно он не меняется, это позволяет оптимизировать
   * тултип.
   */
  @Input()
  dymanicContent = false;

  @Input()
  popperModifiers: Partial<Options> = {};

  @Input()
  zIndex = 9999;

  /**
   * В хроме событие наведения не срабатывает на disabled button
   * Необходимо передавать `true`, чтобы пофиксить
   */
  @Input()
  hasDisabledButton = false;

  @ViewChild('content')
  contentElRef: ElementRef<HTMLElement>;

  @ViewChild('tip')
  tipElRef: ElementRef<HTMLElement>;

  /**
   * Элемент тултипа, добавленный в конец body
   */
  tip: HTMLElement;

  popper: Instance;

  /**
   * Позволяет включить тултип только после того,
   * как компонент отрендерился (AfterViewInit)
   */
  private contentInitialized = false;

  private isBrowser: boolean;

  private readonly outsideClicks$ = new Subject<Event>();
  private readonly closeClicks$ = new Subject();
  private readonly contentChanges$ = new Subject();

  // должны быть раздельными
  private eventsSubscription: Subscription;
  private contentSubsctiption: Subscription;

  constructor(
    @Inject(PLATFORM_ID) platformId: object,
    @Inject(DOCUMENT) private document: Document,
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('behavior' in changes && this.contentInitialized) {
      this.updateVisibility();
    }
  }

  ngOnDestroy() {
    if (this.eventsSubscription) {
      this.eventsSubscription.unsubscribe();
    }
    if (this.contentSubsctiption) {
      this.contentSubsctiption.unsubscribe();
    }
    this.hidePopper();
  }

  ngAfterViewInit() {
    this.contentInitialized = true;
    this.updateVisibility();
    this.subscribeToContentChanges();
  }

  ngAfterContentChecked() {
    if (this.dymanicContent && this.tip) {
      this.contentChanges$.next();
    }
  }

  close() {
    this.closeClicks$.next();
  }

  onClickOutside(event: Event) {
    this.outsideClicks$.next(event);
  }

  private updateContent() {
    if (!this.tip || !this.tipElRef) {
      return;
    }
    if (
      this.tip.innerText.trim() !== this.tipElRef.nativeElement.innerText.trim()
    ) {
      this.tip.innerHTML = this.tipElRef.nativeElement.innerHTML;
      setTimeout(() => {
        this.popper.setOptions(this.getPopperOptions());
      }, 1);
    }
  }

  private updateVisibility() {
    if (!this.isBrowser) {
      return;
    }
    if (this.eventsSubscription) {
      this.eventsSubscription.unsubscribe();
    }
    if (this.behavior === 'always-show') {
      this.showPopper();
      return;
    }
    if (this.behavior === 'always-hidden') {
      this.hidePopper();
      return;
    }
    if (!this.contentElRef) {
      return;
    }
    let showEvents$: Observable<any>;
    let hideEvents$: Observable<any>;
    if (this.behavior === 'show-on-hover') {
      showEvents$ = fromEvent(this.contentElRef.nativeElement, 'mouseover');
      hideEvents$ = fromEvent(this.contentElRef.nativeElement, 'mouseout');
    }
    if (this.behavior === 'show-on-click') {
      showEvents$ = fromEvent(this.contentElRef.nativeElement, 'click');
      hideEvents$ = this.outsideClicks$.asObservable();
    }
    hideEvents$ = merge(hideEvents$, this.closeClicks$);
    this.eventsSubscription = merge(
      showEvents$.pipe(map(() => true)),
      hideEvents$.pipe(map(() => false)),
    )
      .pipe(debounceTime(this.delay))
      .subscribe((action) => {
        if (action) {
          this.showPopper();
        } else {
          this.hidePopper();
        }
      });
  }

  private hidePopper() {
    if (!this.tip) {
      return;
    }
    this.popper.destroy();
    this.document.body.removeChild(this.tip);
    this.tip = undefined;
  }

  private getPopperOptions(): Partial<Options> {
    const modifiers = this.popperModifiers.modifiers || [];
    return {
      ...this.popperModifiers,
      modifiers: [
        ...modifiers,
        {
          name: 'arrow',
          options: {
            element: this.tip.getElementsByClassName('tooltip__arrow')[0],
          },
        },
      ],
    };
  }

  private showPopper() {
    if (this.tip || !this.tipElRef) {
      return;
    }
    this.tip = this.tipElRef.nativeElement.cloneNode(true) as HTMLElement;
    this.document.body.append(this.tip);
    this.popper = createPopper(
      this.contentElRef.nativeElement,
      this.tip,
      this.getPopperOptions(),
    );
  }

  private subscribeToContentChanges() {
    this.contentSubsctiption = this.contentChanges$
      .pipe(throttleTime(500), debounceTime(100))
      .subscribe(() => this.updateContent());
  }
}
