import {
  AfterContentInit,
  Component,
  ContentChildren,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  HostListener,
  OnChanges,
  HostBinding,
  SimpleChanges,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { UiOptionComponent } from './ui-option/ui-option.component';
import { UiOptionInterface } from './ui-option/ui-option.interface';
import { Subscription } from 'rxjs';
import { getAbsoluteHeight } from '@helpers/dom/size';
import { Modifiers } from 'popper.js';
import { PopperComponent } from 'angular-popper';
import { Placement } from '@popperjs/core';

export interface OpenFormEvent {
  value: any;
}

@Component({
  selector: 'ui-select',
  templateUrl: './ui-select.component.html',
  styleUrls: ['./ui-select.component.less'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => UiSelectComponent),
      multi: true,
    },
  ],
})
export class UiSelectComponent
  implements
    ControlValueAccessor,
    OnInit,
    OnChanges,
    OnDestroy,
    AfterContentInit {
  @ContentChildren(UiOptionComponent) options: QueryList<UiOptionComponent>;
  @ViewChild('searchInput') searchInputEl: ElementRef;
  @ViewChild('optionList') optionList;
  @ViewChild('editingContent') editingContent: ElementRef<HTMLElement>;
  @ViewChild('popper')
  popper: PopperComponent;
  @Output() valueChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() openForm: EventEmitter<OpenFormEvent> = new EventEmitter<
    OpenFormEvent
  >();
  @Output() closed: EventEmitter<any> = new EventEmitter<any>();
  @Output() opened = new EventEmitter();
  @Output() search: EventEmitter<string> = new EventEmitter<string>();
  @Input() editable = true;
  @Input() dots;
  @Input() allowEmpty = false;
  @Input() placeholder = 'Выберите категорию';
  @Input() addText = 'UX.SELECT.ADD';
  @Input() disabled = false;
  @Input() withSearch: string | boolean = false;
  @Input() insideMatDialog = false;
  @Input() formHeader = '';
  @Input() values: any[];
  @Input() popperSettings: Modifiers;
  @Input() popperPlacement: Placement = 'bottom';
  @Input() limitHeight = true;
  @Input() preventClosing = false;
  // tslint:disable-next-line:variable-name no-input-rename
  @Input('value') _value: any = '';
  content: any;
  @Input()
  emptyValueView = false;
  @HostBinding('class.ui-select_open')
  isOpen = false;
  @HostBinding('class.ui-select')
  ngClass = true;
  showPopper = this.isOpen;
  isEditing = false;
  popperModifiers: Modifiers = {
    offset: {
      offset: 0,
    },
    flip: {
      enabled: true,
    },
    preventOverflow: {
      boundariesElement: 'scrollParent',
    },
  };
  isBrowser: boolean;

  get value(): string {
    return this._value;
  }

  set value(val: string) {
    this._value = val;
    this.onChange(val);
    this.onTouched();
  }

  get isEmpty(): boolean {
    return !this._value && !this.allowEmpty;
  }

  @HostBinding('class.ui-select_animated')
  get animated(): boolean {
    return this.isEditing && this.animationEnabled;
  }

  private animationEnabled = true;
  private subscription$ = new Subscription();

  constructor(private sanitizer: DomSanitizer) {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    // если обновилась какая-то опция, нужно обновить селект
    // todo: можно попробовать оптимизировать, проверя, изменилась ли именно опция из value
    // но желательно сделать не простую проверку, а прям как у ангуляра алгоритм взять
    if (changes.values && this.options) {
      setTimeout(() => {
        const option = this.options.find((o) => o.value === this.value);
        if (option) {
          this.updateContent(option.content);
        }
      }, 1);
    }
    if (changes.popperSettings) {
      const overwrites = this.popperSettings || {};
      this.popperModifiers = { ...this.popperModifiers, ...overwrites };
    }
  }

  ngOnDestroy() {
    setTimeout(() => this.onClose());
    this.subscription$.unsubscribe();
  }

  writeValue(value: string): void {
    this._value = value;
    if (!this.options) {
      return;
    }
    const option = this.options.find((o) => o.value === this.value);
    if (option) {
      this.updateContent(option.content);
    }
  }

  registerOnChange(fn: (value: string) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  ngAfterContentInit() {
    this.subscribeOptionClick();
    setTimeout(() => {
      this.initialView();
    });
    this.options.changes.subscribe(() => {
      this.subscription$.unsubscribe();
      this.subscription$ = new Subscription();
      this.subscribeOptionClick();
    });
  }

  onChange(value) {}

  onTouched() {}

  // TODO delete
  disableAnimation() {
    this.animationEnabled = false;
  }

  enableAnimation() {
    this.animationEnabled = true;
  }

  onSelect(e: UiOptionInterface) {
    if (e.event) {
      e.event.stopPropagation();
      e.event.preventDefault();
    }
    this.value = e.value;
    this.updateContent(e.content);
    this.valueChange.emit(e.value);
    this.onClose();
  }

  onCreate(event: Event) {
    this.toForm(event, { value: undefined });
  }

  onEdit(e: { value: any; event: Event }) {
    this.toForm(e.event, { value: e.value });
  }

  onCancelEditing(event?: Event) {
    this.isEditing = false;
    if (event) {
      event.stopPropagation();
    }
    setTimeout(() => {
      this.updatePosition();
    }, 1);
  }

  onClose() {
    if (this.preventClosing) {
      return;
    }
    this.isOpen = false;
    this.closed.emit();
    this.isEditing = false;
    this.showPopper = false;
    this.search.emit('');

    if (this.searchInputEl) {
      this.searchInputEl.nativeElement.value = '';
    }
  }

  onOpen() {
    this.isOpen = true;
    this.opened.emit();
    setTimeout(() => {
      if (this.popper) {
        this.updatePosition();
        this.showPopper = this.isOpen;
      }
    }, 1);
  }

  onToggleOpen() {
    if (this.isOpen) {
      this.onClose();
    } else {
      this.onOpen();
      setTimeout(() => {
        if (this.searchInputEl) {
          this.searchInputEl.nativeElement.focus();
        }
      });
    }
  }

  // KEYBOARD
  @HostListener('document:keydown.arrowdown', ['$event'])
  onKeyDown(event: Event) {
    if (!this.isOpen) {
      return;
    }
    event.preventDefault();
    this.focusOption((currentIndex) =>
      currentIndex === this.options.length - 1 ? 0 : currentIndex + 1,
    );
  }

  @HostListener('document:keydown.arrowup', ['$event'])
  onKeyUp(event: Event) {
    if (!this.isOpen) {
      return;
    }
    event.preventDefault();
    this.focusOption((currentIndex) =>
      currentIndex <= 0 ? this.options.length - 1 : currentIndex - 1,
    );
  }

  @HostListener('document:keydown.enter')
  onEnter() {
    if (!this.isOpen) {
      return;
    }
    const focusedOption = this.options.find((o) => o.isFocused);
    if (focusedOption) {
      focusedOption.onClick();
    }
  }

  removeAllFocus() {
    this.focusOption(() => -1);
  }

  updatePosition() {
    // tslint:disable-next-line
    this.popper['popper'].scheduleUpdate();
  }

  /**
   * Фокусирует один компонент option
   *
   * @param calcNextIndex функция вычисления следующего индекса для фокусировки в зависимости от текущего индекса
   */
  private focusOption(calcNextIndex: (currentIndex: number) => number) {
    let currentIndex = -1;
    this.options.find((option, index) => {
      if (option.isFocused) {
        currentIndex = index;
        return true;
      }
    });
    const nextIndex = calcNextIndex(currentIndex);
    this.options.forEach((option, index) => {
      if (index === nextIndex) {
        option.focus();
        if (!this.optionList) {
          return;
        }
        const optionHTMLEl = option.elementRef.nativeElement as HTMLElement;
        const listHTMLEl = this.optionList.nativeElement as HTMLElement;
        const offsetTop = optionHTMLEl.offsetTop;
        if (nextIndex === 0) {
          listHTMLEl.scrollTop = 0;
        } else if (nextIndex === this.options.length - 1) {
          listHTMLEl.scrollTop = 99999999;
        } else if (offsetTop > listHTMLEl.offsetHeight) {
          const height = getAbsoluteHeight(optionHTMLEl);
          listHTMLEl.scrollTop += height;
        } else if (offsetTop < listHTMLEl.scrollTop) {
          const height = getAbsoluteHeight(optionHTMLEl);
          listHTMLEl.scrollTop -= height;
        }
      } else {
        option.blur();
      }
    });
  }
  // END KEYBOARD

  private initialView() {
    if (!this.isEmpty) {
      const component = this.options.find((c) => c.value === this.value);
      if (component) {
        component.onClick();
      }
    }
  }

  private focusInput() {
    setTimeout(() => {
      if (this.editingContent) {
        const input = this.editingContent.nativeElement.getElementsByTagName(
          'input',
        );
        if (input.length) {
          input.item(0).focus();
        }
      }
    }, 100);
  }

  private toForm(htmlEvent: Event, openFormEvent: OpenFormEvent) {
    htmlEvent.stopPropagation();
    htmlEvent.preventDefault();
    // this.isEditing = true;
    this.openForm.emit(openFormEvent);
    // setTimeout(() => {
    //   this.updatePopperPosition();
    // }, 1);
    // this.focusInput();
  }

  private updateContent(content: ElementRef) {
    if (!content.nativeElement) {
      return;
    }
    this.content = this.sanitizer.bypassSecurityTrustHtml(
      content.nativeElement.innerHTML,
    );
  }

  private subscribeOptionClick() {
    this.options.forEach((option) => {
      this.subscription$.add(
        option.selected.subscribe((v) => this.onSelect(v)),
      );
      this.subscription$.add(option.edit.subscribe((v) => this.onEdit(v)));
    });
  }
}
