import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  ChangeDetectionStrategy,
} from '@angular/core';
import { Subtask } from '@core/models/note.interface';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

// TODO: удалить лишний код, этот компонент уже не редактируется
@Component({
  selector: 'ui-checklist',
  templateUrl: './ui-checklist.component.html',
  styleUrls: ['./ui-checklist.component.less'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: UiChecklistComponent,
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UiChecklistComponent implements ControlValueAccessor {
  @Input()
  isEditable = false;

  @Input()
  value: Subtask[] | undefined;

  @Output()
  valueChange = new EventEmitter<Subtask[]>();

  /**
   * Инпут для создания подзадачи
   */
  @ViewChild('subtaskInput')
  newSubtaskInputElRef: ElementRef<HTMLInputElement>;

  /**
   * Какая подзадача сейчас редактируется
   */
  subtaskBeingEdited: Subtask | undefined;

  onChange(value) {}

  onTouched() {}

  writeValue(value: Subtask[] | undefined): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.isEditable = !isDisabled;
  }

  /**
   * Удалить, если заметка пустая
   * @param currentText текст в инпуте изменяемой заметки
   * @param subtask подзадача
   */
  onBackspace(currentText: string, subtask: Subtask) {
    if (currentText) {
      return;
    }
    this.deleteSubtask(subtask);
  }

  /**
   * Меняет статус подзадачи (выполнена/невыполнена)
   */
  onChangeStatus(subtask: Subtask, index: number) {
    const newSubtask: Subtask = {
      ...subtask,
      isComplete: !subtask.isComplete,
    };
    const newValue = [
      ...this.value.slice(0, index),
      newSubtask,
      ...this.value.slice(index + 1),
    ];
    this.updateValue(newValue);
  }

  /**
   * Создание подзадачи
   */
  onCreateSubtask(text: string) {
    // на случай пустого текста
    if (!text) {
      return;
    }
    const task = { text, isComplete: false };
    // проверка на наличие массива (вдруг undefined)
    const newValue = this.value ? [...this.value, task] : [task];
    this.updateValue(newValue);
    // очистка инпута создания подзадачи
    if (this.newSubtaskInputElRef) {
      this.newSubtaskInputElRef.nativeElement.value = '';
    }
  }

  /**
   * Удаление подзадачи
   */
  onDeleteSubtask(subtask: Subtask, event: Event) {
    event.stopPropagation();
    this.deleteSubtask(subtask);
  }

  onDrop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.value, event.previousIndex, event.currentIndex);
    this.updateValue(this.value);
  }

  /**
   * Сфокусировать инпут создания подзадачи
   */
  onEnter(event: Event) {
    event.preventDefault();
    this.newSubtaskInputElRef.nativeElement.focus();
  }

  /**
   * Очищает вставляемый текст от HTML
   * @todo удалить если textarea
   */
  onPasteToSubtaskInput(e: Event) {
    e.preventDefault();
    const text =
      (window as any).clipboardData && (window as any).clipboardData.setData
        ? (window as any).clipboardData.getData('text')
        : ((e as any).originalEvent || e).clipboardData.getData('text/plain');
    document.execCommand('insertText', false, text);
  }

  /**
   * Запустить перед отправкой, чтобы подзадачу из инпута
   */
  startedSubtask(): Subtask {
    const newTaskText =
      this.newSubtaskInputElRef &&
      this.newSubtaskInputElRef.nativeElement.value;
    // если вдруг не получили значение, все равно text должен быть string
    const text = newTaskText || '';
    return { text, isComplete: false };
  }

  /**
   * Удаление подзадачи
   */
  private deleteSubtask(subtask: Subtask) {
    const newValue = this.value.filter((s) => s !== subtask);
    this.updateValue(newValue);
  }

  /**
   * Вызывает событие обновления чеклиста
   */
  private updateValue(value: Subtask[]) {
    this.value = value;
    this.valueChange.emit(this.value);
    this.onChange(this.value);
  }
}
