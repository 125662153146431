import { Action } from '@ngrx/store';
import { TariffGroupInterface } from '@core/models/tariff-group.interface';

export enum TariffGroupActionTypes {
  GetDefaultAction = '[Tariff Group] Get Default',
  GetDefaultActionSuccessAction = '[Tariff Group] Get Default Success',
  GetDefaultActionFailureAction = '[Tariff Group] Get Default Failure',
}

export class GetDefaultAction implements Action {
  readonly type = TariffGroupActionTypes.GetDefaultAction;
}

export class GetDefaultActionSuccessAction implements Action {
  readonly type = TariffGroupActionTypes.GetDefaultActionSuccessAction;

  constructor(public payload: TariffGroupInterface) {}
}

export class GetDefaultActionFailureAction implements Action {
  readonly type = TariffGroupActionTypes.GetDefaultActionFailureAction;

  constructor(public payload: any) {}
}

export type TariffGroupActions =
  | GetDefaultAction
  | GetDefaultActionSuccessAction
  | GetDefaultActionFailureAction;
