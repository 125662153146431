import {
  BackendIdentity,
  IdentityInterface,
  Onboarding,
  processIdentity,
  processOnboarding,
  UserOnboardingStep,
} from '@core/models/identity.interface';
import {
  addAccountAction,
  addAccountFailureAction,
  addAccountSuccessAction,
  changePasswordSuccessAction,
  checkPasswordResetTokenAction,
  checkPasswordResetTokenFailureAction,
  checkPasswordResetTokenSuccessAction,
  getUserAction,
  getUserFailureAction,
  getUserSuccessAction,
  setEmailAction,
  setEmailFailureAction,
  setEmailSuccessAction,
  setTokenAction,
  signInAction,
  signInFailureAction,
  signInSuccessAction,
  signOutSuccessAction,
  signUpAction,
  signUpFailureAction,
  signUpSuccessAction,
  updateAvatarSuccessAction,
  updateProfileAction,
  updateProfileFailureAction,
  updateProfileSuccessAction,
} from './auth.actions';
import { PersistentStorageService } from '@core/services/persistent-storage.service';
import { Action, createReducer, on } from '@ngrx/store';
import {
  saveAffiliateInfoAction,
  saveProfessionAction,
  saveProfessionSuccessAction,
  saveScopeAction,
  saveScopeSuccessAction,
  updateOnboardingAction,
  updateOnboardingSuccessAction,
} from '../onboarding/onboarding.actions';

/**
 * Этап восстановления пароля
 *
 * `NotAsked` — проверка токена не начата
 *
 * `Loading` — в ожидании результата проверки токена
 *
 * `Invalid` — токен неверный
 *
 * `Valid` — токен верный
 *
 * `Reset` — пароль успешно изменен
 */
export enum PasswordResetState {
  NotAsked,
  Loading,
  Invalid,
  Valid,
  Reset,
}

export interface State {
  loading: boolean;
  isAuth: boolean;
  token: string;
  language: string;
  errors: any;
  user: IdentityInterface;
  passwordResetState: PasswordResetState;
}

const token = PersistentStorageService.getToken();

export const initialState: State = {
  loading: false,
  isAuth: !!token,
  token,
  language: null,
  errors: {},
  user: null,
  passwordResetState: PasswordResetState.NotAsked,
};

const authReducer = createReducer<State>(
  initialState,
  on(signOutSuccessAction, () => ({
    ...initialState,
    token: undefined,
    isAuth: false,
  })),
  on(
    setEmailAction,
    signUpAction,
    getUserAction,
    signInAction,
    updateProfileAction,
    addAccountAction,
    (state) => ({ ...state, loading: true, errors: {} }),
  ),
  on(
    signUpSuccessAction,
    signInSuccessAction,
    addAccountSuccessAction,
    setEmailSuccessAction,
    updateProfileSuccessAction,
    getUserSuccessAction,
    (state, action) => authenticateUser(action.user, state),
  ),
  on(setTokenAction, (state, action) => ({
    ...state,
    loading: false,
    isAuth: true,
    errors: {},
    token: action.token,
  })),
  on(
    setEmailFailureAction,
    signInFailureAction,
    signUpFailureAction,
    updateProfileFailureAction,
    getUserFailureAction,
    addAccountFailureAction,
    (state, action) => ({
      ...state,
      loading: false,
      errors: action.error.error,
    }),
  ),
  on(changePasswordSuccessAction, (state, action) => ({
    ...authenticateUser(action.user, state),
    passwordResetState: PasswordResetState.Reset,
  })),
  on(checkPasswordResetTokenAction, (state) => ({
    ...state,
    passwordResetState: PasswordResetState.Loading,
  })),
  on(checkPasswordResetTokenSuccessAction, (state, action) => ({
    ...authenticateUser(action.user, state),
    passwordResetState: PasswordResetState.Valid,
  })),
  on(checkPasswordResetTokenFailureAction, (state) => ({
    ...state,
    passwordResetState: PasswordResetState.Invalid,
  })),
  on(updateAvatarSuccessAction, (state, action) => ({
    ...state,
    user: {
      ...state.user,
      avatar: action.avatar,
    },
  })),

  // Онбординг

  on(updateOnboardingSuccessAction, (state, action) => ({
    ...state,
    user: {
      ...state.user,
      onboarding: processOnboarding(action.onboarding),
    },
  })),
  on(saveScopeSuccessAction, (state, action) => ({
    ...state,
    user: {
      ...state.user,
      onboarding: processOnboarding(action.onboarding),
    },
  })),
  on(saveProfessionSuccessAction, (state, action) => ({
    ...state,
    user: {
      ...state.user,
      onboarding: processOnboarding(action.onboarding),
    },
  })),

  // Оптимистичные ответы

  on(saveAffiliateInfoAction, (state) => ({
    ...state,
    user: {
      ...state.user,
      onboarding: {
        ...state.user.onboarding,
        actions: state.user.onboarding.actions.filter(
          (a) => a.action !== 'affiliates-page',
        ),
      },
    },
  })),
  on(updateOnboardingAction, (state, action) => ({
    ...state,
    user: {
      ...state.user,
      onboarding: predictOnboarding(action.action, state.user.onboarding),
    },
  })),
  on(saveScopeAction, (state) => ({
    ...state,
    user: {
      ...state.user,
      onboarding: predictOnboarding('likes-page', state.user.onboarding),
    },
  })),
  on(saveProfessionAction, (state) => ({
    ...state,
    user: {
      ...state.user,
      onboarding: predictOnboarding(
        'classification-page',
        state.user.onboarding,
      ),
    },
  })),
);

export function reducer(state: State | undefined, action: Action): State {
  return authReducer(state, action);
}

function authenticateUser(user: BackendIdentity, state: State): State {
  return {
    ...state,
    loading: false,
    isAuth: true,
    errors: {},
    token: user.token || state.token,
    language: user.language,
    user: processIdentity(user),
  };
}

export const getIsAuth = (state: State) => state.isAuth;
export const getToken = (state: State) => state.token;
export const getLanguage = (state: State) => state.language;
export const getErrors = (state: State) => state.errors;
export const getLoading = (state: State) => state.loading;
export const getUser = (state: State) => state.user;
export const getIsActive = (state: State) => state.user && state.user.isActive;
export const getPasswordResetState = (state: State) => state.passwordResetState;

const optimisticStepsInOrder: UserOnboardingStep[] = [
  'greeting-page',
  'task-page',
  'result-page',
  'video-page',
  'likes-page',
  'classification-page',
  'create-first-note',
];

const lastOptimisticStepIndex = optimisticStepsInOrder.length - 1;

/**
 * Предсказать ответ сервера после выполнения запроса
 *
 * Фронт самостоятельно обновляет состояние онбординга у пользователя
 * в момент, когда фронт посылает запрос на обновление онбординга /passed/<action>.
 * Это помогает не дожидаться ответа сервера на запрос /passed/<action>.
 *
 * Функция удаляет переваданный action из онбординга и добавляет новый,
 * если знает, какой action должен быть следующим. Фронт знает,
 * что действия в `optimisticStepsInOrder` должны идти друг за другом.
 *
 * @see optimisticStepsInOrder
 *
 * @param action action онбординга, посланный в запросе на /passed/<action>
 * @param currentOnboarding текущее состояние онбординга у пользователя
 */
function predictOnboarding(
  action: UserOnboardingStep,
  currentOnboarding: Onboarding,
): Onboarding {
  const actions = currentOnboarding.actions.filter(
    (onboardingAction) => onboardingAction.action !== action,
  );
  const actionIndex = optimisticStepsInOrder.indexOf(action);
  if (actionIndex !== -1 && actionIndex > lastOptimisticStepIndex) {
    actions.push({
      action: optimisticStepsInOrder[actionIndex],
      scenario: 'global',
    });
  }
  return {
    ...currentOnboarding,
    actions,
  };
}
