import { Injectable } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { SubscriptionEndedComponent } from './subscription-ended.component';
import { DialogService } from '@ui/dialog';

@Injectable({
  providedIn: 'root',
})
export class SubscriptionEndedDialogService {
  constructor(private readonly dialog: DialogService) {}
  open(): MatDialogRef<SubscriptionEndedComponent> {
    return this.dialog.open(SubscriptionEndedComponent, undefined, false);
  }
}
