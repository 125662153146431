import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NoteViewComponent } from './note-view.component';
import { UiNoteViewSliderComponent } from './components/ui-note-view-slider/ui-note-view-slider.component';
import { UiNoteViewTargetComponent } from './components/ui-note-view-target/ui-note-view-target.component';
import { UiIconModule } from '@ui/ui-icon/ui-icon.module';
import { SafeLinkyModule } from '@shared/pipes/safe-linky/safe-linky.module';
import { InnerHtmlLinkModule } from '@shared/directives/inner-html-link/inner-html-link.module';
import { UiChecklistModule } from '@ui/ui-checklist/ui-checklist.module';
import { TranslateModule } from '@ngx-translate/core';
import { ImagePreviewModule } from '@shared/pipes/image-preview/image-preview.module';
import { RouterModule } from '@angular/router';
import { BackgroundImageModule } from '@shared/pipes/background-image/background-image.module';
import { NgxTinySliderModule } from 'ngx-tiny-slider';
import { NoteProgressModule } from '@ui/note-progress';

@NgModule({
  declarations: [
    NoteViewComponent,
    UiNoteViewSliderComponent,
    UiNoteViewTargetComponent,
  ],
  exports: [NoteViewComponent],
  imports: [
    CommonModule,
    UiIconModule,
    SafeLinkyModule,
    InnerHtmlLinkModule,
    UiChecklistModule,
    TranslateModule,
    ImagePreviewModule,
    RouterModule,
    BackgroundImageModule,
    NgxTinySliderModule,
    NoteProgressModule,
  ],
})
export class NoteViewModule {}
