import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Inject,
  OnDestroy,
} from '@angular/core';
import {
  Achievement,
  AchievementLevel,
  UserOnboardingStep,
} from '@core/models/identity.interface';
import { InfoPopupRef, INFO_POPUP_DATA } from '@ui/info-popup';
import { Router } from '@angular/router';
import { StoriesDialogService } from '@dialogs/stories';
import { Story } from '@core/redux/story/story.model';
import { isLevelInProgress } from '@ui/ui-achievement/utils';
import { AnalyticsService } from '@core/services/analytics.service';
import { Subscription } from 'rxjs';
import { AuthService } from '@core/redux/auth/auth.service';

export interface NewLevelPopupData {
  action: { action: UserOnboardingStep; stories?: Story[] };
  achievement: Achievement;
}

const backgrounds = {
  1: 'linear-gradient(181.08deg, rgba(232, 109, 49, 0.04) 33.59%, rgba(255, 255, 255, 0) 258.38%)',
  2: 'linear-gradient(181.08deg, rgba(80, 181, 255, 0.1) 33.59%, rgba(255, 255, 255, 0) 258.38%)',
  3: 'linear-gradient(174.62deg, rgba(244, 194, 33, 0.1) 38.95%, rgba(255, 255, 255, 0) 215.64%)',
  4: 'linear-gradient(156.32deg, rgba(31, 140, 235, 0.06) 32.69%, rgba(244, 194, 33, 0.05) 98.19%)',
  5: 'linear-gradient(109.59deg, rgba(31, 140, 235, 0) 26.26%, rgba(31, 140, 235, 0.06) 83.61%)',
  6: 'linear-gradient(109.59deg, rgba(31, 140, 235, 0) 26.26%, rgba(31, 140, 235, 0.06) 83.61%)',
};

@Component({
  selector: 'app-new-level',
  templateUrl: './new-level.component.html',
  styleUrls: [
    '../../../less/icon-list.less',
    '../info-popup.less',
    './new-level.component.less',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NewLevelComponent implements OnInit, OnDestroy {
  achievement: Achievement;
  background: string;
  action: string;
  levelInProgress: AchievementLevel;

  private readonly subscriptions$ = new Subscription();
  private onClickAction: 'close' | 'navigate';

  constructor(
    @Inject(INFO_POPUP_DATA)
    private readonly data: NewLevelPopupData,
    private readonly infoTooltipRef: InfoPopupRef<any>,
    private readonly router: Router,
    private readonly storiesDialogService: StoriesDialogService,
    private readonly analyticsService: AnalyticsService,
    private readonly authService: AuthService,
  ) {}

  ngOnInit() {
    this.subscriptions$.add(
      this.authService.getUser().subscribe((user) => {
        this.analyticsService.setUserLevel(user);
      }),
    );
    this.achievement = this.data.achievement;
    this.background = backgrounds[this.achievement.currentLevel.levelNumber];
    this.onClickAction =
      this.achievement.currentLevel.levelNumber === 1 ? 'navigate' : 'close';
    this.action =
      this.achievement.currentLevel.levelNumber === 1
        ? 'INFO_DIALOGS.NEW_LEVEL.SHOW_MORE'
        : this.achievement.type === 'filledDays'
        ? undefined
        : '$.CLOSE';
    this.levelInProgress = this.achievement.levels.find((level) =>
      isLevelInProgress(this.achievement, level),
    );
  }

  ngOnDestroy() {
    this.subscriptions$.unsubscribe();
  }

  onActionClick() {
    if (this.onClickAction === 'close') {
      this.infoTooltipRef.close();
    } else if (this.onClickAction === 'navigate') {
      this.router.navigate(['/level']);
      this.infoTooltipRef.close();
      // для мобильных, иначе после попапа бронзы оказывается внизу страницы уровней
      this.infoTooltipRef.afterClosed().subscribe(() => window.scrollTo(0, 0));
    }
  }

  onStoriesClick() {
    this.subscriptions$.add(
      this.storiesDialogService
        .open(this.data.action.stories)
        .afterClosed()
        .subscribe(() => this.infoTooltipRef.close()),
    );
  }
}
