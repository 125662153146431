import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NoteProgressComponent } from './note-progress.component';
import { SignedDecimalModule } from '@shared/pipes/signed-decimal/signed-decimal.module';

@NgModule({
  declarations: [NoteProgressComponent],
  imports: [CommonModule, SignedDecimalModule],
  exports: [NoteProgressComponent],
})
export class NoteProgressModule {}
