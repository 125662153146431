import { Injectable, isDevMode } from '@angular/core';
import { InvoiceInterface } from '@core/models/invoice.interface';
import { NavigationEnd } from '@angular/router';
import { NgxMetrikaService } from '@kolkov/ngx-metrika';
import { environment } from '../../../environments/environment';
import { IdentityInterface, SignSource } from '@core/models/identity.interface';

declare var gtag: any;
declare var fbq: any;
declare var dataLayer: any;

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {
  constructor(private ym: NgxMetrikaService) {}

  reachGoalGoogle(target: string) {
    if (!environment.production) {
      return;
    }
    this.ym.reachGoal.next({ target });
    // gtag('event', target);
  }

  reachGoalFb(target: string) {
    if (!environment.production) {
      return;
    }
    fbq('track', target);
  }

  /**
   * Зарегистрировался
   */
  signedUp() {
    this.reachGoalFb('StartTrial');
    this.reachGoalFb('CompleteRegistration');
  }

  /**
   * Покупка начата
   */
  checkoutStarted() {
    // this.reachGoalGoogle('begin_checkout');
    this.reachGoalFb('InitiateCheckout');
  }

  /**
   * Успешная покупка
   */
  purchased(invoice: InvoiceInterface) {
    if (!environment.production) {
      return;
    }
    this.ym.reachGoal.next({ target: 'purchase' });
    fbq('track', 'Purchase', {
      value: invoice.sum,
      currency: 'RUB',
    });
    gtag('event', 'purchase', {
      transaction_id: invoice.id,
      affiliation: 'Web Site',
      value: invoice.sum,
      currency: 'RUB',
      shipping: 0,
      tax: 0,
      items: [
        {
          id: invoice.tariff.id,
          name: invoice.tariff.title,
          category: 'Site Purchase',
          quantity: 1,
          price: invoice.sum,
        },
      ],
    });
  }

  /**
   * Просмотр страницы
   */
  pageView(page: NavigationEnd) {
    if (!environment.production) {
      return;
    }
    gtag('event', 'site_page_view', {
      page_name: page.url,
      page_path: page.url,
    });

    fbq('track', 'PageView');
  }

  /**
   * Начал основной онбординг
   */
  startedTutorial() {
    this.reachGoalGoogle('tutorial_begin');
  }

  /**
   * Закончил основной онбординг
   */
  completedTutorial() {
    this.reachGoalGoogle('tutorial_complete');
  }

  setUserLevel(user: IdentityInterface) {
    this.pushDataLayer({
      user_level: `level.${user.achievements.filledDays.currentLevel.levelNumber}`,
    });
  }

  setUserId(id: string) {
    this.pushDataLayer({ userid: id });
  }

  wizard(step: number) {
    this.pushDataLayer({ event: 'wizard_passed', step });
  }

  noteCreateStarted() {
    this.pushDataLayer({ event: 'note_create_started' });
  }

  noteCreated() {
    this.pushDataLayer({ event: 'note_created' });
  }

  taskCreateStarted() {
    this.pushDataLayer({ event: 'task_create_started' });
  }

  taskCreated() {
    this.pushDataLayer({ event: 'task_created' });
  }

  goalCreateStarted() {
    this.pushDataLayer({ event: 'goal_create_started' });
  }

  goalCreated() {
    this.pushDataLayer({ event: 'goal_created' });
  }

  signUpCompleted(method: SignSource) {
    this.pushDataLayer({ event: 'sign_up_completed', method });
  }

  signInCompleted(method: SignSource) {
    this.pushDataLayer({ event: 'sign_in_completed', method });
  }

  pushDataLayer(data: {}) {
    dataLayer.push(data);
  }
}
