export function getSearchQuerys(model): string[] {
    let resultKeys = Object.keys(model);
    resultKeys = resultKeys.map(el => {
        if (Array.isArray(model[el])) {
            return `${el}=${model[el].join(',')}`;
        }
        if (el) {
            return `${el}=${model[el]}`;
        }
        return null;
    });
    return resultKeys;
}
