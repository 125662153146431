import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { UiOptionInterface } from './ui-option.interface';

@Component({
  selector: 'ui-option',
  templateUrl: './ui-option.component.html',
  styleUrls: ['./ui-option.component.less'],
})
export class UiOptionComponent implements OnInit {
  @Input() value: any;
  @Input() active = false;
  @Input() editable = true;

  @Output()
  selected: EventEmitter<UiOptionInterface> = new EventEmitter<
    UiOptionInterface
  >();

  @Output()
  edit: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('contentWrapper')
  content: ElementRef;

  get isFocused(): boolean {
    return this._isFocused;
  }

  // tslint:disable-next-line:variable-name no-input-rename
  private _isFocused = false;

  constructor(public readonly elementRef: ElementRef) {}

  ngOnInit() {}

  onClick($event = null) {
    this.selected.emit({
      value: this.value,
      content: this.content,
      event: $event,
    });
  }

  onEdit($event = null) {
    this.edit.emit({ value: this.value, event: $event });
  }

  focus() {
    this._isFocused = true;
  }

  blur() {
    this._isFocused = false;
  }
}
