import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { TargetInterface } from '@core/models/target.interface';

@Component({
  selector: 'goal-select',
  templateUrl: './goal-select.component.html',
  styleUrls: ['./goal-select.component.less'],
})
export class GoalSelectComponent implements OnInit {
  @Input()
  goals: TargetInterface[];

  @Input()
  selectedId: string;

  @Output()
  closed = new EventEmitter();

  @Output()
  selected = new EventEmitter<TargetInterface | undefined>();

  constructor() {}

  ngOnInit() {}

  onClose() {
    this.closed.emit();
  }

  onSelect(goal: TargetInterface | undefined) {
    this.selected.emit(goal);
  }
}
