export enum OnCloseActions {
  Accept,
  Discard,
}

export interface ConfirmationDialogData {
  title: string;
  body: string;
  onClose: OnCloseActions;
  discard: {
    action: () => void;
    text: string;
  };
  accept: {
    action: () => void;
    text: string;
  };
}
