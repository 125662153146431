import {
  Component,
  ViewEncapsulation,
  ChangeDetectionStrategy,
  ElementRef,
  HostBinding,
  Input,
} from '@angular/core';

const BUTTON_HOST_ATTRIBUTES = [
  'ui-button-long',
  'ui-button-primary',
  'ui-button-primary-long',
  'ui-button-secondary',
  'ui-button-secondary-long',
  'ui-button-primary-delete',
  'ui-button-primary-long-delete',
  'ui-button-secondary-delete',
  'ui-button-secondary-long-delete',
  'ui-button-green',
  'ui-button-orange',
  'ui-button-white',
  'ui-button-fab',
  'ui-button-icon',
  'ui-button-clean',
  'ui-button-text',
];

@Component({
  selector: `button[ui-button], button[ui-button-long], button[ui-button-primary],
             button[ui-button-secondary], button[ui-button-primary-delete],
             button[ui-button-secondary-delete],
             button[ui-button-primary-long], button[ui-button-secondary-long],
             button[ui-button-primary-long-delete], button[ui-button-secondary-long-delete],
             button[ui-button-green], button[ui-button-orange], button[ui-button-white],
             button[ui-button-fab], button[ui-button-icon], button[ui-button-text],
             a[ui-button], a[ui-button-long], a[ui-button-primary], a[ui-button-secondary],
             a[ui-button-primary-delete], a[ui-button-secondary-delete],
             a[ui-button-primary-long], a[ui-button-secondary-long],
             a[ui-button-primary-long-delete], a[ui-button-secondary-long-delete],
             a[ui-button-green], a[ui-button-orange], a[ui-button-white],
             a[ui-button-fab], a[ui-button-icon], button[ui-button-clean], a[ui-button-clean],
             a[ui-button-text]
             `,
  exportAs: 'uiButton',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.less'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonComponent {
  @Input() set centerIcon(value: string) {
    this._centerIcon = value;
    this.elementRef.nativeElement.style.padding = '10px 32px';
  }

  get centerIcon(): string {
    return this._centerIcon;
  }

  @Input() set loading(value: boolean) {
    this._loading = value;
    value
      ? (this.elementRef.nativeElement.style.padding = '10px 32px')
      : (this.elementRef.nativeElement.style.padding = '12px 32px');
  }

  get loading(): boolean {
    return this._loading;
  }

  readonly isRippleDisabled = false;
  readonly isRoundButton: boolean = this.hasHostAttributes('ui-button-fab');
  readonly isIconButton: boolean = this.hasHostAttributes('ui-button-icon');

  @HostBinding('class.ui-button')
  readonly ngClass = true;

  @Input() icon: string;

  // tslint:disable-next-line:variable-name
  private _centerIcon: string;
  // tslint:disable-next-line:variable-name
  private _loading: boolean;

  constructor(public readonly elementRef: ElementRef<HTMLButtonElement>) {
    // добавит соответствующий класс текущему варианту кнопки
    for (const attr of BUTTON_HOST_ATTRIBUTES) {
      const hasAttr = this.elementRef.nativeElement.hasAttribute(attr);
      if (hasAttr) {
        this.elementRef.nativeElement.classList.add(attr);
      }
    }
  }

  private hasHostAttributes(...attributes: string[]) {
    return attributes.some((attribute) =>
      this.elementRef.nativeElement.hasAttribute(attribute),
    );
  }
}
