export interface TargetTypeInterface {
  id: string;
  name: string;
}

export function emptyTargetType(
  goalType: Partial<TargetTypeInterface> = {},
): TargetTypeInterface {
  return {
    id: '',
    name: '',
    ...goalType,
  };
}
