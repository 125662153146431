<label
  *ngFor="let subtask of value; index as index"
  class="green-checkbox checklist__checkbox"
>
  <input
    type="checkbox"
    (input)="onChangeStatus(subtask, index)"
    [checked]="subtask.isComplete"
  />
  <span class="green-checkbox__field">
    <i class="green-checkbox__marker"></i>
    <span class="green-checkbox__caption">{{ subtask.text }}</span>
  </span>
</label>
