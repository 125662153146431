import {
  Component,
  OnInit,
  Input,
  ViewChild,
  ElementRef,
  OnDestroy,
  Inject,
} from '@angular/core';
import { NoteInterface, Subtask } from '@core/models/note.interface';
import { FormGroup, FormArray, AbstractControl } from '@angular/forms';
import { CategoryInterface } from '@core/models/category.interface';
import { Subscription, Observable } from 'rxjs';
import { NoteService } from '@core/redux/note/note.service';
import { TagService } from '@core/redux/tag/tag.service';
import { NoteComponentService } from '../note-component.service';
import {
  filter,
  pairwise,
  withLatestFrom,
  startWith,
  map,
  debounceTime,
} from 'rxjs/operators';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NoteComponent, NoteDialogData } from '../note.component';
import { CategoryService } from '@core/redux/category/category.service';
import {
  OpenFormEvent,
  UiSelectComponent,
} from '@ui/ui-select/ui-select.component';
import { findImage } from '@helpers/clipboard/image';
import {
  animate,
  keyframes,
  style,
  transition,
  trigger,
  useAnimation,
} from '@angular/animations';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Options } from '@popperjs/core';
import { TargetInterface } from '@core/models/target.interface';
import { fadeOut, fadeIn } from 'ng-animate';
import { NoteFormService } from './services/note-form.service';
import { HashtagService } from './services/hashtag.service';
import { AutoFocusDirective } from '@shared/directives/auto-focus/auto-focus.directive';
import { FileInterface } from '@core/models/file.interface';
import { CategoryFormDialogService } from '../../category-form/category-form-dialog.service';
import { ErrorHandlerService } from '@core/services/ui/error-handler.service';
import { TOOLTIP_TOP_POSITION, withOffset } from '@ui/tooltip/tooltip.service';
import { OnboardingService } from '@core/redux/onboarding/onboarding.service';
import { IconPopupService } from '@info-popups/icon-popup';
import { AuthService } from '@core/redux/auth/auth.service';
import {
  CleaveNumberConfig,
  CLEAVE_NUMBER_CONFIG,
} from '@shared/tokens/cleave-number';
import { SentSuccessfullyEvent } from '@dialogs/category-form/category-form-body/category-form-body.component';
import { AnalyticsService } from '@core/services/analytics.service';

@Component({
  selector: 'note-form',
  templateUrl: './note-form.component.html',
  styleUrls: ['./note-form.component.less'],
  providers: [HashtagService],
  animations: [
    trigger('fade', [
      transition('* => 1', useAnimation(fadeIn, { params: { timing: 0.1 } })),
      transition('1 => 0', useAnimation(fadeOut, { params: { timing: 0.1 } })),
    ]),
    trigger('anim', [
      // transition('* => active', [
      //   style({ height: '*', opacity: '1', transform: 'translateX(0)', 'box-shadow': '0 1px 4px 0 rgba(0, 0, 0, 0.3)' }),
      //   sequence([
      //     animate('.25s ease', style({ height: '*', opacity: '.2', transform: 'translateX(20px)', 'box-shadow': 'none' })),
      //     animate('.1s ease', style({ height: '0', opacity: 0, transform: 'translateX(20px)', 'box-shadow': 'none' }))
      //   ])
      // ]),
      transition('void => *', [
        animate(
          100,
          keyframes([
            style({
              opacity: 0,
              transform: 'translateY(10%)',
              offset: 0,
              height: 0,
            }),
            style({ opacity: 0.5, transform: 'translateY(5%)', offset: 0.3 }),
            style({
              opacity: 1,
              transform: 'translateY(0%)',
              offset: 1.0,
              height: '*',
            }),
          ]),
        ),
      ]),
    ]),
  ],
})
export class NoteFormComponent implements OnInit, OnDestroy {
  @Input()
  note: NoteInterface;

  @ViewChild('noteTextArea')
  noteTextArea: ElementRef<HTMLTextAreaElement>;

  /**
   * Инпут для создания подзадачи
   */
  @ViewChild('subtaskInput')
  newSubtaskInputElRef: ElementRef<HTMLInputElement>;

  @ViewChild('select')
  categorySelect: UiSelectComponent;

  form: FormGroup;

  /**
   * Анимация
   */
  fade = false;

  /**
   * Включен компонент тегов?
   *
   * Должен быть выключен только после автофокуса, иначе
   * когда заметка заканчивается на #, то при переходе в
   * режим редактирования, сразу появляется список тегов.
   */
  autocompleteTagsActive = false;

  isGoalFormOpened = false;
  isGoalSelectOpened = false;

  /**
   * Текст в поиске селекта категорий
   */
  categorySearchQuery: string;

  isSending = false;

  /**
   * Все категории пользователя
   */
  categoriesList: CategoryInterface[] = [];
  /**
   * Отображаемые с учетом поиска категории
   */
  filteredCategories: CategoryInterface[] = [];

  /**
   * Невыполненные цели и цель заметки (даже если выполнена)
   */
  goals$: Observable<TargetInterface[]>;

  /**
   * Список тегов пользователя
   */
  tags$ = this.tagService.getAll();

  selectedGoal$: Observable<TargetInterface>;

  preventCategorySelectClosing$: Observable<boolean>;

  shouldShowOnboarding: boolean;

  /**
   * Прочитал весь онбординг?
   */
  readOnboarding = false;

  readonly saveAndNewTipPosition = withOffset(TOOLTIP_TOP_POSITION, -20, 0);

  private readonly subscription = new Subscription();

  get categoryId(): AbstractControl {
    return this.form.get('categoryId');
  }

  get pendingFiles(): FormArray {
    return this.form.get('pendingFiles') as FormArray;
  }

  get uploadedFiles(): AbstractControl {
    return this.form.get('files');
  }

  constructor(
    @Inject(CLEAVE_NUMBER_CONFIG)
    public readonly cleaveNumberConfig: CleaveNumberConfig,
    @Inject(MAT_DIALOG_DATA) private readonly data: NoteDialogData,
    private readonly authService: AuthService,
    private readonly categoryFormDialogService: CategoryFormDialogService,
    private readonly categoryService: CategoryService,
    private readonly dialogRef: MatDialogRef<NoteComponent>,
    private readonly errorHandlerService: ErrorHandlerService,
    private readonly hashtagService: HashtagService,
    private readonly iconPopup: IconPopupService,
    private readonly noteComponentService: NoteComponentService,
    private readonly noteFormService: NoteFormService,
    private readonly noteService: NoteService,
    private readonly onboardingService: OnboardingService,
    private readonly tagService: TagService,
    private readonly analyticsService: AnalyticsService,
  ) {}

  ngOnInit() {
    this.shouldShowOnboarding = this.data.withOnboarding;
    this.form = this.noteFormService.form;
    this.goals$ = this.noteComponentService.getGoals(this.note);
    this.preventCategorySelectClosing$ = this.noteFormService.preventCategorySelectClosing;
    this.subscribeToCategories();
    this.subscribeToGoalDeletion();
    this.subscribeToLoading();
    this.subscribeToNoteCategoryUpdate();
    this.subscribeToSelectedGoal();
    this.tagService.dispatchAll();
    if (this.note.id === undefined) {
      if (this.form.get('isTask').value) {
        this.analyticsService.taskCreateStarted();
      } else {
        this.analyticsService.noteCreateStarted();
      }
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  onAnimateGoalSelectClose() {
    this.fade = false;
  }

  onChangeSubtaskStatus(subtask: Subtask) {
    subtask.isComplete = !subtask.isComplete;
  }

  onChangeSubtaskText(newText: string, subtask: Subtask) {
    subtask.text = newText;
  }

  onCloseGoalForm() {
    this.isGoalFormOpened = false;
    this.onAnimateGoalSelectClose();
  }

  onCloseGoalSelect() {
    if (!this.fade) {
      this.isGoalSelectOpened = false;
    }
  }

  onCreateSubtask(text: string) {
    // на случай пустого текста
    if (!text) {
      return;
    }
    const task: Subtask = { text, isComplete: false, animate: 'active' };
    const oldChecklist = this.form.get('checklist').value as Subtask[];
    const newChecklist = [...oldChecklist, task];
    this.form.get('checklist').setValue(newChecklist);
    // очистка инпута создания подзадачи
    if (this.newSubtaskInputElRef) {
      this.newSubtaskInputElRef.nativeElement.value = '';
    }
  }

  /**
   * Удалить заметку
   */
  onDelete() {
    this.noteService.delete(this.form.get('id').value);
  }

  /**
   * Удалить файл
   */
  onDeleteImage(image: FileInterface) {
    this.noteFormService.deleteImage(
      image,
      this.uploadedFiles,
      this.note.files,
    );
  }

  onDeletePendingImage(file: File) {
    this.noteFormService.deletePendingImage(file);
  }

  onDrop(event: CdkDragDrop<string[]>) {
    moveItemInArray(
      this.form.get('checklist').value,
      event.previousIndex,
      event.currentIndex,
    );
  }

  onDeleteSubtask(subtask: Subtask) {
    const oldChecklist = this.form.get('checklist').value as Subtask[];
    const newChecklist = oldChecklist.filter((s) => s !== subtask);
    setTimeout(() => {
      // чтобы диалог не определил клик как внешний
      // ждем, пока клик обработается, потому уже удаляем
      // кнопку, по которой был клик
      this.form.get('checklist').setValue(newChecklist);
    }, 0);
  }

  onFinishOnboarding() {
    this.readOnboarding = true;
    this.noteTextArea.nativeElement.focus();
  }

  onSelectGoal(goal: TargetInterface | undefined) {
    const goalId = goal ? goal.id : null;
    const goalControl = this.form.get('targetId');
    goalControl.setValue(goalId);
    const changed =
      (goalId || this.note.targetId) && goalId !== this.note.targetId;
    if (changed) {
      goalControl.markAsDirty();
    } else {
      goalControl.markAsPristine();
    }
    this.onAnimateGoalSelectClose();
  }

  /**
   * Удалить, если заметка пустая
   * @param currentText текст в инпуте изменяемой заметки
   * @param subtask подзадача
   */
  onSubtaskTextInputBackspace(currentText: string, subtask: Subtask) {
    if (currentText) {
      return;
    }
    this.onDeleteSubtask(subtask);
  }

  onSubtaskTextInputEnter(event: Event) {
    event.preventDefault();
    this.newSubtaskInputElRef.nativeElement.focus();
  }

  /**
   * Включает компонент тегов после автофокуса
   */
  onTextareaFocus(event: FocusEvent) {
    if (AutoFocusDirective.wasAutoFocused(event)) {
      setTimeout(() => (this.autocompleteTagsActive = true));
    }
  }

  /**
   * Фильтрация категорий из селекта
   */
  onFilterCategories(query: string) {
    // в некоторых браузерах (в старом хроме на электроне)
    // тут оказывается event вместо строки
    if (!query || typeof query !== 'string') {
      this.filteredCategories = this.categoriesList;
      return;
    }
    this.categorySearchQuery = query;
    this.filteredCategories = this.noteFormService.filterCategories(
      query,
      this.categoriesList,
    );
  }

  /**
   * Запомнит положение каретки в тексте
   */
  onHashtagMousedown() {
    this.hashtagService.updateCaretPosition(this.noteTextArea);
  }

  /**
   * Добавит хештег в текст заметки
   */
  onHashtagClick() {
    const noteControl = this.form.get('note');
    const [newValue, newCaretPos] = this.hashtagService.addHash(
      noteControl.value,
    );
    noteControl.setValue(newValue);
    setTimeout(() => {
      // обновляет позицию каретки
      this.noteTextArea.nativeElement.blur();
      this.noteTextArea.nativeElement.setSelectionRange(
        newCaretPos,
        newCaretPos,
      );
      this.noteTextArea.nativeElement.focus();
    }, 10);
  }

  /**
   * Закрытие селекта категорий
   */
  onCloseCategorySelect() {
    this.filteredCategories = this.categoriesList;
  }

  /**
   * Открытие формы редактирования категории
   */
  onOpenCategoryForm(event: OpenFormEvent) {
    const category = this.categoriesList.find((c) => c.id === event.value);
    if (!category && event.value) {
      console.error('Unable to find category with id ' + event.value);
      return;
    }
    const dialogRef =
      !category && this.categorySearchQuery
        ? this.categoryFormDialogService.open({
            category: { name: this.categorySearchQuery },
          })
        : this.categoryFormDialogService.open({ category });
    dialogRef.afterClosed().subscribe((result?: SentSuccessfullyEvent) => {
      if (result && result.created) {
        this.categoryId.setValue(result.category.id);
        this.categorySelect.preventClosing = false;
        this.categorySelect.onClose();
      } else {
        this.categoryFormDialogService.close();
      }
    });
  }

  onOpenGoalForm() {
    this.isGoalFormOpened = true;
  }

  onOpenGoalSelect() {
    this.isGoalSelectOpened = true;
    this.fade = true;
  }

  /**
   * Смена типа заметки на задачу и обратно
   */
  onChangeType(isTask) {
    this.form.get('isTask').setValue(isTask);
    if (!this.noteTextArea) {
      return;
    }
    this.noteTextArea.nativeElement.focus();
  }

  /**
   * Загрузить картинку если была
   */
  onPaste(event: ClipboardEvent) {
    const image = findImage(event);
    if (image !== null) {
      this.onFileUpload([image]);
      event.preventDefault();
    }
  }

  /**
   * Загрузка файлов на сервер
   */
  onFileUpload(files: File[]): void {
    if (!files) {
      return;
    }
    this.noteFormService.addNewFiles(files);
  }

  onSubmit(andNew = false) {
    const startedSubtaskText = this.newSubtaskInputElRef
      ? this.newSubtaskInputElRef.nativeElement.value
      : '';
    if (!this.canSubmit(startedSubtaskText) || this.isSending) {
      return;
    }
    this.noteFormService
      .submit(this.form.getRawValue(), startedSubtaskText, this.data.story)
      .subscribe(
        () => {
          this.form.markAsPristine();
          this.dialogRef.close(andNew);
          this.dialogRef
            .afterClosed()
            .pipe(withLatestFrom(this.authService.getUser()), debounceTime(500))
            .subscribe(([_, user]) => {
              if (
                this.data.story &&
                this.data.story.templateGroupKey === 'day1' &&
                user.onboarding.actions
                  .map((a) => a.action)
                  .includes('create-first-note-from-video')
              ) {
                this.iconPopup
                  .open({
                    icon: 'smilee',
                    imgForMobile: './assets/img/smile.svg',
                    description: 'INFO_DIALOGS.GOOD_BOY.DESCRIPTION',
                    title: 'INFO_DIALOGS.GOOD_BOY.TITLE',
                  })
                  .afterClosed()
                  .subscribe(() =>
                    this.onboardingService
                      .pass('create-first-note-from-video')
                      .subscribe(),
                  );
              }
            });
        },
        (errorAction) => {
          this.errorHandlerService.handleHttpError(errorAction.payload);
        },
      );
  }

  private canSubmit(startedSubtaskText: string): boolean {
    const noText = !this.form.get('note').value.trim();
    const isEmpty = this.form.get('isTask').value
      ? noText &&
        !startedSubtaskText &&
        this.form.get('checklist').value.every((s) => !s.text.trim())
      : noText;
    const invalid = this.form.invalid || isEmpty;
    return !invalid;
  }

  private receiveNoteUpdate(note: NoteInterface) {
    if (this.note.categoryId === note.categoryId) {
      return;
    }
    this.categoryId.setValue(note.categoryId);
  }

  private receiveCategories(categories: CategoryInterface[]) {
    this.categoriesList = categories;
    this.filteredCategories = categories;
    if (this.categorySearchQuery) {
      this.onFilterCategories(this.categorySearchQuery);
    }
    const unableToFindSelectedCategory =
      categories.findIndex(
        (c) => c.id === this.form.controls.categoryId.value,
      ) === -1;
    if (unableToFindSelectedCategory && categories && categories.length) {
      this.form.controls.categoryId.setValue(categories[0].id);
    }
  }

  private subscribeToCategories() {
    this.subscription.add(
      this.categoryService
        .getAll()
        .subscribe((categories) => this.receiveCategories(categories)),
    );
  }

  /**
   * Подписываемся на стор для получения флага загрузки
   */
  private subscribeToLoading() {
    this.subscription.add(
      this.noteService.getNoteLoading().subscribe((isSending) => {
        this.isSending = isSending;
      }),
    );
  }

  /**
   * Если цель убрали, то прятать селект
   */
  private subscribeToGoalDeletion() {
    this.subscription.add(
      this.form
        .get('targetId')
        .valueChanges.pipe(
          pairwise(),
          filter(([prev, next]) => prev !== next),
        )
        .subscribe(([prev, next]) => {
          if (!!next) {
            this.onAnimateGoalSelectClose();
          }
        }),
    );
  }

  private subscribeToSelectedGoal() {
    this.selectedGoal$ = this.form.get('targetId').valueChanges.pipe(
      startWith(this.form.get('targetId').value),
      withLatestFrom(this.goals$),
      map(([goalId, goals]) => {
        if (!goalId) {
          return undefined;
        }
        return goals.find((g) => g.id === goalId);
      }),
    );
  }

  private subscribeToNoteCategoryUpdate() {
    this.subscription.add(
      this.noteService
        .getNoteById(this.note.id)
        .pipe(filter((note) => !!note))
        .subscribe((note) => this.receiveNoteUpdate(note)),
    );
  }
}
