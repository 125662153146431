<info-popup-content (actionClick)="onCloseClick()" [action]="'$.CLOSE'">
  <div class="info-popup__main">
    <div class="info-popup__cover">
      <ui-icon
        [name]="data.icon"
        width="100"
        height="100"
        class="info-popup__img info-popup__img_desktop"
      ></ui-icon>
      <img
        [src]="data.imgForMobile"
        class="info-popup__img info-popup__img_mobile"
        alt=""
      />
    </div>
    <div class="info-popup__text">
      <span class="info-popup__congratulation">
        {{ data.title | translate }}
      </span>
      <span class="info-popup__description">
        {{ data.description | translate }}
      </span>
    </div>
  </div>
</info-popup-content>
