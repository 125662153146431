import { GoalStep } from '@core/models/goal-step.interface';
import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import {
  createGoalStepSuccessAction,
  deleteGoalStepSuccessAction,
  getStepsForGoalAction,
  getStepsForGoalSuccessAction,
  sortGoalStepsAction,
  sortGoalStepsSuccessAction,
  updateGoalStepAction,
  updateGoalStepSuccessAction,
} from './goal-steps.actions';

export interface GoalStepsState extends EntityState<GoalStep> {
  goalId?: string;
  ids: string[];
}

export const initialState: GoalStepsState = {
  ids: [],
  entities: {},
};

const adapter = createEntityAdapter<GoalStep>({
  sortComparer: (a, b) => b.order - a.order,
});

const reducer = createReducer(
  initialState,
  on(getStepsForGoalAction, (state, action) =>
    state.goalId === action.goalId
      ? state
      : { ...initialState, goalId: action.goalId },
  ),
  on(getStepsForGoalSuccessAction, (state, action) =>
    adapter.setAll(action.goalSteps, state),
  ),
  on(createGoalStepSuccessAction, (state, action) =>
    adapter.addOne(action.goalStep, state),
  ),
  on(deleteGoalStepSuccessAction, (state, action) =>
    adapter.removeOne(action.goalStepId, state),
  ),
  on(updateGoalStepAction, (state, action) =>
    adapter.updateOne(
      { id: action.request.id, changes: action.request },
      state,
    ),
  ),
  on(updateGoalStepSuccessAction, (state, action) =>
    adapter.updateOne(
      {
        id: action.goalStep.id,
        changes: action.goalStep,
      },
      state,
    ),
  ),
  on(sortGoalStepsAction, (state, action) => {
    const length = action.ids.length;
    return adapter.map((step) => {
      const maybeChanges = action.changes[step.id];
      const changes = maybeChanges || {};
      const order = length - action.ids.indexOf(step.id);
      return {
        ...step,
        ...changes,
        [action.sortField]: order,
      };
    }, state);
  }),
  on(sortGoalStepsSuccessAction, (state, action) =>
    adapter.setAll(action.goalSteps, state),
  ),
);

export function goalStepsReducer(
  state: GoalStepsState = initialState,
  action: Action,
): GoalStepsState {
  return reducer(state, action);
}
