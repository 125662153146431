import { Injectable } from '@angular/core';
import { CanActivate, UrlTree, Router } from '@angular/router';
import { UserOnboardingStep } from '@core/models/identity.interface';
import { AuthService } from '@core/redux/auth/auth.service';
import { Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class OnboardingGuard implements CanActivate {
  private readonly prefix = '/onboarding/';
  private readonly actionToUrlMap: Partial<
    Record<UserOnboardingStep, string>
  > = {
    'greeting-page': this.prefix + 'greeting',
    'task-page': this.prefix + 'task',
    'result-page': this.prefix + 'result',
    'video-page': this.prefix + 'video',
    'likes-page': this.prefix + 'likes',
    'classification-page': this.prefix + 'classification',
  };
  constructor(
    private readonly authService: AuthService,
    private readonly router: Router,
  ) {}

  canActivate(): Observable<boolean | UrlTree> {
    return this.authService.getUser(true).pipe(
      filter((user) => {
        if (user) {
          return true;
        }
        this.authService.refreshUser();
        return false;
      }),
      take(1),
      map((user) => {
        const key = Object.keys(this.actionToUrlMap).find((action) =>
          user.onboarding.actions.find((a) => a.action === action),
        );
        const url = this.actionToUrlMap[key];
        return url ? this.router.parseUrl(url) : true;
      }),
    );
  }
}
