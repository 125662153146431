import {
  createEntityAdapter,
  Dictionary,
  EntityAdapter,
  EntityState,
} from '@ngrx/entity';
import { NoteInterface } from '@core/models/note.interface';
import { Action, createReducer, createSelector, on } from '@ngrx/store';
import {
  clearNotesForReportAction,
  clearNotesForReportTagAction,
  clearNotesForReportTargetAction,
  noteChangeCategoryAction,
  noteChangeSortAction,
  noteClearListFromBatchPopupAction,
  noteCreateAction,
  noteCreateFailureAction,
  noteCreateFromBatchPopupAction,
  noteCreateFromBatchPopupFailureAction,
  noteCreateFromBatchPopupSuccessAction,
  noteCreateSuccessAction,
  noteDeleteAction,
  noteDeleteFailureAction,
  noteDeleteSuccessAction,
  noteHardReloadAction,
  noteHardReloadFailureAction,
  noteHardReloadSuccessAction,
  noteLoadAction,
  noteLoadFailureAction,
  noteLoadForReportAction,
  noteLoadForReportFailuteAction,
  noteLoadForReportSuccessAction,
  noteLoadForReportTagAction,
  noteLoadForReportTagFailuteAction,
  noteLoadForReportTagSuccessAction,
  noteLoadForReportTargetAction,
  noteLoadForReportTargetFailuteAction,
  noteLoadForReportTargetSuccessAction,
  noteLoadSuccessAction,
  noteOneLoadAction,
  noteOneLoadSuccessAction,
  noteUpdateAction,
  noteUpdateFailureAction,
  noteUpdateSuccessAction,
} from '@core/redux/note/note.actions';
import { ALL } from '@core/models/constants/category.model';
import * as moment from 'moment';

export const noteFeatureKey = 'note';

export interface NoteState extends EntityState<NoteInterface> {
  ids: string[];
  idsCurrentCreating: string[];
  idsForReportTag: string[];
  idsForReportTarget: string[];
  idsForReport: string[];
  isLoadingReportTag: boolean;
  isLoadingReportTarget: boolean;
  isLoadingReport: boolean;
  loading: boolean;
}

export const initialState: NoteState = {
  ids: [],
  idsCurrentCreating: [],
  idsForReportTag: [],
  idsForReportTarget: [],
  idsForReport: [],
  isLoadingReportTag: true,
  isLoadingReportTarget: true,
  isLoadingReport: true,
  loading: false,
  entities: {},
};

export const adapter: EntityAdapter<NoteInterface> = createEntityAdapter<
  NoteInterface
>({
  selectId: (note: NoteInterface) => note.id,
  sortComparer: false,
});

const reducer = createReducer(
  initialState,
  on(noteOneLoadAction, (state) => {
    return { ...state, loading: true };
  }),
  on(noteOneLoadSuccessAction, (state, { note }) => {
    return adapter.addOne(note, { ...state, loading: false });
  }),
  on(noteHardReloadAction, (state) => {
    return { ...state, loading: true };
  }),
  on(noteHardReloadSuccessAction, (state, { notes }) => {
    return adapter.setAll(notes, { ...state, loading: false });
  }),
  on(noteHardReloadFailureAction, (state, { error }) => {
    return { ...state, loading: false, errors: error };
  }),
  on(noteLoadAction, (state) => {
    return { ...state, loading: true };
  }),
  on(noteLoadSuccessAction, (state, { notes }) => {
    return adapter.addMany(notes, { ...state, loading: false });
  }),
  on(noteLoadFailureAction, (state, { error }) => {
    return { ...state, loading: false, errors: error };
  }),
  on(noteCreateFromBatchPopupAction, (state) => {
    return { ...state, loading: true };
  }),
  on(noteCreateFromBatchPopupSuccessAction, (state, { note }) => {
    const ids = state.ids;
    const idsCurrentCreating = [...state.idsCurrentCreating, ...[note.id]];
    return adapter.addOne(note, {
      ...state,
      ids,
      idsCurrentCreating,
      loading: false,
    });
  }),
  on(noteClearListFromBatchPopupAction, (state) => {
    return { ...state, idsCurrentCreating: [] };
  }),
  on(noteCreateFromBatchPopupFailureAction, (state, { error }) => {
    return { ...state, loading: false, errors: error };
  }),
  on(noteCreateAction, (state) => {
    return { ...state, loading: true };
  }),
  on(noteCreateSuccessAction, (state, { note }) => {
    return adapter.addOne(note, { ...state, loading: false });
  }),
  on(noteCreateFailureAction, (state, { error }) => {
    return { ...state, loading: false, errors: error };
  }),
  on(noteUpdateAction, (state) => {
    return { ...state, loading: true };
  }),
  on(noteUpdateSuccessAction, (state, { note }) => {
    return adapter.updateOne(
      { id: note.id, changes: note },
      { ...state, loading: false },
    );
  }),
  on(noteUpdateFailureAction, (state, { error }) => {
    return { ...state, loading: false, errors: error };
  }),
  on(clearNotesForReportAction, (state) => {
    return { ...state, idsForReport: [], isLoadingReport: true };
  }),
  on(clearNotesForReportTargetAction, (state) => {
    return { ...state, idsForReportTarget: [], isLoadingReportTarget: true };
  }),
  on(clearNotesForReportTagAction, (state) => {
    return { ...state, idsForReportTag: [], isLoadingReportTag: true };
  }),
  on(noteLoadForReportAction, (state) => {
    return { ...state, isLoadingReport: true };
  }),
  on(noteLoadForReportSuccessAction, (state, { notes }) => {
    const ids = state.ids;
    const idsForReport = notes.map((note) => note.id);
    return adapter.addMany(notes, {
      ...state,
      ids,
      idsForReport,
      isLoadingReport: false,
    });
  }),
  on(noteLoadForReportFailuteAction, (state, { error }) => {
    return { ...state, isLoadingReport: false, errors: error };
  }),
  on(noteLoadForReportTargetAction, (state) => {
    return { ...state, isLoadingReportTarget: false };
  }),
  on(noteLoadForReportTargetSuccessAction, (state, { notes }) => {
    const ids = state.ids;
    const idsForReportTarget = notes.map((note) => note.id);
    return adapter.addMany(notes, {
      ...state,
      ids,
      idsForReportTarget,
      isLoadingReportTarget: false,
    });
  }),
  on(noteLoadForReportTargetFailuteAction, (state, { error }) => {
    return { ...state, isLoadingReportTarget: false, errors: error };
  }),
  on(noteLoadForReportTagAction, (state) => {
    return { ...state, isLoadingReportTag: true };
  }),
  on(noteLoadForReportTagSuccessAction, (state, { notes }) => {
    const ids = state.ids;
    const idsForReportTag = notes.map((note) => note.id);
    return adapter.addMany(notes, {
      ...state,
      ids,
      idsForReportTag,
      isLoadingReportTag: false,
    });
  }),
  on(noteLoadForReportTagFailuteAction, (state, { error }) => {
    return { ...state, isLoadingReportTag: true, errors: error };
  }),
  on(noteChangeSortAction, (state, { ids, note }) => {
    const filteredIds = state.ids.filter((id) => ids.indexOf(id) === -1);
    const nextEntities = {
      ...state.entities,
      [note.id]: note,
    };

    return {
      ...state,
      ids: [...ids, ...filteredIds],
      entities: nextEntities,
    };
  }),
  on(noteDeleteAction, (state) => {
    return { ...state, loading: true };
  }),
  on(noteDeleteSuccessAction, (state, { id }) => {
    return adapter.removeOne(id, { ...state, loading: false });
  }),
  on(noteDeleteFailureAction, (state, { error }) => {
    return { ...state, loading: false, errors: error };
  }),
  on(noteChangeCategoryAction, (state, { categoryId, newCategoryId }) => {
    return adapter.map(
      (entity) =>
        entity.categoryId === categoryId
          ? { ...entity, categoryId: newCategoryId }
          : entity,
      state,
    );
  }),
);

export function noteReducer(
  state: NoteState = initialState,
  action: Action,
): NoteState {
  return reducer(state, action);
}

export const {
  selectAll,
  selectTotal,
  selectEntities,
} = adapter.getSelectors();

export const selectIsLoading = createSelector(
  (state: NoteState) => state.loading,
  (loading: boolean) => loading,
);

export const selectIsLoadingReportTag = createSelector(
  (state: NoteState) => state.isLoadingReportTag,
  (loading: boolean) => loading,
);

export const selectIsLoadingReportTarget = createSelector(
  (state: NoteState) => state.isLoadingReportTarget,
  (loading: boolean) => loading,
);

export const selectCurrentCreating = createSelector(
  (state: NoteState) => ({
    entities: state.entities,
    ids: state.idsCurrentCreating,
  }),
  ({ entities, ids }) => ids.map((id) => entities[id]).filter((n) => !!n),
);

export const selectIsLoadingReport = createSelector(
  (state: NoteState) => state.isLoadingReport,
  (loading: boolean) => loading,
);

export const selectById = (id: string) =>
  createSelector(
    selectEntities,
    (notes: Dictionary<NoteInterface>) => notes[id],
  );

export const notesAll = (categoryId?: string, isTask?: boolean) =>
  createSelector(selectAll, (entities) => {
    const ofCategory = (note: NoteInterface) =>
      typeof categoryId === 'string' && categoryId !== ALL
        ? note.categoryId === categoryId
        : true;
    return entities.filter(
      (item) => ofCategory(item) && item.isTask === isTask,
    );
  });

export const selectByDay = (
  year: number,
  month: number,
  day: number,
  isTask?: boolean,
  categoryId?: string,
) =>
  createSelector(selectAll, (entities) => {
    const ofType = (note: NoteInterface) =>
      typeof isTask === 'boolean' ? note.isTask === isTask : true;
    const ofCategory = (note: NoteInterface) =>
      typeof categoryId === 'string' && categoryId !== ALL
        ? note.categoryId === categoryId
        : true;
    return entities.filter(
      (item) =>
        item.year === year &&
        item.month === month &&
        item.day === day &&
        ofType(item) &&
        ofCategory(item),
    );
  });

export const selectPastByStatus = (
  status: boolean,
  categoryId: string,
  isTask?: boolean,
) =>
  createSelector(selectAll, (entities) => {
    const ofCategory = (note: NoteInterface) =>
      typeof categoryId === 'string' && categoryId !== ALL
        ? note.categoryId === categoryId
        : true;
    const isPastDate = (day: number, month: number, year: number) => {
      return moment([day, month, year].join('-'), 'DD-MM-YYYY').isBefore(
        moment().add(-1, 'day'),
        'day',
      );
    };
    return entities.filter(
      (item) =>
        isPastDate(item.day, item.month, item.year) &&
        item.isComplete === status &&
        item.isTask === isTask &&
        ofCategory(item),
    );
  });

export const selectForReportTag = createSelector(
  (state: NoteState) => ({
    entities: state.entities,
    ids: state.idsForReportTag,
  }),
  ({ entities, ids }) => ids.map((id) => entities[id]).filter((n) => !!n),
);

export const selectForReportTarget = createSelector(
  (state: NoteState) => ({
    entities: state.entities,
    ids: state.idsForReportTarget,
  }),
  ({ entities, ids }) => ids.map((id) => entities[id]).filter((n) => !!n),
);

export const selectForReport = createSelector(
  (state: NoteState) => ({ entities: state.entities, ids: state.idsForReport }),
  ({ entities, ids }) => ids.map((id) => entities[id]).filter((n) => !!n),
);
