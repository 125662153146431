import { Injectable } from '@angular/core';
import { InfoPopupService } from '@ui/info-popup';
import { NewLevelPopupData, NewLevelComponent } from './new-level.component';

@Injectable({
  providedIn: 'root',
})
export class NewLevelPopupService {
  constructor(private readonly infoPopupService: InfoPopupService) {}

  open(data: NewLevelPopupData) {
    const width = data.achievement.currentLevel.levelNumber === 1 ? 500 : 540;
    return this.infoPopupService.open(NewLevelComponent, { data, width });
  }
}
