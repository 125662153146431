import { select, Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import * as fromRoot from '@core/redux/index';
import { TagLoad, TagLoadSuccess } from '@core/redux/tag/tag.actions';

@Injectable({
  providedIn: 'root',
})
export class TagService {
  constructor(private store: Store<fromRoot.State>) {}

  getAll() {
    return this.store.pipe(select(fromRoot.getTagList));
  }

  getIsLoading() {
    return this.store.pipe(select(fromRoot.getTagLoading));
  }

  dispatchAll() {
    this.store.dispatch(new TagLoad());
  }

  dispatchAllSuccess(data) {
    this.store.dispatch(new TagLoadSuccess(data));
  }
}
