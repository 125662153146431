export interface Theme {
  title: string;
  properties: object;
}

export const THEME = 'theme';

export const LIGHT_THEME: Theme = {
  title: 'light',
  properties: {
    '--black': '#171725',
    '--black-link-underline-color': '#c5c5c8',
    '--blue': '#50b5ff',
    '--blue-primary': '#0062ff',
    '--light-blue': '#f2f7ff',
    '--green': '#3dd598',
    '--grey-1': '#44444f',
    '--grey-2': '#696974',
    '--grey-3': '#92929d',
    '--grey-4': '#b5b5be',
    '--grey-5': '#d5d5dc',
    '--light-grey-1': '#e2e2ea',
    '--light-grey-2': '#f1f1f5',
    '--light-grey-3': '#fafafb',
    '--orange': '#ff974a',
    '--red': '#fc5a5a',
    '--white': '#ffffff',
    '--yellow': '#ffc542',
    '--color-gray-ultra-light': '#f5f5f7',
    '--color-gray-pale-light': '#f6f9fb',
  },
};

export const DARK_THEME: Theme = {
  title: 'dark',
  properties: {
    '--black': '#F1F1F2',
    '--black-link-underline-color': '#',
    '--blue': '#',
    '--blue-primary': '#',
    '--light-blue': '#',
    '--green': '#',
    '--grey-1': '#DFDFE1',
    '--grey-2': '#',
    '--grey-3': '#',
    '--grey-4': '#4a4a4a',
    '--grey-5': '#',
    '--light-grey-1': '#b5b5be',
    '--light-grey-2': '#4A4A4D',
    '--light-grey-3': '#19191A',
    '--orange': '#',
    '--red': '#',
    '--white': '#222222',
    '--yellow': '#',
    '--color-gray-ultra-light': '#4A4A4D',
    '--color-gray-pale-light': '#373737',
  },
};
