import { Injectable } from '@angular/core';
import { ApiService } from '@core/services/api.service';
import { NoteInterface, NoteQueryModel } from '@core/models/note.interface';
import { Observable } from 'rxjs/internal/Observable';

/**
 * Сервис для работы с апи заметок
 */
@Injectable({
  providedIn: 'root',
})
export class NoteService extends ApiService {
  /**
   * Получить одну заметку с сервера
   */
  one(id: string): Observable<NoteInterface> {
    return this.http.get<NoteInterface>(this.getApiUrl(`notes/${id}`));
  }

  /**
   * Получить с сервера все категории
   */
  search(query: NoteQueryModel): Observable<NoteInterface[]> {
    return this.http.get<NoteInterface[]>(
      this.getApiUrl(`notes?${query.getQuery()}`),
    );
  }

  /**
   * Создать запись
   */
  create(model: NoteInterface): Observable<NoteInterface> {
    return this.http.post<NoteInterface>(this.getApiUrl(`notes`), model);
  }

  /**
   * Обновить запись
   */
  update(model: NoteInterface): Observable<NoteInterface> {
    return this.http.put<NoteInterface>(
      this.getApiUrl(`notes/${model.id}`),
      model,
    );
  }

  /**
   * Удалить запись
   */
  destroy(id: string): Observable<NoteInterface> {
    return this.http.delete<NoteInterface>(this.getApiUrl(`notes/${id}`));
  }
}
