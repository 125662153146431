import { NgModule } from '@angular/core';
import { TextTooltipForServiceComponent } from './text-tooltip-for-service';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { SafeLinkyModule } from '@shared/pipes/safe-linky/safe-linky.module';
import { ButtonsModule } from '@ui/buttons/buttons.module';
import { InnerHtmlLinkModule } from '@shared/directives/inner-html-link/inner-html-link.module';
import { TextTooltipForTemplateComponent } from './text-tooltip-for-template';

@NgModule({
  declarations: [
    TextTooltipForServiceComponent,
    TextTooltipForTemplateComponent,
  ],
  entryComponents: [TextTooltipForServiceComponent],
  imports: [
    TranslateModule,
    CommonModule,
    SafeLinkyModule,
    ButtonsModule,
    InnerHtmlLinkModule,
  ],
  exports: [TextTooltipForTemplateComponent],
})
export class TextTooltipModule {}
