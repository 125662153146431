import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  template: 'Loading..',
  selector: 'app-index',
  styles: [
    '{:host {display: flex; align-items: center; justify-content: center; width: 100vw; height: 100vh;}}',
  ],
})
export class IndexComponent implements OnInit {
  constructor(private readonly router: Router) {}

  ngOnInit() {
    this.redirectToPathFromQueryParams();
  }
  private redirectToPathFromQueryParams() {
    const initialLocation = this.router.url;
    const indexHtmlString = 'index.html?path=';
    const pathSearchStringIndex = initialLocation.indexOf(indexHtmlString);
    if (pathSearchStringIndex === -1) {
      this.router.navigate(['/calendar']);
      return;
    }
    const pathSearchString = initialLocation.slice(
      pathSearchStringIndex + indexHtmlString.length,
    );
    const decodedRedirectUrl = decodeURIComponent(pathSearchString);
    this.router.navigateByUrl(decodedRedirectUrl);
  }
}
