<button
  (click)="onCloseClick()"
  ui-button-icon
  class="subscription-ended__close"
  type="button"
>
  <ui-icon name="close"></ui-icon>
</button>
<div *ngIf="identity$ | async as identity" class="subscription-ended__left">
  <div
    *ngIf="!identity.isActive && identity.isTest"
    class="subscription-ended__title"
  >
    {{ 'UX.PRO_COMPLETE.TEST_ENDED' | translate }}
  </div>
  <div
    class="subscription-ended__title"
    *ngIf="!identity.isActive && !identity.isTest"
  >
    {{ 'UX.PRO_COMPLETE.PAYED_ENDED' | translate }}
  </div>
  <div class="subscription-ended__description">
    {{ 'UX.PRO_COMPLETE.DESCRIPTION' | translate: { price: price$ | async } }}
  </div>
  <div class="subscription-ended__footer">
    <a
      [routerLink]="['/payment']"
      ui-button-primary
      class="subscription-ended__btn subscription-ended__btn_desktop"
    >
      {{ 'UX.PRO_COMPLETE.PAY' | translate }}
    </a>
    <a
      [routerLink]="['/payment']"
      ui-button-primary-long
      class="subscription-ended__btn subscription-ended__btn_tablet"
    >
      {{ 'UX.PRO_COMPLETE.PAY' | translate }}
    </a>
  </div>
</div>
<div class="subscription-ended__right">
  <div class="subscription-ended__img">
    <img src="./assets/img/test-ended.png" alt="" />
  </div>
</div>
<a
  [routerLink]="['/payment']"
  ui-button-primary-long
  class="subscription-ended__btn subscription-ended__btn_mobile"
>
  {{ 'UX.PRO_COMPLETE.PAY' | translate }}
</a>
