import {
  Component,
  EventEmitter,
  Input,
  Output,
  ChangeDetectionStrategy,
  OnChanges,
  ElementRef,
  OnDestroy,
  AfterViewInit,
  ChangeDetectorRef,
} from '@angular/core';
import { FileInterface } from '@core/models/file.interface';
import { filePreviewImageSize } from '@core/services/images/used-sizes';
import { PreviewPreloadService } from './preview-preload.service';

@Component({
  selector: 'ui-file-preview',
  templateUrl: './ui-file-preview.component.html',
  styleUrls: ['./ui-file-preview.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UiFilePreviewComponent
  implements OnChanges, OnDestroy, AfterViewInit {
  @Output()
  clickImage = new EventEmitter<any>();

  @Output()
  openImage = new EventEmitter();

  @Input()
  image: FileInterface;

  @Input()
  preloadSizes: string[] = [filePreviewImageSize, 'origin'];

  url: string;

  get intersected(): boolean {
    return this._intersected;
  }

  // tslint:disable-next-line:variable-name
  private _intersected = false;

  constructor(
    public readonly elementRef: ElementRef<HTMLElement>,
    private changeDetector: ChangeDetectorRef,
    private previewPreloadService: PreviewPreloadService,
  ) {}

  ngAfterViewInit() {
    this.previewPreloadService.registerComponent(this);
  }

  ngOnChanges() {
    this.url = this.image.sizes[filePreviewImageSize];
  }

  ngOnDestroy() {
    this.previewPreloadService.unregisterComponent(this);
  }

  onOpenModal(event) {
    event.stopPropagation();
    this.clickImage.emit();
    this.openImage.emit();
  }

  markAsIntersected() {
    if (this.intersected) {
      return;
    }
    this._intersected = true;
    this.changeDetector.markForCheck();
  }
}
