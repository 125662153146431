import {
  Component,
  DoCheck,
  ComponentRef,
  ComponentFactoryResolver,
  ElementRef,
  Injector,
  Renderer2,
  HostBinding,
  ChangeDetectionStrategy,
  TemplateRef,
  NgZone,
} from '@angular/core';
import { ComponentType } from '@angular/cdk/portal';
import { TooltipConfig } from './tooltip-config';
import { take } from 'rxjs/operators';
import {
  animate,
  keyframes,
  style,
  transition,
  trigger,
} from '@angular/animations';

@Component({
  selector: 'app-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.less'],
  animations: [
    trigger('enterLeave', [
      transition(
        ':enter',
        animate(
          '200ms cubic-bezier(0, 0, 0.2, 1)',
          keyframes([
            style({ opacity: 0, transform: 'scale(0)', offset: 0 }),
            style({ opacity: 0.5, transform: 'scale(0.99)', offset: 0.5 }),
            style({ opacity: 1, transform: 'scale(1)', offset: 1 }),
          ]),
        ),
      ),
    ]),
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TooltipComponent implements DoCheck {
  @HostBinding('@enterLeave')
  animation = true;

  @HostBinding('style.width')
  width = '';

  private componentRef: ComponentRef<any> | undefined;
  constructor(
    private readonly componentFactoryResolver: ComponentFactoryResolver,
    private readonly elementRef: ElementRef<HTMLElement>,
    private readonly injector: Injector,
    private readonly ngZone: NgZone,
    private readonly renderer: Renderer2,
  ) {}

  setView<T = any>(
    content: ComponentType<T> | TemplateRef<T> | string,
    config: TooltipConfig<any>,
  ): void {
    if (typeof content === 'string') {
      this.createStringContent(content);
    } else if (content instanceof TemplateRef) {
      this.createTemplateContent(content);
    } else {
      this.createComponentContent(content);
    }
    this.width = config.width || this.width;
  }

  ngDoCheck() {
    if (this.componentRef) {
      this.componentRef.changeDetectorRef.detectChanges();
    }
  }

  private createComponentContent<T>(component: ComponentType<T>) {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(
      component,
    );
    this.componentRef = componentFactory.create(this.injector);
    this.renderer.appendChild(
      this.elementRef.nativeElement,
      this.componentRef.location.nativeElement,
    );
  }

  private createStringContent(text: string) {
    const textNode = this.renderer.createText(text);
    this.renderer.appendChild(this.elementRef.nativeElement, textNode);
  }

  private createTemplateContent(templateRef: TemplateRef<any>) {
    const element = templateRef.createEmbeddedView({});
    for (const node of element.rootNodes) {
      this.renderer.appendChild(this.elementRef.nativeElement, node);
    }
    this.ngZone.onMicrotaskEmpty
      .pipe(take(1))
      .subscribe(() => element.detectChanges());
  }
}
