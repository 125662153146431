import { Injectable } from '@angular/core';
import {
  ImageSliderComponent,
  ImageSliderDialogData,
} from './image-slider.component';
import { DialogService } from '@ui/dialog';

@Injectable({
  providedIn: 'root',
})
export class ImageSliderDialogService {
  constructor(private readonly dialog: DialogService) {}

  open(data: ImageSliderDialogData) {
    return this.dialog.open(ImageSliderComponent, { data }, false);
  }
}
