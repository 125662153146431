import { Achievement, AchievementLevel } from '@core/models/identity.interface';

export function isLevelInProgress(
  achievement: Achievement,
  level: AchievementLevel,
): boolean {
  return (
    (!achievement.currentLevel && level.levelNumber === 1) ||
    (achievement.currentLevel &&
      level.levelNumber === achievement.currentLevel.levelNumber + 1)
  );
}
