import { Injectable } from '@angular/core';
import { InfoPopupService } from '@ui/info-popup';
import { ErrorPopupData, ErrorPopupComponent } from './error-popup.component';

@Injectable({
  providedIn: 'root',
})
export class ErrorPopupService {
  private readonly defaultData: ErrorPopupData = {
    header: 'INFO_DIALOGS.TEXT.ERROR',
    text: 'INFO_DIALOGS.TEXT.GENERAL_ERROR',
    message: '',
  };

  constructor(private readonly infoPopupService: InfoPopupService) {}

  /**
   * Не используй этот метод в компонентах. Используй `ErrorHandlerService`
   */
  open(options: Partial<ErrorPopupData> = {}) {
    const data = {
      header: options.header || this.defaultData.header,
      text: options.text || this.defaultData.text,
      message: options.message || this.defaultData.message,
    };
    return this.infoPopupService.open(ErrorPopupComponent, {
      data,
      width: 360,
    });
  }
}
