import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { of } from 'rxjs/internal/observable/of';
import { catchError, exhaustMap, map } from 'rxjs/operators';
import { TagActionTypes, TagLoadFailure, TagLoadSuccess } from './tag.actions';
import { TagService } from '@core/services/tag.service';

/**
 * Effect для работы с тегами
 */
@Injectable()
export class TagEffects {
  /**
   * Поиск тегов
   */
  @Effect()
  search$ = this.actions$.pipe(
    ofType(TagActionTypes.TagLoad),
    exhaustMap(() => {
      return this.tagService.search().pipe(
        map(tags => new TagLoadSuccess(tags)),
        catchError(errors => of(new TagLoadFailure(errors))),
      );
    }),
  );

  constructor(private actions$: Actions, private tagService: TagService) {}
}
