<div class="ui-note-view-slider">
  <div class="ui-note-view-slider__img-container">
    <div *ngIf="shouldShowFakeImage" class="ui-note-view-slider__fake-img">
      <img
        [src]="initialImg | imagePreview"
        class="ui-note-view-slider__fake-img"
        alt=""
      />
    </div>
    <ngx-tiny-slider
      #slider
      [config]="tinySliderConfig"
      [class.ui-note-view-slider__slider_hidden]="shouldShowFakeImage"
      class="ui-note-view-slider__slider"
    >
      <ng-container class="items">
        <div
          #slideList
          *ngFor="let image of images"
          (panup)="onPanUp($event, slideList)"
          (panend)="onPanEnd($event, slideList)"
          (pandown)="onPanDown($event, slideList)"
          class="item ui-note-view-slider__item"
        >
          <img
            [attr.data-src]="image | imagePreview"
            class="tns-lazy-img"
            alt=""
          />
        </div>
      </ng-container>
    </ngx-tiny-slider>
  </div>
  <div *ngIf="!isMobile" class="ui-note-view-slider__actions">
    <button
      (click)="onSelectImage('prev')"
      class="btn btn_not-button ui-note-view-slider__slide-button ui-note-view-slider__slide-button_prev"
      type="button"
    ></button>
    <button
      (click)="onClose()"
      class="btn btn_not-button ui-note-view-slider__close-button"
      type="button"
    ></button>
    <button
      (click)="onSelectImage('next')"
      class="btn btn_not-button ui-note-view-slider__slide-button ui-note-view-slider__slide-button_next"
      type="button"
    ></button>
  </div>
  <div class="ui-note-view-slider__scroll-list">
    <div class="ui-note-view-slider__list">
      <button
        #previewButtons
        *ngFor="let image of images; let i = index"
        (click)="onSelectImage(i)"
        [class.ui-note-view-slider__preview_active]="i === activeIndex"
        class="ui-note-view-slider__preview"
        type="button"
      >
        <img [src]="image | imagePreview: 82:82" alt="" />
      </button>
    </div>
  </div>
</div>
