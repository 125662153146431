import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { of } from 'rxjs/internal/observable/of';
import { catchError, exhaustMap, map } from 'rxjs/operators';
import {
  BillingInformationActionTypes,
  BillingInformationDelete,
  BillingInformationDeleteFailure,
  BillingInformationDeleteSuccess,
  BillingInformationLoadFailure,
  BillingInformationLoadSuccess,
} from './billing-information.actions';
import { BillingInformationService } from '@core/services/billing-information.service';

/**
 * Effect для работы с категориями
 */
@Injectable()
export class BillingInformationEffects {
  @Effect()
  search$ = this.actions$.pipe(
    ofType(BillingInformationActionTypes.BillingInformationLoad),
    exhaustMap(() => {
      return this.billingInformationService.search().pipe(
        map(model => new BillingInformationLoadSuccess(model)),
        catchError(errors => of(new BillingInformationLoadFailure(errors))),
      );
    }),
  );

  @Effect()
  delete$ = this.actions$.pipe(
    ofType(BillingInformationActionTypes.BillingInformationDelete),
    map((action: BillingInformationDelete) => action.payload),
    exhaustMap(model => {
      return this.billingInformationService.delete(model).pipe(
        map(_ => new BillingInformationDeleteSuccess(model.id)),
        catchError(errors => of(new BillingInformationDeleteFailure(errors))),
      );
    }),
  );

  constructor(
    private actions$: Actions,
    private billingInformationService: BillingInformationService,
  ) {}
}
