import {
  Component,
  ChangeDetectionStrategy,
  Inject,
  HostBinding,
} from '@angular/core';
import { TOOLTIP_DATA, TooltipRef } from '@ui/tooltip';
import { getArrowPositionClass } from './helpers';
import { TextTooltipData } from './text-tooltip-data';

@Component({
  selector: 'rt-sdlfkjwoiowijdf-text-tooltip',
  templateUrl: './text-tooltip.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TextTooltipForServiceComponent {
  @HostBinding('class.rt-tooltip')
  ngClass = true;

  // TODO: create better config
  actions?: () => void;
  hasCloseButton: boolean;
  text = '';
  arrowPositionClass = '';
  constructor(
    @Inject(TOOLTIP_DATA) data: TextTooltipData,
    private readonly tooltipRef: TooltipRef<TextTooltipData>,
  ) {
    this.hasCloseButton = data.hasCloseButton ? true : false;
    this.text = data.text;
    this.actions = data.actions;
    this.arrowPositionClass = getArrowPositionClass(
      this.tooltipRef.config.arrow,
    );
  }

  onCloseClick() {
    this.tooltipRef.close();
    if (this.actions) {
      this.actions();
    }
  }
}
