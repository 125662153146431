import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UiReleaseAlertComponent } from './ui-release-alert.component';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonsModule } from '@ui/buttons/buttons.module';

@NgModule({
  declarations: [UiReleaseAlertComponent],
  exports: [UiReleaseAlertComponent],
  imports: [CommonModule, TranslateModule, ButtonsModule],
})
export class UiReleaseAlertModule {}
