import { Component, OnInit, Inject } from '@angular/core';
import { INFO_POPUP_DATA, InfoPopupRef } from '@ui/info-popup';

export interface IconPopupData {
  title: string;
  description: string;
  icon: string;
  imgForMobile: string;
}

@Component({
  selector: 'app-info',
  templateUrl: './icon-popup.component.html',
  styleUrls: ['../info-popup.less', './icon-popup.component.less'],
})
export class IconPopupComponent implements OnInit {
  constructor(
    @Inject(INFO_POPUP_DATA) public readonly data: IconPopupData,
    private readonly infoPopupRef: InfoPopupRef<any>,
  ) {}

  ngOnInit() {}

  onCloseClick() {
    this.infoPopupRef.close();
  }
}
