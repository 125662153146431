import { Action } from '@ngrx/store';

export enum NotificationActionTypes {
  SubscribeAction = '[Notification] Subsribe',
  SubscribeSuccessAction = '[Notification] Subscribe Success',
  SubscribeFailureAction = '[Notification] Subscribe Failure',
}

export class SubscribeAction implements Action {
  readonly type = NotificationActionTypes.SubscribeAction;

  constructor(public readonly subscriber: PushSubscription) {}
}

export class SubscribeSuccessAction implements Action {
  readonly type = NotificationActionTypes.SubscribeSuccessAction;
}

export class SubscribeFailureAction implements Action {
  readonly type = NotificationActionTypes.SubscribeFailureAction;
}
