<ul
  #list
  (mousedown)="$event.stopPropagation(); (false)"
  [hidden]="hidden"
  class="autocomplete-tags-list__list"
>
  <li
    *ngFor="let tag of tags; let i = index"
    (click)="onTagClick(i, $event)"
    [class.autocomplete-tags-list__item_active]="activeIndex === i"
    class="autocomplete-tags-list__item"
  >
    <span class="autocomplete-tags-list__name"> #{{ tag.name }} </span>
    <span class="autocomplete-tags-list__count">
      {{ tag.noteCount }}
    </span>
  </li>
</ul>
