/**
 * Для определения ретины дисплея
 */
export function getDevicePixelRatio(): number {
  const ratio = typeof devicePixelRatio === 'number' ? devicePixelRatio : 3;
  switch (ratio) {
    case 1:
    case 1.5:
    case 2:
    case 3:
    case 4:
      return ratio;
  }

  if (ratio < 1) {
    return 1;
  }

  return Math.ceil(ratio) - 1;
}
