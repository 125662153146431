import { Environment, EnvironmentName } from './environment.interface';

const zone = location.origin.endsWith('.ru') ? 'ru' : 'me';

export const environment: Environment = {
  name: EnvironmentName.Prod,
  sentry: true,
  production: true,
  apiUrl: `https://api.logtime.${ zone }/v5/`,
  vapidKey:
    'BOqTsDyPOnVSaYxs0GMNBzbAUMZkUsoc98eWdy1TRstw8KRRMngvFS0RtsXyJ5MJa3k6jyozBtyUN83bvl56wj8',
  socket: {
    url: `https://app.logtime.${ zone }`,
    options: {},
  },
  amplitude: '871d3563dbc52058f5025617dcaa159f',
  yaCounterId: 49136965,
  electron: {
    storage: 'https://api.logtime.me/space',
    nativeApp: {
      latestReleaseFile: {
        mac: 'latest-mac.yml',
      },
    },
  },
};
