import { Action } from '@ngrx/store';
import { TargetTypeInterface } from '@core/models/target-type.interface';

export enum TargetTypeActionTypes {
  TargetTypeSelect = '[TargetType] Select',
  TargetTypeUnSelect = '[TargetType] Un Select',
  TargetTypeLoad = '[TargetType] Load',
  TargetTypeLoadSuccess = '[TargetType] Load Success',
  TargetTypeLoadFailure = '[TargetType] Load Failure',
  TargetTypeCreate = '[TargetType] Create',
  TargetTypeCreateSuccess = '[TargetType] Create Success',
  TargetTypeCreateFailure = '[TargetType] Create Failure',
  TargetTypeUpdate = '[TargetType] Update',
  TargetTypeUpdateSuccess = '[TargetType] Update Success',
  TargetTypeUpdateFailure = '[TargetType] Update Failure',
  TargetTypeDelete = '[TargetType] Delete',
  TargetTypeDeleteSuccess = '[TargetType] Delete Success',
  TargetTypeDeleteFailure = '[TargetType] Delete Failure',
}

export class TargetTypeLoad implements Action {
  readonly type = TargetTypeActionTypes.TargetTypeLoad;
}

export class TargetTypeLoadSuccess implements Action {
  readonly type = TargetTypeActionTypes.TargetTypeLoadSuccess;

  constructor(public payload: TargetTypeInterface[]) {}
}

export class TargetTypeLoadFailure implements Action {
  readonly type = TargetTypeActionTypes.TargetTypeLoadFailure;

  constructor(public payload: any) {}
}

export class TargetTypeCreate implements Action {
  readonly type = TargetTypeActionTypes.TargetTypeCreate;

  constructor(public payload: TargetTypeInterface) {}
}

export class TargetTypeCreateSuccess implements Action {
  readonly type = TargetTypeActionTypes.TargetTypeCreateSuccess;

  constructor(public payload: TargetTypeInterface) {}
}

export class TargetTypeCreateFailure implements Action {
  readonly type = TargetTypeActionTypes.TargetTypeCreateFailure;

  constructor(public payload: any) {}
}

export class TargetTypeUpdate implements Action {
  readonly type = TargetTypeActionTypes.TargetTypeUpdate;

  constructor(public payload: TargetTypeInterface) {}
}

export class TargetTypeUpdateSuccess implements Action {
  readonly type = TargetTypeActionTypes.TargetTypeUpdateSuccess;

  constructor(public payload: TargetTypeInterface) {}
}

export class TargetTypeUpdateFailure implements Action {
  readonly type = TargetTypeActionTypes.TargetTypeUpdateFailure;

  constructor(public payload: any) {}
}

export class TargetTypeDelete implements Action {
  readonly type = TargetTypeActionTypes.TargetTypeDelete;

  constructor(public payload: string) {}
}

export class TargetTypeDeleteSuccess implements Action {
  readonly type = TargetTypeActionTypes.TargetTypeDeleteSuccess;

  constructor(public payload: string) {}
}

export class TargetTypeDeleteFailure implements Action {
  readonly type = TargetTypeActionTypes.TargetTypeDeleteFailure;

  constructor(public payload: any) {}
}

export class TargetTypeSelect implements Action {
  readonly type = TargetTypeActionTypes.TargetTypeSelect;

  constructor(public payload: string) {}
}

export class TargetTypeUnSelect implements Action {
  readonly type = TargetTypeActionTypes.TargetTypeUnSelect;

  constructor() {}
}

export type TargetTypeActions =
  | TargetTypeLoad
  | TargetTypeLoadSuccess
  | TargetTypeLoadFailure
  | TargetTypeCreate
  | TargetTypeCreateSuccess
  | TargetTypeCreateFailure
  | TargetTypeUpdate
  | TargetTypeUpdateSuccess
  | TargetTypeUpdateFailure
  | TargetTypeDelete
  | TargetTypeDeleteSuccess
  | TargetTypeDeleteFailure
  | TargetTypeSelect
  | TargetTypeUnSelect;
