import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotificationsComponent } from './notifications.component';
import { UiIconModule } from '@ui/ui-icon/ui-icon.module';
import { ButtonsModule } from '@ui/buttons/buttons.module';
import { UiCheckModule } from '@ui/ui-check/ui-check.module';
import { MatDialogModule } from '@angular/material/dialog';
import { SetNotificationsComponent } from './set-notifications/set-notifications.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [NotificationsComponent, SetNotificationsComponent],
  imports: [
    CommonModule,
    UiIconModule,
    ButtonsModule,
    UiCheckModule,
    MatDialogModule,
    TranslateModule,
  ],
  exports: [NotificationsComponent],
  entryComponents: [NotificationsComponent],
})
export class NotificationsModule {}
