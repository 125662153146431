import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  forwardRef,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { iconSelectorIcons } from './icon-selector-icons';

@Component({
  selector: 'rt-icon-selector',
  templateUrl: './icon-selector.component.html',
  styleUrls: ['./icon-selector.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => IconSelectorComponent),
      multi: true,
    },
  ],
})
export class IconSelectorComponent
  implements OnInit, OnDestroy, AfterViewInit, ControlValueAccessor {
  @ViewChildren('iconGroups')
  groups: QueryList<ElementRef<HTMLElement>>;

  /**
   * Первая иконка группы, иконку которой подсвечивать
   */
  activeGroupFirstIcon: string | undefined;

  readonly headerIcons = [
    'comp',
    'weight',
    'hanger',
    'french-bread',
    'chef',
    'roller2',
    'camera',
    'delete-user',
    'cart',
    'folder',
  ];

  readonly groupIcons = iconSelectorIcons;

  value = this.groupIcons[0].icons[0];

  private touched = false;
  private onTouched: () => void;
  private onChanged: (value: string) => void;
  private intersectionObserver: IntersectionObserver;

  constructor(private readonly changeDetector: ChangeDetectorRef) {}

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.unsubscribeFromScrolling();
  }

  ngAfterViewInit() {
    this.subscribeToScrolling();
    this.groups.changes.subscribe(() => {
      this.unsubscribeFromScrolling();
      this.subscribeToScrolling();
    });
  }

  writeValue(value: string): void {
    // set value and update view
    if (value === this.value) {
      return;
    }
    this.value = value;
    if (this.onChanged) {
      this.onChanged(value);
    }
  }
  registerOnChange(fn: (value: string) => void): void {
    // save fn to call it with updated value
    // every time the value changes
    this.onChanged = fn;
  }
  registerOnTouched(fn: () => void): void {
    // save fn to call it the first time
    // user interacts with the component
    this.onTouched = fn;
  }

  onIconClick(icon: string) {
    this.writeValue(icon);
    if (!this.touched) {
      this.touched = true;
      if (this.onTouched) {
        this.onTouched();
      }
    }
  }

  onScrollTo(icon: string) {
    const groupIndex = this.headerIcons.indexOf(icon);
    const group = this.groups.find((_, index) => index === groupIndex);
    if (group) {
      group.nativeElement.scrollIntoView({ behavior: 'smooth' });
    }
  }

  private subscribeToScrolling() {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        const groupFirstIcon = entry.target.getAttribute('data-group');
        const group = this.groupIcons.find(
          (g) => g.icons[0] === groupFirstIcon,
        );
        group.isIntersecting = entry.isIntersecting;
      });
      const firstIntersectingGroup = this.groupIcons.find(
        (g) => g.isIntersecting,
      );
      if (firstIntersectingGroup) {
        this.activeGroupFirstIcon = firstIntersectingGroup.icons[0];
        this.changeDetector.markForCheck();
      }
    });
    this.groups.forEach((group) => {
      observer.observe(
        group.nativeElement.getElementsByClassName(
          'icon-selector__title-group',
        )[0],
      );
    });
    this.intersectionObserver = observer;
  }

  private unsubscribeFromScrolling() {
    if (this.intersectionObserver) {
      this.intersectionObserver.disconnect();
    }
  }
}
