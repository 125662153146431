import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconSelectorComponent } from './icon-selector.component';
import { UiIconModule } from '@ui/ui-icon/ui-icon.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [IconSelectorComponent],
  imports: [CommonModule, UiIconModule, TranslateModule],
  exports: [IconSelectorComponent],
})
export class IconSelectorModule {}
