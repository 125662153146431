import { Injectable } from '@angular/core';
import {
  PromocodeDialogData,
  PromocodeConfirmApplyComponent,
} from './promocode-confirm-apply.component';
import { DialogService } from '@ui/dialog';

@Injectable({
  providedIn: 'root',
})
export class PromocodeDialogService {
  constructor(private readonly dialog: DialogService) {}

  open(data: PromocodeDialogData) {
    return this.dialog.open(PromocodeConfirmApplyComponent, { data }, false);
  }
}
