import {
  Component,
  OnInit,
  Input,
  OnChanges,
  Inject,
  LOCALE_ID,
  ChangeDetectionStrategy,
} from '@angular/core';
import { NoteInterface } from '@core/models/note.interface';
import { DecimalPipe } from '@angular/common';
import { calcProgress } from '@core/models/target.interface';

@Component({
  selector: 'ui-note-view-target',
  templateUrl: './ui-note-view-target.component.html',
  styleUrls: ['./ui-note-view-target.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UiNoteViewTargetComponent implements OnInit, OnChanges {
  @Input()
  note: NoteInterface;

  /**
   * Целое число в процентах
   */
  progress: number;

  link: string;

  progressDetails: string;

  private decimalPipe: DecimalPipe;

  constructor(@Inject(LOCALE_ID) localeId) {
    this.decimalPipe = new DecimalPipe(localeId);
  }

  ngOnInit() {}

  ngOnChanges() {
    this.progress = calcProgress(this.note.target);
    this.link = '/report-target/' + this.note.target.id;
    this.progressDetails = this.calcProgressDetails();
  }

  private calcProgressDetails(): string {
    const measure = this.note.target.type ? this.note.target.type.name : '';
    const sign = this.note.targetProgress < 0 ? '-' : '+';
    const num = this.decimalPipe.transform(Math.abs(this.note.targetProgress));
    return sign + num + measure;
  }
}
