import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UiChecklistComponent } from '@ui/ui-checklist/ui-checklist.component';
import { UiCheckModule } from '@ui/ui-check/ui-check.module';

@NgModule({
  declarations: [UiChecklistComponent],
  exports: [UiChecklistComponent],
  imports: [CommonModule, UiCheckModule],
})
export class UiChecklistModule {}
