import { Story } from './story.model';
import { createReducer, on, Action } from '@ngrx/store';
import {
  getStoriesSuccess,
  getStoriesFailure,
  markStoryAsViewedSuccess,
  getStories,
} from './story.actions';

export interface State {
  stories: Story[];
  isLoadingFirstTime: boolean;
}

const initialState: State = {
  stories: [],
  isLoadingFirstTime: true,
};

const storyReducer = createReducer<State>(
  initialState,
  on(getStoriesSuccess, (state, action) => ({
    ...state,
    stories: action.stories,
    isLoadingFirstTime: false,
  })),
  on(getStoriesFailure, () => ({ stories: [], isLoadingFirstTime: false })),
  on(markStoryAsViewedSuccess, (state, action) => ({
    ...state,
    stories: state.stories.map((s) =>
      s.id === action.story.id ? action.story : s,
    ),
  })),
);

export function reducer(state: State, action: Action) {
  return storyReducer(state, action);
}
