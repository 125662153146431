import { Injectable, ElementRef } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { getCaretPosition } from '@shared/directives/autocomplete-tags/utils';

@Injectable()
export class HashtagService {
  /**
   * Положение каретки в тексте
   * Запоминается перед кликом на хештег
   */
  private caretPosition = 0;

  constructor(private deviceService: DeviceDetectorService) {}

  /**
   * Добавляет хэштег в текст на место, куда последний раз кликал пользователь
   *
   * @param text текущий текст в инпуте
   *
   * @returns кортеж из обновленного текста с хэштегом и позицию, куда нужно поместить каретку
   */
  addHash(text: string | undefined): [string, number] {
    const value = text || '';
    const nextValue =
      value.slice(0, this.caretPosition) +
      '#' +
      value.slice(this.caretPosition);
    const pos = this.getCaretPositionAfterHash(nextValue);
    return [nextValue, pos];
  }

  updateCaretPosition(textArea: ElementRef<HTMLTextAreaElement>) {
    this.caretPosition = getCaretPosition(
      (textArea.nativeElement as never) as HTMLInputElement,
    );
  }

  private getCaretPositionAfterHash(textWithHash: string): number {
    const pos =
      this.deviceService.browser === 'Safari' &&
      textWithHash[this.caretPosition] === ' '
        ? this.caretPosition + 2
        : this.caretPosition + 1;
    return pos;
  }
}
