import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { of } from 'rxjs/internal/observable/of';
import { catchError, exhaustMap, map, switchMap } from 'rxjs/operators';
import {
  TargetTypeActionTypes,
  TargetTypeCreate,
  TargetTypeCreateFailure,
  TargetTypeCreateSuccess,
  TargetTypeDelete,
  TargetTypeDeleteFailure,
  TargetTypeDeleteSuccess,
  TargetTypeLoadFailure,
  TargetTypeLoadSuccess,
  TargetTypeUnSelect,
  TargetTypeUpdate,
  TargetTypeUpdateFailure,
  TargetTypeUpdateSuccess,
} from './target-type.actions';
import { TargetTypeService } from '@core/services/target-type.service';

/**
 * Effect для работы с категориями
 */
@Injectable()
export class TargetTypeEffects {
  /**
   * Поиск типов целей
   */
  @Effect()
  search$ = this.actions$.pipe(
    ofType(TargetTypeActionTypes.TargetTypeLoad),
    exhaustMap(() => {
      return this.targetTypeService.search().pipe(
        map(types => new TargetTypeLoadSuccess(types)),
        catchError(errors => of(new TargetTypeLoadFailure(errors))),
      );
    }),
  );

  /**
   * Создание нового типа цели
   */
  @Effect()
  create$ = this.actions$.pipe(
    ofType(TargetTypeActionTypes.TargetTypeCreate),
    map((action: TargetTypeCreate) => action.payload),
    exhaustMap(model => {
      return this.targetTypeService.create(model).pipe(
        switchMap(user => [
          new TargetTypeCreateSuccess(user),
          new TargetTypeUnSelect(),
        ]),
        catchError(errors => of(new TargetTypeCreateFailure(errors))),
      );
    }),
  );

  /**
   * Обновление категории
   */
  @Effect()
  update$ = this.actions$.pipe(
    ofType(TargetTypeActionTypes.TargetTypeUpdate),
    map((action: TargetTypeUpdate) => action.payload),
    exhaustMap(model => {
      return this.targetTypeService.update(model).pipe(
        switchMap(user => [
          new TargetTypeUpdateSuccess(user),
          new TargetTypeUnSelect(),
        ]),
        catchError(errors => of(new TargetTypeUpdateFailure(errors))),
      );
    }),
  );

  @Effect()
  delete$ = this.actions$.pipe(
    ofType(TargetTypeActionTypes.TargetTypeDelete),
    map((action: TargetTypeDelete) => action.payload),
    exhaustMap((id: string) => {
      return this.targetTypeService.destroy(id).pipe(
        switchMap(() => {
          return [new TargetTypeDeleteSuccess(id), new TargetTypeUnSelect()];
        }),
        catchError(errors => of(new TargetTypeDeleteFailure(errors))),
      );
    }),
  );

  constructor(
    private actions$: Actions,
    private targetTypeService: TargetTypeService,
  ) {}
}
