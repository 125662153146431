import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'customDate',
})
export class CustomDatePipe extends DatePipe implements PipeTransform {
  constructor(private readonly translate: TranslateService) {
    super(translate.currentLang);
  }

  transform(
    value: any,
    format?: string,
    locale?: string,
    timezone?: string,
  ): any {
    let formattedDate = super.transform(value, format, timezone, locale);
    const now = moment();
    const tomorrow = moment().add(1, 'day');
    const currentDate = moment(value);

    if (now.isSame(currentDate, 'day')) {
      formattedDate = '$.TODAY';
    }

    if (tomorrow.isSame(currentDate, 'day')) {
      formattedDate = '$.TOMORROW';
    }

    return formattedDate;
  }
}
