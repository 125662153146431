import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InfoPopupContentComponent } from './info-popup-content/info-popup-content.component';
import { UiIconModule } from '@ui/ui-icon/ui-icon.module';
import { MatDialogModule } from '@angular/material/dialog';
import { ButtonsModule } from '@ui/buttons/buttons.module';
import { TranslateModule } from '@ngx-translate/core';
import { InfoPopupDesktopContainerComponent } from './info-popup-desktop-container/info-popup-desktop-container.component';
import { PortalModule } from '@angular/cdk/portal';
import { InfoPopupMobileContainerComponent } from './info-popup-mobile-container/info-popup-mobile-container.component';

@NgModule({
  declarations: [
    InfoPopupContentComponent,
    InfoPopupDesktopContainerComponent,
    InfoPopupMobileContainerComponent,
  ],
  imports: [
    CommonModule,
    UiIconModule,
    ButtonsModule,
    MatDialogModule,
    TranslateModule,
    PortalModule,
  ],
  exports: [InfoPopupContentComponent],
})
export class InfoPopupModule {}
