import { Injectable } from '@angular/core';
import { InfoPopupService } from '@ui/info-popup';
import { IconPopupComponent, IconPopupData } from './icon-popup.component';

@Injectable({
  providedIn: 'root',
})
export class IconPopupService {
  constructor(private readonly infoPopupService: InfoPopupService) {}

  open(data: IconPopupData) {
    return this.infoPopupService.open(IconPopupComponent, { data, width: 540 });
  }
}
