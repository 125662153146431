import { Injectable } from '@angular/core';
import { inElectron, receiveFromElectron } from '@helpers/consts/electron';
import { InitService } from '@core/services/init.service';
import { NoteService } from '@core/redux/note/note.service';

@Injectable({
  providedIn: 'root',
})
export class SyncService {
  constructor(
    private readonly initService: InitService,
    private readonly noteService: NoteService,
  ) {}

  subscribeElectronSync() {
    if (inElectron) {
      receiveFromElectron('fromElectron:focus', () => {
        this.initService.prepare();
        this.noteService.hardReloadAll();
      });
    }
  }
}
