<!-- Prevent context menu for phones (в фф на андроиде не помогает) -->
<video
  #videoElement
  (contextmenu)="$event.preventDefault(); (false)"
  (mouseup)="onMouseup()"
  (pressup)="onMouseup()"
  (mousedown)="onMousedown()"
  (press)="onMousedown()"
  (tap)="tapped.emit($event)"
  class="story__video"
>
  <source [src]="story.videoUrl" />
</video>
<div
  *ngIf="story.buttonIsShow"
  [ngSwitch]="story.buttonType"
  class="story__container"
>
  <button
    *ngSwitchCase="'style1'"
    (click)="onButtonClick($event)"
    ui-button-orange
    class="story__action"
    type="button"
  >
    {{ story.buttonText }}
  </button>
  <button
    *ngSwitchDefault
    (click)="onButtonClick($event)"
    ui-button-secondary-long
    class="story__action"
    type="button"
  >
    {{ story.buttonText }}
  </button>
</div>
