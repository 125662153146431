import {
  getImageByPixelRatio,
  ImageSizesBasedOnRatio,
} from '@helpers/dom/get-image-by-pixel-ratio';
import { emptyFile } from '@core/models/file.interface';

export const filePreviewSizes: ImageSizesBasedOnRatio = {
  1: '40x40',
  1.5: '80x80',
  2: '80x80',
  3: '120x120',
  default: '160x160',
};

function makeFakeSizes(sizes: ImageSizesBasedOnRatio) {
  return Object.values(sizes).reduce(
    (obj, size) => ({ ...obj, [size]: size }),
    {},
  );
}

/**
 * Размер картинок, используемый в FilePreviewComponent
 */
export const filePreviewImageSize = getImageByPixelRatio(
  emptyFile({
    sizes: makeFakeSizes(filePreviewSizes),
  }),
  filePreviewSizes,
);

export const noteImageSizes = ['82x82', filePreviewImageSize, 'origin'];

export const targetFormImageSizes: ImageSizesBasedOnRatio = {
  1: '330x193',
  1.5: '330x193',
  2: '660x386',
  3: '1320x1544',
  default: 'origin',
};

const targetFormImageSize = getImageByPixelRatio(
  emptyFile({
    sizes: makeFakeSizes(targetFormImageSizes),
  }),
  targetFormImageSizes,
);

export const goalImageSizes = ['82x82', targetFormImageSize, 'origin'];

export const avatarImageSizes: ImageSizesBasedOnRatio = {
  1: '82x82',
  1.5: '82x82',
  2: '164x164',
  3: '246x246',
  default: '246x246',
};
