import {
  ModuleWithProviders,
  NgModule,
  Optional,
  SkipSelf,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { OfflineNotificationComponent } from './offline-notification.component';
import { UiIconModule } from '@ui/ui-icon/ui-icon.module';
import { OfflineNotificationService } from './offline-notification.service';
import { OverlayModule } from '@angular/cdk/overlay';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [OfflineNotificationComponent],
  imports: [CommonModule, UiIconModule, OverlayModule, TranslateModule],
})
export class OfflineNotificationModule {
  static forRoot(): ModuleWithProviders<OfflineNotificationModule> {
    return {
      ngModule: OfflineNotificationModule,
      providers: [OfflineNotificationService],
    };
  }

  constructor(@Optional() @SkipSelf() parentModule: OfflineNotificationModule) {
    if (parentModule) {
      throw new Error(
        'OfflineNotificationModule is already loaded. Import it in the AppModule only',
      );
    }
  }
}
