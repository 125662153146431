import { getSearchQuerys } from '@core/utils/querybuilder';

export interface CategoryCountNoteInterface {
  count: number;
}

/**
 * Модель категорий
 */
export interface CategoryInterface {
  id: string;
  name: string;
  noteCount: number;
  icon: string;
  readOnly: boolean;
}

export function emptyCategory(
  category: Partial<CategoryInterface> = {},
): CategoryInterface {
  return {
    id: '',
    name: '',
    noteCount: 0,
    icon: '',
    readOnly: false,
    ...category,
  };
}

export class CategoryDeleteModel {
  id: string;
  moveId: string;

  getQuery(): string {
    return getSearchQuerys(this).join('&');
  }
}

/**
 * Query Builder для получения заметок
 */
export class CategoryQueryModel {
  expand: string[] = [];

  getQuery(): string {
    return getSearchQuerys(this).join('&');
  }
}
