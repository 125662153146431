<div class="icon-selector__header-wrapper-shadow">
  <div class="icon-selector__header icon-selector__header_shadow">
    <!-- Это для шапки https://prnt.sc/qp99mq -->
    <div
      *ngFor="let icon of headerIcons"
      (click)="onScrollTo(icon)"
      [class.icon-selector__wrap-header-icon_active]="
        activeGroupFirstIcon === icon
      "
      class="icon-selector__wrap-header-icon"
    >
      <ui-icon class="ui-icon" [name]="icon" width="32" height="32"></ui-icon>
      <ui-icon
        class="ui-icon"
        [name]="icon + '_grey'"
        width="32"
        height="32"
      ></ui-icon>
    </div>
  </div>
</div>

<div class="icon-selector__scroll">
  <div class="icon-selector__inner">
    <!-- Контейнер со скролом внутри https://prnt.sc/qp99sr -->
    <div
      *ngFor="let group of groupIcons; first as first"
      #iconGroups
      class="icon-selector__wrap-box-group"
    >
      <div
        [attr.data-group]="group.icons[0]"
        [class.icon-selector__title-group_first]="first"
        class="icon-selector__title-group"
      >
        {{ group.name | translate }}
      </div>

      <div class="icon-selector__wrap-icon">
        <div
          *ngFor="let icon of group.icons"
          [class.icon-selector__wrap-icon-body_active]="value === icon"
          class="icon-selector__wrap-icon-body"
        >
          <ui-icon
            (click)="onIconClick(icon)"
            [name]="icon"
            width="32"
            height="32"
            class="ui-icon"
          ></ui-icon>
        </div>
      </div>
    </div>
  </div>
</div>
