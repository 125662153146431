import { createAction, props } from '@ngrx/store';
import {
  NoteChangeCategoryInterface,
  NoteInterface,
  NoteQueryModel,
} from '@core/models/note.interface';

export const noteCreateFromBatchPopupAction = createAction(
  '[Note] Create From Batch Popup',
  props<{ note: NoteInterface }>(),
);
export const noteCreateFromBatchPopupSuccessAction = createAction(
  '[Note] Create From Batch Popup Success',
  props<{ note: NoteInterface }>(),
);
export const noteCreateFromBatchPopupFailureAction = createAction(
  '[Note] Create From Batch Popup Failure',
  props<{ error: any }>(),
);
export const noteClearListFromBatchPopupAction = createAction(
  '[Note] Clear List From Batch Popup',
);
export const noteCreateAction = createAction(
  '[Note] Create',
  props<{ note: NoteInterface }>(),
);
export const noteCreateSuccessAction = createAction(
  '[Note] Create Success',
  props<{ note: NoteInterface }>(),
);
export const noteCreateFailureAction = createAction(
  '[Note] Create Failure',
  props<{ error: any }>(),
);
export const noteOneLoadAction = createAction(
  '[Note] One Load',
  props<{ id: string }>(),
);
export const noteOneLoadSuccessAction = createAction(
  '[Note] One Load Success',
  props<{ note: NoteInterface }>(),
);
export const noteOneLoadFailureAction = createAction(
  '[Note] One Load Failure',
  props<{ error: any }>(),
);
export const noteHardReloadAction = createAction(
  '[Note] Hard Reload',
  props<{ query: NoteQueryModel }>(),
);
export const noteHardReloadSuccessAction = createAction(
  '[Note]  Hard Reload Success',
  props<{ notes: NoteInterface[] }>(),
);
export const noteHardReloadFailureAction = createAction(
  '[Note]  Hard Reload Failure',
  props<{ error: any }>(),
);
export const noteLoadAction = createAction(
  '[Note] Load',
  props<{ query: NoteQueryModel }>(),
);
export const noteLoadSuccessAction = createAction(
  '[Note] Load Success',
  props<{ notes: NoteInterface[] }>(),
);
export const noteLoadFailureAction = createAction(
  '[Note] Load Failure',
  props<{ error: any }>(),
);
export const noteDeleteAction = createAction(
  '[Note] Delete',
  props<{ id: string }>(),
);
export const noteDeleteSuccessAction = createAction(
  '[Note] Delete Success',
  props<{ id: string }>(),
);
export const noteDeleteFailureAction = createAction(
  '[Note] Delete Failure',
  props<{ error: any }>(),
);
export const noteChangeCategoryAction = createAction(
  '[Note] Change Category',
  props<NoteChangeCategoryInterface>(),
);
export const noteChangeSortAction = createAction(
  '[Note] Change Sort',
  props<{ ids: string[]; note: NoteInterface }>(),
);
export const noteLoadForReportTagAction = createAction(
  '[Report Tag Page] Note Load',
  props<{ tagIds: string[] }>(),
);
export const noteLoadForReportTagSuccessAction = createAction(
  '[Report Tag Page] Note Load Success',
  props<{ notes: NoteInterface[] }>(),
);
export const noteLoadForReportTagFailuteAction = createAction(
  '[Report Tag Page] Note Load Failure',
  props<{ error: any }>(),
);
export const clearNotesForReportTagAction = createAction(
  '[Report Tag Page] Clear Notes',
);
export const noteLoadForReportTargetAction = createAction(
  '[Report Target Page] Note Load',
  props<{ targetId: string }>(),
);
export const noteLoadForReportTargetSuccessAction = createAction(
  '[Report Target Page] Note Load Success',
  props<{ notes: NoteInterface[] }>(),
);
export const noteLoadForReportTargetFailuteAction = createAction(
  '[Report Target Page] Note Load Failure',
  props<{ error: any }>(),
);
export const clearNotesForReportTargetAction = createAction(
  '[Report Target Page] Clear Notes',
);
export const noteLoadForReportAction = createAction(
  '[Report Page] Note Load',
  props<{ categoryId: string }>(),
);
export const noteLoadForReportSuccessAction = createAction(
  '[Report Page] Note Load Success',
  props<{ notes: NoteInterface[] }>(),
);
export const noteLoadForReportFailuteAction = createAction(
  '[Report Page] Note Load Failure',
  props<{ error: any }>(),
);
export const clearNotesForReportAction = createAction(
  '[Report Page] Clear Notes',
);
/**
 *
 * @param payload заметка
 *
 * @param viewMode было ли изменение из режима просмотра заметки
 * Используется, когда в режиме просмотра заметки была поставлена
 * галочка в подзадаче. Этот флаг позволяет не закрывать просмотр.
 */
export const noteUpdateAction = createAction(
  '[Note] Update',
  props<{ note: NoteInterface; viewMode: boolean }>(),
);
export const noteUpdateSuccessAction = createAction(
  '[Note] Update Success',
  props<{ note: NoteInterface; viewMode: boolean }>(),
);
export const noteUpdateFailureAction = createAction(
  '[Note] Update Failure',
  props<{ error: any }>(),
);
