import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { select, Store } from '@ngrx/store';
import { filter, map, take } from 'rxjs/operators';
import * as fromRoot from '@core/redux/index';
import { Observable } from 'rxjs';
import { AuthService } from '@core/redux/auth/auth.service';

@Injectable({
  providedIn: 'root',
})
export class SetEmailService implements CanActivate {
  constructor(
    private readonly authService: AuthService,
    private readonly router: Router,
    private readonly store: Store<fromRoot.State>,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> {
    const user$ = this.store.pipe(select(fromRoot.getUser));

    return user$.pipe(
      filter((user) => {
        if (user) {
          return true;
        }
        this.authService.refreshUser();
        return false;
      }),
      map((user) => {
        if (user && user.isRequestInputEmail) {
          this.router.navigate(['/auth/step-email']);
          return false;
        }
        return true;
      }),
      take(1),
    );
  }
}
