import {
  Directive,
  Input,
  TemplateRef,
  HostListener,
  ElementRef,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { TooltipService, TOOLTIP_TOP_POSITION } from './tooltip.service';
import { TooltipRef } from './tooltip-ref';
import { ConnectedPosition } from '@angular/cdk/overlay';
import { BehaviorSubject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Platform } from '@angular/cdk/platform';
import { coerceBooleanProperty } from '@angular/cdk/coercion';

@Directive({
  selector: '[appTooltip]',
})
export class TooltipDirective implements OnDestroy, OnInit {
  @Input()
  tooltipContent: TemplateRef<any>;

  @Input()
  tooltipPosition: ConnectedPosition = TOOLTIP_TOP_POSITION;

  @Input()
  set tooltipEnabled(v: boolean) {
    this.enabled = coerceBooleanProperty(v);
    if (!this.enabled) {
      this.ngOnDestroy();
    } else {
      this.ngOnInit();
    }
  }

  get tooltipEnabled(): boolean {
    return this.enabled;
  }

  /**
   * Ручной режим управления тултипом
   *
   * При ручном режиме тултип не реагирует на события мыши.
   * Нужно использовать методы `open` и `close`.
   */
  @Input()
  tooltipManualControl = false;

  private tooltipRef: TooltipRef<any>;
  private enabled = true;
  private mouseSub?: Subscription;

  private opened = false;

  private readonly cursorInside$ = new BehaviorSubject(false);

  constructor(
    private readonly elementRef: ElementRef,
    private readonly platform: Platform,
    private readonly tooltipService: TooltipService,
  ) {}

  ngOnDestroy() {
    this.close();
    this.mouseSub?.unsubscribe();
    this.mouseSub = undefined;
  }

  ngOnInit() {
    this.subscribeToCursorPosition();
  }

  /**
   * @hidden
   */
  @HostListener('mouseover')
  onMouseover() {
    if (
      this.tooltipManualControl ||
      !this.platformSupportsMouseEvents() ||
      !this.enabled
    ) {
      return;
    }
    this.cursorInside$.next(true);
  }

  /**
   * @hidden
   */
  @HostListener('mouseout')
  onMouseout() {
    if (
      this.tooltipManualControl ||
      !this.platformSupportsMouseEvents() ||
      !this.enabled
    ) {
      return;
    }
    this.cursorInside$.next(false);
  }

  open() {
    if (this.opened) {
      return;
    }
    this.tooltipRef = this.tooltipService.open(this.tooltipContent, {
      relativeTo: this.elementRef.nativeElement,
      position: this.tooltipPosition,
    });
    this.opened = true;
  }

  close() {
    if (!this.opened) {
      return;
    }
    this.tooltipRef.close();
    this.opened = false;
  }

  private subscribeToCursorPosition() {
    if (!this.enabled) {
      return;
    }
    this.mouseSub = this.cursorInside$
      .pipe(debounceTime(50), distinctUntilChanged())
      .subscribe((inside) => {
        if (inside) {
          this.open();
        } else {
          this.close();
        }
      });
  }

  private platformSupportsMouseEvents(): boolean {
    return !this.platform.IOS && !this.platform.ANDROID;
  }
}
