import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-qiwi',
  templateUrl: './qiwi.component.html',
  styleUrls: ['./qiwi.component.less'],
})
export class QiwiComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
