/**
 * Высота, учитывающая margin
 */
export function getAbsoluteHeight(el: string | HTMLElement) {
  el =
    typeof el === 'string' ? (document.querySelector(el) as HTMLElement) : el;

  const styles = window.getComputedStyle(el);
  const margin = parseFloat(styles.marginTop) + parseFloat(styles.marginBottom);

  return Math.ceil(el.getBoundingClientRect().height + margin);
}
