import { NgModule } from '@angular/core';
import { CategoryFormComponent } from './category-form.component';
import { UiPopupCategoryFormModule } from './category-form-body/category-form-body.module';
import { SelectFormHeaderModule } from '@ui/select-form-header/select-form-header.module';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [CategoryFormComponent],
  imports: [
    CommonModule,
    UiPopupCategoryFormModule,
    SelectFormHeaderModule,
    TranslateModule,
  ],
  entryComponents: [CategoryFormComponent],
})
export class CategoryFormModule {}
