import {
  ActionReducerMap,
  createFeatureSelector,
  createSelector,
  MetaReducer,
} from '@ngrx/store';
import * as fromAuth from '@core/redux/auth/auth.reducer';
import * as fromNote from '@core/redux/note/note.reducer';
import * as fromRelease from '@core/redux/release/release.reducer';
import * as fromCategory from '@core/redux/category/category.reducer';
import * as fromInvoice from '@core/redux/invoice/invoice.reducer';
import * as fromBillingInformation from '@core/redux//billing-information/billing-information.reducer';
import * as fromTarget from '@core/redux/goal/target.reducer';
import * as fromTag from '@core/redux/tag/tag.reducer';
import * as fromTargetType from '@core/redux/target-type/target-type.reducer';
import * as fromTariff from '@core/redux/tariff/tariff.reducer';
import * as fromTariffGroup from '@core/redux/tariff-group/tariff-group.reducer';
import * as fromTargetImage from '@core/redux/target-image/target-image.reducer';
import * as fromStory from '@core/redux/story/story.reducer';
import { signOutSuccessAction } from './auth/auth.actions';
import {
  goalStepsReducer,
  GoalStepsState,
} from './goal-step/goal-steps.reducer';
import { goalStepsFeatureKey } from './goal-step/goal-steps.key';
import {
  noteFeatureKey,
  noteReducer,
  NoteState,
} from '@core/redux/note/note.reducer';

export interface State {
  auth: fromAuth.State;
  [noteFeatureKey]: NoteState;
  release: fromRelease.State;
  category: fromCategory.State;
  invoice: fromCategory.State;
  [goalStepsFeatureKey]: GoalStepsState;
  billingInformation: fromBillingInformation.State;
  target: fromTarget.State;
  tag: fromTag.State;
  targetType: fromTargetType.State;
  tariff: fromTariff.State;
  tariffGroup: fromTariffGroup.State;
  targetImage: fromTargetImage.State;
  story: fromStory.State;
}

export const reducers: ActionReducerMap<State> = {
  // core reducers
  auth: fromAuth.reducer,
  [noteFeatureKey]: noteReducer,
  release: fromRelease.reducer,
  category: fromCategory.reducer,
  invoice: fromInvoice.reducer,
  billingInformation: fromBillingInformation.reducer,
  [goalStepsFeatureKey]: goalStepsReducer,
  target: fromTarget.reducer,
  tag: fromTag.reducer,
  targetType: fromTargetType.reducer,
  tariff: fromTariff.reducer,
  tariffGroup: fromTariffGroup.reducer,
  targetImage: fromTargetImage.reducer,
  story: fromStory.reducer,
};

export function clearState(reducer) {
  return (state, action) => {
    if (action.type === signOutSuccessAction.type) {
      state = undefined;
    }

    return reducer(state, action);
  };
}

export const metaReducers: MetaReducer<State>[] = [clearState];

/**
 * Auth Reducers
 */
export const getAuthState = createFeatureSelector<fromAuth.State>('auth');
export const getIsAuth = createSelector(getAuthState, fromAuth.getIsAuth);
export const getToken = createSelector(getAuthState, fromAuth.getToken);
export const getLanguage = createSelector(getAuthState, fromAuth.getLanguage);
export const getErrors = createSelector(getAuthState, fromAuth.getErrors);
export const getLoading = createSelector(getAuthState, fromAuth.getLoading);
export const getIsActive = createSelector(getAuthState, fromAuth.getIsActive);
export const getUser = createSelector(getAuthState, fromAuth.getUser);
export const getPasswordResetState = createSelector(
  getAuthState,
  fromAuth.getPasswordResetState,
);

/**
 * Note Reducers
 */
export const selectNoteState = createFeatureSelector<NoteState>(noteFeatureKey);
// export const getNotesAll = createSelector(selectNoteState, fromNote.notesAll);
export const getNotesAll = (categoryId?: string, isTask?: boolean) =>
  createSelector(selectNoteState, fromNote.notesAll(categoryId, isTask));

export const getNoteLoading = createSelector(
  selectNoteState,
  fromNote.selectIsLoading,
);
export const getNotesForReportTag = createSelector(
  selectNoteState,
  fromNote.selectForReportTag,
);
export const getReportTagLoading = createSelector(
  selectNoteState,
  fromNote.selectIsLoadingReportTag,
);
export const getNotesForReportTarget = createSelector(
  selectNoteState,
  fromNote.selectForReportTarget,
);
export const getReportTargetLoading = createSelector(
  selectNoteState,
  fromNote.selectIsLoadingReportTarget,
);
export const getNotesForReport = createSelector(
  selectNoteState,
  fromNote.selectForReport,
);
export const getReportLoading = createSelector(
  selectNoteState,
  fromNote.selectIsLoadingReport,
);
export const getNotesCurrentCreating = createSelector(
  selectNoteState,
  fromNote.selectCurrentCreating,
);
export const getNoteById = (id: string) =>
  createSelector(selectNoteState, fromNote.selectById(id));
export const getNoteEntitiesByDay = (
  year: number,
  month: number,
  day: number,
  isTask?: boolean,
  categoryId?: string,
) =>
  createSelector(
    selectNoteState,
    fromNote.selectByDay(year, month, day, isTask, categoryId),
  );
export const getPastNoteByStatus = (
  status: boolean,
  categoryId: string,
  isTask?: boolean,
) =>
  createSelector(
    selectNoteState,
    fromNote.selectPastByStatus(status, categoryId, isTask),
  );

/**
 * Category Reducers
 */
export const getCategoryState = createFeatureSelector<fromCategory.State>(
  'category',
);
export const getCategoryList = createSelector(
  getCategoryState,
  fromCategory.getEntities,
);
export const getCategorySelected = createSelector(
  getCategoryState,
  fromCategory.getSelectedEntity,
);
export const getCategorySelectedId = createSelector(
  getCategoryState,
  fromCategory.getSelectedId,
);
export const getCategoryIsLoading = createSelector(
  getCategoryState,
  fromCategory.getIsLoading,
);

/**
 * Invoices Reducers
 */
export const getInvoiceState = createFeatureSelector<fromInvoice.State>(
  'invoice',
);
export const getInvoiceList = createSelector(
  getInvoiceState,
  fromInvoice.getAll,
);

/**
 * Billing Information Reducers
 */
export const getBillingInformationState = createFeatureSelector<
  fromBillingInformation.State
>('billingInformation');
export const getBillingInformationList = createSelector(
  getBillingInformationState,
  fromBillingInformation.getAll,
);

/**
 * Tag Reducers
 */
export const getTagState = createFeatureSelector<fromTag.State>('tag');
export const getTagLoading = createSelector(getTagState, fromTag.getLoading);
export const getTagList = createSelector(getTagState, fromTag.getAll);
export const getTagErrors = createSelector(getTagState, fromTag.getErrors);

/**
 * Target Reducers
 */
export const getTargetState = createFeatureSelector<fromTarget.State>('target');
export const getTargetLoading = createSelector(
  getTargetState,
  fromTarget.getLoading,
);
export const getTargetListNotComplete = createSelector(
  getTargetState,
  fromTarget.getAllActiveNotComplete,
);
export const getTargetList = createSelector(
  getTargetState,
  fromTarget.getAllActive,
);
export const getTargetListAll = createSelector(
  getTargetState,
  fromTarget.getAll,
);
export const getTargetListForMain = createSelector(
  getTargetState,
  fromTarget.getAllActiveForMain,
);
export const getTargetErrors = createSelector(
  getTargetState,
  fromTarget.getErrors,
);
export const getTargetSelect = createSelector(
  getTargetState,
  fromTarget.getSelect,
);
export const getTargetCount = createSelector(
  getTargetState,
  fromTarget.getCount,
);
export const getTargetById = createSelector(
  getTargetState,
  (state: fromTarget.State, props: { goalId: string }) => {
    return state.entities[props.goalId];
  },
);
export const getTargetRequestState = createSelector(
  getTargetState,
  fromTarget.getRequestState,
);
/**
 * Target Type Reducers
 */
export const getTargetTypeState = createFeatureSelector<fromTargetType.State>(
  'targetType',
);
export const getTargetTypeListAll = createSelector(
  getTargetTypeState,
  fromTargetType.getAll,
);
export const getTargetTypeSelected = createSelector(
  getTargetTypeState,
  fromTargetType.getSelectedEntity,
);
export const getTargetTypeLoading = createSelector(
  getTargetTypeState,
  fromTargetType.getLoading,
);
/**
 * Tariff Reducers
 */
export const getTariffState = createFeatureSelector<fromTariff.State>('tariff');
export const getTariffListAll = createSelector(
  getTariffState,
  fromTariff.getAll,
);
export const getMinPriceTariff = createSelector(
  getTariffState,
  fromTariff.minPriceTariff,
);
export const getBaseTariff = createSelector(getTariffState, (state) =>
  Object.values(state.entities).find((t) => t.months === 1),
);
/**
 * Tariff Group Reducers
 */
export const getTariffGroupState = createFeatureSelector<fromTariffGroup.State>(
  'tariffGroup',
);
export const getTariffGroupDefault = createSelector(
  getTariffGroupState,
  fromTariffGroup.getTariffGroup,
);

/**
 * Target Image Reducers
 */
export const getTargetImageState = createFeatureSelector<fromTargetImage.State>(
  fromTargetImage.targetImageFeatureKey,
);
export const getTargetImageAll = createSelector(
  getTargetImageState,
  fromTargetImage.getAll,
);

export const getReleaseState = createFeatureSelector<fromRelease.State>(
  fromRelease.releaseFeatureKey,
);
export const getShouldShowUpdateAlert = createSelector(
  getReleaseState,
  fromRelease.getShouldShowUpdateAlert,
);
