import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  OnDestroy,
  ChangeDetectorRef,
  AfterViewInit,
  Inject,
  ViewChild,
} from '@angular/core';
import { Story } from '@core/redux/story/story.model';
import { interval, Subscription, timer } from 'rxjs';
import { StoryService } from '@core/redux/story/story.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  removeMatDialogContainerPadding,
  removeMatDialogOverflow,
} from '@shared/functions/mat-dialog-container';
import { take, withLatestFrom } from 'rxjs/operators';
import { trigger, transition, useAnimation } from '@angular/animations';
import { fadeIn, fadeOut } from 'ng-animate';
import { StoryComponent } from '../story';

export const timeForStory = 20000;
const ms = 'ms';
const timeNgStyleDefault = {
  transform: 'translateX(0)',
  'transition-duration': timeForStory + ms,
};

export interface StoriesDialogData {
  stories?: Story[];
}

@Component({
  selector: 'stories-dialog',
  templateUrl: './stories-dialog.component.html',
  styleUrls: ['./stories-dialog.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('storyChange', [
      transition(':enter', [useAnimation(fadeIn, { params: { timing: 0.5 } })]),
      transition(':leave', [
        useAnimation(fadeOut, { params: { timing: 0.5 } }),
      ]),
    ]),
  ],
})
export class StoriesDialogComponent
  implements AfterViewInit, OnDestroy, OnInit {
  @ViewChild(StoryComponent)
  storyComponent: StoryComponent;

  stories: Story[];

  currentStory: Story;
  currentStoryIndex = -1;
  timeNgStyle = {};

  private intervalSub: Subscription;
  private intervalTimestap: number;
  private timeGone: number;
  private volumeSub: Subscription;

  private readonly subscription = new Subscription();

  constructor(
    @Inject(MAT_DIALOG_DATA)
    private readonly data: StoriesDialogData,
    private readonly changeDetector: ChangeDetectorRef,
    private readonly dialogRef: MatDialogRef<StoriesDialogComponent>,
    private readonly storyService: StoryService,
  ) {}

  ngAfterViewInit() {
    removeMatDialogContainerPadding(this.dialogRef);
    removeMatDialogOverflow(this.dialogRef);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.volumeSub?.unsubscribe();
    this.intervalSub?.unsubscribe();
  }

  ngOnInit() {
    if (this.data.stories) {
      this.stories = this.data.stories;
      this.onNext(1);
    } else {
      this.subscribeToStories();
    }
  }

  onClose() {
    this.dialogRef.close();
  }

  onTap(event: { center: { x: number } }) {
    const leftSide = event.center.x * 2 < window.innerWidth;
    if (leftSide) {
      this.onNext(-1);
    } else {
      this.onNext();
    }
  }

  onNext(addend: number = 1, startWithNew: boolean = false) {
    this.fadeVolume();
    const nextIndex = startWithNew
      ? this.stories.findIndex((s) => s.isWatched === false)
      : this.currentStoryIndex + addend;
    const ended = nextIndex >= this.stories.length;
    const tooLow = nextIndex < 0;
    if (ended || tooLow) {
      this.onClose();
      return;
    }
    this.currentStory = this.stories[nextIndex];
    this.currentStoryIndex = nextIndex;
    this.timeNgStyle = {};
    this.changeDetector.markForCheck();
    this.timeGone = 0;
    setTimeout(() => {
      this.timeNgStyle = timeNgStyleDefault;
      this.changeDetector.markForCheck();
    }, 1);
    this.startInterval();
    if (this.currentStory && !this.currentStory.isWatched) {
      this.storyService.markAsViewed(this.currentStory.id);
    }
  }

  onResume() {
    const timeLeft = timeForStory - this.timeGone;
    this.timeNgStyle = {
      transform: 'translateX(0)',
      'transition-duration': timeLeft + ms,
    };
    this.startInterval(timeLeft);
  }

  onStop() {
    const stopTimestap = +new Date();
    this.timeGone += stopTimestap - this.intervalTimestap;
    const percent = Math.round((this.timeGone / timeForStory) * 100);
    const x = percent - 100;
    this.timeNgStyle = { transform: `translateX(${x}%)` };
    if (this.intervalSub) {
      this.intervalSub.unsubscribe();
    }
  }

  private fadeVolume() {
    this.volumeSub?.unsubscribe();
    const video = this.storyComponent?.videoElRef
      ?.nativeElement as HTMLVideoElement;
    if (!video) {
      return;
    }
    this.volumeSub = interval(40)
      .pipe(take(10))
      .subscribe(() => {
        // Fix IndexSizeError
        // Failed to set the 'volume' property on 'HTMLMediaElement':
        // The volume provided (-0.1) is outside the range [0, 1].
        const nextVolume = video.volume - 0.1;
        if (nextVolume >= 0) {
          video.volume = nextVolume;
        }
      });
  }

  private startInterval(time = timeForStory) {
    if (this.intervalSub) {
      this.intervalSub.unsubscribe();
    }
    this.intervalTimestap = +new Date();
    this.intervalSub = timer(time).subscribe(() => {
      this.onNext();
    });
  }

  private subscribeToStories() {
    this.subscription.add(
      this.storyService
        .stories()
        .pipe(withLatestFrom(this.storyService.hasStoriesToView()))
        .subscribe(([stories, has]) => {
          if (!this.currentStory) {
            this.stories = stories;
            this.onNext(1, has);
          }
        }),
    );
  }
}
