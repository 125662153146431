import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UiIconComponent } from '@ui/ui-icon/ui-icon.component';
import { DragIconComponent } from './drag-icon/drag-icon.component';

@NgModule({
  declarations: [UiIconComponent, DragIconComponent],
  exports: [UiIconComponent, DragIconComponent],
  imports: [CommonModule],
})
export class UiIconModule {}
