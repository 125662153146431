import { Injectable } from '@angular/core';
import {
  DARK_THEME,
  LIGHT_THEME,
  THEME,
  Theme,
} from '@core/services/theme/theme.model';

@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  setTheme(theme: Theme): void {
    localStorage.setItem(THEME, theme.title);
    Object.keys(theme.properties).map((property) => {
      document.documentElement.style.setProperty(
        property,
        theme.properties[property],
      );
    });
  }

  getTheme(): string {
    return localStorage.getItem(THEME) || LIGHT_THEME.title;
  }

  isDarkTheme(): boolean {
    return this.getTheme() === DARK_THEME.title;
  }

  setUserTheme(): void {
    const currentThemeName: string = this.getTheme();
    if (currentThemeName) {
      currentThemeName === LIGHT_THEME.title
        ? this.setTheme(LIGHT_THEME)
        : this.setTheme(DARK_THEME);
    }
  }
}
