import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UiOptionComponent } from './ui-option.component';
import { UiIconModule } from '@ui/ui-icon/ui-icon.module';

@NgModule({
  declarations: [UiOptionComponent],
  exports: [UiOptionComponent],
  imports: [CommonModule, UiIconModule],
})
export class UiOptionModule {}
