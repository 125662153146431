import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs/internal/observable/of';
import { catchError, map, switchMap } from 'rxjs/operators';
import { TargetImageService } from '@core/services/target-image.service';
import {
  targetImageLoad,
  targetImageLoadFailure,
  targetImageLoadSuccess,
} from '@core/redux/target-image/target-image.actions';

/**
 * Effect для работы для получения картинок целей
 */
@Injectable()
export class TargetImageEffects {
  search$ = createEffect(() =>
    this.actions$.pipe(
      ofType(targetImageLoad),
      switchMap(() => {
        return this.targetImageService.search().pipe(
          map(images => targetImageLoadSuccess({ images })),
          catchError(errors => of(targetImageLoadFailure())),
        );
      }),
    ),
  );

  constructor(
    private actions$: Actions,
    private targetImageService: TargetImageService,
  ) {}
}
