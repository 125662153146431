import { BrowserModule, HammerModule } from '@angular/platform-browser';
import {
  APP_INITIALIZER,
  ErrorHandler,
  Injector,
  LOCALE_ID,
  NgModule,
} from '@angular/core';
import { RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import {
  CommonModule,
  LOCATION_INITIALIZED,
  registerLocaleData,
} from '@angular/common';
import localeRu from '@angular/common/locales/ru';
import localeEn from '@angular/common/locales/en';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  TranslateLoader,
  TranslateModule,
  TranslateService,
} from '@ngx-translate/core';
import { CoreModule } from '@core/core.module';
import { AuthGuardService } from '@core/services/auth-guard.service';
import { SetEmailService } from '@core/guards/set-email.service';
import {
  ServiceWorkerModule,
  SwRegistrationOptions,
} from '@core/service-worker/service-worker.module';
import { environment } from '../environments/environment';
import { SocketIoModule } from 'ngx-socket-io';
import { CookieModule, CookieService } from 'ngx-cookie';
import { languageCookieKey } from 'src/app/helpers/consts/cookie';
import {
  defaultLang,
  getNavigatorLanguage,
  normalizeLanguage,
} from '@helpers/consts/language';
import { UiReleaseAlertModule } from '@ui/ui-release-alert/ui-release-alert.module';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { GlobalErrorHandler } from '@core/error-handler';
import { InfoPopupsModule } from '@info-popups';
import { TooltipsModule } from '@tooltips';
import {
  MAT_DIALOG_DEFAULT_OPTIONS,
  MatDialogConfig,
} from '@angular/material/dialog';
import { ReleaseService } from '@core/redux/release/release.service';
import { metaReducers, reducers } from '@core/redux';
import { NoteEffects } from '@core/redux/note/note.effects';
import { CategoryEffects } from '@core/redux/category/category.effects';
import { AuthEffects } from '@core/redux/auth/auth.effects';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { BillingInformationEffects } from '@core/redux/billing-information/billing-information.effects';
import { TargetTypeEffects } from '@core/redux/target-type/target-type.effects';
import { InvoiceEffects } from '@core/redux/invoice/invoice.effects';
import { TargetEffects } from '@core/redux/goal/target.effects';
import { NotificationEffects } from '@core/redux/notification/notification.effects';
import { OnboardingEffects } from '@core/redux/onboarding/onboarding.effects';
import { StoryEffects } from '@core/redux/story/story.effects';
import { TagEffects } from '@core/redux/tag/tag.effects';
import { TargetImageEffects } from '@core/redux/target-image/target-image.effects';
import { TariffGroupEffects } from '@core/redux/tariff-group/tariff-group.effects';
import { TariffEffects } from '@core/redux/tariff/tariff.effects';
import { DialogsModule } from '@dialogs';
import { OnboardingGuard } from '@core/services/onboarding.guard';
import {
  CLEAVE_NUMBER_CONFIG,
  defaultCleaveNumberConfig,
} from '@shared/tokens/cleave-number';
import { IndexComponent } from './index.component';
import { OfflineNotificationModule } from '@ui/offline-notification/offline-notification.module';
import { GoalStepsEffects } from '@core/redux/goal-step/goal-steps.effects';
import { OwlNativeDateTimeModule } from '@danielmoncada/angular-datetime-picker';
import { ButtonsModule } from '@ui/buttons/buttons.module';
import { NgxMetrikaModule } from '@kolkov/ngx-metrika';

registerLocaleData(localeRu);
registerLocaleData(localeEn);

export function appInitializerFactory(
  translateService: TranslateService,
  cookieService: CookieService,
  injector: Injector,
): () => Promise<any> {
  return () =>
    new Promise<any>((resolve: any) => {
      const locationInitialized = injector.get(
        LOCATION_INITIALIZED,
        Promise.resolve(null),
      );
      locationInitialized.then(() => {
        translateService.addLangs(['en', 'ru']);
        const cookieLang = cookieService.get(languageCookieKey);
        const lang = cookieLang || getNavigatorLanguage();
        const normalizedLang = normalizeLanguage(lang);
        translateService.setDefaultLang(defaultLang);
        translateService.use(normalizedLang);
        translateService.getTranslation(normalizedLang).subscribe(
          () => resolve(null),
          () => resolve(null),
        );
      });
    });
}

const routes = [
  { path: '', redirectTo: '/calendar', pathMatch: 'full' },
  { path: 'index.html', component: IndexComponent },
  {
    path: 'affiliate',
    loadChildren: () =>
      import('./affiliate/affiliate.module').then((m) => m.AffiliateModule),
    canActivate: [AuthGuardService, SetEmailService],
  },
  {
    path: 'share',
    loadChildren: () =>
      import('./share/share.module').then((m) => m.ShareModule),
  },
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: 'onboarding',
    loadChildren: () =>
      import('./onboarding/onboarding.module').then((m) => m.OnboardingModule),
    canActivate: [AuthGuardService],
  },
  {
    path: 'goal-form',
    loadChildren: () =>
      import('./goal-form/goal-form.module').then((m) => m.GoalFormModule),
    canActivate: [AuthGuardService],
  },
  {
    path: 'pay',
    loadChildren: () =>
      import('./successful-payment/successful-payment.module').then(
        (m) => m.SuccessfulPaymentModule,
      ),
  },
  {
    path: '',
    loadChildren: () => import('./main/main.module').then((m) => m.MainModule),
    canActivate: [AuthGuardService, SetEmailService, OnboardingGuard],
  },
  {
    path: '**',
    redirectTo: '/calendar',
  },
];

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/');
}

@NgModule({
  declarations: [AppComponent, IndexComponent],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    CoreModule.forRoot(),
    InfoPopupsModule,
    TooltipsModule,
    ScrollToModule.forRoot(),
    RouterModule.forRoot(routes),
    ServiceWorkerModule.register('serviceworker.js'),
    TranslateModule.forRoot(),
    SocketIoModule.forRoot(environment.socket),
    CookieModule.forRoot(),
    UiReleaseAlertModule,
    HammerModule,
    OfflineNotificationModule.forRoot(),
    OwlNativeDateTimeModule,
    StoreModule.forRoot(reducers, { metaReducers }),
    // StoreDevtoolsModule.instrument({
    //   maxAge: 25,
    //   logOnly: environment.production,
    // }),
    EffectsModule.forRoot([
      AuthEffects,
      NoteEffects,
      CategoryEffects,
      InvoiceEffects,
      BillingInformationEffects,
      GoalStepsEffects,
      TagEffects,
      TargetEffects,
      TargetTypeEffects,
      TariffEffects,
      TariffGroupEffects,
      OnboardingEffects,
      NotificationEffects,
      TargetImageEffects,
      StoryEffects,
    ]),
    DialogsModule,
    ButtonsModule,
    // MarkdownModule.forRoot(),
    NgxMetrikaModule.forRoot({
      id: environment.yaCounterId,
      defer: true,
      webvisor: true,
      clickmap: true,
      trackLinks: true,
      accurateTrackBounce: true,
    }),
  ],
  providers: [
    {
      provide: LOCALE_ID,
      useValue: 'ru-RU',
    },
    {
      provide: SwRegistrationOptions,
      useValue: {
        enabled: environment.production,
        registrationStrategy: 'registerWithDelay:500',
      },
    },
    {
      provide: TranslateLoader,
      useFactory: HttpLoaderFactory,
      deps: [HttpClient],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      deps: [TranslateService, CookieService, Injector],
      multi: true,
    },
    {
      provide: ErrorHandler,
      useExisting: GlobalErrorHandler,
      deps: [ReleaseService],
    },
    // диалоги перестали центрироваться
    // это нужно, чтобы они снова были по центру
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: {
        ...new MatDialogConfig(),
        maxWidth: '100vw',
      },
    },
    {
      provide: CLEAVE_NUMBER_CONFIG,
      useValue: defaultCleaveNumberConfig,
    },
  ],
  exports: [RouterModule],
  bootstrap: [AppComponent],
})
export class AppModule {}
