import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NewLevelComponent } from './new-level.component';
import { MatDialogModule } from '@angular/material/dialog';
import { UiIconModule } from '@ui/ui-icon/ui-icon.module';
import { TranslateAchievementModule } from '@shared/pipes/translate-achievement/translate-achievement.module';
import { TranslateModule } from '@ngx-translate/core';
import { AchievementIconModule } from '@shared/pipes/achievement-icon/achievement-icon.module';
import { ButtonsModule } from '@ui/buttons/buttons.module';
import { InfoPopupModule } from '@ui/info-popup';
import { AchievementImageModule } from '@shared/pipes/achievement-image/achievement-image.module';
import { CircleProgressModule } from '@ui/circle-progress/circle-progress.module';

@NgModule({
  declarations: [NewLevelComponent],
  imports: [
    CommonModule,
    MatDialogModule,
    UiIconModule,
    TranslateAchievementModule,
    TranslateModule,
    AchievementIconModule,
    AchievementImageModule,
    ButtonsModule,
    InfoPopupModule,
    CircleProgressModule,
  ],
  entryComponents: [NewLevelComponent],
})
export class NewLevelModule {}
