import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DeleteCategoryDialogComponent } from './delete-category-dialog.component';
import { UiSelectModule } from '@ui/ui-select/ui-select.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { UiIconModule } from '@ui/ui-icon/ui-icon.module';
import { ButtonsModule } from '@ui/buttons/buttons.module';

@NgModule({
  declarations: [DeleteCategoryDialogComponent],
  exports: [DeleteCategoryDialogComponent],
  imports: [
    CommonModule,
    UiSelectModule,
    ReactiveFormsModule,
    FormsModule,
    TranslateModule,
    UiIconModule,
    ButtonsModule,
  ],
  entryComponents: [DeleteCategoryDialogComponent],
})
export class DeleteCategoryDialogModule {}
