import { TooltipArrowPosition } from '@ui/tooltip';

const arrowPositionClassMap: { [key in TooltipArrowPosition]: string } = {
  'top-right': 'rt-tooltip__arrow_top-right',
  bottom: 'rt-tooltip__arrow_bottom',
};

export function getArrowPositionClass(
  arrowPosition: TooltipArrowPosition,
): string {
  return typeof arrowPosition === 'undefined'
    ? 'rt-tooltip__arrow_hidden'
    : arrowPositionClassMap[arrowPosition];
}
