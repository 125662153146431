import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { map, exhaustMap, catchError, switchMap } from 'rxjs/operators';
import { of } from 'rxjs/internal/observable/of';

import {
  NotificationActionTypes,
  SubscribeFailureAction,
  SubscribeSuccessAction,
  SubscribeAction,
} from './notification.actions';
import { environment } from '../../../../environments/environment';
import { NotificationService } from '@core/services/notification.service';

/**
 * Effect для работы с оповещениями
 */
@Injectable()
export class NotificationEffects {
  @Effect()
  subscribe$ = this.actions$.pipe(
    ofType(NotificationActionTypes.SubscribeAction),
    exhaustMap((action: SubscribeAction) =>
      this.notificationService.addPushSubscriber(action.subscriber).pipe(
        map(() => new SubscribeSuccessAction()),
        catchError(() => of(new SubscribeFailureAction())),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private notificationService: NotificationService,
  ) {}
}
