<svg
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <mask
    id="mask-drag"
    mask-type="alpha"
    maskUnits="userSpaceOnUse"
    x="7"
    y="5"
    width="10"
    height="14"
  >
    <circle cx="8" cy="6" r="1" fill="#C4C4C4" />
    <circle cx="8" cy="12" r="1" fill="#C4C4C4" />
    <circle cx="16" cy="12" r="1" fill="#C4C4C4" />
    <circle cx="16" cy="6" r="1" fill="#C4C4C4" />
    <circle cx="8" cy="18" r="1" fill="#C4C4C4" />
    <circle cx="16" cy="18" r="1" fill="#C4C4C4" />
  </mask>
  <g mask="url(#mask-drag)">
    <rect width="24" height="24" fill="#B5B5BE" />
  </g>
</svg>
