import {
  ConfirmationDialogData,
  OnCloseActions,
} from '../confirmation-dialog/confirmation-dialog-config';
import { NoteDialogService } from './note-dialog.service';

export const getConfirmDialogConfigForNote: (
  noteDialogService: NoteDialogService,
) => ConfirmationDialogData = noteDialogService => ({
  title: 'UI.NOTE_FORM.COMPONENTS.CONFIRMATION.TITLE',
  body: 'UI.NOTE_FORM.COMPONENTS.CONFIRMATION.TEXT',
  onClose: OnCloseActions.Accept,
  accept: {
    action: () => undefined,
    text: 'UI.NOTE_FORM.COMPONENTS.CONFIRMATION.DISCARD',
  },
  discard: {
    action: () => noteDialogService.restore(),
    text: 'UI.NOTE_FORM.COMPONENTS.CONFIRMATION.RETURN',
  },
});
