import * as moment from 'moment';
import { NavigationEnd, Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { filter, map, startWith } from 'rxjs/operators';

export enum CalendarPageType {
  Notes = 'notes',
  Tasks = 'tasks',
}

export const defaultCalendarPageType = CalendarPageType.Notes;

export function fromString(s: string): CalendarPageType {
  if (s === CalendarPageType.Tasks) {
    return CalendarPageType.Tasks;
  }
  return CalendarPageType.Notes;
}

/**
 * HTML id дня
 */
export function dayId(date: moment.Moment): string {
  return ['day', date.year(), date.month(), date.date()].join('-');
}

export function getMonthFromUrl(locationPath: string): number {
  const monthMaybe = +locationPath.split('/')[4];
  return monthMaybe || new Date().getMonth() + 1;
}

export function getPageTypeFromUrl(locationPath: string): CalendarPageType {
  const segments = locationPath.split('/');
  return segments[segments.length - 1] as CalendarPageType;
}

export function isTaskPageType(locationPath: string): boolean {
  return locationPath.split('/').pop() === CalendarPageType.Tasks;
}

export function getYearFromUrl(locationPath: string): number {
  const yearMaybe = +locationPath.split('/')[3];
  return yearMaybe || new Date().getFullYear();
}

export function isTasksRoute(router: Router): Observable<boolean> {
  return router.events.pipe(
    filter((event) => event instanceof NavigationEnd),
    map((event: NavigationEnd) => event.url),
    startWith(router.url),
    map((value: string) => {
      return value.split('/').pop() === CalendarPageType.Tasks;
    }),
  );
}
