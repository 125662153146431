import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImageSliderComponent } from './image-slider.component';
import { NgxTinySliderModule } from 'ngx-tiny-slider';
import { ImagePreviewModule } from '@shared/pipes/image-preview/image-preview.module';
import { UiIconModule } from '@ui/ui-icon/ui-icon.module';
import { ButtonsModule } from '@ui/buttons/buttons.module';

@NgModule({
  declarations: [ImageSliderComponent],
  imports: [
    CommonModule,
    NgxTinySliderModule,
    ImagePreviewModule,
    UiIconModule,
    ButtonsModule,
  ],
  entryComponents: [ImageSliderComponent],
})
export class ImageSliderModule {}
