import { HttpResponse } from '@angular/common/http';

export class PaginationResponse {
  public currentPage: number;
  public countPage: number;
  public perPage: number;
  public totalCount: number;

  constructor(response: HttpResponse<any>) {
    this.currentPage = +response.headers.get('x-pagination-current-page');
    this.countPage = +response.headers.get('x-pagination-page-count');
    this.perPage = +response.headers.get('x-pagination-per-page');
    this.totalCount = +response.headers.get('x-pagination-total-count');
  }
}
