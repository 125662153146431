import {
  Component,
  OnInit,
  Input,
  ChangeDetectionStrategy,
} from '@angular/core';
import { NoteInterface, Subtask } from '@core/models/note.interface';
import { NoteService } from '@core/redux/note/note.service';

@Component({
  selector: 'note-view',
  templateUrl: './note-view.component.html',
  styleUrls: ['./note-view.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NoteViewComponent implements OnInit {
  @Input()
  note: NoteInterface;

  constructor(private noteService: NoteService) {}

  ngOnInit() {}

  onChecklistChange(checklist: Subtask[]) {
    this.note.checklist = checklist;
    this.noteService.update(this.note);
  }
}
