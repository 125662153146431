import { Component, Input, OnInit, OnChanges } from '@angular/core';
import { calcProgress, TargetInterface } from '@core/models/target.interface';

@Component({
  selector: 'option-target',
  templateUrl: './option-target.component.html',
  styleUrls: ['./option-target.component.less'],
})
export class OptionTargetComponent implements OnInit, OnChanges {
  @Input() target: TargetInterface;
  @Input() selected: boolean;

  measure: string;
  relativeProgress: number;

  ngOnInit() {}

  ngOnChanges() {
    this.relativeProgress = calcProgress(this.target);
    this.measure = this.target.type ? this.target.type.name : '';
  }
}
