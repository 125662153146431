import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { InfoPopupRef } from '../info-popup-ref';

@Component({
  selector: 'info-popup-content',
  templateUrl: './info-popup-content.component.html',
  styleUrls: ['./info-popup-content.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InfoPopupContentComponent {
  @Input()
  action: string | undefined;

  @Output()
  actionClick = new EventEmitter();

  constructor(private readonly infoPopupRef: InfoPopupRef<any>) {}

  onCloseClick() {
    this.infoPopupRef.close();
  }
}
