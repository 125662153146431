import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { BillingInformationInterface } from '@core/models/billing-information.interface';
import {
  BillingInformationActions,
  BillingInformationActionTypes,
} from '@core/redux/billing-information/billing-information.actions';

export interface State {
  ids: string[];
  selectedId: string;
  loading: boolean;
  entities: any;
}

export const initialState: State = {
  ids: [],
  selectedId: null,
  loading: false,
  entities: {},
};

export const adapter: EntityAdapter<BillingInformationInterface> = createEntityAdapter<
  BillingInformationInterface
>({
  selectId: (billingInformation: BillingInformationInterface) =>
    billingInformation.id,
  sortComparer: false,
});

export function reducer(
  state = initialState,
  action: BillingInformationActions,
): State {
  switch (action.type) {
    case BillingInformationActionTypes.BillingInformationLoad:
      return Object.assign({}, state, {
        loading: true,
      });

    case BillingInformationActionTypes.BillingInformationLoadSuccess:
      return adapter.setMany(
        action.payload,
        Object.assign({}, state, {
          loading: false,
        }),
      );

    case BillingInformationActionTypes.BillingInformationLoadFailure:
      return Object.assign({}, state, {
        loading: false,
        errors: action.payload.error,
      });

    case BillingInformationActionTypes.BillingInformationDelete:
      return Object.assign({}, state, {
        loading: true,
        errors: {},
      });

    case BillingInformationActionTypes.BillingInformationDeleteSuccess:
      return adapter.removeOne(
        action.payload,
        Object.assign({}, state, {
          loading: false,
        }),
      );

    case BillingInformationActionTypes.BillingInformationDeleteFailure:
      return Object.assign({}, state, {
        loading: false,
        errors: action.payload.error,
      });

    default:
      return state;
  }
}

export const getSelectedId = (state: State) => state.selectedId;
export const getEntities = (state: State) => state.entities;

export const { selectAll: getAll } = adapter.getSelectors();
