import { Injectable } from '@angular/core';
import { ErrorPopupService } from '@info-popups/error-popup';
import { HttpErrorResponse } from '@angular/common/http';

function getHttpErrorText(httpError: HttpErrorResponse): string {
  return httpError.status === 422 && typeof httpError.error === 'object'
    ? httpError.error[Object.keys(httpError.error)[0]]
    : '';
}

@Injectable({
  providedIn: 'root',
})
export class ErrorHandlerService {
  constructor(private readonly errorPopupService: ErrorPopupService) {}

  handleHttpError(httpError: HttpErrorResponse, text?: string) {
    const message = getHttpErrorText(httpError);
    this.errorPopupService.open({
      message,
      text:
        httpError.status === 422 && !text
          ? 'INFO_DIALOGS.TEXT.HTTP_VALIDATION_ERROR'
          : text,
    });
  }
}
