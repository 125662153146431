import { Injectable, Inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs/internal/observable/of';
import {
  catchError,
  map,
  mergeMap,
  tap,
  filter,
  take,
  debounceTime,
  exhaustMap,
  concatMap,
} from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { State, getUser } from '..';
import {
  IdentityInterface,
  OnboardingAction,
  UserOnboardingStep,
} from '@core/models/identity.interface';
import { Router, NavigationEnd } from '@angular/router';
import {
  saveScopeAction,
  saveScopeSuccessAction,
  saveScopeFailureAction,
  updateOnboardingAction,
  updateOnboardingSuccessAction,
  updateOnboardingFailureAction,
  saveProfessionAction,
  saveProfessionFailureAction,
  saveProfessionSuccessAction,
  saveAffiliateInfoAction,
  saveAffiliateInfoSuccessAction,
  saveAffiliateInfoFailureAction,
} from './onboarding.actions';
import { ApiUserOnboardingService } from '@core/services/user-onboarding/api-user-onboarding.service';
import { combineLatest, merge, Observable } from 'rxjs';
import { getEmptyNote, NoteInterface } from '@core/models/note.interface';
import { ScrollToService } from '@nicky-lenaers/ngx-scroll-to';
import { dayId } from '@helpers/consts/calendar-page';
import * as moment from 'moment';
import { TooltipConfig, TooltipRef } from '@ui/tooltip';
import { TextTooltipService } from '@tooltips';
import { DOCUMENT } from '@angular/common';
import { storiesPlayButtonId } from '@ui/headers/story-button/story-button.component';
import { TextTooltipData } from '@tooltips/text-tooltip';
import { NoteDialogService } from '@dialogs/note';
import { NewLevelPopupService } from '@info-popups/new-level';
import { OnboardingService } from './onboarding.service';
import { actionsToPassAfterStoriesOpening } from './consts';
import { NotificationsPopupService } from '@info-popups/notifications';
import { NotificationsPopupResult } from '@info-popups/notifications/notifications-popup.service';
import { MatDialog } from '@angular/material/dialog';
import { ApiAffiliateService } from '@core/services/affiliate/api-affiliate.service';

const isGoodUrlForOnboarding = (url: string) => {
  return !url.startsWith('/auth');
};

const shouldError = (action: UserOnboardingStep) =>
  !['affiliates-page', 'create-first-note-from-video'].some(
    (a) => a === action,
  );

const isPageAction = (action: UserOnboardingStep) => action.endsWith('-page');

/**
 * Effect для работы с онбоардингом
 */
@Injectable()
export class OnboardingEffects {
  showOnboarding$ = createEffect(
    () =>
      combineLatest([
        this.store.pipe(select(getUser)),
        this.router.events.pipe(
          filter((event) => event instanceof NavigationEnd),
        ),
      ]).pipe(tap(([user]) => this.receiveUpdate(user))),
    { dispatch: false },
  );

  saveProfession$ = createEffect(() =>
    this.actions$.pipe(
      ofType(saveProfessionAction),
      exhaustMap((action) =>
        this.apiOnboardingService.saveProfession(action).pipe(
          map((onboarding) => saveProfessionSuccessAction({ onboarding })),
          catchError((httpError) =>
            of(saveProfessionFailureAction({ httpError })),
          ),
        ),
      ),
    ),
  );

  saveScope$ = createEffect(() =>
    this.actions$.pipe(
      ofType(saveScopeAction),
      exhaustMap((action) =>
        this.apiOnboardingService.saveScope(action).pipe(
          map((onboarding) => saveScopeSuccessAction({ onboarding })),
          catchError((httpError) => of(saveScopeFailureAction({ httpError }))),
        ),
      ),
    ),
  );

  updateOnboarding$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateOnboardingAction),
      tap((action) => {
        if (
          actionsToPassAfterStoriesOpening.includes(action.action) &&
          this.onboardingTooltip
        ) {
          this.onboardingTooltip.close();
          this.onboardingTooltip = undefined;
        }
      }),
      concatMap((action) =>
        this.apiOnboardingService.passed(action.action).pipe(
          map((onboarding) => updateOnboardingSuccessAction({ onboarding })),
          catchError((httpError) =>
            of(updateOnboardingFailureAction({ httpError })),
          ),
        ),
      ),
    ),
  );

  saveInfo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(saveAffiliateInfoAction),
      exhaustMap((action) =>
        this.apiAffiliateService.saveInfo(action.info).pipe(
          map(() => saveAffiliateInfoSuccessAction()),
          catchError(() => of(saveAffiliateInfoFailureAction())),
        ),
      ),
    ),
  );

  private user: IdentityInterface;
  private actions: Map<UserOnboardingStep, (a: OnboardingAction) => void>;
  private onboardingTooltip: TooltipRef<any>;
  private complitedActions: string[] = [];

  constructor(
    private readonly actions$: Actions,
    private readonly apiAffiliateService: ApiAffiliateService,
    private readonly apiOnboardingService: ApiUserOnboardingService,
    @Inject(DOCUMENT)
    private readonly document: Document,
    private readonly matDialog: MatDialog,
    private readonly newLevelPopupService: NewLevelPopupService,
    private readonly noteDialogService: NoteDialogService,
    private readonly notificationsPopupService: NotificationsPopupService,
    private readonly onboardingService: OnboardingService,
    private readonly router: Router,
    private readonly scrollToService: ScrollToService,
    private readonly store: Store<State>,
    private readonly textTooltipService: TextTooltipService,
  ) {
    this.actions = this.generateActions();
  }

  private receiveUpdate(user: IdentityInterface) {
    this.user = user;
    // обновление после логаута
    if (!user) {
      this.resetState();
      return;
    }
    if (user.onboarding.actions.length === 0) {
      return;
    }
    const nextStep = user.onboarding.actions.find(
      (a) => a.scenario === 'global',
    );
    if (!nextStep) {
      return;
    }
    if (!this.isReadyFor(nextStep.action)) {
      return;
    }
    const actionFullName = this.getActionFullName(nextStep.action);
    if (this.complitedActions.includes(actionFullName)) {
      return;
    }
    this.complitedActions.push(actionFullName);
    const idle$ =
      isPageAction(nextStep.action) || this.matDialog.openDialogs.length === 0
        ? of(true)
        : this.afterDialogsClosed();
    idle$.subscribe(
      () => {},
      () => {},
      () => {
        const action = this.actions.get(nextStep.action);
        if (action) {
          action(nextStep);
        } else if (shouldError(nextStep.action)) {
          console.error(nextStep.action);
        }
      },
    );
  }

  private isReadyFor(action: UserOnboardingStep): boolean {
    if (!isGoodUrlForOnboarding(this.router.url)) {
      return false;
    }
    if (
      action === 'create-first-note' &&
      this.router.url.startsWith('/onboarding')
    ) {
      return false;
    }
    return true;
  }

  private openNote() {
    this.router
      .navigate(['/calendar'])
      .then(() => {
        const note: NoteInterface = { ...getEmptyNote() };
        const today = moment();
        setTimeout(
          () =>
            this.scrollToService.scrollTo({
              target: dayId(today),
              offset: -140,
              duration: 100,
            }),
          200,
        );
        setTimeout(() => {
          this.noteDialogService.create(note, true);
        }, 1000);
      })
      .catch((err) => console.warn('open calendar:', err));
  }

  private resetState() {
    this.complitedActions = [];
    if (this.onboardingTooltip) {
      this.onboardingTooltip.close();
      this.onboardingTooltip = undefined;
    }
  }

  private getActionFullName(shortName: UserOnboardingStep): string {
    switch (shortName) {
      case 'show-achievement:affiliates':
        return (
          shortName + this.user.achievements.affiliates.currentLevel.levelNumber
        );
      case 'show-achievement:goals':
        return (
          shortName + this.user.achievements.goals.currentLevel.levelNumber
        );
      case 'show-achievement:max-streak':
        return (
          shortName + this.user.achievements.maxStreak.currentLevel.levelNumber
        );
      case 'show-achievement:questions':
        return (
          shortName + this.user.achievements.questions.currentLevel.levelNumber
        );
      case 'show-achievement:filled-days':
        return (
          shortName + this.user.achievements.filledDays.currentLevel.levelNumber
        );
      default:
        return shortName;
    }
  }

  private getStoriesTooltipConfig(
    tooltipData: TextTooltipData,
    width: number,
  ): TooltipConfig<TextTooltipData> {
    return {
      relativeTo: this.document.getElementById(storiesPlayButtonId),
      arrow: 'top-right',
      width: width + 'px',
      position: {
        originX: 'center',
        originY: 'bottom',
        overlayX: 'end',
        overlayY: 'top',
        offsetX: 24,
        offsetY: 9,
      },
      data: tooltipData,
    };
  }

  private openStoriesTip(
    options: {
      translateKey: string;
      width: number;
      actions?: () => void;
      hasCloseButton?: boolean;
    },
    secondTrial?: true,
  ) {
    const config = this.getStoriesTooltipConfig(
      {
        text: 'TOOLTIPS.ONBOARDING.STORIES.' + options.translateKey,
        actions: options.actions,
        hasCloseButton: options.hasCloseButton,
      },
      options.width,
    );
    if (this.onboardingTooltip) {
      this.onboardingTooltip.close();
    }
    if (!config.relativeTo) {
      if (!secondTrial) {
        setTimeout(() => {
          this.openStoriesTip(options, true);
        }, 100);
      }
      return;
    }
    this.onboardingTooltip = this.textTooltipService.show(config);
  }

  private generateActions() {
    return new Map<UserOnboardingStep, (a: OnboardingAction) => void>([
      [
        'greeting-page',
        () => this.router.navigate(['/onboarding', 'greeting']),
      ],

      ['task-page', () => this.router.navigate(['/onboarding', 'task'])],

      ['result-page', () => this.router.navigate(['/onboarding', 'result'])],

      ['video-page', () => this.router.navigate(['/onboarding', 'video'])],

      ['likes-page', () => this.router.navigate(['/onboarding', 'likes'])],
      [
        'classification-page',
        () => this.router.navigate(['/onboarding', 'classification']),
      ],

      ['create-first-note', () => this.openNote()],

      [
        'show-achievement:affiliates',
        (action) =>
          this.newLevelPopupService
            .open({
              achievement: this.user.achievements.affiliates,
              action,
            })
            .afterClosed()
            .subscribe(() =>
              this.onboardingService
                .pass('show-achievement:affiliates')
                .subscribe(),
            ),
      ],

      [
        'show-achievement:goals',
        (action) =>
          this.newLevelPopupService
            .open({
              achievement: this.user.achievements.goals,
              action,
            })
            .afterClosed()
            .subscribe(() =>
              this.onboardingService.pass('show-achievement:goals').subscribe(),
            ),
      ],

      [
        'show-achievement:max-streak',
        (action) =>
          this.newLevelPopupService
            .open({
              achievement: this.user.achievements.maxStreak,
              action,
            })
            .afterClosed()
            .subscribe(() =>
              this.onboardingService
                .pass('show-achievement:max-streak')
                .subscribe(),
            ),
      ],

      [
        'show-achievement:questions',
        (action) =>
          this.newLevelPopupService
            .open({
              achievement: this.user.achievements.questions,
              action,
            })
            .afterClosed()
            .subscribe(() =>
              this.onboardingService
                .pass('show-achievement:questions')
                .subscribe(),
            ),
      ],

      [
        'show-achievement:filled-days',
        (action) =>
          this.newLevelPopupService
            .open({
              achievement: this.user.achievements.filledDays,
              action,
            })
            .afterClosed()
            .subscribe(() =>
              this.onboardingService
                .pass('show-achievement:filled-days')
                .subscribe(),
            ),
      ],

      [
        'show-popup:notification-settings',
        () =>
          this.notificationsPopupService
            .open()[1]
            .afterClosed()
            .pipe(
              mergeMap((result: NotificationsPopupResult) =>
                result?.navigatedToDownloads
                  ? // Дождись возвращения в календарь
                    this.router.events.pipe(
                      filter(
                        (e) =>
                          e instanceof NavigationEnd &&
                          e.url.includes('calendar'),
                      ),
                      take(1),
                    )
                  : // Сделай запрос сразу
                    of(undefined),
              ),
            )
            .subscribe(() =>
              this.onboardingService
                .pass('show-popup:notification-settings')
                .subscribe(),
            ),
      ],

      [
        'show-stories-tooltip:daily-content',
        () =>
          this.openStoriesTip({
            translateKey: 'FIRST',
            width: 221,
            hasCloseButton: true,
          }),
      ],

      [
        'show-stories-tooltip:next-step',
        () =>
          this.openStoriesTip({
            translateKey: 'SECOND',
            width: 243,
            hasCloseButton: true,
          }),
      ],

      [
        'show-stories-tooltip:in-three-hours',
        () =>
          this.openStoriesTip({
            translateKey: 'THIRD',
            width: 257,
            actions: () =>
              this.onboardingService
                .pass('show-stories-tooltip:in-three-hours')
                .subscribe(),
          }),
      ],
    ]);
  }

  private afterDialogsClosed(): Observable<unknown> {
    return merge(
      this.matDialog.afterAllClosed.pipe(map(() => false)),
      this.matDialog.afterOpened.pipe(map(() => true)),
    ).pipe(
      debounceTime(500),
      filter((hasOpened) => !hasOpened),
      take(1),
    );
  }
}
