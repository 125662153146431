import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, Effect, ofType } from '@ngrx/effects';
import { AuthService } from '@core/services/auth.service';
import {
  addAccountAction,
  addAccountFailureAction,
  addAccountSuccessAction,
  changePasswordAction,
  changePasswordFailureAction,
  changePasswordSuccessAction,
  checkPasswordResetTokenAction,
  checkPasswordResetTokenFailureAction,
  checkPasswordResetTokenSuccessAction,
  getUserAction,
  getUserFailureAction,
  getUserSuccessAction,
  setEmailAction,
  setEmailFailureAction,
  setEmailSuccessAction,
  setTokenAction,
  signInAction,
  signInFailureAction,
  signInSocialAction,
  signInSocialFailureAction,
  signInSuccessAction,
  signOutAction,
  signOutSuccessAction,
  signUpAction,
  signUpFailureAction,
  signUpSuccessAction,
  updateAvatarAction,
  updateAvatarFailureAction,
  updateAvatarSuccessAction,
  updateProfileAction,
  updateProfileFailureAction,
  updateProfileSuccessAction,
} from './auth.actions';
import { catchError, exhaustMap, map, switchMap, tap } from 'rxjs/operators';
import { of } from 'rxjs/internal/observable/of';
import { SocketBaseService } from '@core/socket/socket.base.service';
import { UserService } from '@core/services/user.service';
import { tokenKey } from '@core/utils/token.utils';
import { defaultCalendarPageType } from '@helpers/consts/calendar-page';
import { PersistentStorageService } from '@core/services/persistent-storage.service';
import { HttpErrorResponse } from '@angular/common/http';
import { FileService } from '@core/services/file.service';
import { AnalyticsService } from '@core/services/analytics.service';

@Injectable({
  providedIn: 'root',
})
export class AuthEffects {
  @Effect()
  setToken$ = this.actions$.pipe(
    ofType(setTokenAction),
    tap((action) => this.updateLocalAccessToken(action)),
    map((action) => action.token),
    exhaustMap((token) => {
      if (!token) {
        return of(
          signInFailureAction({
            error: new HttpErrorResponse({ status: 400 }),
          }),
        );
      }
      return this.authService.getUser().pipe(
        map(() => getUserAction()),
        catchError(() => of(signOutAction())),
      );
    }),
  );

  @Effect()
  getUser$ = this.actions$.pipe(
    ofType(getUserAction),
    exhaustMap(() => {
      return this.authService.getUser().pipe(
        map((user) => getUserSuccessAction({ user })),
        catchError((error) => of(getUserFailureAction({ error }))),
      );
    }),
  );

  @Effect()
  signUp$ = this.actions$.pipe(
    ofType(signUpAction),
    exhaustMap((action) => {
      return this.authService.signUp(action.credentials).pipe(
        map((user) => signUpSuccessAction({ user })),
        catchError((error) => of(signUpFailureAction({ error }))),
      );
    }),
    tap((action) => {
      if (action.type === '[account api] sign up success') {
        this.updateLocalAccessToken(action.user);
      }
    }),
  );

  @Effect()
  signOut$ = this.actions$.pipe(
    ofType(signOutAction),
    exhaustMap(() => {
      this.removeLocalAccessToken();
      return this.authService.signOut().pipe(
        map(() => signOutSuccessAction()),
        catchError(() => of(signOutSuccessAction())),
      );
    }),
  );

  @Effect({ dispatch: false })
  signUpSuccess$ = this.actions$.pipe(
    ofType(signUpSuccessAction),
    tap((action) => {
      this.analyticsService.signedUp();
      this.analyticsService.signUpCompleted(action.user.signUpFrom);
      this.router.navigate(['/onboarding', 'greeting']);
    }),
  );

  @Effect()
  signIn$ = this.actions$.pipe(
    ofType(signInAction),
    exhaustMap((action) => {
      return this.authService.signIn(action.credentials).pipe(
        map((user) => signInSuccessAction({ user, redirect: action.redirect })),
        catchError((error) => of(signInFailureAction({ error }))),
      );
    }),
  );

  @Effect({ dispatch: false })
  signInSuccess$ = this.actions$.pipe(
    ofType(signInSuccessAction),
    tap((action) => {
      this.updateLocalAccessToken(setTokenAction({ token: action.user.token }));
      this.analyticsService.signInCompleted(action.user.signInFrom);
      if (action.user.isRequestInputEmail) {
        this.router.navigate(['/auth/step-email']);
      } else {
        const date = new Date();
        const route = action.redirect
          ? action.redirect
          : '/calendar/my/' + date.getFullYear() + '/' + (date.getMonth() + 1);

        if (action.user.isNew) {
          this.analyticsService.signUpCompleted(action.user.signUpFrom);
        } else {
          this.analyticsService.signInCompleted(action.user.signUpFrom);
        }
        this.router.navigateByUrl(route);
      }
    }),
  );

  @Effect({ dispatch: false })
  signInFailure$ = this.actions$.pipe(
    ofType(
      signInFailureAction,
      signInSocialFailureAction,
      signUpFailureAction,
      signOutAction,
    ),
    map(() => this.removeLocalAccessToken()),
  );

  @Effect({ dispatch: false })
  setEmailSuccess$ = this.actions$.pipe(
    ofType(setEmailSuccessAction),
    tap(() => {
      const date = new Date();
      this.router.navigate([
        '/calendar/my',
        date.getFullYear(),
        date.getMonth() + 1,
        defaultCalendarPageType,
      ]);
    }),
  );

  @Effect()
  signInBySocial$ = this.actions$.pipe(
    ofType(signInSocialAction),
    exhaustMap((action) => {
      return this.authService.signInBySocial(action.data).pipe(
        map((user) => signInSuccessAction({ user })),
        catchError((error) => of(signInSocialFailureAction({ error }))),
      );
    }),
  );

  @Effect()
  addAccount$ = this.actions$.pipe(
    ofType(addAccountAction),
    exhaustMap((action) => {
      return this.authService.addAccount(action.data).pipe(
        map((user) => addAccountSuccessAction({ user })),
        catchError((error) => of(addAccountFailureAction({ error }))),
      );
    }),
  );

  @Effect()
  setEmail$ = this.actions$.pipe(
    ofType(setEmailAction),
    exhaustMap((action) =>
      this.authService.setEmail(action).pipe(
        map((user) => setEmailSuccessAction({ user })),
        catchError((error) => of(setEmailFailureAction({ error }))),
      ),
    ),
  );

  @Effect()
  changePassword$ = this.actions$.pipe(
    ofType(changePasswordAction),
    exhaustMap((action) =>
      this.authService.changePassword(action.newPassword).pipe(
        map((user) => changePasswordSuccessAction({ user })),
        catchError((error) => of(changePasswordFailureAction({ error }))),
      ),
    ),
  );

  @Effect()
  checkPasswordResetToken$ = this.actions$.pipe(
    ofType(checkPasswordResetTokenAction),
    exhaustMap((action) =>
      this.authService.checkPasswordResetToken(action.token).pipe(
        map((user) => checkPasswordResetTokenSuccessAction({ user })),
        catchError((error) =>
          of(checkPasswordResetTokenFailureAction({ error })),
        ),
      ),
    ),
  );

  updateAvatar$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateAvatarAction),
      switchMap((action) =>
        this.fileService.uploadAvatar(action.newAvatar).pipe(
          map(
            (avatar) => updateAvatarSuccessAction({ avatar }),
            catchError((error) => of(updateAvatarFailureAction({ error }))),
          ),
        ),
      ),
    ),
  );

  @Effect()
  updateProfile$ = this.actions$.pipe(
    ofType(updateProfileAction),
    exhaustMap((action) =>
      this.userService.update(action.user).pipe(
        map((user) => updateProfileSuccessAction({ user })),
        catchError((error) => of(updateProfileFailureAction({ error }))),
      ),
    ),
  );

  @Effect({ dispatch: false })
  signOutSuccess$ = this.actions$.pipe(
    ofType(signOutSuccessAction),
    tap(() => {
      this.socketService.unsubscribe();
      this.router.navigateByUrl('/auth/sign-in');
    }),
  );

  constructor(
    private readonly actions$: Actions,
    private readonly analyticsService: AnalyticsService,
    private readonly authService: AuthService,
    private readonly fileService: FileService,
    private readonly persistentStorageService: PersistentStorageService,
    private readonly router: Router,
    private readonly socketService: SocketBaseService,
    private readonly userService: UserService,
  ) {}

  private updateLocalAccessToken({ token }: { token: string }) {
    if (token) {
      const date = new Date();
      date.setMonth(date.getMonth() + 3);
      this.persistentStorageService.set(tokenKey, token, { expires: date });
    }
  }

  private removeLocalAccessToken() {
    this.persistentStorageService.remove(tokenKey);
  }
}
