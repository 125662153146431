import {
  Component,
  ElementRef,
  EventEmitter,
  Output,
  ViewChild,
} from '@angular/core';

import { getCaretCoordinates } from './caret-coords';

@Component({
  selector: 'autocomplete-tags-list',
  templateUrl: './autocomplete-tags-list.component.html',
  styleUrls: ['./style.less'],
})
export class AutocompleteTagsListComponent {
  @Output()
  tagClick = new EventEmitter();

  @ViewChild('list')
  list: ElementRef;

  tags = [];
  activeIndex = 0;
  hidden = false;

  private readonly activeClass = 'autocomplete-tags-list__item_active';

  constructor(private elementRef: ElementRef) {}

  onTagClick(i: number, event: Event) {
    this.activeIndex = i;
    this.tagClick.emit();
    event.preventDefault();
  }

  // lots of confusion here between relative coordinates and containers
  position(nativeParentElement: HTMLInputElement) {
    // parent elements need to have postition:relative for this to work correctly?
    const coords = getCaretCoordinates(
      nativeParentElement,
      nativeParentElement.selectionStart,
    );
    coords.top = nativeParentElement.offsetTop + coords.top + 16;
    coords.left = nativeParentElement.offsetLeft + coords.left;
    const el: HTMLElement = this.elementRef.nativeElement;
    el.style.position = 'absolute';
    el.style.left = coords.left + 'px';
    el.style.top = coords.top + 'px';
  }

  get activeItem() {
    return this.tags[this.activeIndex];
  }

  activateNextTag() {
    // adjust scrollable-menu offset if the next item is out of view
    const listEl: HTMLElement = this.list.nativeElement;
    const activeEl = listEl.getElementsByClassName(this.activeClass).item(0);
    if (activeEl) {
      const nextLiEl: HTMLElement = activeEl.nextSibling as HTMLElement;
      if (nextLiEl && nextLiEl.nodeName === 'LI') {
        const nextLiRect: ClientRect = nextLiEl.getBoundingClientRect();
        if (nextLiRect.bottom > listEl.getBoundingClientRect().bottom) {
          listEl.scrollTop =
            nextLiEl.offsetTop + nextLiRect.height - listEl.clientHeight;
        }
      }
    }
    // select the next item
    this.activeIndex = Math.max(
      Math.min(this.activeIndex + 1, this.tags.length - 1),
      0,
    );
  }

  activatePreviousTag() {
    // adjust the scrollable-menu offset if the previous item is out of view
    const listEl: HTMLElement = this.list.nativeElement;
    const activeEl = listEl.getElementsByClassName(this.activeClass).item(0);
    if (activeEl) {
      const prevLiEl: HTMLElement = activeEl.previousSibling as HTMLElement;
      if (prevLiEl && prevLiEl.nodeName === 'LI') {
        const prevLiRect: ClientRect = prevLiEl.getBoundingClientRect();
        if (prevLiRect.top < listEl.getBoundingClientRect().top) {
          listEl.scrollTop = prevLiEl.offsetTop;
        }
      }
    }
    // select the previous item
    this.activeIndex = Math.max(
      Math.min(this.activeIndex - 1, this.tags.length - 1),
      0,
    );
  }

  resetScroll() {
    this.list.nativeElement.scrollTop = 0;
  }
}
