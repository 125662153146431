import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Injectable, Injector } from '@angular/core';
import { hasDoubleHeader } from '@helpers/consts/threshold';
import { fromEvent, Observable, Subject } from 'rxjs';
import { OfflineNotificationComponent } from './offline-notification.component';

@Injectable()
export class OfflineNotificationService {
  private overlayRef?: OverlayRef;
  private initialized = false;
  private readonly refreshRequests$ = new Subject();
  constructor(
    private readonly injector: Injector,
    private readonly overlay: Overlay,
  ) {}

  refreshRequests(): Observable<unknown> {
    if (!this.initialized) {
      this.initialized = true;
      fromEvent(window, 'offline').subscribe(() => this.open());
      fromEvent(window, 'online').subscribe(() => {
        this.refreshRequests$.next();
        this.close();
      });
    }
    return this.refreshRequests$.asObservable();
  }

  close() {
    this.overlayRef?.detach();
    this.overlayRef?.dispose();
    this.overlayRef = undefined;
  }

  /**
   * @private
   */
  clickedRefresh() {
    this.refreshRequests$.next();
  }

  private open() {
    if (this.overlayRef?.hasAttached()) {
      return;
    }
    const bottom = hasDoubleHeader() ? '80px' : '24px';
    const positionStrategy = this.overlay
      .position()
      .global()
      .bottom(bottom)
      .left('24px');
    this.overlayRef = this.overlay.create({
      positionStrategy,
      disposeOnNavigation: false,
    });
    const injector = Injector.create({
      providers: [{ provide: OverlayRef, useValue: this.overlayRef }],
      parent: this.injector,
    });
    const portal = new ComponentPortal(
      OfflineNotificationComponent,
      undefined,
      injector,
    );
    const componentRef = this.overlayRef.attach(portal);
    componentRef.instance.closeClicks().subscribe(() => this.close());
    componentRef.instance
      .refreshClicks()
      .subscribe(() => this.refreshRequests$.next());
  }
}
