<form
  class="popup-category-form__form"
  [formGroup]="form"
  (ngSubmit)="onSubmit()"
>
  <div class="form-group popup-category-form__padding-inner">
    <label class="label" translate>UX.POPUP_CATEGORY_FORM.NAME</label>
    <input class="input" autoFocus type="text" formControlName="name" />
  </div>
  <div class="popup-category-form__selector">
    <span class="popup-category-form__icon-label">
      <ui-icon *ngIf="false" class="ui-icon" name="search"></ui-icon>
      <span>{{ 'UX.POPUP_CATEGORY_FORM.CHOOSE' | translate }}</span>
    </span>
    <div class="popup-category-form__icon-selector">
      <rt-icon-selector formControlName="icon"></rt-icon-selector>
    </div>
  </div>

  <div class="popup-category-form__footer-wrap">
    <button
      *ngIf="form.get('id').value"
      (click)="onDelete()"
      [disabled]="isLoading"
      ui-button-secondary
      class=" "
      type="button"
    >
      {{ '$.DELETE' | translate }}
    </button>
    <button [disabled]="form.invalid || isLoading" ui-button-primary class=" ">
      {{ '$.SAVE' | translate }}
    </button>
  </div>
</form>
