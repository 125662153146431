import {
  Component,
  Input,
  ChangeDetectionStrategy,
  OnInit,
} from '@angular/core';

@Component({
  selector: 'ui-circle-progress',
  templateUrl: './circle-progress.component.html',
  styleUrls: ['./circle-progress.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CircleProgressComponent implements OnInit {
  @Input()
  progress = 0;

  @Input()
  targetValue = 1;

  percent = 0;

  ngOnInit() {
    this.percent = (this.progress / this.targetValue) * 100;
  }
}
