import { select, Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { getTargetImageAll, State } from '@core/redux/index';
import {
  targetImageLoad,
  targetImageLoadSuccess,
} from '@core/redux/target-image/target-image.actions';
import { TargetTypeLoadSuccess } from '@core/redux/target-type/target-type.actions';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class TargetImageService {
  constructor(private store: Store<State>) {}

  getAll() {
    return this.store.pipe(
      select(getTargetImageAll),
      filter(_ => _.length > 0),
    );
  }

  dispatchAll = () => this.store.dispatch(targetImageLoad());

  dispatchAllSuccess(data) {
    this.store.dispatch(targetImageLoadSuccess({ images: data }));
  }
}
