import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { TargetTypeInterface } from '@core/models/target-type.interface';
import { createSelector } from '@ngrx/store';
import {
  TargetTypeActions,
  TargetTypeActionTypes,
} from '@core/redux/target-type/target-type.actions';

export interface State {
  ids: string[];
  selectedId: string;
  loading: boolean;
  entities: any;
}

export const initialState: State = {
  ids: [],
  selectedId: null,
  loading: false,
  entities: {},
};

export const adapter: EntityAdapter<TargetTypeInterface> = createEntityAdapter<
  TargetTypeInterface
>({
  selectId: (targetType: TargetTypeInterface) => targetType.id,
  sortComparer: false,
});

export function reducer(
  state = initialState,
  action: TargetTypeActions,
): State {
  switch (action.type) {
    case TargetTypeActionTypes.TargetTypeLoad: {
      return Object.assign({}, state, {
        loading: true,
      });
    }

    case TargetTypeActionTypes.TargetTypeLoadSuccess: {
      return adapter.setMany(
        action.payload,
        Object.assign({}, state, {
          loading: false,
        }),
      );
    }

    case TargetTypeActionTypes.TargetTypeLoadFailure: {
      return Object.assign({}, state, {
        loading: false,
        errors: action.payload.error,
      });
    }

    case TargetTypeActionTypes.TargetTypeCreate: {
      return Object.assign({}, state, {
        loading: true,
        errors: {},
      });
    }

    case TargetTypeActionTypes.TargetTypeCreateSuccess: {
      return adapter.upsertOne(
        action.payload,
        Object.assign({}, state, {
          loading: false,
          errors: {},
        }),
      );
    }

    case TargetTypeActionTypes.TargetTypeCreateFailure: {
      return Object.assign({}, state, {
        loading: false,
        errors: action.payload.error,
      });
    }

    case TargetTypeActionTypes.TargetTypeUpdate: {
      return Object.assign({}, state, {
        loading: true,
        errors: {},
      });
    }

    case TargetTypeActionTypes.TargetTypeUpdateSuccess: {
      return adapter.updateOne(
        {
          id: action.payload.id,
          changes: action.payload,
        },
        Object.assign({}, state, {
          loading: false,
          errors: {},
        }),
      );
    }

    case TargetTypeActionTypes.TargetTypeUpdateFailure: {
      return Object.assign({}, state, {
        loading: false,
        errors: action.payload.error,
      });
    }

    case TargetTypeActionTypes.TargetTypeDelete: {
      return Object.assign({}, state, {
        loading: true,
        errors: {},
      });
    }

    case TargetTypeActionTypes.TargetTypeDeleteSuccess: {
      return adapter.removeOne(
        action.payload,
        Object.assign({}, state, {
          loading: false,
          errors: {},
        }),
      );
    }

    case TargetTypeActionTypes.TargetTypeDeleteFailure: {
      return Object.assign({}, state, {
        loading: false,
        errors: action.payload.error,
      });
    }

    case TargetTypeActionTypes.TargetTypeSelect:
      return Object.assign({}, state, {
        selectedId: action.payload,
      });

    case TargetTypeActionTypes.TargetTypeUnSelect:
      return Object.assign({}, state, {
        selectedId: null,
      });

    default:
      return state;
  }
}

export const getSelectedId = (state: State) => state.selectedId;
export const getEntities = (state: State) => state.entities;
export const getLoading = (state: State) => state.loading;
export const getSelectedEntity = createSelector(
  getEntities,
  getSelectedId,
  (entities, selectedId) => entities[selectedId],
);

export const { selectAll: getAll } = adapter.getSelectors();
