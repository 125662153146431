<div class="ui-note-view-slider">
  <ngx-tiny-slider
    #slider
    [config]="tinySliderConfig"
    class="ui-note-view-slider__img-container"
  >
    <ng-container class="items">
      <div
        #slideList
        *ngFor="let image of images; trackBy: trackById"
        class="item ui-note-view-slider__item"
      >
        <img
          [attr.data-src]="image | imagePreview"
          class="tns-lazy-img"
          alt=""
        />
        <!-- <button (click)="onSelectImage('prev')" class="btn btn_not-button"></button>
        <button (click)="onSelectImage('next')" class="btn btn_not-button"></button> -->
      </div>
    </ng-container>
  </ngx-tiny-slider>
  <div class="ui-note-view-slider__list">
    <button
      *ngFor="let image of images; let i = index; trackBy: trackById"
      (click)="onSelectImage(i)"
      [class.ui-note-view-slider__preview_active]="i === activeIndex"
      class="ui-note-view-slider__preview"
      type="button"
    >
      <img [src]="image | imagePreview: 82:82" alt="" />
    </button>
  </div>
</div>
