import { Injectable } from '@angular/core';
import { GoalStepsHttpService } from '@core/redux/goal-step/goal-steps-http.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { forkJoin, of } from 'rxjs';
import {
  catchError,
  concatMap,
  exhaustMap,
  map,
  mergeMap,
  switchMap,
} from 'rxjs/operators';
import {
  createGoalStepAction,
  createGoalStepFailureAction,
  createGoalStepSuccessAction,
  deleteGoalStepAction,
  deleteGoalStepFailureAction,
  deleteGoalStepSuccessAction,
  getStepsForGoalAction,
  getStepsForGoalFailureAction,
  getStepsForGoalSuccessAction,
  sortGoalStepsAction,
  sortGoalStepsFailureAction,
  sortGoalStepsSuccessAction,
  updateGoalStepAction,
  updateGoalStepFailureAction,
  updateGoalStepSuccessAction,
} from './goal-steps.actions';

@Injectable()
export class GoalStepsEffects {
  create$ = createEffect(() =>
    this.actions.pipe(
      ofType(createGoalStepAction),
      exhaustMap((action) =>
        this.goalStepService.create(action.request).pipe(
          map((goalStep) => createGoalStepSuccessAction({ goalStep })),
          catchError((httpError) =>
            of(createGoalStepFailureAction({ httpError })),
          ),
        ),
      ),
    ),
  );

  delete$ = createEffect(() =>
    this.actions.pipe(
      ofType(deleteGoalStepAction),
      mergeMap((action) =>
        this.goalStepService.delete(action.goalStepId).pipe(
          map(() =>
            deleteGoalStepSuccessAction({ goalStepId: action.goalStepId }),
          ),
          catchError((httpError) =>
            of(deleteGoalStepFailureAction({ httpError })),
          ),
        ),
      ),
    ),
  );

  get$ = createEffect(() =>
    this.actions.pipe(
      ofType(getStepsForGoalAction),
      switchMap((action) =>
        this.goalStepService.getByGoalId(action.goalId).pipe(
          map((goalSteps) => getStepsForGoalSuccessAction({ goalSteps })),
          catchError((httpError) =>
            of(getStepsForGoalFailureAction({ httpError })),
          ),
        ),
      ),
    ),
  );

  update$ = createEffect(() =>
    this.actions.pipe(
      ofType(updateGoalStepAction),
      concatMap((action) =>
        this.goalStepService.update(action.request.id, action.request).pipe(
          map((goalStep) => updateGoalStepSuccessAction({ goalStep })),
          catchError((httpError) =>
            of(updateGoalStepFailureAction({ httpError })),
          ),
        ),
      ),
    ),
  );

  sort$ = createEffect(() =>
    this.actions.pipe(
      ofType(sortGoalStepsAction),
      switchMap((action) =>
        Object.keys(action.changes).length === 0
          ? of(action)
          : forkJoin(
              Object.keys(action.changes).map((id) =>
                this.goalStepService.update(id, action.changes[id]),
              ),
            ).pipe(map(() => action)),
      ),
      switchMap((action) =>
        this.goalStepService.sort(action.ids, action.view, action.goalId),
      ),
      map((goalSteps) => sortGoalStepsSuccessAction({ goalSteps })),
      catchError((httpError) => of(sortGoalStepsFailureAction({ httpError }))),
    ),
  );

  constructor(
    private readonly actions: Actions,
    private readonly goalStepService: GoalStepsHttpService,
  ) {}
}
