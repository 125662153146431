import { NgModule } from '@angular/core';
import { NoteFormComponent } from './note-form/note-form.component';
import { UiTooltipModule } from '@ui/ui-tooltip/ui-tooltip.module';
import { UiChecklistModule } from '@ui/ui-checklist/ui-checklist.module';
import { UiFilePreviewFormModule } from '@ui/ui-file-preview-form/ui-file-preview-form.module';
import { UiSelectModule } from '@ui/ui-select/ui-select.module';
import { ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { AutocompleteTagsModule } from '@shared/directives/autocomplete-tags/autocomplete-tags.module';
import { InnerHtmlLinkModule } from '@shared/directives/inner-html-link/inner-html-link.module';
import { AutoFocusModule } from '@shared/directives/auto-focus/auto-focus.module';
import { SafeLinkyModule } from '@shared/pipes/safe-linky/safe-linky.module';
import { ImagePreviewModule } from '@shared/pipes/image-preview/image-preview.module';
import { MatDialogModule } from '@angular/material/dialog';
import { UiPopupCategoryFormModule } from '../category-form';
import { UiIconModule } from '@ui/ui-icon/ui-icon.module';
import { NoteComponent } from './note.component';
import { UiFilePreviewModule } from '@ui/ui-file-preview/ui-file-preview.module';
import { ButtonsModule } from '@ui/buttons/buttons.module';
import { UiOptionTargetModule } from './option-target/option-target.module';
import { NoteViewModule } from './note-view/note-view.module';
import { UiTuitionModule } from '@ui/ui-tuition/ui-tuition.module';
import { NgxPopper } from 'angular-popper';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { GoalSelectComponent } from './goal-select/goal-select.component';
import { NoteProgressModule } from '@ui/note-progress/note-progress.module';
import { AutosizeModule } from 'ngx-autosize';
import { SignedDecimalModule } from '@shared/pipes/signed-decimal/signed-decimal.module';
import { TooltipModule } from '@ui/tooltip';
import { TextTooltipModule } from '@tooltips';
import { NgxCleaveDirectiveModule } from 'ngx-cleave-directive';
import { ClickOutsideModule } from '@shared/directives/click-outside/click-outside.module';

@NgModule({
  imports: [
    CommonModule,
    AutocompleteTagsModule,
    UiTooltipModule,
    UiChecklistModule,
    UiFilePreviewFormModule,
    UiSelectModule,
    ReactiveFormsModule,
    TranslateModule,
    InnerHtmlLinkModule,
    AutoFocusModule,
    SafeLinkyModule,
    ImagePreviewModule,
    ClickOutsideModule,
    MatDialogModule,
    UiPopupCategoryFormModule,
    UiIconModule,
    UiFilePreviewModule,
    ButtonsModule,
    UiOptionTargetModule,
    NoteViewModule,
    UiTuitionModule,
    NgxPopper,
    DragDropModule,
    NoteProgressModule,
    AutosizeModule,
    SignedDecimalModule,
    TooltipModule,
    TextTooltipModule,
    NgxCleaveDirectiveModule,
  ],
  declarations: [NoteFormComponent, NoteComponent, GoalSelectComponent],
  exports: [NoteFormComponent],
  entryComponents: [NoteComponent],
})
export class NoteDialogModule {}
