import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { catchError, exhaustMap, map } from 'rxjs/operators';
import { of } from 'rxjs/index';
import {
  GetAllAction,
  GetAllFailureAction,
  GetAllSuccessAction,
  TariffActionTypes,
} from '@core/redux/tariff/tariff.actions';
import { TariffService } from '@core/services/tariff.service';

@Injectable()
export class TariffEffects {
  @Effect()
  getAll$ = this.actions$.pipe(
    ofType(TariffActionTypes.GetAllAction),
    map((action: GetAllAction) => action),
    exhaustMap(() => {
      return this.tariffService.getAll().pipe(
        map(response => new GetAllSuccessAction(response)),
        catchError(errors => of(new GetAllFailureAction(errors))),
      );
    }),
  );

  constructor(
    private actions$: Actions,
    private tariffService: TariffService,
  ) {}
}
