export enum Images {
  Affiliates = './assets/img/levels/affiliates.svg',
  Goals = './assets/img/levels/goals.svg',
  Questions = './assets/img/levels/questions.svg',
  MaxStreak1 = './assets/img/levels/maxStreak-1.svg',
  MaxStreak2 = './assets/img/levels/maxStreak-2.svg',
  MaxStreak3 = './assets/img/levels/maxStreak-3.svg',
  MaxStreak4 = './assets/img/levels/maxStreak-4.svg',
  Records1 = './assets/img/levels/records-1.svg',
  Records2 = './assets/img/levels/records-2.svg',
  Records3 = './assets/img/levels/records-3.svg',
  Records4 = './assets/img/levels/records-4.svg',
  Records5 = './assets/img/levels/records-5.svg',

  GoalsConstructor = './assets/img/cs-bg.svg',

  PartnersCab = './assets/img/affiliate/partners-cab.svg',
  TablePlaceholder = './assets/img/affiliate/table-placeholder.svg',
  UsersTable = './assets/img/affiliate/users-table.svg',

  GreenDone = './assets/icons/green-done.svg',
  Unlink = './assets/icons/unlink.svg',

  // не подгружается сейчас, во время акции можно добавить
  BlackFriday = './assets/img/black-friday.png',
  Email = './assets/img/email.svg',
  Gift = './assets/img/gift.svg',
  Notification = './assets/img/onboarding/notification.svg',
  NoNotes = './assets/img/no-notes.png',
  Trashcan = './assets/img/trashcan.svg',

  OnboardingResult = './assets/img/onboard-result.svg',
  OnboardingTrial = './assets/img/onboarding/trial.svg',
  Task = './assets/img/task.svg',
  Welcome = './assets/img/welcome.svg',

  Envelope = './assets/img/envelope.svg',
  AuthBg = './assets/img/auth-bg.svg',
}

export const onboardingImages = [
  Images.Welcome,
  Images.Task,
  Images.OnboardingResult,
  Images.OnboardingTrial,
];

export const achievementImages = [
  Images.Affiliates,
  Images.Goals,
  Images.Questions,
  Images.MaxStreak1,
  Images.MaxStreak2,
  Images.MaxStreak3,
  Images.MaxStreak4,
  Images.Records1,
  Images.Records2,
  Images.Records3,
  Images.Records4,
  Images.Records5,
];

export const affiliateImages = [
  Images.PartnersCab,
  Images.TablePlaceholder,
  Images.UsersTable,
];

export const passwordRecoveryImages = [Images.GreenDone, Images.Unlink];

export const otherImages = [
  Images.Email,
  Images.Gift,
  Images.Notification,
  Images.NoNotes,
  Images.Trashcan,
  Images.GoalsConstructor,
];

export const mainImages = [...otherImages, ...achievementImages];
