import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PromocodeConfirmApplyComponent } from './promocode-confirm-apply.component';
import { ButtonsModule } from '@ui/buttons/buttons.module';
import { TranslateModule } from '@ngx-translate/core';
import { UiIconModule } from '@ui/ui-icon/ui-icon.module';
import { MatDialogModule } from '@angular/material/dialog';

@NgModule({
  declarations: [PromocodeConfirmApplyComponent],
  exports: [PromocodeConfirmApplyComponent],
  entryComponents: [PromocodeConfirmApplyComponent],
  imports: [
    CommonModule,
    ButtonsModule,
    TranslateModule,
    UiIconModule,
    MatDialogModule,
  ],
})
export class PromocodeConfirmApplyModule {}
