import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiService } from '@core/services/api.service';
import { BackendIdentity } from '@core/models/identity.interface';
import { ApplicationInfoInterface } from '@core/models/applicationInfo.interface';

@Injectable({
  providedIn: 'root',
})
export class UserService extends ApiService {
  /**
   * Обновление профиля и настроек
   */
  update(user: BackendIdentity): Observable<BackendIdentity> {
    const url = this.getApiUrl('users/profile');
    return this.http.put<BackendIdentity>(url, user);
  }

  /**
   * Отписка пользователя от email рассылок
   */
  unsubscribe(email: string): Observable<object> {
    return this.http.put(this.getApiUrl('users/unsubscribe'), { email });
  }

  applicationInfo(data: ApplicationInfoInterface) {
    return this.http.put(this.getApiUrl('users/application-info'), data);
  }
}
