import { PaginationResponse } from './pagination-response';
import { HttpResponse } from '@angular/common/http';

export class PageResult<T> {
  data: T;
  pagination: PaginationResponse;

  constructor(response: HttpResponse<T>, data: T) {
    this.pagination = new PaginationResponse(response);
    this.data = data;
  }
}
