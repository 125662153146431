<button
  (click)="onClose($event)"
  ui-button-icon
  class="delete-category-dialog__close"
  type="button"
>
  <ui-icon class="ui-icon ui-icon_hover_close" name="close"></ui-icon>
</button>
<form class="form" (ngSubmit)="onSubmit($event)">
  <span class="delete-category-dialog__icon"></span>
  <span class="delete-category-dialog__title"
    ><span translate>UX.DELETE_CATEGORY_FORM.DELETING</span> <br />«{{
      category?.name
    }}», <span translate>UX.DELETE_CATEGORY_FORM.NOTES</span>: {{ noteCount }}
    <span translate>UX.DELETE_CATEGORY_FORM.PIECES</span></span
  >
  <span class="delete-category-dialog__description" translate
    >UX.DELETE_CATEGORY_FORM.WHERE</span
  >
  <label class="label" translate>UX.DELETE_CATEGORY_FORM.CHOOSE</label>
  <ui-select
    class="delete-category-dialog__select"
    [editable]="false"
    [insideMatDialog]="true"
    [formControl]="categoryIdControl"
  >
    <ui-option
      *ngFor="let option of categoriesList"
      [value]="option.id"
      [editable]="false"
      ><ui-icon
        class="ui-icon ui-icon_hover_close"
        [name]="option.icon"
      ></ui-icon>
      {{ option.name }}
    </ui-option>
  </ui-select>
  <button ui-button-primary class="delete-category-dialog__btn">
    {{ '$.APPLY' | translate }}
  </button>
</form>
