import { Directive, ElementRef, HostListener } from '@angular/core';
import { Router } from '@angular/router';

@Directive({
  selector: '[innerHtmlLink]',
})
export class InnerHtmlLinkDirective {
  constructor(private el: ElementRef, private router: Router) {}

  @HostListener('click', ['$event'])
  public onClick(event) {
    if (event.target.tagName === 'A') {
      const routerLink = event.target.getAttribute('routerlink');
      if (routerLink) {
        this.router.navigateByUrl(routerLink);
        event.preventDefault();
      }
      event.stopPropagation();
    } else {
      return;
    }
  }
}
