import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ErrorPopupComponent } from './error-popup.component';
import { InfoPopupModule } from '@ui/info-popup';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [ErrorPopupComponent],
  imports: [CommonModule, InfoPopupModule, TranslateModule],
  entryComponents: [ErrorPopupComponent],
})
export class ErrorPopupModule {}
