<div class="notifications__header">
  <h3 class="notifications__title">
    {{ 'INFO_DIALOGS.NOTIFICATIONS.TITLE' | translate }}
  </h3>
  <button
    (click)="onCloseClick()"
    ui-button-icon
    class="new-level__close"
    type="button"
  >
    <ui-icon class="ui-icon ui-icon_hover_close" name="close"></ui-icon>
  </button>
</div>
<div
  *ngFor="let group of notificationGroups$ | async"
  class="notifications__group"
>
  <div class="notifications__group-header">
    {{ group.date | translate }}
  </div>
  <ng-container
    *ngFor="let notification of group.notifications"
    [ngSwitch]="notification.type"
  >
    <app-set-notifications
      *ngSwitchCase="'set-notifications'"
      (navigated)="onNavigationToDownloads()"
    ></app-set-notifications>
  </ng-container>
</div>
<div
  *ngIf="(notificationGroups$ | async)?.length === 0"
  class="notifications__placeholder"
>
  <ui-icon
    class="ui-icon"
    width="172"
    height="172"
    name="nnotification"
    class="notifications__placeholder-img"
  ></ui-icon>
  Уведомлений нет
</div>
