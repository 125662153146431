import { Injectable } from '@angular/core';
import { Actions, createEffect, Effect, ofType } from '@ngrx/effects';
import { of } from 'rxjs/internal/observable/of';
import { catchError, exhaustMap, map, switchMap, tap } from 'rxjs/operators';
import {
  TargetActionTypes,
  TargetCreateAction,
  TargetCreateFailureAction,
  TargetCreateSuccessAction,
  TargetDeleteAction,
  TargetDeleteFailure,
  TargetDeleteSuccess,
  TargetLoadFailure,
  TargetLoadSuccess,
  TargetUpdateAction,
  TargetUpdateFailureAction,
  TargetUpdateSuccessAction,
  TargetViewAction,
  TargetViewFailureAction,
  TargetViewSuccessAction,
} from './target.actions';
import { GoalsHttpService } from '@core/services/goals-http.service';
import { TargetInterface } from '@core/models/target.interface';
import { AmplitudeService } from '@core/services/amplitude.service';
import {
  changeGoalStepsGroupingAction,
  changeGoalStepsGroupingFailureAction,
  changeGoalStepsGroupingSuccessAction,
} from './goals.actions';
import { AnalyticsService } from '@core/services/analytics.service';

/**
 * Effect для работы с целями
 */
@Injectable()
export class TargetEffects {
  /**
   * Поиск целей
   */
  @Effect()
  search$ = this.actions$.pipe(
    ofType(TargetActionTypes.TargetLoad),
    exhaustMap(() => {
      return this.goalsHttp.search().pipe(
        map((targets) => new TargetLoadSuccess(targets)),
        catchError((errors) => of(new TargetLoadFailure(errors))),
      );
    }),
  );

  /**
   * Создание новой цели
   */
  @Effect()
  create$ = this.actions$.pipe(
    ofType(TargetActionTypes.TargetCreateAction),
    map((action: TargetCreateAction) => action.payload),
    exhaustMap((model: TargetInterface) => {
      return this.goalsHttp.create(model).pipe(
        map((response) => new TargetCreateSuccessAction(response)),
        catchError((errors) => of(new TargetCreateFailureAction(errors))),
      );
    }),
  );

  /**
   * Обновление цели
   */
  @Effect()
  update$ = this.actions$.pipe(
    ofType(TargetActionTypes.TargetUpdateAction),
    map((action: TargetUpdateAction) => action.payload),
    exhaustMap((model: TargetInterface) => {
      return this.goalsHttp.update(model).pipe(
        map((response) => new TargetUpdateSuccessAction(response)),
        catchError((errors) => of(new TargetUpdateFailureAction(errors))),
      );
    }),
  );

  updateStepsGroupingView$ = createEffect(() =>
    this.actions$.pipe(
      ofType(changeGoalStepsGroupingAction),
      switchMap((action) =>
        this.goalsHttp.updateStepsGroupingView(action.goalId, action.view).pipe(
          map((goal) => changeGoalStepsGroupingSuccessAction({ goal })),
          catchError((httpError) =>
            of(changeGoalStepsGroupingFailureAction({ httpError })),
          ),
        ),
      ),
    ),
  );

  @Effect()
  view$ = this.actions$.pipe(
    ofType(TargetActionTypes.TargetViewAction),
    map((action: TargetViewAction) => action.id),
    exhaustMap((id: string) => {
      return this.goalsHttp.view(id).pipe(
        map((response) => new TargetViewSuccessAction(response)),
        catchError((errors) => of(new TargetViewFailureAction(errors))),
      );
    }),
  );

  @Effect()
  delete$ = this.actions$.pipe(
    ofType(TargetActionTypes.TargetDelete),
    map((action: TargetDeleteAction) => action.payload),
    exhaustMap((id: string) => {
      return this.goalsHttp.destroy(id).pipe(
        map((response) => new TargetDeleteSuccess(response)),
        catchError((errors) => of(new TargetDeleteFailure(errors))),
      );
    }),
  );

  @Effect({ dispatch: false })
  createSuccess$ = this.actions$.pipe(
    ofType(TargetActionTypes.TargetCreateSuccessAction),
    tap(() => {
      this.amplitudeService.logEvent('Цель поставлена');
      this.analyticsService.goalCreated();
    }),
  );

  constructor(
    private readonly actions$: Actions,
    private readonly amplitudeService: AmplitudeService,
    private readonly goalsHttp: GoalsHttpService,
    private readonly analyticsService: AnalyticsService,
  ) {}
}
