import { Action, createReducer, on } from '@ngrx/store';
import { showUpdateAlert } from './release.actions';

export const releaseFeatureKey = 'release';

export interface State {
  shouldShowUpdateAlert: boolean;
}

export const initialState: State = {
  shouldShowUpdateAlert: false,
};

const releaseReducer = createReducer<State>(
  initialState,
  on(showUpdateAlert, () => ({ shouldShowUpdateAlert: true })),
);

export function reducer(state: State, action: Action) {
  return releaseReducer(state, action);
}

export const getShouldShowUpdateAlert = (state: State) =>
  state.shouldShowUpdateAlert;
