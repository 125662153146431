declare const electron;

export const inElectron = typeof electron === 'object';

export const nativeOS = inElectron && electron.platform;

export const sendToElectron = inElectron && electron.send;

export const receiveFromElectron = inElectron && electron.receive;

/**
 * Возвращает адрес, начинающийся с logtime:// вместо https://
 */
export function changeToNativeProtocol(httpsUrl: string): string {
  if (httpsUrl.startsWith('https')) {
    return 'logtime' + httpsUrl.slice('https'.length);
  } else {
    return httpsUrl;
  }
}
