import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UiSelectComponent } from '@ui/ui-select/ui-select.component';
import { ClickOutsideModule } from '@shared/directives/click-outside/click-outside.module';
import { TranslateModule } from '@ngx-translate/core';
import { UiOptionModule } from './ui-option/ui-option.module';
import { UiIconModule } from '@ui/ui-icon/ui-icon.module';
import { NgxPopper } from 'angular-popper';
import { SelectFormHeaderModule } from '@ui/select-form-header/select-form-header.module';

@NgModule({
  declarations: [UiSelectComponent],
  exports: [UiSelectComponent, UiOptionModule],
  imports: [
    CommonModule,
    ClickOutsideModule,
    TranslateModule,
    UiIconModule,
    UiOptionModule,
    NgxPopper,
    SelectFormHeaderModule,
  ],
})
export class UiSelectModule {}
