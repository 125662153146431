import { Injectable } from '@angular/core';
import * as uuid from 'uuid';

@Injectable({
  providedIn: 'root',
})
export class DataVersionService {
  private versionHash = uuid.v4();

  get version() {
    return this.versionHash;
  }
}
