import {
  Component,
  Input,
  OnChanges,
  ViewChild,
  AfterViewInit,
  ViewChildren,
  QueryList,
  OnDestroy,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
} from '@angular/core';
import { FileInterface } from '@core/models/file.interface';
import { NoImagesException } from './no-images-exception';
import { NgxTinySliderSettingsInterface } from 'ngx-tiny-slider';
import { NgxTinySliderComponent } from 'ngx-tiny-slider/lib/ngx-tiny-slider.component';
import { Subscription } from 'rxjs';
import { trackById } from '@shared/functions/trackBy';

@Component({
  selector: 'ui-note-view-slider',
  templateUrl: './ui-note-view-slider.component.html',
  styleUrls: ['./ui-note-view-slider.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UiNoteViewSliderComponent
  implements AfterViewInit, OnChanges, OnDestroy {
  @Input()
  images: FileInterface[];

  @ViewChild('slider')
  slider: NgxTinySliderComponent;

  @ViewChildren('slideList')
  slideList: QueryList<HTMLDivElement>;

  tinySliderConfig: NgxTinySliderSettingsInterface = {
    items: 1,
    arrowKeys: true,
    lazyload: true,
    touch: true,
    mouseDrag: true,
    nav: false,
    controls: false,
    waitForDom: true,
  };

  activeIndex = 0;

  trackById = trackById;

  private readonly subscription = new Subscription();

  constructor(private changeDetector: ChangeDetectorRef) {}

  ngAfterViewInit() {
    this.slidesReadyRender();
    this.slider.sliderInstance.events.on('indexChanged', (info) => {
      this.activeIndex = info.displayIndex - 1;
      this.changeDetector.markForCheck();
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  ngOnChanges() {
    if (this.images.length === 0) {
      throw new NoImagesException();
    }
  }

  onSelectImage(index: number | 'next' | 'prev') {
    this.slider.sliderInstance.goTo(index);
  }

  private slidesReadyRender() {
    this.slider.domReady.next();
    this.subscription.add(
      this.slideList.changes.subscribe(() => {
        this.slider.domReady.next();
      }),
    );
  }
}
