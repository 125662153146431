import {
  MatDialogRef,
  DialogPosition,
  MatDialogConfig,
} from '@angular/material/dialog';
import { fromEvent, merge, Subscription } from 'rxjs';
import { filter, map, pairwise } from 'rxjs/operators';
import { NoopScrollStrategy } from '@angular/cdk/overlay';

export enum DialogRole {
  Primary,
  Secondary,
}

const globalScrollPanelClass = 'matdialog__panel_global-scroll';
const mobileThreshold = 650;
const mobilePosition = {
  bottom: '0',
  left: '0',
  right: '0',
};
const desktopPosition = {
  bottom: undefined,
  left: undefined,
  right: undefined,
};
const ngContainerIdCommonPart = 'mat-dialog-';
const ngContainerClass = 'mat-dialog-container';
const ngContainerNumberStart = ngContainerIdCommonPart.length;

export function isMobile(): boolean {
  return window.innerWidth <= mobileThreshold;
}

export function getDialogConfig(
  changePosition: boolean,
  mobile: boolean,
  customConfig: MatDialogConfig,
): MatDialogConfig {
  let panelClass: string = globalScrollPanelClass;
  let position: DialogPosition = {};
  if (changePosition) {
    position = getPosition(mobile);
    panelClass = mobile ? '' : globalScrollPanelClass;
  }
  return {
    scrollStrategy: new NoopScrollStrategy(),
    panelClass,
    position,
    ...customConfig,
  };
}

/**
 * Проверяет, есть ли диалоги, открытые поверх этого
 *
 * @param dialogContainerId
 * HTML id mat-dialog-container диалога, наличие диалогов поверх которого проверить
 */
function checkIfHasFrontDialog(dialogContainerId: string): boolean {
  const idNum = parseInt(dialogContainerId.slice(ngContainerNumberStart), 10);
  const allDialogs = document.getElementsByClassName(ngContainerClass);
  let hasFrontDialog = false;
  for (let i = 0; i < allDialogs.length; i++) {
    const dialog = allDialogs.item(i);
    const dialogNum = parseInt(dialog.id.slice(ngContainerNumberStart), 10);
    if (dialogNum > idNum) {
      hasFrontDialog = true;
      break;
    }
  }
  return hasFrontDialog;
}

/**
 * Закрывает диалог при клике снаружи
 *
 * @returns Подписка на клик
 */
export function closeOnClickOutside(
  dialogRef: MatDialogRef<any>,
): Subscription {
  const id = dialogRef._containerInstance._id;
  const container = document.getElementById(id);
  // Не клик, чтобы когда делаешь вначале клик в диалоге,
  // а отпускаешь мышь вне диалога, чтобы диалог тогда не закрылся
  return merge(
    fromEvent(document.body, 'mousedown'),
    fromEvent(document.body, 'mouseup'),
  )
    .pipe(
      pairwise(),
      filter(
        ([mousedown, mouseup]) =>
          mousedown.type === 'mousedown' &&
          mouseup.type === 'mouseup' &&
          mouseup.target === mousedown.target,
      ),
      map(([e]) => e),
    )
    .subscribe((event: MouseEvent) => {
      const target = event.target as HTMLElement;
      const clickedOnTooltip = target.closest('.tooltip__tip');
      const hasFrontDialog = checkIfHasFrontDialog(id);
      if (
        !hasFrontDialog &&
        // тултипы вставляются в конец body, т.е. находятся не в диалоге
        // поэтому без такой проверки клики по тултипам закрывают диалоги
        !clickedOnTooltip &&
        // иногда после клика элемент может исчезнуть,
        // в этих случаях container.contains(target)
        // всегда будет false, так что такие случаи нужно пропустить
        document.body.contains(target) &&
        (!container.contains(target) || target === container)
      ) {
        dialogRef.close();
      }
    });
}

function getPosition(mobile: boolean): DialogPosition {
  return mobile ? mobilePosition : desktopPosition;
}
