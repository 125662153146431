import { Injectable } from '@angular/core';
import { ApiService } from '@core/services/api.service';
import { BackendIdentity } from '@core/models/identity.interface';
import { SignUpInterface } from '@core/models/sign-up.interface';
import { SignInInterface } from '@core/models/sign-in.interface';
import { SignInSocialInterface } from '@core/models/sign-in-social.interface';
import { AddAccountInterface } from '@core/models/add-account.interface';

@Injectable({
  providedIn: 'root',
})
export class AuthService extends ApiService {
  getSocialAuthUrl(provider, isLink = false) {
    return this.http.get<{ url: string }>(
      this.getApiUrl(
        `authentications/social/auth-url/${provider}?isLink=${
          isLink ? true : ''
        }`,
      ),
    );
  }

  getUser() {
    return this.http.get<BackendIdentity>(
      this.getApiUrl('authentications/get-user'),
    );
  }

  addAccount(model: AddAccountInterface) {
    return this.http.post<BackendIdentity>(
      this.getApiUrl('authentications/social/add-account'),
      model,
    );
  }

  signUp(model: SignUpInterface) {
    return this.http.post<BackendIdentity>(
      this.getApiUrl('authentications/sign-up'),
      model,
      {
        withCredentials: true,
      },
    );
  }

  signOut() {
    return this.http.delete(this.getApiUrl('authentications/sign-out'));
  }

  signIn(model: SignInInterface) {
    return this.http.post<BackendIdentity>(
      this.getApiUrl('authentications/sign-in'),
      model,
    );
  }

  signInBySocial(model: SignInSocialInterface) {
    return this.http.post<BackendIdentity>(
      this.getApiUrl(`authentications/social/${model.provider}`),
      model,
    );
  }

  setEmail(model: { email: string }) {
    return this.http.put<BackendIdentity>(
      this.getApiUrl(`authentications/set-email`),
      model,
    );
  }

  confirmation(token: string) {
    return this.http.get<BackendIdentity>(
      this.getApiUrl(`authentications/confirmation?token=${token}`),
    );
  }

  reSendConfirmationEmail() {
    return this.http.post(
      this.getApiUrl(`authentications/re-send-confirmation-email`),
      {},
    );
  }

  passwordRecovery(email: string) {
    return this.http.post(this.getApiUrl(`authentications/password-recovery`), {
      email,
      target: 'web',
    });
  }

  changePassword(newPassword: string) {
    return this.http.put<BackendIdentity>(
      this.getApiUrl('authentications/change-password'),
      { newPassword },
    );
  }

  checkPasswordResetToken(token: string) {
    return this.http.get<BackendIdentity>(
      this.getApiUrl(
        'authentications/confirmation-reset-password?token=' + token,
      ),
    );
  }

  checkEmail(email: string) {
    return this.http.get(
      this.getApiUrl(`authentications/check-email?email=${email}`),
    );
  }
}
