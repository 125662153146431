<info-popup-content (actionClick)="onCloseClick()" [action]="'$.CLOSE'">
  <div class="info-popup__text">
    <h3 class="info-popup__congratulation">
      {{ header | translate }}
    </h3>
    <div class="info-popup__description">
      {{ text | translate: { message: message } }}
    </div>
  </div>
</info-popup-content>
