import { select, Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import * as fromRoot from '@core/redux/index';
import {
  GetDefaultAction,
  GetDefaultActionSuccessAction,
} from '@core/redux/tariff-group/tariff-group.actions';
import { filter } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { TariffGroupInterface } from '@core/models/tariff-group.interface';

@Injectable({
  providedIn: 'root',
})
export class TariffGroupService {
  constructor(private store: Store<fromRoot.State>) {}

  getDefaultGroup(): Observable<TariffGroupInterface> {
    return this.store.pipe(
      select(fromRoot.getTariffGroupDefault),
      filter(_ => !!_),
    );
  }

  /**
   *
   */
  dispatchDefault() {
    this.store.dispatch(new GetDefaultAction());
  }

  dispatchDefaultSuccess(data) {
    this.store.dispatch(new GetDefaultActionSuccessAction(data));
  }
}
