<div class="qiwi">
  <!-- TODO: добавить функционал для кнопки закрыть -->
  <button ui-button-icon class="qiwi__close" type="button">
    <ui-icon class="ui-icon ui-icon_hover_close" name="close"></ui-icon>
  </button>

  <div class="qiwi__img">
    <ui-icon class="ui-icon" name="qiwi" width="100" height="100"></ui-icon>
  </div>

  <div class="qiwi__form">
    <div class="field form-group_big">
      <span class="label">Номер счета qiwi кошелька</span>
      <input type="text" class="input" placeholder="+" />
    </div>

    <div class="qiwi__btn">
      <button ui-button-primary>Продолжить</button>
    </div>
  </div>
</div>
