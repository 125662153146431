import { NgModule } from '@angular/core';
import { NewLevelModule } from './new-level/new-level.module';
import { NotificationsModule } from './notifications/notifications.module';
import { IconPopupModule } from './icon-popup/icon-popup.module';
import { ErrorPopupModule } from './error-popup';

@NgModule({
  exports: [
    NewLevelModule,
    NotificationsModule,
    IconPopupModule,
    ErrorPopupModule,
  ],
})
export class InfoPopupsModule {}
