export enum EnvironmentName {
  Prod = 'production',
  Staging = 'staging',
  Dev = 'dev',
}

export interface Environment {
  name: EnvironmentName;
  sentry: boolean;
  production: boolean;
  apiUrl: string;
  vapidKey: string;
  socket: {
    url: string;
    options: object;
  };
  amplitude: false | string;
  yaCounterId: number;
  electron: {
    storage: string;
    nativeApp: {
      latestReleaseFile: {
        mac: string;
      };
    };
  };
}
