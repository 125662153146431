import { Injectable } from '@angular/core';
import { QiwiComponent } from './qiwi.component';
import { DialogService } from '@ui/dialog';

@Injectable({
  providedIn: 'root',
})
export class QiwiDialogService {
  constructor(private readonly dialog: DialogService) {}

  open() {
    return this.dialog.open(QiwiComponent, {}, true);
  }
}
