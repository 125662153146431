import { Pipe, PipeTransform, Inject, LOCALE_ID } from '@angular/core';
import { DecimalPipe } from '@angular/common';

@Pipe({
  name: 'signedDecimal',
})
export class SignedDecimalPipe implements PipeTransform {
  private decimalPipe: DecimalPipe;

  constructor(@Inject(LOCALE_ID) localeId) {
    this.decimalPipe = new DecimalPipe(localeId);
  }

  transform(n: number): string {
    const sign = n > 0 ? '+' : '-';
    const value = this.decimalPipe.transform(Math.abs(n));
    return sign + value;
  }
}
