import { Action } from '@ngrx/store';
import {
  InvoiceInterface,
  NewInvoiceInterface,
} from '@core/models/invoice.interface';

export enum InvoiceActionTypes {
  InvoiceLoad = '[Invoice] Load',
  InvoiceLoadSuccess = '[Invoice] Load Success',
  InvoiceLoadFailure = '[Invoice] Load Failure',
  InvoiceCreate = '[Invoice] Create',
  InvoiceCreateSuccess = '[Invoice] Create Success',
  InvoiceCreateFailure = '[Invoice] Create Failure',
}

export class InvoiceLoad implements Action {
  readonly type = InvoiceActionTypes.InvoiceLoad;

  constructor() {}
}

export class InvoiceLoadSuccess implements Action {
  readonly type = InvoiceActionTypes.InvoiceLoadSuccess;

  constructor(public payload: InvoiceInterface[]) {}
}

export class InvoiceLoadFailure implements Action {
  readonly type = InvoiceActionTypes.InvoiceLoadFailure;

  constructor(public payload: any) {}
}

export class InvoiceCreate implements Action {
  readonly type = InvoiceActionTypes.InvoiceCreate;

  constructor(public payload: NewInvoiceInterface) {}
}

export class InvoiceCreateSuccess implements Action {
  readonly type = InvoiceActionTypes.InvoiceCreateSuccess;

  constructor(public payload: InvoiceInterface) {}
}

export class InvoiceCreateFailure implements Action {
  readonly type = InvoiceActionTypes.InvoiceCreateFailure;

  constructor(public payload: any) {}
}

export type InvoiceActions =
  | InvoiceLoad
  | InvoiceLoadSuccess
  | InvoiceLoadFailure
  | InvoiceCreate
  | InvoiceCreateSuccess
  | InvoiceCreateFailure;
