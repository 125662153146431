import { Component, OnInit } from '@angular/core';
import { AuthService } from '@core/redux/auth/auth.service';
import { InfoPopupRef } from '@ui/info-popup';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { NotificationsPopupResult } from './notifications-popup.service';

const onboardingNotification: NotificationGroup = {
  date: 'Сегодня',
  notifications: [
    {
      type: 'set-notifications',
    },
  ],
};

export interface NotificationGroup {
  date: string;
  notifications: { type: string }[];
}

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.less'],
})
export class NotificationsComponent implements OnInit {
  notificationGroups$: Observable<NotificationGroup[]>;
  constructor(
    private readonly authService: AuthService,
    private readonly infoPopupRef: InfoPopupRef<
      NotificationsComponent,
      NotificationsPopupResult
    >,
  ) {}

  ngOnInit() {
    this.notificationGroups$ = this.authService
      .getUser()
      .pipe(
        map((user) =>
          user.onboarding.actions.find(
            (a) => a.action === 'show-popup:notification-settings',
          ) || !user.isConfirmation
            ? [onboardingNotification]
            : [],
        ),
      );
  }

  onCloseClick() {
    this.infoPopupRef.close({
      navigatedToDownloads: false,
    });
  }

  onNavigationToDownloads() {
    this.infoPopupRef.close({
      navigatedToDownloads: true,
    });
  }
}
