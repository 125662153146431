<div class="offline-notification__icon">
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle opacity="0.3" cx="16" cy="16" r="16" fill="currentColor" />
    <path
      d="M15.9999 20.2188C16.6064 20.2188 17.0999 20.6954 17.0999 21.2813C17.0999 21.8671 16.6064 22.3438 15.9999 22.3438C15.3933 22.3438 14.8999 21.8671 14.8999 21.2813C14.8999 20.6954 15.3933 20.2188 15.9999 20.2188ZM15.9999 18.625C14.4811 18.625 13.2499 19.8142 13.2499 21.2813C13.2499 22.7483 14.4811 23.9375 15.9999 23.9375C17.5186 23.9375 18.7499 22.7483 18.7499 21.2813C18.7499 19.8142 17.5186 18.625 15.9999 18.625ZM23.0839 17.568C23.2436 17.4063 23.2353 17.1484 23.0636 16.9985C19.0582 13.5016 12.9439 13.4995 8.9361 16.9985C8.7644 17.1484 8.75605 17.4063 8.91586 17.568L9.48569 18.1447C9.64007 18.3009 9.89451 18.3091 10.0609 18.1647C13.4331 15.2386 18.5634 15.2358 21.9389 18.1647C22.1052 18.3091 22.3596 18.3009 22.514 18.1447L23.0839 17.568ZM26.8528 13.1634C20.7329 7.69095 11.2566 7.70011 5.14691 13.1634C4.97734 13.3151 4.96772 13.5709 5.12729 13.7324L5.69722 14.3091C5.85236 14.4661 6.10948 14.4747 6.27448 14.3274C11.7538 9.43411 20.2417 9.43029 25.7252 14.3274C25.8902 14.4747 26.1473 14.4661 26.3025 14.3091L26.8724 13.7324C27.032 13.5709 27.0224 13.3151 26.8528 13.1634Z"
      fill="currentColor"
    />
  </svg>
</div>
<div class="offline-notification__title">
  {{ 'UI.OFFLINE_NOFITICATION.TITLE' | translate }}
</div>
<div class="offline-notification__refresh">
  <button
    (click)="onRefreshClick()"
    class="offline-notification__refresh-button"
    type="button"
  >
    {{ 'UI.OFFLINE_NOFITICATION.REFRESH' | translate }}
  </button>
</div>
<button
  (click)="onCloseClick()"
  class="offline-notification__close-button"
  type="button"
>
  <ui-icon class="ui-icon ui-icon_hover_close" name="close"></ui-icon>
</button>
