import { Action, createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { TargetImageInterface } from '@core/models/target-image.interface';
import * as TargetImageActions from '@core/redux/target-image/target-image.actions';

export const targetImageFeatureKey = 'targetImage';

export interface State extends EntityState<TargetImageInterface> {
  loading: boolean;
}

export const adapter = createEntityAdapter<TargetImageInterface>({
  selectId: (image: any) => image.id,
  sortComparer: false,
});

export const initialState: State = adapter.getInitialState({
  loading: false,
});

export const targetImageReducer = createReducer(
  initialState,
  on(TargetImageActions.targetImageLoad, state => ({
    ...state,
    loading: true,
  })),
  on(TargetImageActions.targetImageLoadSuccess, (state, { images }) =>
    adapter.addMany(images, state),
  ),
  on(TargetImageActions.targetImageLoadFailure, state => ({
    ...state,
    loading: true,
  })),
);

export function reducer(state: State | undefined, action: Action) {
  return targetImageReducer(state, action);
}

export const { selectAll: getAll } = adapter.getSelectors();
