import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UiPopupCategoryFormComponent } from './category-form-body.component';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { AutoFocusModule } from '@shared/directives/auto-focus/auto-focus.module';
import { UiIconModule } from '@ui/ui-icon/ui-icon.module';
import { ButtonsModule } from '@ui/buttons/buttons.module';
import { IconSelectorModule } from '@ui/icon-selector/icon-selector.module';

@NgModule({
  declarations: [UiPopupCategoryFormComponent],
  exports: [UiPopupCategoryFormComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TranslateModule,
    AutoFocusModule,
    UiIconModule,
    ButtonsModule,
    IconSelectorModule,
  ],
})
export class UiPopupCategoryFormModule {}
