import { Pipe, PipeTransform } from '@angular/core';
import { SafeStyle, DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'backgroundImage',
})
export class BackgroundImagePipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(url: string): SafeStyle {
    return this.sanitizer.bypassSecurityTrustStyle(`url('${url}')`);
  }
}
