import { Injectable } from '@angular/core';
import { NoteComponent, NoteDialogData } from './note.component';
import { DialogService } from '@ui/dialog';
import { MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { NoteFormService } from './note-form/services/note-form.service';
import { ConfirmationDialogService } from '@dialogs/confirmation-dialog';
import { getConfirmDialogConfigForNote } from './confirm-dialog-config';
import { NoteInterface, getEmptyNote } from '@core/models/note.interface';
import { NoteComponentService } from './note-component.service';
import { Story } from '@core/redux/story/story.model';
import { DeviceDetectorService } from 'ngx-device-detector';

@Injectable({
  providedIn: 'root',
})
export class NoteDialogService {
  constructor(
    private readonly confirmationDialogService: ConfirmationDialogService,
    private readonly dialog: DialogService,
    private readonly deviceDetector: DeviceDetectorService,
    private readonly noteComponentService: NoteComponentService,
    private readonly noteFormService: NoteFormService,
  ) {}

  create(
    note: NoteInterface = getEmptyNote(),
    withOnboarding = false,
    story?: Story,
  ) {
    this.noteComponentService.startEditing();
    this.noteComponentService.updateViewWith(note);
    this.noteFormService.updateFormWith(note);
    return this.open(withOnboarding, story);
  }

  restore() {
    this.noteComponentService.startEditing();
    this.noteFormService.updateFormWith(undefined);
    return this.open();
  }

  update(noteId: string) {
    this.noteComponentService.startEditing();
    this.noteComponentService.updateViewWith(noteId);
    this.noteFormService.updateFormWith(noteId);
    return this.open();
  }

  view(noteId: string) {
    this.noteComponentService.stopEditing();
    this.noteComponentService.updateViewWith(noteId);
    this.noteFormService.updateFormWith(noteId);
    return this.open();
  }

  private askConfirmationIfClosedWhenDirty(dialogRef: MatDialogRef<any>) {
    dialogRef.afterClosed().subscribe(() => {
      if (this.noteFormService.isDirty()) {
        this.confirmationDialogService.open(
          getConfirmDialogConfigForNote(this),
        );
      }
    });
    return dialogRef;
  }

  private open(withOnboarding = false, story?: Story) {
    const config: MatDialogConfig<NoteDialogData> = {
      data: {
        withOnboarding,
        story,
      },
      autoFocus: false,
      disableClose: withOnboarding,
      // На мобильных vh нельзя доверять.
      // vh всегда имеет фиксированную величину,
      // хотя размер экрана может меняться в зависимости
      // от наличия адресной строки
      maxHeight: this.deviceDetector.isDesktop() ? '100vh' : window.innerHeight,
      closeOnNavigation: false,
    };
    const dialogRef = this.dialog.open(NoteComponent, config, false);
    return this.openAgainIfAsked(
      this.askConfirmationIfClosedWhenDirty(dialogRef),
    );
  }

  private openAgainIfAsked(dialogRef: MatDialogRef<any>) {
    dialogRef.afterClosed().subscribe((result) => {
      if (result && !this.noteFormService.isDirty()) {
        const note = getEmptyNote();
        for (const fieldName of ['day', 'month', 'year', 'isTask']) {
          note[fieldName] = this.noteFormService.form.get(fieldName).value;
        }
        this.create(note);
      }
    });
    return dialogRef;
  }
}
