import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import * as fromRoot from '@core/redux/index';
import {
  selectNewStories,
  selectHasStoryToView,
  selectStories,
  selectStoriesIsLoadingFirstTime,
} from './story.selectors';
import { getStories, markStoryAsViewed } from './story.actions';

@Injectable({
  providedIn: 'root',
})
export class StoryService {
  constructor(private store: Store<fromRoot.State>) {}

  get() {
    this.store.dispatch(getStories());
  }

  markAsViewed(id: string) {
    this.store.dispatch(markStoryAsViewed({ id }));
  }

  newStories() {
    return this.store.pipe(select(selectNewStories));
  }

  stories() {
    return this.store.pipe(select(selectStories));
  }

  hasStoriesToView() {
    return this.store.pipe(select(selectHasStoryToView));
  }

  isLoadingFirstTime() {
    return this.store.pipe(select(selectStoriesIsLoadingFirstTime));
  }
}
