import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { first, flatMap, tap } from 'rxjs/operators';
import { AuthService } from '@core/redux/auth/auth.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class TokenInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    if (
      !request.url.startsWith(environment.apiUrl) ||
      request.url.startsWith(environment.electron.storage)
    ) {
      return next.handle(request);
    }
    return this.authService.getAccessToken().pipe(
      first(),
      flatMap((token) => {
        const params: { [key: string]: string } = {};

        if (token) {
          params['access-token'] = token;
        }

        request = request.clone({
          setParams: params,
          withCredentials: true,
        });

        return next.handle(request).pipe(
          tap(
            (event: HttpEvent<any>) => {
              if (event instanceof HttpResponse) {
                if (event.status === 302) {
                  console.error('[HttpRequest] Редирект');
                }
              }
            },
            (err: any) => {
              if (err instanceof HttpErrorResponse) {
                if (err.status === 401) {
                  this.authService.signOut();
                }
              }
            },
          ),
        );
      }),
    );
  }
}
