import {
  Directive,
  ElementRef,
  Input,
  OnInit,
  Inject,
  PLATFORM_ID,
  HostListener,
} from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

const autoFocusEventMarker = '__AutoFocusDirective__';

@Directive({
  selector: '[autoFocus]',
})
export class AutoFocusDirective implements OnInit {
  static wasAutoFocused(event: FocusEvent): boolean {
    return event[autoFocusEventMarker] || false;
  }

  @Input() set autoFocus(condition: boolean) {
    this.focus = condition !== false;
    this.setFocus();
  }

  private focus = true;

  private waitingForEvent = false;

  constructor(
    private el: ElementRef,
    @Inject(PLATFORM_ID) private platformId: object,
  ) {}

  ngOnInit() {
    this.setFocus();
  }

  @HostListener('focus', ['$event'])
  onFocus(event: FocusEvent) {
    if (this.waitingForEvent) {
      event[autoFocusEventMarker] = true;
    }
    this.waitingForEvent = false;
  }

  setFocus(): void {
    if (this.focus && isPlatformBrowser(this.platformId)) {
      window.setTimeout(() => {
        this.waitingForEvent = true;
        this.el.nativeElement.focus();
      });
    }
  }
}
