import { Component, ChangeDetectionStrategy } from '@angular/core';
import { inElectron, sendToElectron } from 'src/app/helpers/consts/electron';

@Component({
  selector: 'ui-release-alert',
  templateUrl: './ui-release-alert.component.html',
  styleUrls: ['./ui-release-alert.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UiReleaseAlertComponent {
  readonly msg = inElectron
    ? 'UPDATE_ALERT.ELECTRON_MESSAGE'
    : 'UPDATE_ALERT.BROWSER_MESSAGE';

  reload() {
    if (inElectron) {
      sendToElectron('toElectron:reload');
    } else {
      location.reload();
    }
  }
}
