import { Injectable } from '@angular/core';
import { ApiService } from '@core/services/api.service';
import { NoteInterface } from '@core/models/note.interface';
import { BillingInformationInterface } from '@core/models/billing-information.interface';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/operators';

/**
 * Сервис для работы с способами оплаты
 */
@Injectable({
  providedIn: 'root',
})
export class BillingInformationService extends ApiService {
  /**
   * Получить с сервера все способы оплаты
   */
  search(): Observable<BillingInformationInterface[]> {
    return this.http.get<BillingInformationInterface[]>(
      this.getApiUrl(`billing-informations`),
    );
  }

  /**
   * Удалить запись
   */
  delete(model: BillingInformationInterface): Observable<any> {
    return this.http.delete(this.getApiUrl(`billing-informations/${model.id}`));
  }
}
