import {
  Component,
  OnInit,
  Inject,
  ChangeDetectionStrategy,
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  ConfirmationDialogData,
  OnCloseActions,
} from './confirmation-dialog-config';

enum ConfirmState {
  Accepted,
  Discarded,
  Closed,
}

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmationDialogComponent implements OnInit {
  state: ConfirmState = ConfirmState.Closed;

  constructor(
    @Inject(MAT_DIALOG_DATA) public readonly data: ConfirmationDialogData,
    private dialogRef: MatDialogRef<ConfirmationDialogComponent>,
  ) {}

  ngOnInit() {
    this.dialogRef.afterClosed().subscribe(() => {
      const shouldAccept =
        this.state === ConfirmState.Accepted ||
        (this.state === ConfirmState.Closed &&
          this.data.onClose === OnCloseActions.Accept);
      if (shouldAccept) {
        this.data.accept.action();
      } else {
        this.data.discard.action();
      }
    });
  }

  onAccept() {
    this.state = ConfirmState.Accepted;
    this.close();
  }

  onClose() {
    this.close();
  }

  onDiscard() {
    this.state = ConfirmState.Discarded;
    this.close();
  }

  private close() {
    this.dialogRef.close();
  }
}
