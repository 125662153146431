<div class="circle-progress">
  <svg viewBox="0 0 36 36" class="circle-progress__chart">
    <path
      class="circle-progress__bg"
      d="M18 2.0845
        a 15.9155 15.9155 0 0 1 0 31.831
        a 15.9155 15.9155 0 0 1 0 -31.831"
    />
    <path
      class="circle-progress__circle"
      [attr.stroke-dasharray]="percent + ', 100'"
      d="M18 2.0845
        a 15.9155 15.9155 0 0 1 0 31.831
        a 15.9155 15.9155 0 0 1 0 -31.831"
    />
    <text x="18" y="22.35" class="circle-progress__percentage">
      {{ progress }}
    </text>
  </svg>
</div>
