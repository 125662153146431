import { Injectable } from '@angular/core';
import { inElectron } from '@helpers/consts/electron';
import { CookieService, CookieOptions } from 'ngx-cookie';
import * as Cookie from 'js-cookie';
import { tokenKey } from '@core/utils/token.utils';

export class PersistentStorageNotAvailableError extends Error {}

@Injectable({
  providedIn: 'root',
})
export class PersistentStorageService {
  static getToken(): string {
    if (inElectron) {
      return localStorage.getItem(tokenKey);
    } else {
      return Cookie.get(tokenKey);
    }
  }

  constructor(private cookieService: CookieService) {}

  get(key: string): string | undefined {
    const savedValue = inElectron
      ? localStorage.getItem(key)
      : this.cookieService.get(key);
    return typeof savedValue === 'string' ? savedValue : undefined;
  }

  /**
   * @throws LocalStorageNotAvailableError
   */
  set(key: string, value: string, options?: CookieOptions) {
    if (inElectron) {
      this.setInLocalStorage(key, value);
    } else {
      this.cookieService.put(key, value, options);
    }
  }

  remove(key: string) {
    if (inElectron) {
      localStorage.removeItem(key);
    } else {
      this.cookieService.remove(key);
    }
  }

  private setInLocalStorage(key: string, value: string) {
    try {
      localStorage.setItem(key, value);
    } catch (e) {
      throw new PersistentStorageNotAvailableError(e.toString());
    }
  }
}
