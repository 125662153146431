import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
} from '@angular/core';

@Component({
  selector: 'ui-select-form-header',
  templateUrl: './select-form-header.component.html',
  styleUrls: ['./select-form-header.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectFormHeaderComponent implements OnInit {
  @Input()
  text: string;

  @Output()
  canceled = new EventEmitter<MouseEvent>();

  constructor() {}

  ngOnInit() {}

  onBackButtonClick(event: MouseEvent) {
    this.canceled.emit(event);
  }
}
