import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { InvoiceInterface } from '@core/models/invoice.interface';
import {
  InvoiceActions,
  InvoiceActionTypes,
} from '@core/redux/invoice/invoice.actions';

export interface State {
  ids: string[];
  selectedId: string;
  loading: boolean;
  entities: any;
}

export const initialState: State = {
  ids: [],
  selectedId: null,
  loading: false,
  entities: {},
};

export const adapter: EntityAdapter<InvoiceInterface> = createEntityAdapter<
  InvoiceInterface
>({
  selectId: (invoice: InvoiceInterface) => invoice.id,
  sortComparer: false,
});

export function reducer(state = initialState, action: InvoiceActions): State {
  switch (action.type) {
    case InvoiceActionTypes.InvoiceLoad:
      return Object.assign({}, state, {
        loading: true,
      });

    case InvoiceActionTypes.InvoiceLoadSuccess:
      return adapter.setMany(
        action.payload,
        Object.assign({}, state, {
          loading: false,
        }),
      );

    case InvoiceActionTypes.InvoiceLoadFailure:
      return Object.assign({}, state, {
        loading: false,
        errors: action.payload.error,
      });

    case InvoiceActionTypes.InvoiceCreate:
      return Object.assign({}, state, {
        loading: true,
        errors: {},
      });

    case InvoiceActionTypes.InvoiceCreateSuccess:
      return adapter.addOne(
        action.payload,
        Object.assign({}, state, {
          loading: false,
        }),
      );

    case InvoiceActionTypes.InvoiceCreateFailure:
      return Object.assign({}, state, {
        loading: false,
        errors: action.payload.error,
      });

    default:
      return state;
  }
}

export const getSelectedId = (state: State) => state.selectedId;
export const getEntities = (state: State) => state.entities;

export const { selectAll: getAll } = adapter.getSelectors();
