import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { IdentityInterface } from '@core/models/identity.interface';
import { AuthService } from '@core/redux/auth/auth.service';
import { Images } from '@core/services/images/static-images';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

const knownEmailProviders = [
  'gmail.com',
  'mail.ru',
  'yandex.ru',
  'outlook.com',
];

function getKnownEmailProvider(email: string | undefined): string | undefined {
  const emailProvider = (email || '').split('@').pop();
  return knownEmailProviders.some((p) => emailProvider === p)
    ? emailProvider
    : undefined;
}

@Component({
  selector: 'app-set-notifications',
  templateUrl: './set-notifications.component.html',
  styleUrls: ['../notification.less', './set-notifications.component.less'],
})
export class SetNotificationsComponent implements OnInit {
  @Output()
  navigated = new EventEmitter();

  Images = Images;
  appValue = false;
  user$: Observable<IdentityInterface>;
  knownEmailProvider$: Observable<string | undefined>;

  constructor(
    private readonly authService: AuthService,
    private readonly router: Router,
  ) {}

  ngOnInit() {
    this.user$ = this.authService.getUser();
    this.knownEmailProvider$ = this.authService
      .getUser()
      .pipe(map((u) => getKnownEmailProvider(u.email)));
  }

  onDownload() {
    this.appValue = true;
    this.router.navigate(['/downloads']).then(() => this.navigated.emit());
  }

  onConfirmEmail(email: string) {
    const knownProvider = getKnownEmailProvider(email);
    if (!knownProvider) {
      return;
    }
    const url = 'https://' + knownProvider;
    window.open(url, '_blank');
  }
}
