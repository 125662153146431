import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UiTuitionComponent } from './ui-tuition.component';
import { ButtonsModule } from '@ui/buttons/buttons.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [UiTuitionComponent],
  exports: [UiTuitionComponent],
  imports: [CommonModule, ButtonsModule, TranslateModule],
})
export class UiTuitionModule {}
