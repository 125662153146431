import {
  Component,
  OnInit,
  HostBinding,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
} from '@angular/core';

@Component({
  selector: 'ui-tuition',
  templateUrl: './ui-tuition.component.html',
  styleUrls: ['./ui-tuition.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UiTuitionComponent implements OnInit {
  @Output()
  readonly finished = new EventEmitter();

  @HostBinding('class.step-2')
  get step2Class(): boolean {
    return this.step === 2;
  }

  @HostBinding('class.finished')
  get finishedClass(): boolean {
    return this.step === 3;
  }

  step: 1 | 2 | 3 = 1;

  ngOnInit() {}

  onBack() {
    this.step = 1;
  }

  onNext() {
    if (this.step === 1) {
      this.step = 2;
    } else {
      this.step = 3;
      this.finished.emit();
    }
  }
}
