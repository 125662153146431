import { getDevicePixelRatio } from '@helpers/dom/get-device-pixel-ratio';
import { FileInterface } from '@core/models/file.interface';

export interface ImageSizesBasedOnRatio {
  '1': string;
  '1.5': string;
  '2': string;
  '3': string;
  default: string;
}

/**
 * Получение размера изображения согласно разрешению экрана
 */
export function getImageByPixelRatio(
  image: FileInterface,
  sizes: ImageSizesBasedOnRatio,
): string {
  if (image && image.sizes) {
    const pixelRatio = getDevicePixelRatio().toString();
    switch (pixelRatio) {
      case '1':
        return image.sizes[sizes[1]];
      case '1.5':
        return image.sizes[sizes[1.5]];
      case '2':
        return image.sizes[sizes[2]];
      case '3':
        return image.sizes[sizes[3]];
      default:
        return image.sizes[sizes.default];
    }
  } else {
    return null;
  }
}
