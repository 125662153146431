<div class="ui-note-view-target">
  <span class="ui-note-view-target__box-caption caption">
    {{ 'UI.NOTE_VIEW.NOTE_TARGET.HEADER' | translate }}
  </span>
  <a [routerLink]="link" class="ui-note-view-target__wrapper">
    <span
      *ngIf="note.target.image"
      class="ui-note-view-target__image"
      [style.backgroundImage]="
        'url(' + (note.target.image | imagePreview: 82:82) + ')'
      "
    ></span>
    <div class="ui-note-view-target__infowrap">
      <span class="ui-note-view-target__info">
        <b class="ui-note-view-target__title">
          {{ note.target.title }}
        </b>
        <!-- <span class="ui-note-view-target__smart-caption">
          {{ 'UI.NOTE_VIEW.NOTE_TARGET.PROGRESS' | translate }} {{ progress }}%
        </span> -->
      </span>
      <span *ngIf="note.targetProgress" class="ui-note-view-target__target">
        {{ progressDetails }}
      </span>
    </div>
  </a>
</div>
