<info-popup-content (actionClick)="onActionClick()" [action]="action">
  <div
    [class.info-popup__main_bigger]="
      achievement.type === 'filledDays' &&
      achievement.currentLevel.levelNumber > 1
    "
    class="info-popup__main"
  >
    <div [ngStyle]="{ backgroundImage: background }" class="info-popup__cover">
      <ui-icon
        [name]="
          achievement.type
            | achievementIcon: achievement.currentLevel.levelNumber
        "
        width="80"
        height="80"
        class="info-popup__img info-popup__img_desktop"
      ></ui-icon>
      <img
        [src]="
          achievement.type
            | achievementImage: achievement.currentLevel.levelNumber
        "
        [alt]="'Level ' + achievement.currentLevel.levelNumber + ' icon'"
        class="info-popup__img info-popup__img_mobile"
      />
      <span class="info-popup__name">
        {{
          achievement.type
            | translateAchievement: achievement.currentLevel.levelNumber:'NAME'
            | translate
        }}<span
          *ngIf="
            achievement.type !== 'filledDays' &&
            achievement.type !== 'maxStreak'
          "
          class="info-popup__subname info-popup__subname_mobile"
          >. {{ '$.LEVEL' | translate }}
          {{ achievement.currentLevel.levelNumber }}
        </span>
      </span>
      <span
        *ngIf="
          achievement.type !== 'filledDays' &&
          achievement.type !== 'maxStreak' &&
          achievement.currentLevel.levelNumber > 1
        "
        class="info-popup__subname info-popup__subname_desktop"
      >
        {{ '$.LEVEL' | translate }} {{ achievement.currentLevel.levelNumber }}
      </span>
    </div>
    <div
      [class.info-popup__text_with-margin]="
        achievement.currentLevel.levelNumber === 1
      "
      class="info-popup__text"
    >
      <span class="info-popup__congratulation">
        {{
          achievement.type
            | translateAchievement: 'CONGRATULATION'
            | translate
              : {
                  level:
                    achievement.type
                    | translateAchievement
                      : achievement.currentLevel.levelNumber
                      : 'NAME'
                    | translate
                }
        }}
      </span>
      <span
        [innerHTML]="
          achievement.type
            | translateAchievement
              : achievement.currentLevel.levelNumber
              : 'CONGRATULATION'
            | translate
        "
        class="info-popup__description"
      >
      </span>
      <div
        *ngIf="
          achievement.type === 'filledDays' &&
          achievement.currentLevel.levelNumber > 1 &&
          achievement.currentLevel.levelNumber < 6
        "
        class="info-popup__action"
      >
        <button
          (click)="onStoriesClick()"
          ui-button-primary
          class="info-popup__stories-button"
          type="button"
        >
          <div class="info-popup__stories-button-content">
            <ui-icon name="play-stories" class="info-popup__play-icon">
            </ui-icon>
            {{ 'PAGES.LEVEL.NEXT' | translate }}
          </div>
        </button>
      </div>
    </div>
  </div>
  <div
    *ngIf="achievement.currentLevel.levelNumber === 1"
    class="info-popup__more"
  >
    <h3 [ngSwitch]="achievement.type" class="info-popup__more-title">
      <ng-container *ngSwitchCase="'filledDays'">
        {{ 'PAGES.LEVEL.SYSTEM' | translate }}
      </ng-container>
      <ng-container *ngSwitchDefault>
        {{ 'PAGES.LEVEL.ACHIEVEMENTS' | translate }}
      </ng-container>
    </h3>
    <p [ngSwitch]="achievement.type" class="info-popup__more-text">
      <ng-container *ngSwitchCase="'filledDays'">
        {{ 'PAGES.LEVEL.SYSTEM_DESCRIPTION' | translate }}
      </ng-container>
      <ng-container *ngSwitchDefault>
        {{ 'PAGES.LEVEL.ACHIEVEMENTS_DESCRIPTION' | translate }}
      </ng-container>
    </p>
    <ul class="icon-list">
      <ng-container *ngFor="let level of achievement.levels">
        <li *ngIf="!level.hide" class="icon-list__item">
          <div
            [class.icon-list__icon_transparent]="!level.achieved"
            class="icon-list__icon"
          >
            <ui-icon
              [name]="achievement.type | achievementIcon: level.levelNumber"
              class="ui-icon"
              width="48"
              height="48"
            ></ui-icon>
          </div>
          <div class="icon-list__info icon-list__info_gray">
            <div
              [class.icon-list__name_gray]="!level.achieved"
              class="icon-list__name"
            >
              {{
                achievement.type
                  | translateAchievement: level.levelNumber:'NAME'
                  | translate
              }}
            </div>
            <div [class.rt-color-green]="level.achieved">
              {{
                achievement.type
                  | translateAchievement: level.levelNumber:'TARGET'
                  | translate
              }}
            </div>
          </div>
          <ng-container
            *ngIf="level === levelInProgress; else notInProgressTemplate"
          >
            <ui-circle-progress
              [progress]="achievement.stepsDone"
              [targetValue]="levelInProgress.stepsNeeded"
            ></ui-circle-progress>
          </ng-container>
          <ng-template #notInProgressTemplate>
            <div [ngSwitch]="level.achieved">
              <ui-icon
                *ngSwitchCase="true"
                name="manage-tasks"
                width="32"
                height="32"
                class="ui-icon"
              ></ui-icon>
              <ui-icon
                *ngSwitchDefault
                name="lock"
                width="32"
                height="32"
                class="ui-icon"
              ></ui-icon>
            </div>
          </ng-template>
        </li>
      </ng-container>
    </ul>
  </div>
</info-popup-content>
