import { InjectionToken } from '@angular/core';

export interface CleaveNumberConfig {
  numeral: true;
  numeralDecimalScale: number;
  numeralDecimalMark: string;
  delimiter: string;
}
export const CLEAVE_NUMBER_CONFIG = new InjectionToken<CleaveNumberConfig>(
  'RtCleaveNumberConfig',
);
export const defaultCleaveNumberConfig = {
  numeral: true,
  numeralDecimalScale: 8,
  numeralDecimalMark: ',',
  delimiter: ' ',
};
export const parseCleaveNumber = (s: string) =>
  parseFloat(s.replace(/\s/g, '').replace(',', '.'));
