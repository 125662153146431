import { HttpErrorResponse } from '@angular/common/http';
import {
  GoalStepsViewMode,
  TargetInterface,
} from '@core/models/target.interface';
import { createAction, props } from '@ngrx/store';

export const changeGoalStepsGroupingAction = createAction(
  '[goal page] change goal grouping',
  props<{
    goalId: string;
    view: GoalStepsViewMode;
  }>(),
);

export const changeGoalStepsGroupingSuccessAction = createAction(
  '[goals api] change goal grouping success',
  props<{
    goal: TargetInterface;
  }>(),
);

export const changeGoalStepsGroupingFailureAction = createAction(
  '[goals api] change goal grouping failure',
  props<{ httpError: HttpErrorResponse }>(),
);
