import { Injectable } from '@angular/core';
import { ApiService } from '@core/services/api.service';
import { Observable } from 'rxjs/internal/Observable';
import {
  InvoiceInterface,
  NewInvoiceInterface,
} from '@core/models/invoice.interface';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class InvoiceService extends ApiService {
  /**
   * Получить с сервера все категории
   */
  search(): Observable<InvoiceInterface[]> {
    return this.http.get<InvoiceInterface[]>(this.getApiUrl(`invoices`));
  }

  /**
   * Создать запись
   */
  create(model: NewInvoiceInterface): Observable<InvoiceInterface> {
    return this.http.post<InvoiceInterface>(this.getApiUrl(`invoices`), model);
  }
}
