import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs/internal/Subscription';
import { IdentityInterface } from '@core/models/identity.interface';
import { AuthService } from '@core/redux/auth/auth.service';
import { MatDialogRef } from '@angular/material/dialog';
import { Observable, combineLatest } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { getPriceString } from '@core/models/tariff.interface';
import { TranslateService } from '@ngx-translate/core';
import { TariffService } from '@core/redux/tariff/tariff.service';

@Component({
  selector: 'lt-subscription-ended',
  templateUrl: './subscription-ended.component.html',
  styleUrls: ['./subscription-ended.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SubscriptionEndedComponent implements OnInit, OnDestroy {
  readonly identity$: Observable<
    IdentityInterface
  > = this.authService.getUser();
  readonly price$: Observable<string> = combineLatest([
    this.translate.onLangChange.pipe(
      map((e) => e.lang),
      startWith(this.translate.currentLang),
    ),
    this.tariffService.getBaseTariff(),
  ]).pipe(map(([lang, tariff]) => getPriceString(tariff, lang)));

  private subscriptions$: Subscription = new Subscription();

  constructor(
    private readonly authService: AuthService,
    private readonly dialogRef: MatDialogRef<SubscriptionEndedComponent>,
    private readonly router: Router,
    private readonly tariffService: TariffService,
    private readonly translate: TranslateService,
  ) {}

  ngOnInit() {
    this.subscribeToNavigation();
  }

  ngOnDestroy() {
    this.subscriptions$.unsubscribe();
  }

  onCloseClick() {
    this.dialogRef.close();
  }

  private subscribeToNavigation() {
    this.subscriptions$.add(
      this.router.events.subscribe(() => this.dialogRef.close()),
    );
  }
}
