import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UiCheckComponent } from '@ui/ui-check/ui-check.component';
import { UiIconModule } from '@ui/ui-icon/ui-icon.module';

@NgModule({
  declarations: [UiCheckComponent],
  exports: [UiCheckComponent],
  imports: [CommonModule, UiIconModule],
})
export class UiCheckModule {}
