import { select, Store } from '@ngrx/store';
import * as fromRoot from '@core/redux/index';
import {
  saveScopeAction,
  updateOnboardingAction,
  saveProfessionAction,
  saveAffiliateInfoAction,
  updateOnboardingFailureAction,
  updateOnboardingSuccessAction,
  saveProfessionSuccessAction,
  saveProfessionFailureAction,
  saveScopeSuccessAction,
  saveScopeFailureAction,
} from './onboarding.actions';
import { Injectable } from '@angular/core';
import { UserOnboardingStep } from '@core/models/identity.interface';
import { AffiliateInfoInterface } from '@core/models/affiliate-info.interface';
import { of, throwError } from 'rxjs';
import { Actions, ofType } from '@ngrx/effects';
import { concatMap, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class OnboardingService {
  constructor(
    private readonly actions: Actions,
    private readonly store: Store<fromRoot.State>,
  ) {}

  // todo: rename with actions
  saveScope(scope: { scope: string[] }) {
    return this.store.pipe(
      select(fromRoot.getUser),
      take(1),
      concatMap((user) => {
        if (user.onboarding.actions.some((a) => a.action === 'likes-page')) {
          this.store.dispatch(saveScopeAction(scope));
          return this.actions.pipe(
            ofType(saveScopeSuccessAction, saveScopeFailureAction),
            take(1),
            concatMap((a) =>
              a.type === saveScopeFailureAction.type
                ? throwError(a.httpError)
                : of(true),
            ),
          );
        } else {
          return of(false);
        }
      }),
    );
  }

  pass(actionName: UserOnboardingStep) {
    return this.store.pipe(
      select(fromRoot.getUser),
      take(1),
      concatMap((user) => {
        if (user.onboarding.actions.some((a) => a.action === actionName)) {
          const modifiedActionName =
            actionName === 'show-achievement:filled-days' &&
            user.achievements.filledDays.currentLevel?.levelNumber === 1
              ? ('show-achievement:filled-days:extended' as UserOnboardingStep)
              : actionName;
          this.store.dispatch(
            updateOnboardingAction({ action: modifiedActionName }),
          );
          return this.actions.pipe(
            ofType(
              updateOnboardingSuccessAction,
              updateOnboardingFailureAction,
            ),
            take(1),
            concatMap((action) =>
              action.type === updateOnboardingFailureAction.type
                ? throwError(action.httpError)
                : of(true),
            ),
          );
        } else {
          return of(false);
        }
      }),
    );
  }

  saveProfession(profession: string) {
    return this.store.pipe(
      select(fromRoot.getUser),
      take(1),
      concatMap((user) => {
        if (
          user.onboarding.actions.some(
            (a) => a.action === 'classification-page',
          )
        ) {
          this.store.dispatch(saveProfessionAction({ profession }));
          return this.actions.pipe(
            ofType(saveProfessionSuccessAction, saveProfessionFailureAction),
            take(1),
            concatMap((action) =>
              action.type === saveProfessionFailureAction.type
                ? throwError(action.httpError)
                : of(true),
            ),
          );
        } else {
          return of(false);
        }
      }),
    );
  }

  saveAffiliateInfo(info: AffiliateInfoInterface) {
    this.store.dispatch(saveAffiliateInfoAction({ info }));
  }
}
