import { select, Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import * as fromRoot from '@core/redux/index';
import { filter } from 'rxjs/operators';
import {
  GetAllAction,
  GetAllSuccessAction,
} from '@core/redux/tariff/tariff.actions';

@Injectable({
  providedIn: 'root',
})
export class TariffService {
  constructor(private store: Store<fromRoot.State>) {}

  getAll() {
    return this.store.pipe(
      select(fromRoot.getTariffListAll),
      filter((tariffs) => !!tariffs.length),
    );
  }

  getBaseTariff() {
    return this.store.pipe(select(fromRoot.getBaseTariff));
  }

  getMinPriceTariff() {
    return this.store.pipe(select(fromRoot.getMinPriceTariff));
  }

  dispatchAll() {
    this.store.dispatch(new GetAllAction());
  }

  dispatchAllSuccess(data) {
    this.store.dispatch(new GetAllSuccessAction(data));
  }
}
