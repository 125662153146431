import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'ui-check',
  templateUrl: './ui-check.component.html',
  styleUrls: ['./ui-check.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UiCheckComponent {
  /**
   * Полностью залита зеленым?
   */
  @Input()
  isFull = false;

  @Input()
  shape: 'circle' | 'square' = 'circle';

  @Input()
  color: 'blue' | 'gray' = 'gray';

  @Input()
  size: 'normal' | 'large' = 'normal';

  @Input()
  value: boolean;

  @Input()
  disabled = false;

  @Output()
  valueChange = new EventEmitter<boolean>();

  @ViewChild('context')
  contextElRef: ElementRef<HTMLElement>;

  @ViewChild('icon')
  iconElRef: ElementRef<HTMLElement>;

  @ViewChild('parent')
  parentElRef: ElementRef<HTMLElement>;

  onClick(event: Event) {
    event.stopPropagation();
    if (this.disabled) {
      return;
    }
    this.valueChange.emit(!this.value);
  }
}
