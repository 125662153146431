import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { StoryService } from '@core/redux/story/story.service';
import { StoriesDialogService } from '@dialogs/stories';
import { OnboardingService } from '@core/redux/onboarding/onboarding.service';
import { actionsToPassAfterStoriesOpening } from '@core/redux/onboarding/consts';
import { AuthService } from '@core/redux/auth/auth.service';

export const storiesPlayButtonId = 'lt-stories-play-button';

@Component({
  selector: 'app-story-button',
  templateUrl: './story-button.component.html',
  styleUrls: ['../button.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StoryButtonComponent implements OnInit {
  storiesCount$: Observable<number>;
  shouldDisable$: Observable<boolean>;
  readonly storiesPlayButtonId = storiesPlayButtonId;

  constructor(
    private readonly authService: AuthService,
    private readonly onboardingService: OnboardingService,
    private readonly storiesDialogService: StoriesDialogService,
    private readonly storyService: StoryService,
  ) {}

  ngOnInit(): void {
    this.storiesCount$ = this.storyService
      .newStories()
      .pipe(map((s) => s.length));
    this.shouldDisable$ = this.storyService
      .stories()
      .pipe(map((s) => s.length === 0));
  }

  onStoriesClick() {
    this.storiesDialogService.open();
    this.authService
      .getUser()
      .pipe(take(1))
      .subscribe((user) => {
        const action = user.onboarding.actions.find((a) =>
          actionsToPassAfterStoriesOpening.includes(a.action),
        );
        if (action) {
          this.onboardingService.pass(action.action).subscribe();
        }
      });
  }
}
