import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UiIconModule } from '@ui/ui-icon/ui-icon.module';
import { StoryComponent } from './story/story.component';
import { ButtonsModule } from '@ui/buttons/buttons.module';
import { StoriesDialogComponent } from './stories-dialog/stories-dialog.component';

@NgModule({
  declarations: [StoryComponent, StoriesDialogComponent],
  imports: [CommonModule, UiIconModule, ButtonsModule],
  exports: [StoriesDialogComponent],
  entryComponents: [StoriesDialogComponent],
})
export class StoriesModule {}
