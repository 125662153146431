import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, ReplaySubject } from 'rxjs';
import { NoteInterface } from '@core/models/note.interface';
import { map, take, tap } from 'rxjs/operators';
import { TargetInterface } from '@core/models/target.interface';
import { Actions, ofType } from '@ngrx/effects';
import {
  noteCreateSuccessAction,
  noteDeleteSuccessAction,
  noteUpdateSuccessAction,
} from '@core/redux/note/note.actions';
import { GoalsService } from '@core/redux/goal/goals.service';
import { NoteService } from '@core/redux/note/note.service';
import * as moment from 'moment';
import { ImagePreloadService } from '@core/services/images/image-preload.service';

function isGoodGoal(note: NoteInterface, target: TargetInterface) {
  const targetOfNote = note ? note.targetId === target.id : false;
  const active = !target.isDelete && !target.isComplete;
  return active || targetOfNote;
}

// TODO rename
@Injectable({
  providedIn: 'root',
})
export class NoteComponentService {
  readonly closingActions = this.actions.pipe(
    ofType(
      noteCreateSuccessAction,
      noteUpdateSuccessAction,
      noteDeleteSuccessAction,
    ),
  );

  get dateString(): Observable<string> {
    return this.dateString$.asObservable();
  }

  get shortDateString(): Observable<string> {
    return this.shortDateString$.asObservable();
  }

  get note(): Observable<NoteInterface> {
    return this.note$.asObservable();
  }

  get editable(): Observable<boolean> {
    return this.editable$.asObservable();
  }

  private readonly editable$ = new BehaviorSubject<boolean>(false);
  private readonly note$ = new ReplaySubject<NoteInterface>();
  private readonly dateString$ = new ReplaySubject<string>();
  private readonly shortDateString$ = new ReplaySubject<string>();

  constructor(
    private readonly actions: Actions,
    private readonly imagePrelodService: ImagePreloadService,
    private readonly noteService: NoteService,
    private readonly targetService: GoalsService,
  ) {}

  startEditing() {
    this.editable$.next(true);
  }

  stopEditing() {
    this.editable$.next(false);
  }

  getGoals(note: NoteInterface) {
    // map на случай если цели не загрузились
    return this.targetService.getAllReallyAll().pipe(
      map((t) => (Array.isArray(t) ? t : [])),
      map((goals) =>
        goals
          .filter((t) => isGoodGoal(note, t))
          .sort((a, b) => b.noteCount - a.noteCount),
      ),
      tap((goals) =>
        goals.forEach((g) =>
          this.imagePrelodService.preloadSelectGoalImages(g),
        ),
      ),
    );
  }

  /**
   *
   * @param data note id of note
   */
  updateViewWith(data: string | NoteInterface) {
    const note$ =
      typeof data === 'string'
        ? this.noteService.getNoteById(data).pipe(take(1))
        : of(data);
    note$.subscribe((note) => {
      this.note$.next(note);
      const shortDate = this.getDateString('dddd, DD MMMM', note);
      this.shortDateString$.next(shortDate);
      const longDate = this.getDateString('ddd, DD MMMM', note);
      this.dateString$.next(longDate);
    });
  }

  private getDateString(format: string, note: NoteInterface): string {
    return (
      moment()
        .date(note.day)
        .month(note.month - 1)
        .year(note.year)
        .format(format)
        // сделает первую букву заглавной
        .split('')
        .map((char, index) => (index === 0 ? char.toUpperCase() : char))
        .join('')
    );
  }
}
