import { Injectable } from '@angular/core';
import { NoteInterface } from '@core/models/note.interface';
import { preloadImage } from '@helpers/dom/preload-image';
import { Subject, from, of, throwError } from 'rxjs';
import { mergeMap, catchError, concatMap, map } from 'rxjs/operators';
import { FileInterface } from '@core/models/file.interface';
import { noteImageSizes, goalImageSizes } from './used-sizes';
import { TargetInterface } from '@core/models/target.interface';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class ImagePreloadService {
  private readonly loaded: string[] = [];
  private readonly images$ = new Subject<string>();
  private readonly videos$ = new Subject<string>();

  constructor(private readonly httpClient: HttpClient) {
    this.images$
      .pipe(
        // mergeMap - для параллельной загрузки
        // concatMap - для последовательной
        mergeMap((img) =>
          from(
            preloadImage(img)
              .then(() => img)
              .catch((error) => throwError(error)),
          ),
        ),
        // необходимо, чтобы после ошибки, например 404,
        // сервис смог продолжить работу
        catchError(() => of(undefined)),
      )
      .subscribe((img: string | undefined) => img && this.loaded.push(img));
    this.videos$
      .pipe(
        concatMap((videoUrl) => this.httpClient.get(videoUrl)),
        catchError(() => of(undefined)),
      )
      .subscribe();
  }

  preloadImage(src: string | string[]) {
    const srcs: string[] = Array.isArray(src) ? src : [src];
    srcs
      .filter((img) => !this.loaded.includes(img))
      .forEach((img) => this.images$.next(img));
  }

  preloadImageInSizes(image: FileInterface, sizes: string[]) {
    for (const size of sizes) {
      if (image.sizes && image.sizes[size]) {
        this.preloadImage(image.sizes[size]);
      }
    }
  }

  preloadNoteImages(note: NoteInterface) {
    if (!note || !note.files) {
      return;
    }
    for (const file of note.files) {
      this.preloadImageInSizes(file, noteImageSizes);
    }
  }

  preloadSelectGoalImages(goal: TargetInterface) {
    if (!goal || !goal.image) {
      return;
    }
    this.preloadImageInSizes(goal.image, goalImageSizes.slice(0, 1));
  }

  preloadVideo(url: string) {
    this.videos$.next(url);
  }
}
