<ui-select-form-header
  (canceled)="onCloseClick()"
  [text]="headerText | translate"
>
</ui-select-form-header>
<category-form-body
  (sentSuccessfully)="onSentSuccessfully($event)"
  class="category-form__body"
>
</category-form-body>
