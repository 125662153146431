import {
  Component,
  OnDestroy,
  OnInit,
  Output,
  EventEmitter,
  Inject,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  CategoryCountNoteInterface,
  CategoryDeleteModel,
  CategoryInterface,
} from '@core/models/category.interface';
import { Subscription } from 'rxjs/internal/Subscription';
import { CategoryService } from '@core/redux/category/category.service';
import { CategoryService as CategoryServiceApi } from '@core/services/category.service';
import { first } from 'rxjs/operators';
import { CategoryFormDialogData } from '../category-form.component';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DeleteCategoryDialogService } from '../../delete-category-dialog/delete-category-dialog.service';
import { iconSelectorIcons } from '@ui/icon-selector/icon-selector-icons';

export interface SentSuccessfullyEvent {
  category: CategoryInterface;
  created: boolean;
}

@Component({
  selector: 'category-form-body',
  templateUrl: './category-form-body.component.html',
  styleUrls: ['./category-form-body.component.less'],
})
export class UiPopupCategoryFormComponent implements OnInit, OnDestroy {
  @Output()
  sentSuccessfully = new EventEmitter<SentSuccessfullyEvent>();

  /**
   * Форма создания категории
   */
  form: FormGroup;

  isLoading = false;

  model: CategoryInterface = {} as CategoryInterface;

  private readonly subscription$ = new Subscription();

  constructor(
    private readonly categoryServiceApi: CategoryServiceApi,
    private readonly categoryService: CategoryService,
    @Inject(MAT_DIALOG_DATA)
    private readonly data: CategoryFormDialogData,
    private readonly deleteCategoryDialogService: DeleteCategoryDialogService,
    private readonly formBuilder: FormBuilder,
  ) {}

  /**
   * Инициализация компонента
   */
  ngOnInit(): void {
    this.initForm();
    this.receiveState(this.data);
  }

  ngOnDestroy(): void {
    this.subscription$.unsubscribe();
  }

  onSubmit() {
    this.isLoading = true;
    const model = this.form.getRawValue() as CategoryInterface;
    const categoryCreator = model.id
      ? this.categoryService.update
      : this.categoryService.create;
    categoryCreator
      .bind(this.categoryService)(model)
      .subscribe(
        (category: CategoryInterface) => {
          this.close({ category, created: !model.id });
        },
        () => {},
        () => {
          this.isLoading = false;
        },
      );
  }

  onDelete() {
    const model = this.form.getRawValue() as CategoryInterface;
    // TODO: ?
    this.categoryServiceApi
      .countNote(model.id)
      .pipe(first())
      .subscribe((response: CategoryCountNoteInterface) => {
        if (response.count > 0) {
          this.deleteCategoryDialogService.open({ categoryId: model.id });
        } else {
          const deleteModel = new CategoryDeleteModel();
          deleteModel.id = model.id;
          deleteModel.moveId = null;
          this.categoryService.delete(deleteModel);
        }
      });
    this.onClose();
  }

  onClose() {
    this.close();
  }

  private close(event?: SentSuccessfullyEvent) {
    this.sentSuccessfully.emit(event);
  }

  /**
   * Инициализация формы, описание валидаторов
   */
  private initForm(): void {
    this.form = this.formBuilder.group({
      id: [null],
      name: [null, [Validators.required]],
      icon: [null, [Validators.required]],
    });
    this.form.controls.icon.setValue(iconSelectorIcons[0].icons[0]);
  }

  private receiveState(state: CategoryFormDialogData) {
    this.form.controls.id.setValue(null);
    this.form.controls.name.setValue(null);
    this.form.controls.icon.setValue(iconSelectorIcons[0].icons[0]);

    // создается новая категория, имя категории не пришло
    if (!state.category) {
      return;
    }

    // имя пришло из поиска по категориям, или это старой категории
    this.form.controls.name.setValue(state.category.name);

    // категория старая
    if ((state.category as CategoryInterface).id) {
      const category = state.category as CategoryInterface;
      this.form.controls.icon.setValue(category.icon);
      this.form.controls.id.setValue(category.id);
    }
  }
}
