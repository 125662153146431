<form [formGroup]="form" class="note-form__group">
  <div class="note-form__maincont">
    <div class="form-group note-form__textarea-row">
      <div *ngIf="!shouldShowOnboarding" class="note-form__actions">
        <div class="note-form__tabs">
          <span
            (click)="onChangeType(false)"
            class="note-form__tab caption"
            [class.note-form__tab_active]="!form.get('isTask').value"
            translate
            >$.NOTE</span
          >
          <span
            (click)="onChangeType(true)"
            class="note-form__tab caption"
            [class.note-form__tab_active]="form.get('isTask').value"
            translate
            >$.TASK</span
          >
        </div>
        <div class="note-form__actions-right">
          <div
            (mousedown)="onHashtagMousedown()"
            (click)="onHashtagClick()"
            class="note-form__hashtag"
          >
            <ui-icon class="ui-icon" name="hashtag"></ui-icon>
          </div>
        </div>
      </div>

      <div class="note-form__select-wrap">
        <ui-select
          #select
          (click)="$event.stopPropagation()"
          (openForm)="onOpenCategoryForm($event)"
          (closed)="onCloseCategorySelect()"
          (search)="onFilterCategories($event)"
          [insideMatDialog]="true"
          [values]="filteredCategories"
          [popperSettings]="{ flip: { enabled: false } }"
          [preventClosing]="preventCategorySelectClosing$ | async"
          [emptyValueView]="
            categoriesList?.length && categoryId.value === categoriesList[0].id
          "
          withSearch="{{ 'UI.NOTE_FORM.WITH_SEARCH' | translate }}"
          addText="{{ 'UI.NOTE_FORM.ADD_CATEGORY' | translate }}"
          formControlName="categoryId"
          class="note-form__select select_noborder"
        >
          <ui-option
            *ngFor="let option of filteredCategories"
            [editable]="!option.readOnly"
            [active]="option.id == categoryId.value"
            [value]="option.id"
          >
            <ui-icon
              class="ui-icon"
              [name]="option.icon"
              class="note-form__category-icon"
            ></ui-icon>
            {{ option.name }}
          </ui-option>
        </ui-select>
      </div>

      <!-- Автофокус отключать при наличии онбординга, чтобы в мобильном не открывалась клава -->
      <textarea
        *ngIf="form.get('isTask').value"
        id="form-note"
        #noteTextArea
        (focus)="onTextareaFocus($event)"
        (paste)="onPaste($event)"
        [autocompleteTagsActive]="autocompleteTagsActive"
        [autocompleteTags]="tags$ | async"
        [attr.placeholder]="'UI.NOTE_FORM.PLAN' | translate"
        class="textarea note-form__textarea"
        formControlName="note"
        (keydown.shift.enter)="$event.preventDefault(); onSubmit(true)"
        [autoFocus]="!shouldShowOnboarding"
        autosize
      >
      </textarea>
      <!-- Приходится копипастить, а не просто менять класс, чтобы не было прыжков. Прыжки из-за autosize -->
      <textarea
        *ngIf="!form.get('isTask').value"
        id="form-note"
        #noteTextArea
        (focus)="onTextareaFocus($event)"
        (paste)="onPaste($event)"
        [autocompleteTagsActive]="autocompleteTagsActive"
        [autocompleteTags]="tags$ | async"
        [attr.placeholder]="'UI.NOTE_FORM.THOUGHT' | translate"
        class="textarea note-form__textarea note-form__textarea_note"
        formControlName="note"
        (keydown.shift.enter)="$event.preventDefault(); onSubmit(true)"
        [autoFocus]="!shouldShowOnboarding"
        autosize
      >
      </textarea>
      <ui-tuition
        *ngIf="shouldShowOnboarding"
        (finished)="onFinishOnboarding()"
      >
      </ui-tuition>
    </div>

    <div *ngIf="form.get('isTask').value" class="note-form__checklist">
      <div class="input-with-btn note-form__input-btn">
        <input
          #subtaskInput
          (keydown.enter)="onCreateSubtask(subtaskInput.value)"
          class="input-with-btn__input"
          placeholder="{{ 'UX.CHECKLIST.ADD' | translate }}"
          type="text"
          name="subtask"
          autocomplete="off"
        />
        <button
          (click)="onCreateSubtask(subtaskInput.value)"
          class="input-with-btn__btn"
          type="button"
        >
          <span class="input-with-btn__btn-caption"> Enter </span>
          <ui-icon
            class="ui-icon input-with-btn__btn-icon"
            name="angle-right"
            width="8px"
            height="14px"
          ></ui-icon>
        </button>
      </div>
      <div
        (cdkDropListDropped)="onDrop($event)"
        cdkDropList
        class="note-form__subtasks"
      >
        <div
          *ngFor="let subtask of form.get('checklist').value"
          [@anim]="subtask.state"
          cdkDragLockAxis="y"
          cdkDrag
          class="note-form__subtask"
        >
          <label
            class="green-checkbox note-form__checkbox"
            [class.note-form__checkbox_checked]="subtask.isComplete"
          >
            <input
              type="checkbox"
              class="inp-cbx"
              (input)="onChangeSubtaskStatus(subtask)"
              [checked]="subtask.isComplete"
            />
            <span class="green-checkbox__field">
              <i class="green-checkbox__marker"></i>
            </span>
          </label>

          <textarea
            #subtaskEditingInput
            (input)="onChangeSubtaskText(subtaskEditingInput.value, subtask)"
            (keydown.enter)="onSubtaskTextInputEnter($event)"
            (keydown.backspace)="
              onSubtaskTextInputBackspace(subtaskEditingInput.value, subtask)
            "
            [value]="subtask.text"
            rows="1"
            autosize
            class="note-form__subtask-text"
          >
          </textarea>
          <button
            (click)="onDeleteSubtask(subtask)"
            ui-button-icon
            class="btn btn_not-button note-form__subtask-delete"
            type="button"
          >
            <ui-icon
              class="ui-icon ui-icon_hover_close-red"
              name="close"
            ></ui-icon>
          </button>
        </div>
      </div>
    </div>
  </div>

  <div
    *ngIf="isGoalSelectOpened"
    (clickOutside)="onAnimateGoalSelectClose()"
    (@fade.done)="onCloseGoalSelect()"
    [delayClickOutsideInit]="true"
    [@fade]="fade"
    class="note-form__goal-select"
  >
    <goal-select
      (closed)="onAnimateGoalSelectClose()"
      (selected)="onSelectGoal($event)"
      [goals]="goals$ | async"
      [selectedId]="form.get('targetId').value"
    >
    </goal-select>
  </div>

  <div class="note-forms__rcfooter">
    <div class="note-forms__forms">
      <ng-container *ngIf="isGoalFormOpened || form.get('targetId').value">
        <div class="note-form__select-row">
          <div class="note-form__side-left">
            <label class="label">
              {{ 'UI.NOTE_FORM.GOAL' | translate }}
            </label>
            <button
              (click)="onOpenGoalSelect()"
              class="btn btn_not-button input note-form__goal-button"
              type="button"
            >
              <i
                *ngIf="selectedGoal$ | async as selectedGoal"
                [style.background-color]="selectedGoal?.color"
                class="circle"
              >
              </i>
              <span class="note-form__selected-goal-name">
                {{ (selectedGoal$ | async)?.title }}
              </span>
              <ui-icon
                name="arrow"
                width="40"
                height="40"
                class="ui-icon ui-icon_hover_edit"
              >
              </ui-icon>
            </button>
          </div>
          <div class="note-form__side-right">
            <label class="label">
              {{ 'UI.NOTE_FORM.ADDED' | translate }}
            </label>
            <input
              [cleave]="cleaveNumberConfig"
              formControlName="targetProgress"
              type="text"
              class="input note-form__added-input"
            />
          </div>
        </div>
      </ng-container>
    </div>

    <div class="note-form__footer">
      <div class="note-form__footer-target">
        <div *ngIf="!shouldShowOnboarding" class="note-form__files">
          <label class="note-form__add-btn">
            <ui-icon class="ui-icon note-form__icon-plus" name="plus"></ui-icon>
            <input
              #inputUploadFiles
              [multiple]="true"
              (change)="onFileUpload($event.target.files)"
              accept="image/x-png,image/gif,image/jpeg"
              type="file"
            />
          </label>

          <ui-file-preview-form
            *ngFor="let fileControl of pendingFiles.controls"
            (delete)="onDeletePendingImage(fileControl.value)"
            [image]="fileControl"
            class="note-form__img"
          >
          </ui-file-preview-form>

          <ui-file-preview-form
            *ngFor="let file of uploadedFiles.value.slice(0, 3)"
            (delete)="onDeleteImage(file)"
            [image]="file"
            class="note-form__img"
          >
          </ui-file-preview-form>
        </div>

        <span
          *ngIf="!(shouldShowOnboarding || (goals$ | async)?.length === 0)"
          (click)="onOpenGoalForm()"
          [class.note-form__add-select_hidden]="
            isGoalFormOpened || form.get('targetId').value
          "
          class="note-form__add-select body-text body-text_style_2"
        >
          <ui-icon
            class="ui-icon note-form__icon-plus-add"
            name="plus"
          ></ui-icon>
          {{ 'UI.NOTE_FORM.ATTACH' | translate }}
        </span>
      </div>

      <div class="note-form__buttons">
        <ng-template #newTipTemplate>
          <rt-tooltip
            [text]="'UI.NOTE_FORM.NEW_TIP' | translate"
            arrowPosition="bottom"
          ></rt-tooltip>
        </ng-template>
        <button
          *ngIf="!shouldShowOnboarding"
          (click)="onSubmit(true)"
          [disabled]="form.invalid || isSending"
          ngProjectAs="content"
          ui-button-secondary
          appTooltip
          [tooltipContent]="newTipTemplate"
          [tooltipPosition]="saveAndNewTipPosition"
          type="button"
          class="note-form__btn note-form__clean-mobile-btn"
        >
          {{ 'UI.NOTE_FORM.NEW' | translate }}
        </button>
        <button
          (click)="onSubmit(false)"
          [disabled]="
            form.invalid ||
            isSending ||
            (!readOnboarding && shouldShowOnboarding)
          "
          ui-button-primary
          class="note-form__btn note-form__int-button"
          type="button"
        >
          {{ '$.SAVE' | translate }}
        </button>
      </div>
    </div>
    <div class="note-form__img-footer">
      <ui-file-preview-form
        *ngFor="let fileControl of pendingFiles.controls"
        (delete)="onDeletePendingImage(fileControl)"
        [image]="fileControl"
        class="note-form__img"
      >
      </ui-file-preview-form>

      <ui-file-preview-form
        *ngFor="let file of uploadedFiles.value"
        (delete)="onDeleteImage(file)"
        [image]="file"
        class="note-form__img"
      >
      </ui-file-preview-form>
    </div>
  </div>
</form>
