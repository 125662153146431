import { Component, HostBinding, ViewEncapsulation } from '@angular/core';
import { InfoPopupDesktopContainerComponent } from '../info-popup-desktop-container/info-popup-desktop-container.component';

@Component({
  selector: 'rt-info-popup-mobile-container',
  templateUrl: './info-popup-mobile-container.component.html',
  styleUrls: ['./info-popup-mobile-container.component.less'],
  encapsulation: ViewEncapsulation.None,
})
export class InfoPopupMobileContainerComponent extends InfoPopupDesktopContainerComponent {
  @HostBinding('class')
  klass = 'rt-info-popup-mobile-container';
}
