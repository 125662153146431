<div class="notification__body">
  <div class="notification__img">
    <img [src]="Images.Notification" alt="notification bell" />
  </div>
  <div class="notification__text">
    <h4 class="notification__name">
      {{ 'INFO_DIALOGS.NOTIFICATIONS.SET.NAME' | translate }}
    </h4>
    <p class="notification__description">
      {{ 'INFO_DIALOGS.NOTIFICATIONS.SET.DESCRIPTION' | translate }}
    </p>
  </div>
</div>
<div *ngIf="user$ | async as user" class="notification__actions">
  <button
    (click)="onConfirmEmail(user.email)"
    [disabled]="(knownEmailProvider$ | async) === undefined"
    [class.notification__action_disabled]="
      (knownEmailProvider$ | async) === undefined
    "
    class="notification__action"
    type="button"
  >
    <div
      [class.notification__label_top]="
        (knownEmailProvider$ | async) === undefined
      "
      class="notification__label"
    >
      <ui-check
        [value]="user.isConfirmation"
        [disabled]="(knownEmailProvider$ | async) === undefined"
        class="notification__check"
      ></ui-check>
      <span
        [class.rt-color-green]="user.isConfirmation"
        [class.notification__action-text_top]="
          (knownEmailProvider$ | async) === undefined
        "
        class="notification__action-text"
      >
        {{ 'INFO_DIALOGS.NOTIFICATIONS.SET.EMAIL' | translate
        }}<ng-container *ngIf="(knownEmailProvider$ | async) === undefined"
          >.
          {{ 'INFO_DIALOGS.NOTIFICATIONS.SET.CHECK' | translate }}</ng-container
        >
      </span>
    </div>
    <a
      *ngIf="knownEmailProvider$ | async"
      [ngSwitch]="user.isConfirmation"
      [class.rt-color-green]="user.isConfirmation"
      class="link link_dark-blue"
    >
      <ng-container *ngSwitchCase="true">
        {{ 'INFO_DIALOGS.NOTIFICATIONS.SET.DONE' | translate }}
      </ng-container>
      <ng-container *ngSwitchDefault>
        {{ 'INFO_DIALOGS.NOTIFICATIONS.SET.CONFIRM' | translate }}
      </ng-container>
    </a>
  </button>
  <button (click)="onDownload()" class="notification__action" type="button">
    <div class="notification__label">
      <ui-check [value]="appValue" class="notification__check"></ui-check>
      <span [class.rt-color-green]="appValue" class="notification__action-text">
        {{ 'INFO_DIALOGS.NOTIFICATIONS.SET.APP' | translate }}
      </span>
    </div>
    <a
      [ngSwitch]="appValue"
      [class.rt-color-green]="appValue"
      class="link link_dark-blue"
    >
      <ng-container *ngSwitchCase="true">
        {{ 'INFO_DIALOGS.NOTIFICATIONS.SET.DONE' | translate }}
      </ng-container>
      <ng-container *ngSwitchDefault>
        {{ 'INFO_DIALOGS.NOTIFICATIONS.SET.INSTALL' | translate }}
      </ng-container>
    </a>
  </button>
</div>
