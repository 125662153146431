import { createFeatureSelector, createSelector } from '@ngrx/store';
import { State } from './story.reducer';

export const selectStoryState = createFeatureSelector<State>('story');
export const selectHasStoryToView = createSelector(
  selectStoryState,
  state => state.stories.some(s => !s.isWatched),
);
export const selectStories = createSelector(
  selectStoryState,
  state => state.stories,
);
export const selectNewStories = createSelector(
  selectStories,
  stories => stories.filter(s => s.isWatched === false),
);
export const selectStoriesIsLoadingFirstTime = createSelector(
  selectStoryState,
  state => state.isLoadingFirstTime,
);
