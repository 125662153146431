import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CategoryInterface } from '@core/models/category.interface';
import { SentSuccessfullyEvent } from './category-form-body/category-form-body.component';

export type CategoryFormCategory =
  | CategoryInterface
  | { name: string }
  | undefined;

/**
 * При передаче категории форма открывается на ее редактирование
 * При передаче имени открывается форма создания с заполненным именем
 * При передаче `undefined` открывается чистая форма создания категории
 */
export interface CategoryFormDialogData {
  category: CategoryFormCategory;
}

@Component({
  selector: 'category-form',
  templateUrl: './category-form.component.html',
  styleUrls: ['./category-form.component.less'],
})
export class CategoryFormComponent implements OnInit {
  headerText: string;
  constructor(
    @Inject(MAT_DIALOG_DATA)
    private readonly data: CategoryFormDialogData,
    private readonly dialogRef: MatDialogRef<CategoryFormComponent>,
  ) {}

  ngOnInit() {
    this.headerText =
      this.data.category && (this.data.category as CategoryInterface).id
        ? 'UX.SELECT.EDIT_CATEGORY'
        : 'UX.SELECT.NEW_CATEGORY';
  }

  onCloseClick() {
    this.close();
  }

  onSentSuccessfully(event: SentSuccessfullyEvent) {
    this.close(event);
  }

  private close(result?: SentSuccessfullyEvent) {
    this.dialogRef.close(result);
  }
}
