import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UiTooltipComponent } from './ui-tooltip.component';
import { ClickOutsideModule } from '@shared/directives/click-outside/click-outside.module';

@NgModule({
  declarations: [UiTooltipComponent],
  exports: [UiTooltipComponent],
  imports: [CommonModule, ClickOutsideModule],
})
export class UiTooltipModule {}
