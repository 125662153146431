import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { ComponentType } from '@angular/cdk/portal';
import {
  MatDialogConfig,
  MatDialogRef,
  MatDialog,
} from '@angular/material/dialog';
import { isPlatformServer } from '@angular/common';
import { isMobile, getDialogConfig, closeOnClickOutside } from './helpers';
import { Subscription } from 'rxjs/internal/Subscription';

@Injectable({
  providedIn: 'root',
})
export class DialogService {
  constructor(
    @Inject(PLATFORM_ID)
    private platformId: object,
    private dialog: MatDialog,
  ) {}

  open<C, D, R>(
    component: ComponentType<C>,
    config: MatDialogConfig<D> = {},
    changePosition: boolean,
  ): MatDialogRef<C, R> {
    if (isPlatformServer(this.platformId)) {
      return;
    }
    const mobile = changePosition && isMobile();
    const dialogConfig = getDialogConfig(changePosition, mobile, config);
    const dialogRef = this.dialog.open(component, dialogConfig);
    document.body.style.overflow = 'hidden';
    const openSub = dialogRef.afterOpened().subscribe(() => {
      this.setUpOpenedDialog({
        dialogRef,
      });
      openSub.unsubscribe();
    });
    return dialogRef;
  }

  openDefault<C, D, R>(
    component: ComponentType<C>,
    config: MatDialogConfig<D> = {},
  ): MatDialogRef<C, R> {
    const dialogRef = this.dialog.open(component, config);
    document.body.style.overflow = 'hidden';
    dialogRef.afterClosed().subscribe(() => {
      if (this.dialog.openDialogs.length === 0) {
        document.body.style.removeProperty('overflow');
      }
    });
    return dialogRef;
  }

  private setUpOpenedDialog({ dialogRef }: { dialogRef: MatDialogRef<any> }) {
    let clickOutsideSubscription: Subscription;
    if (!dialogRef.disableClose) {
      clickOutsideSubscription = closeOnClickOutside(dialogRef);
    }

    dialogRef.afterClosed().subscribe(() => {
      if (this.dialog.openDialogs.length === 0) {
        document.body.style.removeProperty('overflow');
      }
      if (clickOutsideSubscription) {
        clickOutsideSubscription.unsubscribe();
      }
    });
  }
}
