<button
  *ngIf="hasCloseButton"
  (click)="onCloseClick()"
  class="rt-tooltip__close"
  type="button"
>
  x
</button>
<!-- todo [performance]: вместо safeLinky только bypassHTMLSecurity -->
<p
  [innerHTML]="text | translate | safeLinky"
  innerHtmlLink
  class="rt-tooltip__text"
></p>
<div [ngClass]="arrowPositionClass" class="rt-tooltip__arrow"></div>
<div *ngIf="actions" class="rt-tooltip__actions">
  <button (click)="onCloseClick()" ui-button-secondary type="button">
    {{ 'TOOLTIPS.ONBOARDING.STORIES.OK' | translate }}
  </button>
</div>
