import { OverlayRef } from '@angular/cdk/overlay';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { InfoPopupDesktopContainerComponent } from './info-popup-desktop-container/info-popup-desktop-container.component';

export class InfoPopupRef<C, R = any> {
  private infoPopupResult?: R;
  constructor(
    private readonly overlayRef: OverlayRef,
    private containerInstance: InfoPopupDesktopContainerComponent,
  ) {
    if (this.overlayRef.getConfig().hasBackdrop) {
      this.overlayRef.backdropClick().subscribe(() => this.close());
    }
    this.beforeClosed().subscribe(() => this.overlayRef.detachBackdrop());
    this.afterClosed().subscribe(() => {
      this.overlayRef.detach();
      this.overlayRef.dispose();
      this.containerInstance = undefined;
    });
  }

  afterOpened() {
    return this.containerInstance._afterEnter.asObservable();
  }

  afterClosed(): Observable<R | undefined> {
    return this.containerInstance._afterExit
      .asObservable()
      .pipe(map(() => this.infoPopupResult));
  }

  beforeClosed(): Observable<R | undefined> {
    return this.containerInstance._beforeExit
      .asObservable()
      .pipe(map(() => this.infoPopupResult));
  }

  close(result?: R) {
    this.infoPopupResult = result;
    this.containerInstance._startExiting();
  }
}
