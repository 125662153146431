import { createAction, props } from '@ngrx/store';
import { TargetImageInterface } from '@core/models/target-image.interface';

export const targetImageLoad = createAction('[TargetImage] Load');
export const targetImageLoadSuccess = createAction(
  '[TargetImage] Load Success',
  props<{ images: TargetImageInterface[] }>(),
);
export const targetImageLoadFailure = createAction(
  '[TargetImage] Load Failure',
);
