import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { map, first, filter, tap } from 'rxjs/operators';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthService } from '@core/redux/auth/auth.service';
import { NoteService } from '@core/redux/note/note.service';
import { Router, NavigationStart } from '@angular/router';
import { Subscription, Observable } from 'rxjs';
import { NoteInterface } from '@core/models/note.interface';
import { NoteComponentService } from './note-component.service';
import { SubscriptionEndedDialogService } from '../subscription-ended/subscription-ended-dialog.service';
import { Story } from '@core/redux/story/story.model';

/**
 * Варианты состояния для открытия заметки
 *
 * `Partial<NoteInterface>` -- для создания новой заметки или для редактирования сохраненной локально заметки
 *
 * `string` -- для редактирования заметки с сервера
 */
export type NoteFormData = string | Partial<NoteInterface>;

export interface NoteDialogData {
  withOnboarding: boolean;
  /**
   * Если создание заметки инициализированно из истории, то нужно передать историю
   */
  story?: Story;
}

@Component({
  selector: 'app-note',
  templateUrl: './note.component.html',
  styleUrls: ['./note.component.less'],
})
export class NoteComponent implements OnInit, OnDestroy {
  editable$: Observable<boolean>;
  notEditable$: Observable<boolean>;
  dateString$: Observable<string>;
  note$: Observable<NoteInterface>;
  shortDateString$: Observable<string>;
  shouldShowOnboarding: boolean;

  private readonly subscription = new Subscription();

  constructor(
    private authService: AuthService,
    @Inject(MAT_DIALOG_DATA) private readonly data: NoteDialogData,
    private dialogRef: MatDialogRef<NoteComponent>,
    private noteComponentService: NoteComponentService,
    private noteService: NoteService,
    private router: Router,
    private subscriptionEndedDialogService: SubscriptionEndedDialogService,
  ) {}

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  ngOnInit() {
    this.dateString$ = this.noteComponentService.dateString;
    this.editable$ = this.noteComponentService.editable;
    this.notEditable$ = this.noteComponentService.editable.pipe(map((i) => !i));
    this.note$ = this.noteComponentService.note;
    this.shortDateString$ = this.noteComponentService.shortDateString;
    this.shouldShowOnboarding = this.data.withOnboarding;
    if (!this.shouldShowOnboarding) {
      this.closeOnNavigation();
    }
  }

  onClose() {
    this.dialogRef.close();
  }

  onDelete(id: string) {
    this.noteService.delete(id);
    this.onClose();
  }

  onEdit() {
    this.authService
      .getIsActive()
      .pipe(first())
      .subscribe((isActive) => {
        if (isActive) {
          this.noteComponentService.startEditing();
        } else {
          this.subscriptionEndedDialogService.open();
        }
      });
  }

  private closeOnNavigation() {
    this.subscription.add(
      this.router.events
        .pipe(filter((event) => event instanceof NavigationStart))
        .subscribe(() => this.onClose()),
    );
  }
}
