import { createAction, props } from '@ngrx/store';
import {
  Onboarding,
  UserOnboardingStep,
} from '@core/models/identity.interface';
import { AffiliateInfoInterface } from '@core/models/affiliate-info.interface';
import { HttpErrorResponse } from '@angular/common/http';

export const saveAffiliateInfoAction = createAction(
  '[affiliate onboarding page] save affiliate info',
  props<{ info: AffiliateInfoInterface }>(),
);

export const saveAffiliateInfoSuccessAction = createAction(
  '[affiliate api] save affiliate info success',
);

export const saveAffiliateInfoFailureAction = createAction(
  '[affiliate api] save affiliate info failure',
);

export const saveProfessionAction = createAction(
  '[Profession Page] Save',
  props<{ profession: string }>(),
);

export const saveProfessionSuccessAction = createAction(
  '[Profession API] Save Success',
  props<{ onboarding: Onboarding }>(),
);
export const saveProfessionFailureAction = createAction(
  '[Profession API] Save Failute',
  props<{ httpError: HttpErrorResponse }>(),
);

export const saveScopeAction = createAction(
  '[Scope Page] Save',
  props<{ scope: string[] }>(),
);

export const saveScopeSuccessAction = createAction(
  '[Scope API] Save Success',
  props<{ onboarding: Onboarding }>(),
);

export const saveScopeFailureAction = createAction(
  '[Scope API] Save Failure',
  props<{ httpError: HttpErrorResponse }>(),
);

export const updateOnboardingAction = createAction(
  '[Onboarding Components] Update Onboarding',
  props<{ action: UserOnboardingStep }>(),
);

export const updateOnboardingSuccessAction = createAction(
  '[Onboarding API] Update Onboarding Success',
  props<{ onboarding: Onboarding }>(),
);

export const updateOnboardingFailureAction = createAction(
  '[Onboarding API] Update Onboarding Failure',
  props<{ httpError: HttpErrorResponse }>(),
);
