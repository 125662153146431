import { Injectable } from '@angular/core';
import { ApiService } from '@core/services/api.service';
import { Observable } from 'rxjs/internal/Observable';
import { TariffInterface } from '@core/models/tariff.interface';

@Injectable({
  providedIn: 'root',
})
export class TariffService extends ApiService {
  getAll(): Observable<TariffInterface[]> {
    return this.http.get<TariffInterface[]>(this.getApiUrl(`tariffs`));
  }
}
