export function findImage(event: ClipboardEvent) {
  // use event.originalEvent.clipboard for newer chrome versions
  const items = (
    event.clipboardData || (event as any).originalEvent.clipboardData
  ).items;
  // find pasted image among pasted items
  let blob = null;
  for (const item of items) {
    if (item.type.indexOf('image') === 0) {
      blob = item.getAsFile();
    }
  }
  // return image if there is a pasted image
  return blob;
}
