import { Injectable } from '@angular/core';
import { GoalStep } from '@core/models/goal-step.interface';
import { GoalStepsViewMode } from '@core/models/target.interface';
import { Observable } from 'rxjs';
import { ApiService } from '../../services/api.service';

export type CreateGoalStepRequest = Omit<GoalStep, 'id'> & { targetId: string };
export type UpdateGoalStepRequest = Omit<
  GoalStep,
  | 'order'
  | 'orderYear'
  | 'orderMonth'
  | 'orderQuarter'
  | 'createdAt'
  | 'updatedAt'
>;

@Injectable({
  providedIn: 'root',
})
export class GoalStepsHttpService extends ApiService {
  getByGoalId(goalId: string): Observable<GoalStep[]> {
    const params = { targetId: goalId };
    return this.http.get<GoalStep[]>(this.getApiUrl('target-achievements'), {
      params,
    });
  }
  create(goalStepRequest: CreateGoalStepRequest): Observable<GoalStep> {
    return this.http.post<GoalStep>(
      this.getApiUrl('target-achievements'),
      goalStepRequest,
    );
  }
  update(stepId: string, updates: Partial<GoalStep>): Observable<GoalStep> {
    return this.http.put<GoalStep>(
      this.getApiUrl(`target-achievements/${stepId}`),
      updates,
    );
  }
  delete(stepId: string) {
    return this.http.delete(this.getApiUrl(`target-achievements/${stepId}`));
  }
  sort(ids: string[], type: GoalStepsViewMode, goalId: string) {
    return this.http.put<GoalStep[]>(
      this.getApiUrl('target-achievements/sort?targetId=' + goalId),
      {
        ids,
        type,
      },
    );
  }
}
