import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

/**
 * Сервис для работы с апи оповещений
 */
@Injectable({
  providedIn: 'root',
})
export class NotificationService extends ApiService {
  addPushSubscriber(sub: PushSubscription) {
    return this.http.put(
      this.getApiUrl('users/add-token-browser-push-notification'),
      sub.toJSON(),
    );
  }
}
