import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SelectFormHeaderComponent } from './select-form-header.component';
import { UiIconModule } from '@ui/ui-icon/ui-icon.module';

@NgModule({
  declarations: [SelectFormHeaderComponent],
  imports: [CommonModule, UiIconModule],
  exports: [SelectFormHeaderComponent],
})
export class SelectFormHeaderModule {}
