import { Injectable } from '@angular/core';
import { ApiService } from '@core/services/api.service';

@Injectable({
  providedIn: 'root',
})
export class PromoCodeService extends ApiService {
  check(code: string) {
    return this.http.get<{ isApply: boolean }>(
      this.getApiUrl(`promo-code/check/${code}`),
    );
  }

  apply(code: string) {
    return this.http.post(this.getApiUrl(`promo-code/apply`), {
      code,
    });
  }
}
