import { Pipe, PipeTransform } from '@angular/core';
import { Images } from '@core/services/images/static-images';
import { AchievementType } from '@core/models/identity.interface';

const typeToImgMap: { [key in AchievementType]: string[] } = {
  filledDays: [
    Images.Records1,
    Images.Records2,
    Images.Records3,
    Images.Records4,
    Images.Records5,
    Images.Records5,
  ],
  maxStreak: [
    Images.MaxStreak1,
    Images.MaxStreak2,
    Images.MaxStreak3,
    Images.MaxStreak4,
  ],
  affiliates: [
    Images.Affiliates,
    Images.Affiliates,
    Images.Affiliates,
    Images.Affiliates,
  ],
  questions: [
    Images.Questions,
    Images.Questions,
    Images.Questions,
    Images.Questions,
  ],
  goals: [Images.Goals, Images.Goals, Images.Goals, Images.Goals],
};

@Pipe({
  name: 'achievementImage',
})
export class AchievementImagePipe implements PipeTransform {
  transform(
    achievementType: AchievementType,
    achievementLevel: number,
  ): string {
    return typeToImgMap[achievementType][achievementLevel - 1];
  }
}
