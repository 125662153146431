import { Injectable, InjectionToken, Inject } from '@angular/core';
import { AbstractControl, ValidationErrors, Validator } from '@angular/forms';

/**
 * Ошибки валидации размера файла
 */
export interface FileSizeValidationErrors extends ValidationErrors {
  /**
   * Название ошибки
   */
  fileSize: {
    /**
     * Ошибка типа файл слишком большой
     */
    fileTooBig?: {
      actualSize: number;
      maxSize: number;
    };
  };
}

const defaultMaxFileSize = 10000000;

export const MAX_FILE_SIZE = new InjectionToken<number>('Max file size', {
  factory: () => defaultMaxFileSize,
});

/**
 * @description
 * Валидатор, проверяющий, что был передан файл нужного размера.
 *
 * @usageNotes
 *
 * ### Валидация размера файла
 *
 * ```typescript
 * const control = new FormControl(bigFile, this.fileSizeValidator.validate.bind(this.fileSizeValidator));
 *
 * console.log(control.errors); // {fileSize: {fileTooBig: {actualSize: 10, maxSize: 9 }}}
 * ```
 */
@Injectable({ providedIn: 'root' })
export class FileSizeValidator implements Validator {
  constructor(
    @Inject(MAX_FILE_SIZE)
    private readonly maxFileSize: number,
  ) {}

  validate(control: AbstractControl): FileSizeValidationErrors | null {
    const value = control.value as Blob;
    if (!value) {
      return null;
    }
    if (value.size > this.maxFileSize) {
      const err: FileSizeValidationErrors = {
        fileSize: {
          fileTooBig: {
            actualSize: value.size,
            maxSize: this.maxFileSize,
          },
        },
      };
      return err;
    }
  }
}
