import { select, Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import * as fromRoot from '@core/redux/index';
import * as TargetTypeActions from '@core/redux/target-type/target-type.actions';
import {
  TargetTypeLoad,
  TargetTypeLoadSuccess,
} from '@core/redux/target-type/target-type.actions';
import { filter } from 'rxjs/operators';
import { TargetTypeInterface } from '@core/models/target-type.interface';

@Injectable({
  providedIn: 'root',
})
export class TargetTypeService {
  constructor(private store: Store<fromRoot.State>) {}

  /**
   *
   */
  dispatchAll() {
    this.store.dispatch(new TargetTypeLoad());
  }

  dispatchAllSuccess(data) {
    this.store.dispatch(new TargetTypeLoadSuccess(data));
  }

  unSelect() {
    this.store.dispatch(new TargetTypeActions.TargetTypeUnSelect());
  }

  update(model: TargetTypeInterface) {
    this.store.dispatch(new TargetTypeActions.TargetTypeUpdate(model));
  }

  create(model: TargetTypeInterface) {
    this.store.dispatch(new TargetTypeActions.TargetTypeCreate(model));
  }

  select(id: string) {
    this.store.dispatch(new TargetTypeActions.TargetTypeSelect(id));
  }

  delete(id: string) {
    this.store.dispatch(new TargetTypeActions.TargetTypeDelete(id));
  }

  getAll() {
    return this.store.pipe(select(fromRoot.getTargetTypeListAll));
  }

  getSelected() {
    return this.store.pipe(
      select(fromRoot.getTargetTypeSelected),
      filter(_ => !!_),
    );
  }

  getLoading() {
    return this.store.pipe(select(fromRoot.getTargetTypeLoading));
  }
}
