import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

/**
 * Не работает через спрайт в хроме
 */
@Component({
  selector: 'ui-drag-icon',
  templateUrl: './drag-icon.component.html',
  styleUrls: ['./drag-icon.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DragIconComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
