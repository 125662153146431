import {
  Component,
  OnDestroy,
  OnInit,
  HostListener,
  Inject,
} from '@angular/core';
import { Validators, FormControl } from '@angular/forms';
import {
  CategoryInterface,
  CategoryDeleteModel,
} from '@core/models/category.interface';
import { Subscription } from 'rxjs';
import { CategoryService } from '@core/redux/category/category.service';
import { NoteService } from '@core/redux/note/note.service';
import { combineLatest } from 'rxjs';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { addFlag } from 'src/app/helpers/consts/event-field-name';

export interface DeleteCategoryDialogData {
  categoryId: string;
}

@Component({
  selector: 'delete-category-dialog',
  templateUrl: './delete-category-dialog.component.html',
  styleUrls: ['./delete-category-dialog.component.less'],
})
export class DeleteCategoryDialogComponent implements OnInit, OnDestroy {
  category: CategoryInterface;
  noteCount = 0;
  categoriesList: CategoryInterface[] = [];
  categoryIdControl = new FormControl(null, [Validators.required]);

  private readonly subscription$ = new Subscription();

  constructor(
    public readonly dialogRef: MatDialogRef<DeleteCategoryDialogComponent>,
    private categoryService: CategoryService,
    @Inject(MAT_DIALOG_DATA)
    private readonly data: DeleteCategoryDialogData,
    private noteService: NoteService,
  ) {}

  ngOnInit() {
    this.subscribeToState();
  }

  ngOnDestroy() {
    this.subscription$.unsubscribe();
  }

  @HostListener('document:keydown.escape', ['$event'])
  onClose(event: Event) {
    addFlag(event);
    this.dialogRef.close();
  }

  onSubmit(event: Event) {
    const model = new CategoryDeleteModel();
    model.id = this.category.id;
    model.moveId = this.categoryIdControl.value;
    this.noteService.changeCategory(model.id, model.moveId);
    this.categoryService.delete(model);
    this.onClose(event);
  }

  private subscribeToState() {
    const categories$ = this.categoryService.getAll();
    const category$ = this.categoryService.getById(this.data.categoryId);
    const sub = combineLatest([categories$, category$]).subscribe(
      ([categories, category]) => {
        this.category = category;
        if (category) {
          // TODO
          this.noteCount = category.noteCount;
          this.categoriesList = categories.filter(
            (c) => c.id !== this.category.id,
          );
          this.categoryIdControl.setValue(this.categoriesList[0].id);
        }
      },
    );
    this.subscription$.add(sub);
  }
}
