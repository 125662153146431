import { Action } from '@ngrx/store';
import { TariffInterface } from '@core/models/tariff.interface';

export enum TariffActionTypes {
  GetAllAction = '[Tariff] Get All',
  GetAllSuccessAction = '[Tariff] Get All Success',
  GetAllFailureAction = '[Tariff] Get All Failure',
}

export class GetAllAction implements Action {
  readonly type = TariffActionTypes.GetAllAction;
}

export class GetAllSuccessAction implements Action {
  readonly type = TariffActionTypes.GetAllSuccessAction;

  constructor(public payload: TariffInterface[]) {}
}

export class GetAllFailureAction implements Action {
  readonly type = TariffActionTypes.GetAllFailureAction;

  constructor(public payload: any) {}
}

export type TariffActions =
  | GetAllAction
  | GetAllSuccessAction
  | GetAllFailureAction;
