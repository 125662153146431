import * as Sentry from '@sentry/browser';
import { environment } from '../../environments/environment';
import { Injectable, ErrorHandler } from '@angular/core';
import { ReleaseService } from './redux/release/release.service';
// import * as packageJson from 'package.json';

if (environment.sentry) {
  Sentry.init({
    dsn: 'https://20694611d7784b088da7aa68936c64ec@sentry.io/1350637',
    environment: environment.name,
    // release: packageJson.version,
  });
}

@Injectable({
  providedIn: 'root',
})
export class GlobalErrorHandler implements ErrorHandler {
  private readonly chunkFailedMessage = /Loading chunk [\d]+ failed/;

  constructor(private releaseService: ReleaseService) {}

  handleError(error: Error) {
    if (!error) {
      return;
    }
    if (error.message && this.chunkFailedMessage.test(error.message)) {
      this.releaseService.receiveWebUpdate();
      return;
    }
    if (environment.sentry) {
      Sentry.captureException((error as any).originalError || error);
    }
    throw error;
  }
}
