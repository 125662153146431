import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { Socket } from 'ngx-socket-io';
import { NoteService } from '@core/redux/note/note.service';
import { CategoryService } from '@core/redux/category/category.service';
import { TagService } from '@core/redux/tag/tag.service';
import { GoalsService } from '@core/redux/goal/goals.service';
import { TargetTypeService } from '@core/redux/target-type/target-type.service';
import { SocketDataInterface } from '@core/interface/socket-data.interface';
import { AuthService } from '@core/redux/auth/auth.service';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { isPlatformServer } from '@angular/common';
import { ReleaseService } from '@core/redux/release/release.service';
import { InitService } from '@core/services/init.service';
import { DataVersionService } from '@core/services/data-version.service';
import { StoryService } from '@core/redux/story/story.service';
import { Store } from '@ngrx/store';
import { State } from '@core/redux';
import { getStoriesSuccess } from '@core/redux/story/story.actions';
import { Story } from '@core/redux/story/story.model';
import {
  BackendAchievements,
  Onboarding,
} from '@core/models/identity.interface';
import { getUserSuccessAction } from '@core/redux/auth/auth.actions';
import { updateOnboardingSuccessAction } from '@core/redux/onboarding/onboarding.actions';

@Injectable({
  providedIn: 'root',
})
export class SocketBaseService {
  private tokenBehavior: BehaviorSubject<string> = new BehaviorSubject(null);

  constructor(
    @Inject(PLATFORM_ID) private platformId: object,
    private socket: Socket,
    private store: Store<State>,
    private categoryService: CategoryService,
    private tagService: TagService,
    private targetService: GoalsService,
    private targetTypeService: TargetTypeService,
    private authService: AuthService,
    private noteService: NoteService,
    private releaseService: ReleaseService,
    private initService: InitService,
    private dataVersionService: DataVersionService,
    private storyService: StoryService,
  ) {
    if (isPlatformServer(this.platformId)) {
      return;
    }
    this.socket.on('authenticated', (_) => {
      console.log('socket.io — авторизация прошла успешно');
      this.initService.prepare();
    });
    this.socket.fromEvent('connect').subscribe(() => {
      console.log('socket.io — connect');
    });
    const connect$ = this.socket.fromEvent('connect');
    const token$ = this.tokenBehavior.pipe(filter((_) => _ !== null));
    combineLatest([connect$, token$])
      .pipe(map(([a, b]) => b))
      .subscribe((token: string) => {
        this.subscribe();
        this.socket.emit('authentication', { accessToken: token });
      });
  }

  /**
   * Проходим авторизацию на socket.io сервере по токену пользователя
   * @param token string
   */
  listen(token) {
    this.tokenBehavior.next(token);
  }

  /**
   * После logout пользователя отписываемся от каналов сокета
   */
  unsubscribe() {
    if (isPlatformServer(this.platformId)) {
      return;
    }
    this.socket.removeListener('[Note] Refresh');
    this.socket.removeListener('[User] Refresh');
    this.socket.removeListener('[Category] Refresh');
    this.socket.removeListener('[Tag] Refresh');
    this.socket.removeListener('[Target] Refresh');
    this.socket.removeListener('[TargetType] Refresh');
  }

  private subscribe() {
    this.socket.on('[Release] Web', () => {
      this.releaseService.receiveWebUpdate();
    });
    this.socket.on('[Note] Refresh', (data: SocketDataInterface) => {
      if (this.isNewDataVersion(data)) {
        this.noteService.hardReloadAll();
      }
    });
    this.socket.on('[User] Refresh', (data: SocketDataInterface) => {
      this.authService.setUser(data.user);
    });
    this.socket.on('[Category] Refresh', (data: SocketDataInterface) => {
      if (this.isNewDataVersion(data)) {
        this.categoryService.dispatchAll();
      }
    });
    this.socket.on('[Tag] Refresh', (data: SocketDataInterface) => {
      if (this.isNewDataVersion(data)) {
        this.tagService.dispatchAll();
      }
    });
    this.socket.on('[Target] Refresh', (data: SocketDataInterface) => {
      if (this.isNewDataVersion(data)) {
        this.targetService.dispatchAll();
      }
    });
    this.socket.on('[TargetType] Refresh', (data: SocketDataInterface) => {
      if (this.isNewDataVersion(data)) {
        this.targetTypeService.dispatchAll();
      }
    });

    this.socket.on('[Story] Refresh', () => {
      this.storyService.get();
    });

    this.socket.on('[Story] Update', (data: { stories: Story[] }) => {
      this.store.dispatch(getStoriesSuccess(data));
    });

    this.socket.on(
      '[Onboarding] Update',
      (data: { onboarding: Onboarding }) => {
        this.store.dispatch(updateOnboardingSuccessAction(data));
      },
    );

    this.socket.on(
      '[Achievements] Update',
      (data: { achievements: BackendAchievements; onboarding: Onboarding }) => {
        this.authService
          .getUser()
          .pipe(take(1))
          .subscribe((user) => {
            this.store.dispatch(
              getUserSuccessAction({
                user: {
                  ...user,
                  achievements: data.achievements,
                  onboarding: data.onboarding,
                },
              }),
            );
          });
      },
    );
  }

  /**
   * Проверяет совпдает ли хэш данных апи с хэшем данных в приложении.
   * Хэш передается в каждом response запросе и сохраняется в DataVersionService
   * @param data SocketDataInterface
   */
  private isNewDataVersion(data: SocketDataInterface) {
    return this.dataVersionService.version !== (data.dataVersion || null);
  }
}
