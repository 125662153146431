<div class="matdialog_adaptive promocode-confirm-apply">
  <button
    ui-button-icon
    mat-dialog-close
    class="promocode-confirm-apply__close"
    type="button"
  >
    <ui-icon class="ui-icon ui-icon_hover_close" name="close"></ui-icon>
  </button>
  <h2
    [innerHTML]="'DIALOGS.PROMOCODE_CONFIRM_APPLY.TITLE' | translate"
    class="promocode-confirm-apply__header"
  ></h2>
  <p class="promocode-confirm-apply__text">
    {{ 'DIALOGS.PROMOCODE_CONFIRM_APPLY.RESULT' | translate }}
  </p>
  <div class="promocode-confirm-apply__actions">
    <button
      (click)="onAccept()"
      ui-button-green
      class="promocode-confirm-apply__discard"
    >
      {{ 'DIALOGS.PROMOCODE_CONFIRM_APPLY.ACCEPT' | translate }}
    </button>
  </div>
</div>
