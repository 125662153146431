import { Injectable } from '@angular/core';
import { ApiService } from '@core/services/api.service';
import {
  CategoryCountNoteInterface,
  CategoryDeleteModel,
  CategoryInterface,
  CategoryQueryModel,
} from '@core/models/category.interface';
import { NoteInterface } from '@core/models/note.interface';
import { Observable } from 'rxjs/internal/Observable';

/**
 * Сервис для работы с апи категорий
 */
@Injectable({
  providedIn: 'root',
})
export class CategoryService extends ApiService {
  /**
   * Создать категорию
   */
  create(model: CategoryInterface): Observable<CategoryInterface> {
    return this.http.post<CategoryInterface>(
      this.getApiUrl(`categories`),
      model,
    );
  }

  update(model: CategoryInterface): Observable<CategoryInterface> {
    return this.http.put<CategoryInterface>(
      this.getApiUrl(`categories/${model.id}`),
      model,
    );
  }

  /**
   * Получить с сервера все категории
   */
  search(query: CategoryQueryModel): Observable<CategoryInterface[]> {
    return this.http.get<CategoryInterface[]>(
      this.getApiUrl(`categories?${query.getQuery()}`),
    );
  }

  /**
   * Получить с сервера все категории
   */
  countNote(id): Observable<CategoryCountNoteInterface> {
    return this.http.get<CategoryCountNoteInterface>(
      this.getApiUrl(`categories/count-note/${id}`),
    );
  }

  /**
   * Удалить запись
   */
  destroy(model: CategoryDeleteModel): Observable<NoteInterface> {
    let url = `categories/${model.id}`;
    if (model.moveId) {
      url += `/${model.moveId}`;
    }
    return this.http.delete<NoteInterface>(this.getApiUrl(url));
  }
}
