import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';
import { FileInterface } from '@core/models/file.interface';
import { FormControl } from '@angular/forms';
import { filePreviewImageSize } from '@core/services/images/used-sizes';
import { TOOLTIP_TOP_POSITION, withOffset } from '@ui/tooltip/tooltip.service';
import { Subscription } from 'rxjs';

/**
 * Компонент загружаемой картинки
 *
 * Имеет состояния:
 *
 * - загрузка: image = valid FormControl
 * - ошибка: image = invalid FormControl
 * - картинка: image = FileInterface
 *
 * В состояниях "ошибка" и "загрузка" есть кнопка удаления.
 * В состоянии "картинка" картинка показывается поверх лоадера, чтобы
 * пока браузер грузит картинку с сервера, лоадер все еще был.
 * В состоянии "загрузка" показан только лоадер.
 * В состоянии "ошибка" показаны иконка ошибки и тултип с описанием ошибки.
 */
@Component({
  selector: 'ui-file-preview-form',
  templateUrl: './ui-file-preview-form.component.html',
  styleUrls: ['./ui-file-preview-form.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UiFilePreviewFormComponent implements OnDestroy {
  @Output() delete = new EventEmitter();

  readonly position = withOffset(TOOLTIP_TOP_POSITION, -16, 0);

  imageControl: FormControl;
  imageFile: FileInterface;

  readonly filePreviewPreloadSizes = [filePreviewImageSize];

  private sub?: Subscription;

  constructor(private readonly changeDetector: ChangeDetectorRef) {}

  @Input()
  set image(v: FileInterface | FormControl) {
    this.sub?.unsubscribe();
    if ((v as FormControl).setAsyncValidators) {
      this.imageControl = v as FormControl;
      this.imageFile = undefined;
      this.sub = this.imageControl.statusChanges.subscribe(() => {
        this.changeDetector.markForCheck();
      });
    } else {
      this.imageFile = v as FileInterface;
      this.imageControl = undefined;
    }
  }

  ngOnDestroy() {
    this.sub?.unsubscribe();
  }

  onDelete($event) {
    $event.stopPropagation();
    this.delete.emit();
  }

  getTextForTooltip(): string {
    if (this.imageControl?.hasError('fileType')) {
      return '$VALIDATION_ERRORS.MUST_BE_IMAGE';
    }
    if (this.imageControl?.hasError('fileSize')) {
      return '$VALIDATION_ERRORS.FILE_SIZE';
    }
    if (this.imageControl?.hasError('imageSize')) {
      return '$VALIDATION_ERRORS.IMAGE_SIZE';
    }
    if (this.imageControl?.hasError('uploadFailure')) {
      return '$VALIDATION_ERRORS.UPLOAD_FAILURE';
    }
    return '';
  }
}
