import { HttpErrorResponse } from '@angular/common/http';
import { GoalStep } from '@core/models/goal-step.interface';
import { GoalStepsViewMode } from '@core/models/target.interface';
import {
  CreateGoalStepRequest,
  UpdateGoalStepRequest,
} from '@core/redux/goal-step/goal-steps-http.service';
import { createAction, props } from '@ngrx/store';

export const createGoalStepAction = createAction(
  '[goal page] create goal step',
  props<{ request: CreateGoalStepRequest }>(),
);
export const createGoalStepSuccessAction = createAction(
  '[goal api] create goal step success',
  props<{ goalStep: GoalStep }>(),
);
export const createGoalStepFailureAction = createAction(
  '[goal api] create goal step failure',
  props<{ httpError: HttpErrorResponse }>(),
);

export const getStepsForGoalAction = createAction(
  '[goal page] get steps for goal',
  props<{ goalId: string }>(),
);
export const getStepsForGoalSuccessAction = createAction(
  '[goal api] get steps for goal success',
  props<{ goalSteps: GoalStep[] }>(),
);
export const getStepsForGoalFailureAction = createAction(
  '[goal api] get steps for goal failure',
  props<{ httpError: HttpErrorResponse }>(),
);

export const deleteGoalStepAction = createAction(
  '[goal page] delete goal step',
  props<{ goalStepId: string }>(),
);
export const deleteGoalStepSuccessAction = createAction(
  '[goal step api] delete goal step success',
  props<{ goalStepId: string }>(),
);
export const deleteGoalStepFailureAction = createAction(
  '[goal step api] delete goal step failure',
  props<{ httpError: HttpErrorResponse }>(),
);

export const updateGoalStepAction = createAction(
  '[goal page] update goal step',
  props<{ request: UpdateGoalStepRequest }>(),
);
export const updateGoalStepSuccessAction = createAction(
  '[goal step api] update goal step success',
  props<{ goalStep: GoalStep }>(),
);
export const updateGoalStepFailureAction = createAction(
  '[goal step api] update goal step failure',
  props<{ httpError: HttpErrorResponse }>(),
);
export const sortGoalStepsAction = createAction(
  '[goal page] sort goal steps',
  props<{
    goalId: string;
    ids: string[];
    view: GoalStepsViewMode;
    changes: { [id: string]: Partial<GoalStep> };
    sortField: keyof Pick<
      GoalStep,
      'order' | 'orderYear' | 'orderMonth' | 'orderQuarter'
    >;
  }>(),
);
export const sortGoalStepsSuccessAction = createAction(
  '[goal step api] sort goal steps success',
  props<{ goalSteps: GoalStep[] }>(),
);
export const sortGoalStepsFailureAction = createAction(
  '[goal step api] sort goal steps failure',
  props<{ httpError: HttpErrorResponse }>(),
);
