import { parseLangString, Language } from '@helpers/consts/language';

export interface TariffInterface {
  id: string;
  badge: string;
  oldPrice: number;
  price: number;
  months: number;
  title: string;
  /**
   * Сумма к оплате
   */
  sum: number;
  paymentBonus: string;
  description: string;
  /**
   * Нижняя строчка описания большого акционного тарифа
   */
  subDescription?: string;
  currency: Currency;
  isRenewable: boolean;
  /**
   * Если true, то показывать большую карточку
   */
  isFavorite: boolean;
  /**
   * Если true, то выделен по умолчанию
   */
  isSelected: boolean;
}

type Currency = 'RUB' | 'USD';

const rubCurrency: Currency = 'RUB';
const usdCurrency: Currency = 'USD';

const currencies = [rubCurrency, usdCurrency];

const priceStrings: {
  [lang in Language]: {
    [currency in Currency]: (price: number) => string;
  };
} = {
  ru: {
    RUB: (price) => `${price} рублей`,
    USD: (price) => `$${price}`,
  },
  en: {
    RUB: (price) => `${price} rub`,
    USD: (price) => `$${price}`,
  },
};

export function getPriceString(tariff: TariffInterface, lang: string): string {
  if (tariff.price === Infinity) {
    return '';
  }
  const parsedLang = parseLangString(lang);
  if (currencies.indexOf(tariff.currency) === -1) {
    return `${tariff.price} ${tariff.currency}`;
  }
  const price: string = priceStrings[parsedLang][tariff.currency](tariff.price);
  return price;
}

export function emptyTariff(
  tariff: Partial<TariffInterface> = {},
): TariffInterface {
  return {
    id: '',
    badge: '',
    oldPrice: 0,
    price: 0,
    currency: 'USD',
    months: 0,
    sum: 0,
    title: '',
    description: '',
    paymentBonus: '',
    isRenewable: true,
    isFavorite: false,
    isSelected: false,
    ...tariff,
  };
}
