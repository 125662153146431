import { FileInterface } from './file.interface';
import { zoneOptions } from 'src/app/helpers/consts/zone-options';
import { Story } from '@core/redux/story/story.model';

export enum SignSource {
  Email = 'email',
  Facebook = 'facebook',
  Vk = 'vk',
  Apple = 'apple',
  E2e = 'e2e',
}

interface IdentityCommonFields {
  avatar?: FileInterface;
  id: string;
  firstName: string;
  email: string;
  phone: string;
  token: string;
  isActive: boolean;
  isTest: boolean;
  expiredAt: string;
  isConfirmation: boolean;
  isNotificationEmail: boolean;
  isNotificationTelegram: boolean;
  notificationTime: number;
  timeZone: string;
  promoCodes: any[];
  affiliateCode: string;
  affiliateReward: number;
  affiliateBalance: number;
  totalAffiliateSum: number;
  language: 'ru' | 'en';
  isRequestInputEmail: boolean;
  isNew: boolean;
  connectedSocialAccounts: string[];
  onboarding: Onboarding;
  signInFrom: SignSource;
  signUpFrom: SignSource;
}

export interface BackendIdentity extends IdentityCommonFields {
  achievements: BackendAchievements;
}

export interface OnboardingAction {
  action: UserOnboardingStep;
  scenario: 'global' | 'affiliates';
  stories?: Story[];
}

export interface Onboarding {
  actions: OnboardingAction[];
}

export interface IdentityInterface extends IdentityCommonFields {
  achievements: Achievements;
}

export type BackendAchievements = {
  [key in AchievementType]: BackendAchievement;
};
export type Achievements = {
  [key in AchievementType]: Achievement;
};

export interface BackendAchievementLevel {
  achieved: boolean;
  stepsNeeded: number;
  levelNumber: number;
  hide: boolean;
}

export interface AchievementLevel extends BackendAchievementLevel {
  nextLevel?: AchievementLevel;
  prevLevel?: AchievementLevel;
}

export type AchievementType =
  | 'affiliates'
  | 'goals'
  | 'maxStreak'
  | 'questions'
  | 'filledDays';

interface AchievementCommonFields {
  type: AchievementType;
  stepsDone: number;
  maxLevel: number;
}

export interface BackendAchievement extends AchievementCommonFields {
  currentLevel: number;
  levels: BackendAchievementLevel[];
}

export interface Achievement extends AchievementCommonFields {
  currentLevel: AchievementLevel;
  levels: AchievementLevel[];
}

export interface ReferralInterface {
  id: string;
  email: string;
  firstName: string;
  isTest: boolean;
  isActive: boolean;
  countTargets: number;
  countNotes: number;
  lastActive: string;
  sumReferralFees: number;
  createdAt: string;
}

export type UserOnboardingStep =
  | 'affiliates-page'
  | 'greeting-page'
  | 'task-page'
  | 'result-page'
  | 'video-page'
  | 'likes-page'
  | 'classification-page'
  | 'create-first-note'
  | 'create-first-note-from-video'
  | 'show-achievement:affiliates'
  | 'show-achievement:goals'
  | 'show-achievement:max-streak'
  | 'show-achievement:questions'
  | 'show-achievement:filled-days'
  | 'show-popup:notification-settings'
  | 'show-stories-tooltip:daily-content'
  | 'show-stories-tooltip:next-step'
  | 'show-stories-tooltip:in-three-hours';

function mockAchievement(type: AchievementType): BackendAchievement {
  return {
    type,
    stepsDone: 0,
    levels: [{ levelNumber: 1, stepsNeeded: 1, achieved: true, hide: false }],
    maxLevel: 1,
    currentLevel: 1,
  };
}

export function getEmptyIdentity(
  identity: Partial<IdentityInterface> = {},
): IdentityInterface {
  const backendAchievements: BackendAchievements = {
    maxStreak: mockAchievement('maxStreak'),
    questions: mockAchievement('questions'),
    goals: mockAchievement('goals'),
    affiliates: mockAchievement('affiliates'),
    filledDays: mockAchievement('filledDays'),
  };
  return {
    id: '',
    avatar: undefined,
    firstName: '',
    email: '',
    phone: '',
    token: '',
    isActive: false,
    isTest: false,
    expiredAt: '3000-02-02',
    isConfirmation: false,
    isNotificationEmail: false,
    isNotificationTelegram: false,
    notificationTime: 20,
    timeZone: zoneOptions[0],
    promoCodes: [],
    affiliateCode: '',
    affiliateReward: 0,
    affiliateBalance: 0,
    totalAffiliateSum: 0,
    onboarding: {
      actions: [],
    },
    achievements: processAchievements(backendAchievements),
    connectedSocialAccounts: [],
    language: 'en',
    isNew: false,
    isRequestInputEmail: false,
    signInFrom: SignSource.E2e,
    signUpFrom: SignSource.E2e,
    ...identity,
  };
}

export function processAchievement(
  backendAchievement: BackendAchievement,
): Achievement {
  const levels: AchievementLevel[] = backendAchievement.levels.map(
    (level, index) => ({
      ...level,
      nextLevel: backendAchievement.levels[index + 1],
      prevLevel: backendAchievement.levels[index - 1],
    }),
  );
  // поправит ошибку на беке
  // беку нельзя доверять, он присылает currentLevel: 1,
  // когда уровень 0
  const currentLevelNumber = backendAchievement.levels[0].achieved
    ? backendAchievement.currentLevel
    : 0;
  return {
    ...backendAchievement,
    currentLevel: levels[currentLevelNumber - 1],
    levels,
  };
}

export function processAchievements(
  backendAchievements: BackendAchievements,
): Achievements {
  const result = {};
  for (const [key, value] of Object.entries(backendAchievements)) {
    result[key] = processAchievement(value);
  }
  return result as Achievements;
}

/**
 * На беке есть разделение достижений
 * show-achievement:records
 * show-achievement:records:extended (для первого уровня)
 * На фронте оно не нужно, эта функция обрезает :extended
 */
export function processOnboarding(onboarding: Onboarding): Onboarding {
  return {
    actions: onboarding.actions.map((action) => ({
      ...action,
      action: action.action.replace(':extended', '') as UserOnboardingStep,
    })),
  };
}

/**
 * Добавляет дополнительные поля в объект с бека
 */
export function processIdentity(
  backendIdentity: BackendIdentity,
): IdentityInterface {
  return {
    ...backendIdentity,
    achievements: processAchievements(backendIdentity.achievements),
    onboarding: processOnboarding(backendIdentity.onboarding),
  };
}
