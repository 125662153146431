<div #content (clickOutside)="onClickOutside($event)" class="tooltip__content">
  <div [class.tooltip__disabled-content]="hasDisabledButton">
    <ng-content select="content"></ng-content>
  </div>
</div>
<div
  #tip
  [style.z-index]="zIndex"
  class="body-text body-text_style_2 tooltip__tip"
>
  <ng-content select="tip"></ng-content>

  <div
    [class.tooltip__arrow_big]="arrowSize === 'big'"
    class="tooltip__arrow"
  ></div>
</div>
