<div class="file-preview__shadow file-preview__shadow_visible">
  <div class="file-preview__opacity">
    <div *ngIf="!imageControl?.invalid" class="file-preview__loader">
      <span class="file-preview__dot"></span>
      <span class="file-preview__dot"></span>
      <span class="file-preview__dot"></span>
    </div>
    <ui-icon
      *ngIf="imageControl?.invalid"
      ngProjectAs="content"
      name="info"
      class="file-preview__error ui-icon_color_white"
    >
    </ui-icon>
  </div>
</div>
<ui-file-preview
  *ngIf="imageFile"
  [image]="imageFile"
  [preloadSizes]="filePreviewPreloadSizes"
>
</ui-file-preview>

<ng-template #errorTemplate>
  <rt-tooltip [text]="getTextForTooltip() | translate"></rt-tooltip>
</ng-template>

<!--
  Кнопка удаления. Показывается, если картинка загружена или есть ошибка валидации.
  Если есть ошибки валидации, показывается с тултипом при ховере.
-->
<div
  *ngIf="imageControl?.invalid || imageFile"
  class="file-preview__shadow"
  appTooltip
  [tooltipContent]="errorTemplate"
  [tooltipPosition]="position"
  [tooltipEnabled]="imageControl?.invalid"
>
  <div class="file-preview__opacity">
    <button
      (click)="onDelete($event)"
      ui-button
      class="file-preview__button"
      type="button"
    >
      <ui-icon
        class="ui-icon ui-icon_color_white file-preview__icon-trash"
        name="trash"
      >
      </ui-icon>
    </button>
  </div>
</div>
