import { Action } from '@ngrx/store';
import {
  CategoryDeleteModel,
  CategoryInterface,
  CategoryQueryModel,
} from '@core/models/category.interface';

export enum CategoryActionTypes {
  CategorySelect = '[Category] Select',
  CategoryUnSelect = '[Category] Un Select',
  CategoryLoad = '[Category] Load',
  CategoryLoadSuccess = '[Category] Load Success',
  CategoryLoadFailure = '[Category] Load Failure',
  CategoryCreate = '[Category] Create',
  CategoryCreateSuccess = '[Category] Create Success',
  CategoryCreateFailure = '[Category] Create Failure',
  CategoryUpdate = '[Category] Update',
  CategoryUpdateSuccess = '[Category] Update Success',
  CategoryUpdateFailure = '[Category] Update Failure',
  CategoryDelete = '[Category] Delete',
  CategoryDeleteSuccess = '[Category] Delete Success',
  CategoryDeleteFailure = '[Category] Delete Failure',
}

export class CategoryLoad implements Action {
  readonly type = CategoryActionTypes.CategoryLoad;

  constructor(public payload: CategoryQueryModel) {}
}

export class CategoryLoadSuccess implements Action {
  readonly type = CategoryActionTypes.CategoryLoadSuccess;

  constructor(public payload: CategoryInterface[]) {}
}

export class CategoryLoadFailure implements Action {
  readonly type = CategoryActionTypes.CategoryLoadFailure;

  constructor(public payload: any) {}
}

export class CategoryCreate implements Action {
  readonly type = CategoryActionTypes.CategoryCreate;

  constructor(public payload: CategoryInterface) {}
}

export class CategoryCreateSuccess implements Action {
  readonly type = CategoryActionTypes.CategoryCreateSuccess;

  constructor(public payload: CategoryInterface) {}
}

export class CategoryCreateFailure implements Action {
  readonly type = CategoryActionTypes.CategoryCreateFailure;

  constructor(public payload: any) {}
}

export class CategoryUpdate implements Action {
  readonly type = CategoryActionTypes.CategoryUpdate;

  constructor(public payload: CategoryInterface) {}
}

export class CategoryUpdateSuccess implements Action {
  readonly type = CategoryActionTypes.CategoryUpdateSuccess;

  constructor(public payload: CategoryInterface) {}
}

export class CategoryUpdateFailure implements Action {
  readonly type = CategoryActionTypes.CategoryUpdateFailure;

  constructor(public payload: any) {}
}

export class CategoryDelete implements Action {
  readonly type = CategoryActionTypes.CategoryDelete;

  constructor(public payload: CategoryDeleteModel) {}
}

export class CategoryDeleteSuccess implements Action {
  readonly type = CategoryActionTypes.CategoryDeleteSuccess;

  constructor(public id: string) {}
}

export class CategoryDeleteFailure implements Action {
  readonly type = CategoryActionTypes.CategoryDeleteFailure;

  constructor(public payload: any) {}
}

export class CategorySelect implements Action {
  readonly type = CategoryActionTypes.CategorySelect;

  constructor(public payload: string) {}
}

export class CategoryUnSelect implements Action {
  readonly type = CategoryActionTypes.CategoryUnSelect;

  constructor() {}
}

export type CategoryActions =
  | CategoryLoad
  | CategoryLoadSuccess
  | CategoryLoadFailure
  | CategoryCreate
  | CategoryCreateSuccess
  | CategoryCreateFailure
  | CategoryUpdate
  | CategoryUpdateSuccess
  | CategoryUpdateFailure
  | CategoryDelete
  | CategoryDeleteSuccess
  | CategoryDeleteFailure
  | CategorySelect
  | CategoryUnSelect;
