import {
  Injectable,
  InjectionToken,
  Injector,
  StaticProvider,
} from '@angular/core';
import { ComponentPortal, ComponentType } from '@angular/cdk/portal';
import {
  NoopScrollStrategy,
  BlockScrollStrategy,
  ViewportRuler,
  Overlay,
  OverlayConfig,
  OverlayRef,
} from '@angular/cdk/overlay';
import { hasDoubleHeader } from '@helpers/consts/threshold';
import { InfoPopupRef } from './info-popup-ref';
import { InfoPopupDesktopContainerComponent } from './info-popup-desktop-container/info-popup-desktop-container.component';
import { InfoPopupMobileContainerComponent } from './info-popup-mobile-container/info-popup-mobile-container.component';

interface InfoPopupConfigDefaults {
  width: number;
}

export type InfoPopupConfig<D> = Partial<InfoPopupConfigDefaults> & {
  data?: D;
};

const defaultConfig: InfoPopupConfigDefaults = {
  width: 500,
};

export const INFO_POPUP_DATA = new InjectionToken('InfoPopupData');

@Injectable({
  providedIn: 'root',
})
export class InfoPopupService {
  /**
   * Ширина, меньше которой переходить в мобильный режим
   */
  readonly mobileThreshold = 550;
  private readonly mobilePosition = {
    bottom: '0px',
  };
  private readonly desktopPosition = {
    top: '96px',
    right: '24px',
  };
  private readonly tabletPosition = {
    ...this.desktopPosition,
    top: '156px',
  };

  constructor(
    private readonly injector: Injector,
    private readonly overlay: Overlay,
    private readonly viewportRuler: ViewportRuler,
  ) {}

  open<C, D>(
    component: ComponentType<C>,
    infoPopupConfig: InfoPopupConfig<D> = {},
  ): InfoPopupRef<C> {
    const config = {
      ...defaultConfig,
      ...infoPopupConfig,
    };
    const isMobile = this.isMobile();
    if (isMobile) {
      return this.openMobileVersion(component, config.data);
    } else {
      return this.openDesktopVersion(component, config);
    }
  }

  private createInjector<C, D>(
    data: D,
    infoPopupRef: InfoPopupRef<C>,
  ): Injector {
    const providers: StaticProvider[] = [
      { provide: INFO_POPUP_DATA, useValue: data || {} },
      { provide: InfoPopupRef, useValue: infoPopupRef },
    ];
    return Injector.create({
      parent: this.injector,
      providers,
    });
  }

  private isMobile(): boolean {
    return window.innerWidth < this.mobileThreshold;
  }

  private openDesktopVersion<C, D>(
    component: ComponentType<C>,
    config: InfoPopupConfig<D>,
  ): InfoPopupRef<C> {
    const scrollStrategy = new NoopScrollStrategy();
    const position = hasDoubleHeader()
      ? this.tabletPosition
      : this.desktopPosition;
    const positionStrategy = this.overlay.position().global();
    positionStrategy.right(position.right);
    positionStrategy.top(position.top);
    const overlayConfig = new OverlayConfig({
      positionStrategy,
      scrollStrategy,
      maxHeight: '75vh',
      width: config.width + 'px',
    });
    const overlayRef = this.overlay.create(overlayConfig);
    const infoPopupContainer = this.attachContainer(
      InfoPopupDesktopContainerComponent,
      overlayRef,
    );
    const infoPopupRef = new InfoPopupRef(overlayRef, infoPopupContainer);
    const injector = this.createInjector(config.data, infoPopupRef);
    this.attachContent(component, infoPopupContainer, injector);
    return infoPopupRef;
  }

  private openMobileVersion<C, D>(
    component: ComponentType<C>,
    data: D,
  ): InfoPopupRef<C> {
    const scrollStrategy = new BlockScrollStrategy(
      this.viewportRuler,
      document,
    );
    const positionStrategy = this.overlay.position().global();
    positionStrategy.centerHorizontally().bottom(this.mobilePosition.bottom);
    const overlayConfig = new OverlayConfig({
      width: '100vw',
      maxHeight: '80vh',
      scrollStrategy,
      positionStrategy,
      hasBackdrop: true,
    });
    const overlayRef = this.overlay.create(overlayConfig);
    const infoPopupContainer = this.attachContainer(
      InfoPopupMobileContainerComponent,
      overlayRef,
    );
    const infoPopupRef = new InfoPopupRef(overlayRef, infoPopupContainer);
    const injector = this.createInjector(data, infoPopupRef);
    this.attachContent(component, infoPopupContainer, injector);
    return infoPopupRef;
  }

  private attachContainer<C>(
    containerType: ComponentType<C>,
    overlayRef: OverlayRef,
  ) {
    const containerPortal = new ComponentPortal(containerType);
    const containerRef = overlayRef.attach(containerPortal);
    return containerRef.instance;
  }

  private attachContent<C>(
    component: ComponentType<C>,
    container: InfoPopupDesktopContainerComponent,
    injector: Injector,
  ) {
    container.attachComponentPortal(
      new ComponentPortal(component, undefined, injector),
    );
  }
}
