import { createAction, props } from '@ngrx/store';
import { SignInInterface } from '@core/models/sign-in.interface';
import { BackendIdentity } from '@core/models/identity.interface';
import { SignUpInterface } from '@core/models/sign-up.interface';
import { SignInSocialInterface } from '@core/models/sign-in-social.interface';
import { AddAccountInterface } from '@core/models/add-account.interface';
import { HttpErrorResponse } from '@angular/common/http';
import { FileInterface } from '@core/models/file.interface';

export const signOutAction = createAction('[app] sign out');

export const signOutSuccessAction = createAction(
  '[account api] sign out success',
);

export const setTokenAction = createAction(
  '[app] set token',
  props<{ token: string }>(),
);

export const signInAction = createAction(
  '[auth page] sign in',
  props<{ credentials: SignInInterface; redirect?: string }>(),
);

export const signInSuccessAction = createAction(
  '[account api] sign in success',
  props<{ user: BackendIdentity; redirect?: string }>(),
);

export const signInFailureAction = createAction(
  '[account api] sign in failure',
  props<{ error: HttpErrorResponse }>(),
);

export const signInSocialAction = createAction(
  '[auth page] sign in social',
  props<{ data: SignInSocialInterface }>(),
);

export const signInSocialFailureAction = createAction(
  '[account api] sign in social failure',
  props<{ error: HttpErrorResponse }>(),
);

export const addAccountAction = createAction(
  '[account page] add account',
  props<{ data: AddAccountInterface }>(),
);

export const addAccountSuccessAction = createAction(
  '[account api] add account success',
  props<{ user: BackendIdentity }>(),
);

export const addAccountFailureAction = createAction(
  '[account api] add account failure',
  props<{ error: HttpErrorResponse }>(),
);

export const signUpAction = createAction(
  '[auth page] sign up',
  props<{ credentials: SignUpInterface }>(),
);

export const signUpSuccessAction = createAction(
  '[account api] sign up success',
  props<{ user: BackendIdentity }>(),
);

export const signUpFailureAction = createAction(
  '[account api] sign up failure',
  props<{ error: HttpErrorResponse }>(),
);

export const getUserAction = createAction('[app] get user');

export const getUserSuccessAction = createAction(
  '[account api] get user success',
  props<{ user: BackendIdentity }>(),
);

export const getUserFailureAction = createAction(
  '[account api] get user failure',
  props<{ error: HttpErrorResponse }>(),
);

export const setEmailAction = createAction(
  '[account api] set email',
  props<{ email: string }>(),
);

export const setEmailSuccessAction = createAction(
  '[account api] set email success',
  props<{ user: BackendIdentity }>(),
);

export const setEmailFailureAction = createAction(
  '[account api] set email failure',
  props<{ error: HttpErrorResponse }>(),
);

export const changePasswordAction = createAction(
  '[account api] change password',
  props<{ newPassword: string }>(),
);

export const changePasswordSuccessAction = createAction(
  '[account api] change password success',
  props<{ user: BackendIdentity }>(),
);

export const changePasswordFailureAction = createAction(
  '[account api] change password failure',
  props<{ error: HttpErrorResponse }>(),
);

export const checkPasswordResetTokenAction = createAction(
  '[account api] change password reset token',
  props<{ token: string }>(),
);

export const checkPasswordResetTokenSuccessAction = createAction(
  '[account api] change password reset token success',
  props<{ user: BackendIdentity }>(),
);

export const checkPasswordResetTokenFailureAction = createAction(
  '[account api] check password reset token failure',
  props<{ error: HttpErrorResponse }>(),
);

export const updateProfileAction = createAction(
  '[account api] update profile',
  props<{ user: BackendIdentity }>(),
);

export const updateProfileSuccessAction = createAction(
  '[account api] update profile success',
  props<{ user: BackendIdentity }>(),
);

export const updateProfileFailureAction = createAction(
  '[account api] update profile failure',
  props<{ error: HttpErrorResponse }>(),
);

export const updateAvatarAction = createAction(
  '[account page] update avatar',
  props<{ newAvatar: File }>(),
);

export const updateAvatarSuccessAction = createAction(
  '[account api] update avatar success',
  props<{ avatar: FileInterface }>(),
);

export const updateAvatarFailureAction = createAction(
  '[account api] update avatar failure',
  props<{ error: HttpErrorResponse }>(),
);
