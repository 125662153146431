import { Injectable } from '@angular/core';
import { ApiService } from '@core/services/api.service';
import { FileInterface } from '@core/models/file.interface';

@Injectable({
  providedIn: 'root',
})
export class FileService extends ApiService {
  upload(file: File, forObject: string = 'note') {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    formData.append('forObject', forObject);

    return this.http.post(this.getApiUrl('files'), formData);
  }

  uploadAvatar(file: File) {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);

    return this.http.post<FileInterface>(
      this.getApiUrl('files/upload-avatar'),
      formData,
    );
  }
}
