<ng-container *ngIf="note$ | async as note">
  <div class="note__header">
    <h2 class="note__title heading heading_h2">
      {{ dateString$ | async }}
    </h2>
    <h2 class="note__title note__title_mobile heading heading_h2">
      {{ shortDateString$ | async }}
    </h2>
    <div class="note__header-right">
      <ng-container *ngIf="(editable$ | async) === false">
        <note-progress
          *ngIf="note?.targetProgress || note?.target"
          [note]="note"
          class="note__progress"
        >
        </note-progress>
        <button (click)="onDelete(note.id)" ui-button-icon type="button">
          <ui-icon
            class="ui-icon ui-icon_hover_close-red"
            name="trash"
          ></ui-icon>
        </button>
        <button (click)="onEdit()" ui-button-icon type="button">
          <ui-icon class="ui-icon ui-icon_hover_close" name="edit"></ui-icon>
        </button>
      </ng-container>
      <button
        *ngIf="!shouldShowOnboarding"
        (click)="onClose()"
        ui-button-icon
        type="button"
      >
        <ui-icon class="ui-icon ui-icon_hover_close" name="close"></ui-icon>
      </button>
    </div>
  </div>

  <note-view *ngIf="notEditable$ | async" [note]="note$ | async"></note-view>

  <note-form
    *ngIf="editable$ | async"
    (deleted)="onClose()"
    [note]="note$ | async"
  ></note-form>
</ng-container>
