import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-offline-notification',
  templateUrl: './offline-notification.component.html',
  styleUrls: ['./offline-notification.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OfflineNotificationComponent implements OnDestroy {
  private readonly refreshClicks$ = new Subject();
  private readonly closeClicks$ = new Subject();

  ngOnDestroy(): void {
    this.refreshClicks$.complete();
    this.closeClicks$.complete();
  }

  refreshClicks() {
    return this.refreshClicks$.asObservable();
  }

  closeClicks() {
    return this.closeClicks$.asObservable();
  }

  onRefreshClick() {
    this.refreshClicks$.next();
  }

  onCloseClick() {
    this.closeClicks$.next();
  }
}
