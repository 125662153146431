import { FileInterface, emptyFile } from '@core/models/file.interface';
import {
  TargetTypeInterface,
  emptyTargetType,
} from '@core/models/target-type.interface';

export enum GoalStepsViewMode {
  List = 'list',
  Years = 'year',
  Months = 'month',
  Quarters = 'quarter',
}

/**
 * Модель цели
 */
export class TargetInterface {
  id: string;
  title: string;
  color: string;
  progress: number;
  criterionDescription: string;
  noteCount: number;
  target: number;
  isFocus: boolean;
  isComplete: boolean;
  isDelete: boolean;
  order: number;
  userId: number;
  deadlineAt?: string;
  completedAt: string;
  updatedAt: string;
  createdAt: string;
  image: FileInterface;
  typeId: string;
  type: TargetTypeInterface;
  achievementView: GoalStepsViewMode;
}

export function emptyTarget(
  goal: Partial<TargetInterface> = {},
): TargetInterface {
  return {
    id: '',
    title: '',
    color: 'black',
    progress: 0,
    criterionDescription: '',
    noteCount: 0,
    target: 0,
    isFocus: false,
    isComplete: false,
    isDelete: false,
    order: 0,
    userId: 0,
    completedAt: '2020-01-09',
    updatedAt: '2020-01-09',
    createdAt: '2020-01-09',
    image: emptyFile(),
    typeId: '',
    type: emptyTargetType(),
    achievementView: GoalStepsViewMode.List,
    ...goal,
  };
}

export function calcProgress(goal: TargetInterface): number {
  const progress = goal.progress || 0;
  const target = goal.target || 0;
  if (target === 0) {
    return 0;
  }
  return Math.round((progress / target) * 100);
}
