// DOM element manipulation functions...
//

export function insertValue(
  el: HTMLInputElement,
  start: number,
  end: number,
  text: string,
) {
  const val = el.value;
  el.value = val.substring(0, start) + text + val.substring(end, val.length);
  setCaretPosition(el, start + text.length);
}

export function setCaretPosition(el: HTMLInputElement, pos: number) {
  if (el.selectionStart) {
    el.focus();
    el.setSelectionRange(pos, pos);
  } else {
    const range = document.createRange();
    range.setStart(el, pos);
    range.collapse(true);
    const sel = window.getSelection();
    sel.removeAllRanges();
    sel.addRange(range);
  }
}

export function getCaretPosition(el: HTMLInputElement) {
  const val = el.value;
  return val.slice(0, el.selectionStart).length;
}

// Based on ment.io functions...
//

export function getContentEditableCaretCoords(parent: Element) {
  const markerTextChar = '\ufeff';
  const markerId =
    'sel_' +
    new Date().getTime() +
    '_' +
    Math.random()
      .toString()
      .substr(2);
  const sel = window.getSelection();
  const prevRange = sel.getRangeAt(0);

  // create new range and set postion using prevRange
  const range = document.createRange();
  range.setStart(sel.anchorNode, prevRange.startOffset);
  range.setEnd(sel.anchorNode, prevRange.startOffset);
  range.collapse(false);

  // Create the marker element containing a single invisible character
  // using DOM methods and insert it at the position in the range
  const markerEl = document.createElement('span');
  markerEl.id = markerId;
  markerEl.appendChild(document.createTextNode(markerTextChar));
  range.insertNode(markerEl);
  sel.removeAllRanges();
  sel.addRange(prevRange);

  const coordinates = {
    left: 0,
    top: markerEl.offsetHeight,
  };

  localToRelativeCoordinates(parent, markerEl, coordinates);

  markerEl.parentNode.removeChild(markerEl);
  return coordinates;
}

function localToRelativeCoordinates(
  parent: Element,
  element: Element,
  coordinates: { top: number; left: number },
) {
  let obj = element as HTMLElement;
  while (obj) {
    if (parent != null && parent === obj) {
      break;
    }
    coordinates.left += obj.offsetLeft + obj.clientLeft;
    coordinates.top += obj.offsetTop + obj.clientTop;
    obj = obj.offsetParent as HTMLElement;
  }
  obj = element as HTMLElement;
  while (obj !== document.body && obj != null) {
    if (parent != null && parent === obj) {
      break;
    }
    if (obj.scrollTop && obj.scrollTop > 0) {
      coordinates.top -= obj.scrollTop;
    }
    if (obj.scrollLeft && obj.scrollLeft > 0) {
      coordinates.left -= obj.scrollLeft;
    }
    obj = obj.parentNode as HTMLElement;
  }
}
