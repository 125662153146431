import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SubscriptionEndedComponent } from './subscription-ended.component';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { UiIconModule } from '@ui/ui-icon/ui-icon.module';
import { ButtonsModule } from '@ui/buttons/buttons.module';

@NgModule({
  declarations: [SubscriptionEndedComponent],
  exports: [SubscriptionEndedComponent],
  imports: [
    CommonModule,
    TranslateModule,
    UiIconModule,
    RouterModule,
    ButtonsModule,
  ],
  entryComponents: [SubscriptionEndedComponent],
})
export class SubscriptionEndedModule {}
