import { Injectable } from '@angular/core';
import { ApiService } from '@core/services/api.service';
import { NoteInterface } from '@core/models/note.interface';
import { Observable } from 'rxjs/internal/Observable';
import { TargetTypeInterface } from '@core/models/target-type.interface';

/**
 * Сервис для работы с апи типами целей
 */
@Injectable({
  providedIn: 'root',
})
export class TargetTypeService extends ApiService {
  /**
   * Создать
   */
  create(model: TargetTypeInterface): Observable<TargetTypeInterface> {
    return this.http.post<TargetTypeInterface>(
      this.getApiUrl(`target-types`),
      model,
    );
  }

  /**
   * Обновить
   */
  update(model: TargetTypeInterface): Observable<TargetTypeInterface> {
    return this.http.put<TargetTypeInterface>(
      this.getApiUrl(`target-types/${model.id}`),
      model,
    );
  }

  /**
   * Получить с сервера все типы
   */
  search(): Observable<TargetTypeInterface[]> {
    return this.http.get<TargetTypeInterface[]>(this.getApiUrl(`target-types`));
  }

  /**
   * Удалить запись
   */
  destroy(id: string): Observable<NoteInterface> {
    return this.http.delete<NoteInterface>(
      this.getApiUrl(`target-types/${id}`),
    );
  }
}
