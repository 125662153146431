export function preloadImage(url: string): Promise<unknown> {
  const img = new Image();
  img.src = url;
  let onloadListener: () => void;
  let onerrorListener: () => void;
  const promise = new Promise((resolve, reject) => {
    onloadListener = () => resolve(true);
    onerrorListener = () => reject();
    img.onload = onloadListener;
    img.onerror = onerrorListener;
  });
  return promise.finally(() => {
    img.removeEventListener('load', onloadListener);
    img.removeEventListener('error', onerrorListener);
  });
}
