export const iconSelectorIcons = [
  {
    name: 'Основные',
    isIntersecting: false,
    icons: [
      'comp',
      'roller3',
      'heart2',
      'wood2',
      'thunder',
      'lamp2',
      'add-user2',
      'marker2',
      'lamp-category',
      'dimond2',
      'taskk',
      'ghostt',
      'firee',
      'musicc',
      'book-openn',
      'smilee',
      'wallett',
      'picturee',
      'calll',
      'rocket-category',
      'groupp',
      'userr',
      'pencil',
      'eyee',
      'dropp',
      'winee',
      'searchh',
      'starr',
      'like-category',
      'save',
      'movie-lane',
      'movie-lanee2',
      'gift',
      'pen-tool',
      'stamp',
      'sun',
    ],
  },
  {
    name: 'Предметы',
    isIntersecting: false,
    icons: [
      'weight',
      'key',
      'globe',
      'alarm-clock',
      'stairs',
      'earth',
      'bookk',
      'chair',
      'couch',
      'pictureee',
      'bath',
    ],
  },
  {
    name: 'Одежда',
    isIntersecting: false,
    icons: [
      'hanger',
      't-shirt',
      'shorts',
      'shirt',
      'comp',
      'tie',
      'shoes',
      'sneakers',
      'socks',
    ],
  },
  {
    name: 'Еда',
    isIntersecting: false,
    icons: [
      'french-bread',
      'cake',
      'fish',
      'pizza',
      'ice-cream',
      'chicken',
      'burger',
      'sushi',
      'carrot',
      'coffee',
      'beer',
      'wineee',
    ],
  },
  {
    name: 'Кухня',
    isIntersecting: false,
    icons: [
      'chef',
      'shovel',
      'knife',
      'kitchen-scale',
      'fork',
      'spoon',
      'cooking-pot',
      'knifefork',
      'cooking-book',
      'washer',
      'fridge',
      'gas-stove',
      'iron',
      'mixer',
      'kettle',
    ],
  },
  {
    name: 'Инструменты',
    isIntersecting: false,
    icons: [
      'roller2',
      'hummer',
      'axe',
      'brush',
      'screwdriver',
      'compass',
      'shovelll',
    ],
  },
  {
    name: 'Гаджеты',
    isIntersecting: false,
    icons: [
      'camera',
      'headphones',
      'airpods',
      'mic',
      'gamepad',
      'mouse',
      'keyboard',
      'printer',
      'usb-storage',
      'watch',
      'iph',
      'tv',
      'laptop',
      'laptop-macbook',
      'generator',
    ],
  },
  {
    name: 'Коммуникация',
    isIntersecting: false,
    icons: [
      'delete-user',
      'calll',
      'maill',
      'adress-book',
      'group-chat',
      'chattt',
      'thumbtack',
      'sad',
      'picturee',
    ],
  },
  {
    name: 'Финансы',
    isIntersecting: false,
    icons: [
      'cart',
      'euro',
      'rouble',
      'dollar',
      'credit-card',
      'safe',
      'calculator',
      'sale',
      'chart-line',
      'wallett',
      'ticket',
    ],
  },
  {
    name: 'Папки',
    isIntersecting: false,
    icons: [
      'folder',
      'deleted-folder',
      'locked-folder',
      'folder-check',
      'file',
      'file-done',
      'file-plus',
      'deleted-file',
      'protected-file',
      'folder-heart',
      'folder-star',
      'user-folder',
    ],
  },
];
