import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import {
  GoalStepsViewMode,
  TargetInterface,
} from '@core/models/target.interface';
import { createSelector } from '@ngrx/store';
import {
  TargetActions,
  TargetActionTypes,
} from '@core/redux/goal/target.actions';
import { changeGoalStepsGroupingAction } from './goals.actions';

export interface State extends EntityState<TargetInterface> {
  ids: string[];
  state: 'loading' | 'not-asked' | 'success' | 'error';
  updateId: string | null;
  selectId: string | null;
  errors: any;
}

export const initialState: State = {
  ids: [],
  state: 'not-asked',
  updateId: null,
  selectId: null,
  entities: {},
  errors: {},
};

export const adapter: EntityAdapter<TargetInterface> = createEntityAdapter<
  TargetInterface
>({
  selectId: (target: TargetInterface) => target.id,
  sortComparer: false,
});

export function reducer(state = initialState, action: TargetActions): State {
  switch (action.type) {
    case changeGoalStepsGroupingAction.type as any:
      const { goalId, view: achievementView } = (action as unknown) as {
        goalId: string;
        view: GoalStepsViewMode;
      };
      return adapter.updateOne(
        { id: goalId, changes: { achievementView } },
        state,
      );
    case TargetActionTypes.TargetSelectAction:
      return Object.assign({}, state, {
        selectId: action.id,
      });

    case TargetActionTypes.TargetLoad:
      return { ...state, state: 'loading' };

    case TargetActionTypes.TargetLoadSuccess:
      return adapter.setAll(action.payload, { ...state, state: 'success' });

    case TargetActionTypes.TargetLoadFailure:
      return {
        ...state,
        state: 'error',
        errors: action.payload.error,
      };

    case TargetActionTypes.TargetCreateAction:
      return Object.assign({}, state, {
        loading: true,
        errors: {},
      });

    case TargetActionTypes.TargetCreateSuccessAction:
      return adapter.addOne(
        action.payload,
        Object.assign({}, state, {
          loading: false,
        }),
      );

    case TargetActionTypes.TargetCreateFailureAction:
      return Object.assign({}, state, {
        loading: false,
        errors: action.payload.error,
      });

    case TargetActionTypes.TargetUpdateAction:
      return Object.assign({}, state, {
        loading: true,
        errors: {},
      });

    case TargetActionTypes.TargetUpdateSuccessAction:
      return Object.assign({}, state, {
        entities: Object.assign({}, state.entities, {
          [action.payload.id]: action.payload,
        }),
        loading: false,
      });

    case TargetActionTypes.TargetUpdateFailureAction:
      return Object.assign({}, state, {
        loading: false,
        errors: action.payload.error,
      });

    case TargetActionTypes.TargetViewAction:
      return Object.assign({}, state, {
        loading: true,
        errors: {},
      });

    case TargetActionTypes.TargetViewSuccessAction:
      return adapter.upsertOne(
        action.payload,
        Object.assign({}, state, {
          loading: false,
          errors: {},
        }),
      );

    case TargetActionTypes.TargetViewFailureAction:
      return Object.assign({}, state, {
        loading: false,
        errors: action.payload.error,
      });

    case TargetActionTypes.TargetDelete:
      return Object.assign({}, state, {
        loading: true,
      });

    case TargetActionTypes.TargetDeleteSuccess:
      return Object.assign({}, state, {
        entities: Object.assign({}, state.entities, {
          [action.payload.id]: action.payload,
        }),
        loading: false,
      });

    case TargetActionTypes.TargetDeleteFailure:
      return Object.assign({}, state, {
        loading: false,
        errors: action.payload.error,
      });

    default:
      return state;
  }
}

export const getEntitiesObjects = (state: State) => state.entities;
export const getIds = (state: State) => state.ids;
export const getSelectId = (state: State) => state.selectId;
export const getLoading = (state: State) => state.state === 'loading';
export const getRequestState = (state: State) => state.state;

export const getCount = createSelector(getIds, (ids) => ids.length);
export const getAll = createSelector(
  getEntitiesObjects,
  getIds,
  (entities, ids) => ids.map((id: string) => entities[id]),
);
export const getSelect = createSelector(
  getEntitiesObjects,
  getSelectId,
  (entities, id) => entities[id],
);

export const getAllActive = createSelector<State, any, TargetInterface[]>(
  getAll,
  (entities) => entities.filter((item) => !item.isDelete),
);
export const getAllActiveNotComplete = createSelector(
  getAllActive,
  (entities) => entities.filter((item) => !item.isComplete),
);
export const getAllActiveForMain = createSelector(getAllActive, (entities) =>
  entities.filter((item) => item.isFocus),
);

export const getErrors = (state: State) => state.errors;
