<div class="ui-note-view">
  <div class="ui-note-view__dateinfo">
    <span class="ui-note-view__cat">
      <ui-icon class="ui-icon" [name]="note.category?.icon"></ui-icon>
      <span class="ui-note-view__cat-name">{{ note.category?.name }}</span>
    </span>
    <note-progress
      *ngIf="note?.targetProgress || note?.target"
      [note]="note"
      class="ui-note-view__progress"
    ></note-progress>
    <!-- <span class="ui-note-view__remind">Напомнить: завтра, в 12:00</span> -->
  </div>

  <div
    *ngIf="note.note"
    [innerHTML]="note.note | safeLinky"
    innerHtmlLink
    class="ui-note-view__content"
  ></div>

  <ui-checklist
    *ngIf="note.isTask"
    (valueChange)="onChecklistChange($event)"
    [value]="note.checklist"
    [isEditable]="false"
    class="ui-note-view__checklist"
  >
  </ui-checklist>

  <div *ngIf="note.target" class="ui-note-view__group">
    <ui-note-view-target [note]="note"></ui-note-view-target>
  </div>

  <div *ngIf="note.files?.length" class="ui-note-view__group">
    <ui-note-view-slider [images]="note.files"></ui-note-view-slider>
  </div>
</div>
