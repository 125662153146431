import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AutocompleteTagsListComponent } from './autocomplete-tags-list.component';
import { AutocompleteTagsDirective } from './autocomplete-tags.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [AutocompleteTagsDirective, AutocompleteTagsListComponent],
  exports: [AutocompleteTagsDirective],
  entryComponents: [AutocompleteTagsListComponent],
})
export class AutocompleteTagsModule {}
