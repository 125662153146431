import { Injectable } from '@angular/core';
import { TooltipService, TooltipConfig } from '@ui/tooltip';
import { TextTooltipForServiceComponent } from './text-tooltip-for-service';
import { TextTooltipData } from './text-tooltip-data';

@Injectable({
  providedIn: 'root',
})
export class TextTooltipService {
  constructor(private readonly tooltipService: TooltipService) {}

  show(config: TooltipConfig<TextTooltipData>) {
    if (!config.relativeTo) {
      throw new Error('relaviteTo must be set');
    }
    return this.tooltipService.open<
      TextTooltipData,
      TextTooltipForServiceComponent
    >(TextTooltipForServiceComponent, config);
  }
}
