import { TariffActions, TariffActionTypes } from './tariff.actions';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { TariffInterface, emptyTariff } from '@core/models/tariff.interface';

export interface State extends EntityState<TariffInterface> {
  ids: string[];
  loading: boolean;
}

export const initialState: State = {
  ids: [],
  loading: false,
  entities: {},
};

export const adapter: EntityAdapter<TariffInterface> = createEntityAdapter<
  TariffInterface
>({
  selectId: (tariffType: TariffInterface) => tariffType.id,
  sortComparer: false,
});

export function reducer(state = initialState, action: TariffActions): State {
  switch (action.type) {
    case TariffActionTypes.GetAllAction: {
      return { ...state, loading: true };
    }

    case TariffActionTypes.GetAllSuccessAction:
      return adapter.setMany(action.payload, {
        ...state,
        loading: false,
      });

    case TariffActionTypes.GetAllFailureAction:
      return { ...state, loading: false };

    default:
      return state;
  }
}

export const { selectAll: getAll } = adapter.getSelectors();
export const minPriceTariff = (state: State) =>
  Object.values(state.entities).reduce(
    (smallest, next) => (next.price < smallest.price ? next : smallest),
    emptyTariff({ price: Infinity }),
  );
