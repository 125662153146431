import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { CategoryInterface } from '@core/models/category.interface';
import { createSelector, createFeatureSelector } from '@ngrx/store';
import {
  CategoryActions,
  CategoryActionTypes,
} from '@core/redux/category/category.actions';

export interface State extends EntityState<CategoryInterface> {
  ids: string[];
  selectedId: string;
  loading: boolean;
}

export const initialState: State = {
  ids: [],
  selectedId: null,
  loading: false,
  entities: {},
};

export const adapter: EntityAdapter<CategoryInterface> = createEntityAdapter<
  CategoryInterface
>({
  selectId: (category: CategoryInterface) => category.id,
  sortComparer: false,
});

export function reducer(state = initialState, action: CategoryActions): State {
  switch (action.type) {
    case CategoryActionTypes.CategorySelect:
      return Object.assign({}, state, {
        selectedId: action.payload,
      });

    case CategoryActionTypes.CategoryUnSelect:
      return Object.assign({}, state, {
        selectedId: null,
      });

    case CategoryActionTypes.CategoryLoad:
      return Object.assign({}, state, {
        loading: true,
      });

    case CategoryActionTypes.CategoryLoadSuccess:
      return adapter.setAll(
        action.payload,
        Object.assign({}, state, {
          loading: false,
        }),
      );

    case CategoryActionTypes.CategoryLoadFailure:
      return Object.assign({}, state, {
        loading: false,
        errors: action.payload.error,
      });

    case CategoryActionTypes.CategoryCreate:
      return Object.assign({}, state, {
        loading: true,
        errors: {},
      });

    case CategoryActionTypes.CategoryCreateSuccess:
      return adapter.addOne(
        action.payload,
        Object.assign({}, state, {
          loading: false,
        }),
      );

    case CategoryActionTypes.CategoryCreateFailure:
      return Object.assign({}, state, {
        loading: false,
        errors: action.payload.error,
      });

    case CategoryActionTypes.CategoryUpdate:
      return Object.assign({}, state, {
        loading: true,
        errors: {},
      });

    case CategoryActionTypes.CategoryUpdateSuccess:
      return Object.assign({}, state, {
        entities: Object.assign({}, state.entities, {
          [action.payload.id]: action.payload,
        }),
        loading: false,
      });

    case CategoryActionTypes.CategoryUpdateFailure:
      return Object.assign({}, state, {
        loading: false,
        errors: action.payload.error,
      });

    case CategoryActionTypes.CategoryDelete:
      return Object.assign({}, state, {
        loading: true,
        errors: {},
      });

    case CategoryActionTypes.CategoryDeleteSuccess:
      return adapter.removeOne(
        action.id,
        Object.assign({}, state, {
          loading: false,
        }),
      );

    case CategoryActionTypes.CategoryDeleteFailure:
      return Object.assign({}, state, {
        loading: false,
        errors: action.payload.error,
      });

    default:
      return state;
  }
}

export const getIds = (state: State) => state.ids;
export const getSelectedId = (state: State) => state.selectedId;
export const getIsLoading = (state: State) => state.loading;
export const getEntitiesObjects = (state: State) => state.entities;
export const getEntities = createSelector(
  getEntitiesObjects,
  getIds,
  (entities, ids) => ids.map((id: string) => entities[id]),
);
export const getCategoryState = createFeatureSelector<State>('category');
export const getCategoryById = createSelector(
  getCategoryState,
  (obj: State, props: { categoryId: string }) => obj.entities[props.categoryId],
);
export const getSelectedEntity = createSelector(
  getEntitiesObjects,
  getSelectedId,
  (entities, selectedId) => entities[selectedId],
);
