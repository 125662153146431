<button
  (click)="onStoriesClick()"
  [id]="storiesPlayButtonId"
  [disabled]="(shouldDisable$ | async) === true"
  ui-button-icon
  class="header-button"
  type="button"
>
  <ng-container
    *ngIf="storiesCount$ | async as storiesCount; else noStoriesTemplate"
  >
    <ui-icon name="play-stories"></ui-icon>
    <span class="header-button__counter">
      {{ storiesCount }}
    </span>
  </ng-container>
  <ng-template #noStoriesTemplate>
    <ui-icon name="play-outlined"></ui-icon>
  </ng-template>
</button>
