import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { NoteInterface } from '@core/models/note.interface';

@Component({
  selector: 'note-progress',
  templateUrl: './note-progress.component.html',
  styleUrls: ['./note-progress.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NoteProgressComponent {
  @Input()
  note: NoteInterface;

  @Input()
  circleTopOffset = 0;
}
