import { select, Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import * as fromRoot from '@core/redux/index';
import * as CategoryActions from '@core/redux/category/category.actions';
import {
  CategoryLoad,
  CategoryLoadSuccess,
  CategorySelect,
} from '@core/redux/category/category.actions';
import {
  CategoryDeleteModel,
  CategoryInterface,
  CategoryQueryModel,
} from '@core/models/category.interface';
import { concatMap, filter, take } from 'rxjs/operators';
import { getCategoryById } from './category.reducer';
import { Actions, ofType } from '@ngrx/effects';
import { Observable, of, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CategoryService {
  filterQuery: CategoryQueryModel;

  constructor(
    private readonly actions: Actions,
    private readonly store: Store<fromRoot.State>,
  ) {
    this.resetFilterQuery();
  }

  getAll() {
    return this.store.pipe(
      select(fromRoot.getCategoryList),
      filter((_) => !!_.length),
    );
  }

  getById(categoryId: string) {
    return this.store.pipe(select(getCategoryById, { categoryId }));
  }

  getCategorySelectedId() {
    return this.store.pipe(select(fromRoot.getCategorySelectedId));
  }

  getCategorySelected() {
    return this.store.pipe(select(fromRoot.getCategorySelected));
  }

  getLoadingState() {
    return this.store.pipe(select(fromRoot.getCategoryIsLoading));
  }

  resetFilterQuery() {
    this.filterQuery = new CategoryQueryModel();
  }

  dispatchAll() {
    this.store.dispatch(new CategoryLoad(this.filterQuery));
  }

  dispatchAllSuccess(data) {
    this.store.dispatch(new CategoryLoadSuccess(data));
  }

  select(value) {
    this.store.dispatch(new CategorySelect(value));
  }

  delete(model: CategoryDeleteModel) {
    this.store.dispatch(new CategoryActions.CategoryDelete(model));
  }

  update(model: CategoryInterface): Observable<CategoryInterface> {
    this.store.dispatch(new CategoryActions.CategoryUpdate(model));
    return this.actions.pipe(
      ofType(
        CategoryActions.CategoryActionTypes.CategoryUpdateSuccess,
        CategoryActions.CategoryActionTypes.CategoryUpdateFailure,
      ),
      take(1),
      concatMap((action: CategoryActions.CategoryActions) =>
        action.type ===
        CategoryActions.CategoryActionTypes.CategoryUpdateFailure
          ? throwError(action.payload)
          : of((action as CategoryActions.CategoryUpdateSuccess).payload),
      ),
    );
  }

  create(model: CategoryInterface): Observable<CategoryInterface> {
    this.store.dispatch(new CategoryActions.CategoryCreate(model));
    return this.actions.pipe(
      ofType(
        CategoryActions.CategoryActionTypes.CategoryCreateSuccess,
        CategoryActions.CategoryActionTypes.CategoryCreateFailure,
      ),
      take(1),
      concatMap((action: CategoryActions.CategoryActions) =>
        action.type ===
        CategoryActions.CategoryActionTypes.CategoryCreateFailure
          ? throwError(action.payload)
          : of((action as CategoryActions.CategoryCreateSuccess).payload),
      ),
    );
  }
}
