import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiService } from '@core/services/api.service';
import {
  Onboarding,
  UserOnboardingStep,
} from '@core/models/identity.interface';
import { Store } from '@ngrx/store';
import * as fromRoot from '@core/redux/index';

@Injectable({
  providedIn: 'root',
})
export class ApiUserOnboardingService extends ApiService {
  constructor(private store: Store<fromRoot.State>, http: HttpClient) {
    super(http);
  }

  passed(action: UserOnboardingStep) {
    return this.http.put<Onboarding>(
      this.getApiUrl('onboarding/passed/' + action),
      {},
    );
  }

  saveProfession(profession: { profession: string }) {
    return this.http.put<Onboarding>(
      this.getApiUrl('users/profession'),
      profession,
    );
  }

  saveScope(scope: { scope: string[] }) {
    return this.http.put<Onboarding>(this.getApiUrl('users/save-scope'), scope);
  }
}
