import {
  Input,
  Component,
  HostBinding,
  OnChanges,
  SimpleChanges,
  ViewEncapsulation,
  ChangeDetectionStrategy,
} from '@angular/core';
import { TooltipArrowPosition } from '@ui/tooltip';
import { getArrowPositionClass } from './helpers';

@Component({
  selector: 'rt-tooltip',
  templateUrl: './text-tooltip.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TextTooltipForTemplateComponent implements OnChanges {
  @Input()
  text: string;

  @Input()
  actions?: () => void;

  @Input()
  arrowPosition: TooltipArrowPosition = 'bottom';

  @Input()
  hasCloseButton = false;

  @HostBinding('class.rt-tooltip')
  ngClass = true;

  arrowPositionClass = getArrowPositionClass(this.arrowPosition);

  ngOnChanges(changes: SimpleChanges) {
    if ('arrowPosition' in changes) {
      this.arrowPositionClass = getArrowPositionClass(this.arrowPosition);
    }
  }

  // todo
  onCloseClick() {
    throw new Error('TextTooltip2Component.onCloseClick is not implemented');
  }
}
