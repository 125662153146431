import { createAction, props } from '@ngrx/store';
import { Story } from './story.model';

export const getStories = createAction('[Stories] Get Stories');
export const getStoriesSuccess = createAction(
  '[Stories API] Get Stories Success',
  props<{ stories: Story[] }>(),
);
export const getStoriesFailure = createAction(
  '[Stories API] Get Stories Failure',
  props<{ error: any }>(),
);

export const markStoryAsViewed = createAction(
  '[Stories] Mark Story As Viewed',
  props<{ id: string }>(),
);
export const markStoryAsViewedSuccess = createAction(
  '[Stories API] Mark Story As Viewed Success',
  props<{ story: Story }>(),
);
export const markStoryAsViewedFailute = createAction(
  '[Stories API] Mark Story As Viewed Failure',
  props<{ error: any }>(),
);
