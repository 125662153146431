<div class="ui-tuition" style="left: 20px;top:30px;max-width: 328px">
  <span class="ui-tuition__arrow" style="left:-10px;top: 25px;"></span>
  <span class="ui-tuition__arrow" style="left:25px;top: -8px;"></span>
  <span class="ui-tuition__arrow" style="right:-10px;top: 30px;"></span>
  <span class="ui-tuition__arrow" style="right: 30px;bottom: -10px;"></span>
  <div class="ui-tuition__wrap">
    <div class="ui-tuition__head">
      <div class="ui-tuition__number">
        {{ step }}
      </div>
      <span class="ui-tuition__title">
        {{ (step === 1 ? 'UX.TUITION.T1' : 'UX.TUITION.T2') | translate }}
      </span>
    </div>
    <div class="ui-tuition__content">
      <p *ngIf="step === 1" class="ui-tuition__paragraph">
        {{ 'UX.TUITION.P1_1' | translate }}
      </p>
      <p *ngIf="step === 1" class="ui-tuition__paragraph">
        {{ 'UX.TUITION.P1_2' | translate }}
      </p>
      <p *ngIf="step === 2" class="ui-tuition__paragraph">
        {{ 'UX.TUITION.P2_1' | translate }}
      </p>
    </div>
    <div class="ui-tuition__buttons">
      <button *ngIf="step === 2" (click)="onBack()" ui-button-secondary>
        {{ '$.BACK' | translate }}
      </button>
      <button (click)="onNext()" ui-button-primary>
        {{ (step === 1 ? 'UX.TUITION.NEXT' : 'UX.TUITION.OK') | translate }}
      </button>
    </div>
  </div>
</div>
